import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Dialog,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import Transition from "../../tools/transition";

import { connect } from "react-redux";
import {
  createAction,
  clearAction,
  updateAction,
} from "../../../actions/action-template";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
  formButton: {
    marginRight: "10px",
  },
  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
});

class DistritoFederalDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      isOpenDialog: true,

      entidadId: "",
      id: "",
      cabecera: "",
    };
  }

  showError = (value) => {
    return this.state.submitted && !value ? true : false;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { cabecera, entidadId } = this.state;
    if (cabecera && entidadId) {
      if (!this.state.id) {
        this.props.createAction("postDistritoFederal", {
          entidadId,
          cabecera,
        });
      } else {
        this.props.updateAction("updateDistritoFederal", this.state.id, {
          id: this.state.id,
          entidadId,
          cabecera,
        });
      }
    } else {
      this.props.enqueueSnackbar("Debe ingresar los campos marcados con *", {
        variant: "warning",
      });
    }
  };

  componentDidUpdate() {
    const {
      createDistritoFederal,
      createDistritoFederalError,
      distritoFederal,
      getDistritoFederal,
      getDistritoFederalError,
      updateDistritoFederalError,
      updateDistritoFederal,
    } = this.props;

    if (createDistritoFederalError) {
      this.props.enqueueSnackbar(createDistritoFederalError, {
        variant: "error",
      });
      this.props.clearAction("clearDistritoFederal");
    } else if (createDistritoFederal) {
      this.props.clearAction("clearDistritoFederal");

      this.setState(
        {
          isOpenDialog: false,
        },
        () => {
          this.props.resultOp(distritoFederal);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (updateDistritoFederalError) {
      this.props.enqueueSnackbar(updateDistritoFederalError, {
        variant: "error",
      });
      this.props.clearAction("clearDistritoFederal");
    } else if (updateDistritoFederal) {
      this.props.clearAction("clearDistritoFederal");

      this.setState(
        {
          isOpenDialog: false,
        },
        () => {
          this.props.resultOp(distritoFederal);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (getDistritoFederalError) {
      this.props.enqueueSnackbar(getDistritoFederalError, {
        variant: "error",
      });
      this.props.clearAction("clearDistritoFederal");
    } else if (getDistritoFederal) {
      this.props.clearAction("clearDistritoFederal");
      this.setState({
        id: distritoFederal.id,
        cabecera: distritoFederal.cabecera,
        entidadId: distritoFederal.entidadId,
      });
    }
  }

  renderContent() {
    const { cabecera, entidadId, id } = this.state;
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item xs={12} md={6}>
          <FormControl size="small" className={classes.formControl}>
            <InputLabel id="lblEntidad">Entidad</InputLabel>
            <Select
              disabled
              labelId="lblEntidad"
              id="entidadId"
              value={entidadId}
              name="entidadId"
              onChange={this.handleChange}
            >
              {this.props.entidadCatalog.map((p) => {
                return (
                  <MenuItem key={p.value} value={p.value}>
                    {p.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.formControl}
            error={this.showError(id)}
            required
            label="Distrito Federal"
            name="id"
            autoFocus
            value={id}
            onChange={this.handleChange}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.formControl}
            error={this.showError(cabecera)}
            required
            label="Cabecera"
            name="cabecera"
            autoFocus
            value={cabecera}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>

        {isBrowser && (
          <Grid item xs={12}>
            <Box align="right" pt={4}>
              <Button
                className={classes.formButton}
                variant="contained"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
              >
                cancelar
              </Button>

              <Button
                className={classes.formButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.handleSubmit();
                }}
              >
                Guardar
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  }

  renderBrowser() {
    return (
      <Box p={10}>
        <Typography variant="h4">Detalle Distrito Federal</Typography>
        {this.renderContent()}
      </Box>
    );
  }

  renderMobile() {
    const { classes } = this.props;
    return (
      <Box>
        <Dialog
          fullScreen
          open={this.state.isOpenDialog}
          onClose={() => this.setState({ isOpenDialog: false })}
          TransitionComponent={Transition}
          scroll="body"
          style={{ paddingTop: 40 }}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Detalle Distrito Federal
              </Typography>
            </Toolbar>
          </AppBar>
          <Box py={3} px={1}>
            {this.renderContent()}
            <div style={{ marginBottom: 100 }}></div>
          </Box>
          <AppBar
            position="fixed"
            color="primary"
            className={classes.appBottomBar}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  cancelar
                </Typography>
              </IconButton>
              <div className={classes.grow} />

              <IconButton
                edge="end"
                color="inherit"
                onClick={() => {
                  this.handleSubmit();
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  Guardar
                </Typography>
              </IconButton>
            </Toolbar>
          </AppBar>
        </Dialog>
      </Box>
    );
  }

  render() {
    return (
      <Box>
        <BrowserView>{this.renderBrowser()}</BrowserView>
        <MobileView>{this.renderMobile()}</MobileView>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    createDistritoFederal: state.distritoFederalState.createDistritoFederal,
    createDistritoFederalError:
      state.distritoFederalState.createDistritoFederalError,
    distritoFederal: state.distritoFederalState.distritoFederal,
    getDistritoFederal: state.distritoFederalState.getDistritoFederal,
    getDistritoFederalError: state.distritoFederalState.getDistritoFederalError,

    updateDistritoFederal: state.distritoFederalState.updateDistritoFederal,
    updateDistritoFederalError:
      state.distritoFederalState.updateDistritoFederalError,
    entidadCatalog: state.entidadState.entidadCatalog,
  };
};

export default connect(mapStatetoProps, {
  createAction,
  clearAction,
  updateAction,
})(withStyles(useStyles)(withSnackbar(DistritoFederalDetail)));
