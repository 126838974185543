import React from "react";
import {
  Box,
  Grid,
  Typography,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  Paper,
  ButtonGroup,
  Button,
  TablePagination,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";
//import AddIcon from "@material-ui/icons/AddCircleOutline";
import { connect } from "react-redux";

import ConfirmDialog from "../../tools/confirm-dialog";
import { withSnackbar } from "notistack";
import SearchIcon from "@material-ui/icons/Search";
import AutorenewIcon from "@material-ui/icons/Autorenew";

import {
  getAllAction,
  deleteAction,
  clearAction,
  getAction,
  getCatalogAction,
} from "../../../actions/action-template";

const useStyles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
});

class DistritoFederalList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 0,
      pageSize: 10,
      entidadFiltro: "0",
      cabeceraFiltro: "",

      isDeleteDialogOpen: false,
      selectedIdForDelete: "",

      titleConfirm: "Desactivar Distrito Federal",
      textConfirm: "¿Realmente desea desactivar el distrito federal?",

      entidades: [],
    };
    this.props.getCatalogAction("getEntidadCatalogo");
    this.fetchList();
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  fetchList() {
    const { pageNumber, pageSize, cabeceraFiltro, entidadFiltro } = this.state;
    let filtro = `cabecera=${cabeceraFiltro}&entidadId=${entidadFiltro}`;

    this.props.getAllAction(
      "getAllDistritosFederales",
      pageNumber,
      pageSize,
      filtro
    );
  }

  onDeleteDialogClose = (res) => {
    if (res) {
      this.props.deleteAction(
        "deleteDistritoFederal",
        this.state.selectedIdForDelete
      );
    }
    this.setState({ isDeleteDialogOpen: false, selectedIdForDelete: "" });
  };

  clearFilter = () => {
    this.setState(
      {
        cabeceraFiltro: "",
        entidadFiltro: "0",
      },
      () => {
        this.fetchList();
      }
    );
  };

  confirmDelete = (r) => {
    let textConfirm = "";
    let titleConfirm = "";

    if (r.estatus) {
      titleConfirm = "Desactivar Distritos Federal";
      textConfirm = "¿Realmente desea desactivar el distrito federal?";
    } else {
      titleConfirm = "Activar Distrito Federal";
      textConfirm = "¿Realmente desea activar el distrito deferal?";
    }
    this.setState({
      isDeleteDialogOpen: true,
      selectedIdForDelete: r.id,
      textConfirm: textConfirm,
      titleConfirm: titleConfirm,
    });
  };

  getKey(d) {
    return `${d.entidadId}-${d.id}`;
  }
  renderItems() {
    return this.props.distritosFederales.map((r) => {
      return (
        <TableRow key={this.getKey(r)}>
          <TableCell>{r.entidad}</TableCell>
          <TableCell>{r.cabecera}</TableCell>
          <TableCell align="center">{r.id}</TableCell>
          <TableCell>
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button
                color="primary"
                title="Editar"
                onClick={() => {
                  let filtro = `entidadId=${r.entidadId}`;
                  this.props.getAction("getDistritoFederal", r.id, filtro);
                  this.props.showDetailItem();
                }}
              >
                <EditIcon></EditIcon>
              </Button>
            </ButtonGroup>
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { classes } = this.props;
    const { cabeceraFiltro, entidadFiltro } = this.state;
    return (
      <Box mx={2} mb={4}>
        <Typography variant="h2" gutterBottom>
          Distritos Federales
        </Typography>
        <Paper
          style={{
            marginBottom: 20,
            padding: 20,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <FormControl size="small" className={classes.formControl}>
                <InputLabel id="lblEntidad">Entidad</InputLabel>
                <Select
                  style={{ width: "200px", marginRight: "5px" }}
                  labelId="lblEntidad"
                  id="entidadFiltro"
                  value={entidadFiltro}
                  name="entidadFiltro"
                  onChange={this.handleChange}
                >
                  {this.props.entidadCatalog.map((p) => {
                    return (
                      <MenuItem key={p.value} value={p.value}>
                        {p.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                size="small"
                name="cabeceraFiltro"
                label="Cabecera"
                value={cabeceraFiltro}
                onChange={this.handleChange}
                style={{ marginRight: 5 }}
              />

              <ButtonGroup
                color="primary"
                aria-label="Acciones"
                style={{ marginTop: 10, marginLeft: 10 }}
                size="small"
              >
                <Button
                  title="Filtrar"
                  color="primary"
                  onClick={() => {
                    this.fetchList();
                  }}
                >
                  <SearchIcon />
                </Button>
                <Button
                  title="Limpiar filtros"
                  color="primary"
                  onClick={this.clearFilter}
                >
                  <AutorenewIcon />
                </Button>

                {/* <Button
                  title="Nuevo distrito federal"
                  color="primary"
                  onClick={this.props.showDetailItem}
                >
                  <AddIcon />
                </Button> */}
              </ButtonGroup>
            </Grid>
          </Grid>
        </Paper>
        <TableContainer component={Paper}>
          <Table
            size="small"
            className={classes.table}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Entidad</TableCell>
                <TableCell>Cabecera</TableCell>
                <TableCell align="center">Distrito Federal</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{this.renderItems()}</TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={this.props.totalList}
            rowsPerPage={this.state.pageSize}
            page={this.state.pageNumber}
            onChangePage={(e, newPage) => {
              this.setState({ pageNumber: newPage }, () => {
                this.fetchList();
              });
            }}
            onChangeRowsPerPage={(e) => {
              this.setState({ pageSize: parseInt(e.target.value, 10) }, () => {
                this.fetchList();
              });
            }}
          />
        </TableContainer>

        <ConfirmDialog
          isDeleteDialogOpen={this.state.isDeleteDialogOpen}
          onDeleteDialogClose={this.onDeleteDialogClose}
          title={this.state.titleConfirm}
          message={this.state.textConfirm}
        ></ConfirmDialog>
      </Box>
    );
  }

  componentDidUpdate() {
    const { delDistritoFederal, delDistritoFederalError } = this.props;

    if (delDistritoFederalError) {
      this.props.enqueueSnackbar(delDistritoFederalError, {
        variant: "error",
      });
      this.props.clearAction("clearDistritoFederal");
    } else if (delDistritoFederal) {
      this.props.clearAction("clearDistritoFederal");
      this.fetchList();
      this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
        variant: "success",
      });
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    distritosFederales: state.distritoFederalState.distritosFederales,
    totalList: state.distritoFederalState.totalList,

    delDistritoFederal: state.distritoFederalState.delDistritoFederal,
    delDistritoFederalError: state.distritoFederalState.delDistritoFederalError,
    entidadCatalog: state.entidadState.entidadCatalog,
  };
};

export default connect(mapStatetoProps, {
  getCatalogAction,
  getAllAction,
  deleteAction,
  clearAction,
  getAction,
})(withStyles(useStyles)(withSnackbar(DistritoFederalList)));
