import React from "react";
import {
  Box,
  Grid,
  Typography,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  Paper,
  ButtonGroup,
  Button,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import ConfirmDialog from "../tools/confirm-dialog";
import { withSnackbar } from "notistack";
import SearchIcon from "@material-ui/icons/Search";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CancelIcon from "@material-ui/icons/Cancel";

import {
  getAllAction,
  deleteAction,
  clearAction,
  getAction,
  getCatalogAction,
} from "../../actions/action-template";
import {
  establecerCliente,
  establecerCampania,
} from "../../actions/preferencias/preferencias-actions";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import BarChartIcon from "@material-ui/icons/BarChart";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    width: "95%",
    marginRight: "5px",
  },
});

class EncuestaList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clienteId:
        this.props.customerId !== ""
          ? this.props.customerId
          : this.props.preferenciaClienteId,
      campaniaId:
        this.props.campaniaId !== ""
          ? this.props.campaniaId
          : this.props.preferenciaCampaniaId > 0
          ? this.props.preferenciaCampaniaId
          : "",

      pageNumber: 0,
      pageSize: 10,

      nombreFiltro: "",

      isDeleteDialogOpen: false,
      selectedIdForDelete: "",

      titleConfirm: "Desactivar Encuesta",
      textConfirm: "¿Realmente desea eliminar?",
    };
    this.props.getCatalogAction("getClienteCatalogo");
    this.props.getCatalogAction("getTipoPreguntaCatalogo");
    this.fetchList();
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === "clienteId") {
        this.props.establecerCliente(value);
        this.props.getCatalogAction(
          "getCampaniaCatalogo",
          `clienteId=${value}`
        );
      } else if (name === "campaniaId") {
        this.props.establecerCampania(value);
      }
    });
  };

  fetchList() {
    const { pageNumber, pageSize, campaniaId, nombreFiltro } = this.state;
    if (campaniaId) {
      let filtro = `campaniaId=${campaniaId}&nombre=${nombreFiltro}`;
      trackPromise(
        this.props.getAllAction("getAllEncuestas", pageNumber, pageSize, filtro)
      );
    }
  }

  onDeleteDialogClose = (res) => {
    if (res) {
      this.props.deleteAction("deleteEncuesta", this.state.selectedIdForDelete);
    }
    this.setState({ isDeleteDialogOpen: false, selectedIdForDelete: "" });
  };

  clearFilter = () => {
    this.setState(
      {
        entidadFiltro: "0",
        nombreFiltro: "",
      },
      () => {
        this.fetchList();
      }
    );
  };

  confirmDelete = (r) => {
    let textConfirm = "";
    let titleConfirm = "";

    // if (r.estatus) {
    //   titleConfirm = "Eliminar Encuesta";
    //   textConfirm = "¿Realmente desea desactivar el colonia?";
    // } else {
    titleConfirm = "Eliminar Encuesta";
    textConfirm = "¿Realmente desea eliminar el registro?";
    //}
    this.setState({
      isDeleteDialogOpen: true,
      selectedIdForDelete: r.id,
      textConfirm: textConfirm,
      titleConfirm: titleConfirm,
    });
  };

  padStart(d) {
    return d.toString().padStart(2, "0");
  }
  getUTCDate = (date) => {
    let d = new Date(date);
    if (d !== "-") {
      return (
        this.padStart(d.getDate()) +
        "/" +
        this.padStart(d.getMonth() + 1) +
        "/" +
        d.getFullYear()
        //  +
        // " " +
        // d.getHours() +
        // ":" +
        // d.getMinutes()
      );
    } else return d;
  };
  getVigencia(r) {
    return `${this.getUTCDate(r.inicioVigencia)}  ${this.getUTCDate(
      r.finVigencia
    )}`;
  }

  getStatus(r) {
    return r.esPublicada ? "Activa" : "Inactiva";
  }
  renderItems() {
    return this.props.encuestas.map((r) => {
      return (
        <TableRow key={r.id}>
          <TableCell>{r.nombre}</TableCell>
          <TableCell>{this.getVigencia(r)}</TableCell>
          <TableCell>{this.getStatus(r)}</TableCell>
          <TableCell>{r.totalParticipantes}</TableCell>

          <TableCell>
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button
                color="secondary"
                title="Eliminar"
                onClick={() => {
                  this.confirmDelete(r);
                }}
              >
                <CancelIcon></CancelIcon>
              </Button>
              <Button
                color="primary"
                title="Ver resumen"
                onClick={() => {
                  this.props.getAction(
                    "getEncuestaResumen",
                    r.id,
                    "esFiltro=false"
                  );
                  this.props.showResumenItem(r.id);
                }}
              >
                <BarChartIcon />
              </Button>
              <Button
                color="primary"
                title="Editar"
                onClick={() => {
                  this.props.getAction("getEncuesta", r.id);
                  this.props.showDetailItem();
                }}
              >
                <EditIcon></EditIcon>
              </Button>
            </ButtonGroup>
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { classes } = this.props;
    const { nombreFiltro, clienteId, campaniaId } = this.state;
    return (
      <Box mx={2} mb={4}>
        <Typography variant="h2" gutterBottom>
          Encuestas
        </Typography>
        <Paper
          style={{
            marginBottom: 20,
            padding: 20,
          }}
        >
          <Grid container>
            {!this.props.customerId && (
              <Grid item xs={6} md={3}>
                <FormControl size="small" className={classes.formControl}>
                  <InputLabel id="lblCliente">Cliente</InputLabel>
                  <Select
                    labelId="lblCliente"
                    value={clienteId}
                    name="clienteId"
                    onChange={this.handleChange}
                  >
                    {this.props.clienteCatalog.map((p) => {
                      return (
                        <MenuItem key={p.value} value={p.value}>
                          {p.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {!this.props.campaniaId && (
              <Grid item xs={6} md={3}>
                <FormControl size="small" className={classes.formControl}>
                  <InputLabel id="lblCampania">Campaña</InputLabel>
                  <Select
                    labelId="lblCampania"
                    value={campaniaId}
                    name="campaniaId"
                    onChange={this.handleChange}
                  >
                    {this.props.campaniaCatalog.map((p) => {
                      return (
                        <MenuItem key={p.id} value={p.id}>
                          {p.nombre}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                name="nombreFiltro"
                label="Nombre"
                value={nombreFiltro}
                onChange={this.handleChange}
                style={{ marginRight: 5 }}
              />

              <ButtonGroup
                color="primary"
                aria-label="Acciones"
                style={{ marginTop: 10, marginLeft: 10 }}
                size="small"
              >
                <Button
                  title="Filtrar"
                  color="primary"
                  onClick={() => {
                    this.fetchList();
                  }}
                >
                  <SearchIcon />
                </Button>
                <Button
                  title="Limpiar filtros"
                  color="primary"
                  onClick={this.clearFilter}
                >
                  <AutorenewIcon />
                </Button>

                <Button
                  title="Nueva Encuesta"
                  color="primary"
                  onClick={this.props.showDetailItem}
                >
                  <AddIcon />
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Paper>
        <TableContainer component={Paper}>
          <Table
            size="small"
            className={classes.table}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Vigencia</TableCell>
                <TableCell>Estatus</TableCell>
                <TableCell>Participantes</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{this.renderItems()}</TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={this.props.totalList}
            rowsPerPage={this.state.pageSize}
            page={this.state.pageNumber}
            onChangePage={(e, newPage) => {
              this.setState({ pageNumber: newPage }, () => {
                this.fetchList();
              });
            }}
            onChangeRowsPerPage={(e) => {
              this.setState({ pageSize: parseInt(e.target.value, 10) }, () => {
                this.fetchList();
              });
            }}
          />
        </TableContainer>

        <ConfirmDialog
          isDeleteDialogOpen={this.state.isDeleteDialogOpen}
          onDeleteDialogClose={this.onDeleteDialogClose}
          title={this.state.titleConfirm}
          message={this.state.textConfirm}
        ></ConfirmDialog>
      </Box>
    );
  }

  componentDidUpdate() {
    const { delEncuesta, delEncuestaError } = this.props;

    if (delEncuestaError) {
      this.props.enqueueSnackbar(delEncuestaError, {
        variant: "error",
      });
      this.props.clearAction("clearEncuesta");
    } else if (delEncuesta) {
      this.props.clearAction("clearEncuesta");
      this.fetchList();
      this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
        variant: "success",
      });
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    encuestas: state.encuestaState.encuestas,
    totalList: state.encuestaState.totalList,

    delEncuesta: state.encuestaState.delEncuesta,
    delEncuestaError: state.encuestaState.delEncuestaError,

    campaniaCatalog: state.campaniaState.campaniaCatalog,
    clienteCatalog: state.customerState.clienteCatalog,

    customerId: state.authState.user.customerId,
    campaniaId: state.authState.user.campaniaId,

    preferenciaClienteId: state.preferenciasGeneralState.clienteId,
    preferenciaCampaniaId: state.preferenciasGeneralState.campaniaId,
  };
};

export default connect(mapStatetoProps, {
  getCatalogAction,
  getAllAction,
  deleteAction,
  clearAction,
  getAction,
  establecerCliente,
  establecerCampania,
})(withStyles(useStyles)(withSnackbar(EncuestaList)));
