import React from "react";
import { Link } from "react-router-dom";

import {
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  AppBar,
  Toolbar,
  Dialog,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  recoveryPasswordAction,
  clearLogin,
} from "../../../actions/auth/auth-actions";
import Transition from "../../tools/transition";

import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import emailValidator from "../../validators/email-validator";

import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  root: {
    minWidth: 400,
    maxWidth: 400,
  },
  content: {
    padding: 30,
  },
  main: {
    minHeight: "80vh",
  },
  spacingTop: {
    paddingTop: 30,
  },
  spacingRight: {
    marginRight: theme.spacing(1),
  },
  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
});

class RecoveryPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      submitted: false,
      isOpenDialog: true,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  showError(value) {
    return this.state.submitted && !value ? true : false;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    const { username } = this.state;
    if (username && emailValidator(username)) {
      trackPromise(this.props.recoveryPasswordAction(username)).then(() => {
        this.setState({ submitted: false });
      });
    } else {
      this.props.enqueueSnackbar("Debe ingresar los campos marcados con *", {
        variant: "warning",
      });
    }
  };

  renderContent() {
    const { username } = this.state;
    const { classes } = this.props;

    return (
      <form noValidate autoComplete="off">
        <TextField
          error={
            this.showError(this.state.username) ||
            (!emailValidator(this.state.username) && this.state.submitted)
          }
          margin="normal"
          className={classes.spacingRight}
          required
          fullWidth
          id="username"
          label="Email"
          name="username"
          autoComplete="email"
          autoFocus
          helperText={
            !emailValidator(this.state.username) && this.state.submitted
              ? "Ingrese un correo electrónico válido"
              : "Ingrese su correo electrónico con que se registró"
          }
          value={username}
          onChange={this.handleChange}
        />
        {isBrowser && (
          <Grid
            container
            direction="row"
            justify="center"
            className={classes.spacingTop}
          >
            <Button
              component={Link}
              to="/login"
              type="button"
              className={classes.spacingRight}
            >
              Cancelar
            </Button>
            <Button
              disabled={this.state.submitted}
              type="submit"
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Enviar
            </Button>
          </Grid>
        )}
      </form>
    );
  }

  componentDidUpdate() {
    const { recoveryPassword, recoveryPasswordError } = this.props;
    if (recoveryPasswordError) {
      this.props.enqueueSnackbar(recoveryPasswordError, {
        variant: "warning",
      });
      this.props.clearLogin();
    }
    if (recoveryPassword) {
      this.props.enqueueSnackbar(
        "Se ha enviado un correo electrónico para recuperar su contraseña!",
        {
          variant: "success",
        }
      );
      this.props.clearLogin();

      this.props.history.push("/login");
    }
  }

  renderBrowser() {
    const { classes } = this.props;

    return (
      <Card className={classes.root}>
        <CardHeader title="Recuperar contraseña"></CardHeader>
        <CardContent className={classes.content}>
          {this.renderContent()}
        </CardContent>
      </Card>
    );
  }

  renderMobile() {
    const { classes } = this.props;

    return (
      <Dialog
        fullScreen
        open={this.state.isOpenDialog}
        onClose={() => this.setState({ isOpenDialog: false })}
        TransitionComponent={Transition}
        scroll="body"
        style={{ paddingTop: 40 }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Recuperar contraseña
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justify="center"
          className={classes.main}
        >
          <Grid item>
            {this.renderContent()}
            <div style={{ marginBottom: 100 }}></div>
          </Grid>
        </Grid>

        <AppBar
          position="fixed"
          color="primary"
          className={classes.appBottomBar}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              component={Link}
              to="/login"
              aria-label="close"
            >
              <Typography variant="button" display="block" gutterBottom>
                Cancelar
              </Typography>
            </IconButton>
            <div className={classes.grow} />

            <IconButton
              disabled={this.state.submitted}
              edge="end"
              color="inherit"
              onClick={this.handleSubmit}
              aria-label="close"
            >
              <Typography variant="button" display="block" gutterBottom>
                Enviar
              </Typography>
            </IconButton>
          </Toolbar>
        </AppBar>
      </Dialog>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justify="center"
        className={classes.main}
      >
        <Grid item>
          <BrowserView>{this.renderBrowser()}</BrowserView>
          <MobileView>{this.renderMobile()}</MobileView>
        </Grid>
      </Grid>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    recoveryPasswordError: state.authState.recoveryPasswordError,
    recoveryPassword: state.authState.recoveryPassword,
  };
};

export default connect(mapStatetoProps, {
  clearLogin,
  recoveryPasswordAction,
})(withStyles(useStyles)(withSnackbar(RecoveryPassword)));
