const defaultState = {
  postInteraccion: {},
  postInteracciones: [],
  totalList: 0,
  createPostInteraccion: false,
  createPostInteraccionError: "",
  delPostInteraccion: false,
  delPostInteraccionError: "",
  getPostInteraccion: false,
  getPostInteraccionError: "",
  updatePostInteraccion: false,
  updatePostInteraccionError: "",
};

export const postInteraccionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_POST_INTERACCIONES":
      return {
        ...state,
        postInteracciones: action.payload.data,
        totalList: action.payload.totalRegs,
      };

    case "CREATE_POST_INTERACCION":
      return {
        ...state,
        postInteraccion: { ...action.payload },
        createPostInteraccion: true,
      };
    case "CREATE_POST_INTERACCION_ERROR":
      return { ...state, createPostInteraccionError: action.payload.error };
    case "UPDATE_POST_INTERACCION":
      return {
        ...state,
        postInteraccion: { ...action.payload },
        updatePostInteraccion: true,
      };
    case "UPDATE_POST_INTERACCION_ERROR":
      return { ...state, updatePostInteraccionError: action.payload.error };
    case "CLEAR_POST_INTERACCION":
      return {
        ...state,
        postInteraccion: {},
        postInteracciones: [],
        createPostInteraccion: false,
        createPostInteraccionError: "",
        delPostInteraccion: false,
        delPostInteraccionError: "",
        getPostInteraccion: false,
        getPostInteraccionError: "",
        updatePostInteraccion: false,
        updatePostInteraccionError: "",
      };
    case "DEL_POST_INTERACCION":
      return {
        ...state,
        delPostInteraccion: true,
      };
    case "DEL_POST_INTERACCION_ERROR":
      return { ...state, delPostInteraccionError: action.payload.error };
    case "GET_POST_INTERACCION":
      return {
        ...state,
        getPostInteraccion: true,
        post: { ...action.payload },
      };
    case "GET_POST_INTERACCION_ERROR":
      return { ...state, getPostInteraccionError: action.payload.error };

    default:
      return {
        ...state,
      };
  }
};
