import React from "react";
import { Bar } from "@nivo/bar";

class BarChart extends React.Component {
  render() {
    let { leyendaLateral, leyendaAbajo } = this.props;

    return (
      <Bar
        colors={{ scheme: "nivo" }}
        //  indexScale={{ type: "band", round: true }}
        padding={0.3}
        margin={{ top: 10, right: 0, bottom: 40, left: 60 }}
        width={500}
        height={350}
        data={this.props.data}
        keys={this.props.keys}
        //   groupMode="grouped"
        indexBy={this.props.index}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: leyendaLateral,
          legendPosition: "middle",
          legendOffset: -40,
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: leyendaAbajo,
          legendPosition: "middle",
          legendOffset: 32,
        }}
        // animate={true}
        // motionStiffness={90}
        // motionDamping={15}
      />
    );
  }
}

export default BarChart;
