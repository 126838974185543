const modulos = {
  Clientes: 1,
  Usuarios: 2,
  Roles: 3,
  Entidades: 4,
  DistritosFederales: 5,
  Municipios: 6,
  DistritosLocales: 7,
  Secciones: 8,
  Colonias: 9,
  Activados: 10,
  Dashboard: 11,
  Encuestas: 12,
  Posts: 13,
  Preferencias: 14,
  Territorio: 15,
  Arbol: 16,
  Tareas: 17,
  PublicacionesReportadas: 18,
};

export default modulos;
