import React from "react";
import DistritoLocalList from "./distrito-local-list";
import DistritoLocalDetail from "./distrito-local-detail";

class DistritosLocales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listVisible: true,
    };
  }

  showDetailItem = () => {
    this.setState({ listVisible: false });
  };

  resultOp = (o) => {
    this.setState({ listVisible: true });
  };

  render() {
    const { listVisible } = this.state;
    if (listVisible)
      return (
        <DistritoLocalList
          showDetailItem={this.showDetailItem}
          updateItem={this.updateItem}
        ></DistritoLocalList>
      );
    else
      return (
        <DistritoLocalDetail resultOp={this.resultOp}></DistritoLocalDetail>
      );
  }
}

export default DistritosLocales;
