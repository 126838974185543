const defaultState = {
  createEntidad: false,
  createEntidadError: "",
  entidad: {},
  delEntidad: false,
  delEntidadError: "",
  getEntidadError: "",

  entidades: [],
  entidadCatalog: [],

  totalList: 0,
};

export const entidadReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_ENTIDADES":
      return {
        ...state,
        entidades: action.payload.data,
        totalList: action.payload.totalRegs,
      };
    case "FETCH_ENTIDAD-CATALOG":
      return {
        ...state,
        entidadCatalog: action.payload,
      };

    case "CREATE_ENTIDAD":
      return {
        ...state,
        entidad: { ...action.payload },
        createEntidad: true,
      };
    case "CREATE_ENTIDAD_ERROR":
      return { ...state, createEntidadError: action.payload.error };
    case "UPDATE_ENTIDAD":
      return {
        ...state,
        entidad: { ...action.payload },
        updateEntidad: true,
      };
    case "UPDATE_ENTIDAD_ERROR":
      return { ...state, updateEntidadError: action.payload.error };
    case "CLEAR_ENTIDAD":
      return {
        ...state,
        entidad: {},
        createEntidad: false,
        createEntidadError: "",
        delEntidad: false,
        delEntidadError: "",
        getEntidad: false,
        getEntidadError: "",
        updateEntidad: false,
        updateEntidadError: "",
      };
    case "DEL_ENTIDAD":
      return { ...state, delEntidad: true };
    case "DEL_ENTIDAD_ERROR":
      return { ...state, delEntidadError: action.payload.error };
    case "GET_ENTIDAD":
      return { ...state, getEntidad: true, entidad: { ...action.payload } };
    case "GET_ENTIDAD_ERROR":
      return { ...state, getEntidadError: action.payload.error };
    default:
      return {
        ...state,
      };
  }
};
