import { combineReducers } from "redux";
import { authReducer } from "./auth-reducer";
import { customerReducer } from "./administration/custormer-reducer";
import { entidadReducer } from "./administration/entidad-reducer";
import { distritoFederalReducer } from "./administration/distrito-federal-reducer";
import { municipioReducer } from "./administration/municipio-reducer";
import { distritoLocalReducer } from "./administration/distrito-local-reducer";
import { seccionReducer } from "./administration/seccion-reducer";
import { usuarioReducer } from "./administration/usuario-reducer";
import { rolReducer } from "./administration/rol-reducer";
import { moduloReducer } from "./administration/modulo-reducer";
import { campaniaReducer } from "./administration/campanias-reducer";
import { ambitoTerritorialReducer } from "./administration/ambito-territorial-reducer";
import { activadoReducer } from "./administration/activado-reducer";
import { coloniaReducer } from "./administration/colonia-reducer";
import { datosPersonalesReducer } from "./administration/datos-personales-reducer";
import { metasReducer } from "./administration/meta-reducer";
import { autoRegistroReducer } from "./administration/auto-registro-reducer";
import { generoReducer } from "./administration/genero-reducer";
import { tipoPreguntaReducer } from "./administration/tipo-pregunta-reducer";
import { preguntaReducer } from "./administration/pregunta-reducer";
import { opcionPreguntaReducer } from "./administration/opcion-pregunta-reducer";
import { avanceReducer } from "./administration/avance-reducer";
import { encuestaReducer } from "./administration/encuesta-reducer";
import { miEncuestaReducer } from "./administration/mis-encuestas-reducer";
import { preferenciasGeneralReducer } from "./preferencias-general-reducer";
import { agenteReducer } from "./administration/agente-reducer";
import { encuestaUsuarioReducer } from "./administration/encuesta-usuario-reducer";
import { postReducer } from "./administration/post-reducer";
import { misPostReducer } from "./administration/mis-posts-reducer";
import { postInteraccionReducer } from "./administration/post-interaccion-reducer";
import { preferenciaReducer } from "./administration/preferencia-reducer";
import { misPreferenciasReducer } from "./administration/mis-preferencias-reducer";
import { encuestaResumenReducer } from "./administration/encuesta-resumen-reducer";
import { usuarioTerritorioReducer } from "./administration/usuario-territorio-reducer";
import { storageReducer } from "./administration/storage-reducer";
import { arbolReducer } from "./administration/arbol-reducer";
import { campaniaTareaReducer } from "./administration/campania-tarea-reducer";
import { reportarReducer } from "./administration/reportar-reducer";

export default combineReducers({
  authState: authReducer,
  customerState: customerReducer,
  entidadState: entidadReducer,
  distritoFederalState: distritoFederalReducer,
  municipioState: municipioReducer,
  distritoLocalState: distritoLocalReducer,
  seccionState: seccionReducer,
  usuarioState: usuarioReducer,
  rolState: rolReducer,
  moduloState: moduloReducer,
  campaniaState: campaniaReducer,
  ambitoTerritorialState: ambitoTerritorialReducer,
  activadoState: activadoReducer,
  coloniaState: coloniaReducer,
  datosPersonalesState: datosPersonalesReducer,
  metasState: metasReducer,
  autoRegistroState: autoRegistroReducer,
  generoState: generoReducer,
  avanceState: avanceReducer,
  encuestaState: encuestaReducer,
  preferenciasGeneralState: preferenciasGeneralReducer,
  tipoPreguntaState: tipoPreguntaReducer,
  preguntaState: preguntaReducer,
  opcionPreguntaState: opcionPreguntaReducer,
  agenteState: agenteReducer,
  miEncuestaState: miEncuestaReducer,
  encuestaUsuarioState: encuestaUsuarioReducer,
  postState: postReducer,
  misPostsState: misPostReducer,
  postInteraccionState: postInteraccionReducer,
  preferenciaState: preferenciaReducer,
  misPreferenciaState: misPreferenciasReducer,
  encuestaResumenState: encuestaResumenReducer,
  usuarioTerritorioState: usuarioTerritorioReducer,
  storageState: storageReducer,
  arbolState: arbolReducer,
  campaniaTareaState: campaniaTareaReducer,
  reportarState: reportarReducer,
});
