const defaultState = {
  encuesta: {},
  encuestas: [],
  totalList: 0,
  encuestaOffline: {},
  createEncuesta: false,
  createEncuestaError: "",
  delEncuesta: false,
  delEncuestaError: "",
  getEncuesta: false,
  getEncuestaOffline: false,
  getEncuestaError: "",
  updateEncuesta: false,
  updateEncuestaError: "",
};

export const encuestaReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_ENCUESTAS":
      return {
        ...state,
        encuestas: action.payload.data,
        totalList: action.payload.totalRegs,
      };

    case "CREATE_ENCUESTA":
      return {
        ...state,
        encuesta: { ...action.payload },
        createEncuesta: true,
      };
    case "CREATE_ENCUESTA_ERROR":
      return { ...state, createEncuestaError: action.payload.error };
    case "UPDATE_ENCUESTA":
      return {
        ...state,
        encuesta: { ...action.payload },
        updateEncuesta: true,
      };
    case "UPDATE_ENCUESTA_ERROR":
      return { ...state, updateEncuestaError: action.payload.error };
    case "CLEAR_ENCUESTA":
      return {
        ...state,
        encuesta: {},
        encuestaOffline: {},
        createEncuesta: false,
        createEncuestaError: "",
        delEncuesta: false,
        delEncuestaError: "",
        getEncuesta: false,
        getEncuestaOffline: false,
        getEncuestaError: "",
        updateEncuesta: false,
        updateEncuestaError: "",
      };
    case "DEL_ENCUESTA":
      return { ...state, delEncuesta: true };
    case "DEL_ENCUESTA_ERROR":
      return { ...state, delEncuestaError: action.payload.error };
    case "GET_ENCUESTA":
      return { ...state, getEncuesta: true, encuesta: { ...action.payload } };
    case "GET_ENCUESTA_ERROR":
      return { ...state, getEncuestaError: action.payload.error };
    case "GET_ENCUESTA_OFFLINE":
      return {
        ...state,
        getEncuestaOffline: true,
        encuestaOffline: { ...action.payload },
      };
    default:
      return {
        ...state,
      };
  }
};
