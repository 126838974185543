import React from "react";
import MetaList from "./campania-meta-list";

class CampaniaMetas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listVisible: true,
    };
  }

  showDetailItem = () => {
    this.setState({ listVisible: false });
  };

  resultOp = (o) => {
    this.setState({ listVisible: true });
  };

  render() {
    const { listVisible } = this.state;
    if (listVisible)
      return (
        <MetaList
          showDetailItem={this.showDetailItem}
          updateItem={this.updateItem}
          campania={this.props.campania}
          resultOp={this.props.resultOp}
        ></MetaList>
      );
  }
}

export default CampaniaMetas;
