import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Dialog,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import Transition from "../../tools/transition";
import emailValidator from "../../validators/email-validator";
import "react-html5-camera-photo/build/css/index.css";
import WebCamera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import { connect } from "react-redux";
import {
  createAction,
  clearAction,
  updateAction,
  getCatalogAction,
} from "../../../actions/action-template";
import CameraIcon from "@material-ui/icons/CameraAlt";
import { createWorker } from "tesseract.js";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import CircularProgressLabel from "../../tools/circular-progress-label";
import getGeoReference from "../../../apis/geo-reference-api";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
  formButton: {
    marginRight: "10px",
  },
  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
});

class ActivadoDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      isOpenDialog: true,

      nombres: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      email: "",
      telefono: "",
      claveElectoral: "",
      ocr: "",
      folio: "",
      seccion: "",

      calle: "",
      numeroExterior: "",
      numeroInterior: "",
      colonia: "",
      codigoPostal: "",
      generoId: "",
      edad: "",

      isTakingPhoto: false,
      avanceFoto: 0,
      prevFoto: "",
      crop: {},

      isRegOffline: false,
    };
    this.props.getCatalogAction("getGeneroCatalogo");

    this.worker = React.createRef();
  }

  updateProgressAndLog(m) {
    var MAX_PARCENTAGE = 1;
    var DECIMAL_COUNT = 2;
    if (m.status === "recognizing text") {
      var pctg = (m.progress / MAX_PARCENTAGE) * 100;
      this.setState({
        avanceFoto: parseFloat(pctg.toFixed(DECIMAL_COUNT)),
      });
    }
  }

  componentDidMount() {
    // Logs the output object to Update Progress, which
    // checks for Tesseract JS status & Updates the progress
    this.worker = createWorker({
      logger: (m) => this.updateProgressAndLog(m),
    });
  }

  showError = (value) => {
    return this.state.submitted && !value ? true : false;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleChangeNumber = (e) => {
    const { name, value } = e.target;
    let number = parseInt(value);
    number = Number.isNaN(number) ? "" : value;
    this.setState({ [name]: number });
  };

  saveOffline(datos) {
    if (this.props.db) {
      datos.nombreCompleto = `${datos.nombres} ${datos.apellidoPaterno} ${datos.apellidoMaterno}`;
      if (this.state.id > 0) {
        datos.id = this.state.id;
      }
      this.props.db.putValue("activados", datos).then((e) => {
        this.setState(
          {
            isOpenDialog: false,
          },
          () => {
            this.props.resultOp(datos);
          }
        );
      });
    }
  }

  handleSubmit = (e) => {
    const {
      nombres,
      apellidoPaterno,
      apellidoMaterno,
      email,
      telefono,
      claveElectoral,
      ocr,
      folio,
      seccion,
      calle,
      numeroExterior,
      numeroInterior,
      colonia,
      codigoPostal,
      emailUsuarioInvitacion,
      generoId,
      edad,
      campaniaId,
      isRegOffline,
    } = this.state;
    if (
      nombres &&
      apellidoPaterno &&
      email &&
      telefono &&
      emailValidator(email) &&
      (folio || claveElectoral || ocr) &&
      seccion
    ) {
      this.setState({ submitted: true });

      if (!this.props.isOnline || isRegOffline) {
        this.saveOffline({
          nombres,
          apellidoPaterno,
          apellidoMaterno,
          email,
          telefono,
          claveElectoral,
          ocr,
          folio,
          seccion,
          calle,
          numeroExterior,
          numeroInterior,
          colonia,
          codigoPostal,
          emailUsuarioInvitacion,
          generoId,
          edad,
          isRegOffline: true,
          sincronizado: false,
          sincronizando: false,
          erroresSincronizacion: "",
        });
        return;
      }
      getGeoReference({
        calle,
        numeroExterior,
        numeroInterior,
        colonia,
        codigoPostal,
      }).then((geoReferencia) => {
        if (!this.state.id) {
          trackPromise(
            this.props.createAction("postActivado", {
              nombres,
              campaniaId,
              apellidoPaterno,
              apellidoMaterno,
              email,
              telefono,
              claveElectoral,
              ocr,
              folio,
              seccion,
              calle,
              numeroExterior,
              numeroInterior,
              colonia,
              codigoPostal,
              generoId,
              edad: edad === "" ? 0 : edad,
              ...geoReferencia,
            }),
            () => {
              this.setState({ submitted: false });
            }
          );
        } else {
          trackPromise(
            this.props.updateAction("updateActivado", this.state.id, {
              id: this.state.id,
              nombres,
              campaniaId,
              apellidoPaterno,
              apellidoMaterno,
              email,
              telefono,
              claveElectoral,
              ocr,
              folio,
              seccion,
              calle,
              numeroExterior,
              numeroInterior,
              colonia,
              codigoPostal,
              emailUsuarioInvitacion,
              generoId,
              edad: edad === "" ? 0 : edad,
              ...geoReferencia,
            }),
            () => {
              this.setState({ submitted: false });
            }
          );
        }
      });
    } else {
      this.props.enqueueSnackbar("Debe ingresar los campos marcados con *", {
        variant: "warning",
      });
    }
  };

  dataURItoBlob = (dataURI) => {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  };

  handleTakePhoto = (dataUri) => {
    //let blob = this.dataURItoBlob(dataUri);
    setTimeout(() => {
      this.setState({ isTakingPhoto: false, prevFoto: dataUri });
    }, 1000);
    // this.procesaFoto(dataUri).then((e) => {
    //   this.setState({ ...e });
    // });
  };

  procesaFoto = async (dataUri) => {
    await this.worker.load();

    await this.worker.loadLanguage("eng+spa");
    await this.worker.initialize("eng+spa");
    return this.worker.recognize(dataUri).then((res) => {
      return this.procesaDatos(res);
    });
    // .then((e) => {
    //   this.setState({ ...e });
    // });

    //   this.worker.terminate();
  };

  procesaDatos = (d) => {
    let datos = {};
    let tipo = {
      nombre: 0,
      domicilio: 0,
      claveElector: 0,
      seccion: 0,
    };
    for (let i = 0; i < d.data.lines.length; i++) {
      const l = d.data.lines[i];
      if (l.text.toUpperCase().indexOf("NOM") >= 0) tipo.nombre = i;
      if (l.text.toUpperCase().indexOf("DOM") >= 0) tipo.domicilio = i;
      if (l.text.toUpperCase().indexOf("CLAV") >= 0) tipo.claveElector = i;
      if (l.text.toUpperCase().indexOf("SECC") >= 0) tipo.seccion = i;
    }
    let confidenceMin = 80;

    //Nombre
    if (tipo.nombre >= 0) {
      let ap = d.data.lines[tipo.nombre + 1].words.filter(
        (e) => e.confidence > confidenceMin
      );
      ap = ap.length > 0 ? ap.map((e) => e.text).join(" ") : "";
      let am = d.data.lines[tipo.nombre + 2].words.filter(
        (e) => e.confidence > confidenceMin
      );
      am = am.length > 0 ? am.map((e) => e.text).join(" ") : "";

      let nom = "";
      if (tipo.nombre + 3 < tipo.domicilio) {
        nom = d.data.lines[tipo.nombre + 3].words.filter(
          (e) => e.confidence > confidenceMin
        );
        nom = nom.length > 0 ? nom.map((e) => e.text).join(" ") : "";
      }

      datos.nombres = nom;
      datos.apellidoPaterno = ap;
      datos.apellidoMaterno = am;
    } else {
      datos.nombres = "";
      datos.apellidoPaterno = "";
      datos.apellidoMaterno = "";
    }

    if (tipo.domicilio >= 0) {
      let calle = d.data.lines[tipo.domicilio + 1].words.filter(
        (e) => e.confidence > confidenceMin
      );
      calle = calle.length > 0 ? calle.map((e) => e.text).join(" ") : "";
      datos.calle = calle;

      let col = d.data.lines[tipo.domicilio + 2].words.filter(
        (e) =>
          e.confidence > confidenceMin && !Number.isInteger(parseInt(e.text))
      );
      col = col.length > 0 ? col.map((e) => e.text).join(" ") : "";
      datos.colonia = col;

      let cp = d.data.lines[tipo.domicilio + 2].words.filter(
        (e) =>
          e.confidence > confidenceMin && Number.isInteger(parseInt(e.text))
      );
      cp = cp.length > 0 ? cp.map((e) => e.text).join(" ") : "";
      datos.codigoPostal = cp;
    } else {
      datos.codigoPostal = "";
      datos.colonia = "";
      datos.calle = "";
    }

    if (tipo.claveElector >= 0) {
      let clave = d.data.lines[tipo.claveElector].words.filter(
        (e) => e.confidence > confidenceMin && e.text.length > 10
      );
      clave = clave.length > 0 ? clave.map((e) => e.text).join(" ") : "";
      datos.claveElectoral = clave;
    } else {
      datos.claveElectoral = "";
    }

    if (tipo.seccion >= 0) {
      let seccion = d.data.lines[tipo.seccion].words.filter(
        (e) =>
          e.confidence > confidenceMin && Number.isInteger(parseInt(e.text))
      );
      seccion = seccion[seccion.length - 1];
      datos.seccion = seccion?.text;
    } else {
      datos.seccion = "";
    }
    //   d.data.lines.map();
    // setTimeout(() => {
    //   this.setState({ ...datos });
    // }, 500);

    return datos;
  };

  componentDidUpdate() {
    const {
      createActivado,
      createActivadoError,
      activado,
      activadoOffline,
      getActivado,
      getActivadoOffline,
      getActivadoError,
      updateActivadoError,
      updateActivado,
    } = this.props;

    if (createActivadoError) {
      this.props.enqueueSnackbar(createActivadoError, {
        variant: "error",
      });
      this.setState({ submitted: false });
      this.props.clearAction("clearActivado");
    } else if (createActivado) {
      this.props.clearAction("clearActivado");

      this.setState(
        {
          isOpenDialog: false,
        },
        () => {
          this.props.resultOp(activado);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (updateActivadoError) {
      this.props.enqueueSnackbar(updateActivadoError, {
        variant: "error",
      });
      this.setState({ submitted: false });

      this.props.clearAction("clearActivado");
    } else if (updateActivado) {
      this.props.clearAction("clearActivado");

      this.setState(
        {
          isOpenDialog: false,
        },
        () => {
          this.props.resultOp(activado);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (getActivadoError) {
      this.props.enqueueSnackbar(getActivadoError, {
        variant: "error",
      });
      this.props.clearAction("clearActivado");
    } else if (getActivado) {
      this.props.clearAction("clearActivado");
      this.setState({
        ...activado,
      });
    }

    if (getActivadoOffline) {
      this.props.db.getValue("activados", activadoOffline.id).then((d) => {
        this.setState({
          ...d,
        });
      });

      this.props.clearAction("clearActivado");
    }
  }

  getCroppedImg(normalizedImage) {
    const { crop } = this.state;

    const canvas = document.createElement("canvas");
    const scaleX = normalizedImage.naturalWidth / normalizedImage.width;
    const scaleY = normalizedImage.naturalHeight / normalizedImage.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      normalizedImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise((resolve, reject) => {
      let res = canvas.toDataURL("image/png");
      resolve(res);
    });
  }

  renderContent() {
    const {
      nombres,
      apellidoPaterno,
      apellidoMaterno,
      email,
      telefono,
      claveElectoral,
      ocr,
      folio,
      seccion,
      calle,
      numeroExterior,
      numeroInterior,
      colonia,
      codigoPostal,
      generoId,
      edad,
      isRegOffline,
      prevFoto,
      crop,
    } = this.state;
    const { classes } = this.props;
    return (
      <Grid container>
        {isMobile && (
          <Grid item xs={12}>
            <Box align="center" m={1}>
              <Button
                fullWidth
                color="primary"
                startIcon={<CameraIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    isTakingPhoto: true,
                    ocr: "",
                    avanceFoto: 0,
                    cortarImagen: true,
                  });
                }}
              >
                Capturar imagen
              </Button>
            </Box>
            {/* <Box mx={10}>
              {this.state.avanceFoto > 0 && this.state.avanceFoto < 100 && (
                <LinearProgress
                  variant="determinate"
                  value={this.state.avanceFoto}
                />
              )}
            </Box> */}
            {/* <img src={prevFoto} height="300"></img> */}
          </Grid>
        )}
        <Grid item xs={12}>
          <Box style={{ display: "none" }}>
            <ReactCrop
              src={prevFoto}
              crop={crop}
              onChange={(newCrop) => {
                this.setState({ crop: newCrop });
              }}
              onImageLoaded={(image) => {
                this.setState(
                  {
                    crop: {
                      // width: image.width - 20,
                      // height: image.height * 0.5,
                      // x: 10,
                      // y: parseInt(image.height * 0.25),
                      width: image.width - 1,
                      height: image.height - 1,
                      x: 1,
                      y: 1,
                    },
                  },
                  () => {
                    if (this.state.cortarImagen) {
                      this.getCroppedImg(image).then((cropped) => {
                        this.setState(
                          {
                            // prevFoto: cropped,
                            procesandoFoto: true,
                            cortarImagen: false,
                          },
                          () => {
                            this.procesaFoto(cropped).then((datos) => {
                              this.setState({ ...datos });
                            });
                          }
                        );
                      });
                    }
                  }
                );
                return false; // Return false when setting crop state in here.
              }}
            />
          </Box>
        </Grid>
        {this.state.procesandoFoto && (
          <Grid item xs={12}>
            <Box align="center">
              <Typography variant="caption" display="block">
                Progreso:
              </Typography>
              <CircularProgressLabel value={this.state.avanceFoto} />
            </Box>
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(nombres)}
            required
            label="Nombre(s)"
            name="nombres"
            autoFocus
            value={nombres}
            inputProps={{ maxLength: 128 }}
            onChange={this.handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(apellidoPaterno)}
            required
            label="Apellido Paterno"
            name="apellidoPaterno"
            value={apellidoPaterno}
            inputProps={{ maxLength: 128 }}
            onChange={this.handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Apellido Materno"
            name="apellidoMaterno"
            value={apellidoMaterno}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl size="small" className={classes.formControl}>
            <InputLabel id="lblGenero">Género</InputLabel>
            <Select
              labelId="lblGenero"
              id="generoId"
              value={generoId}
              name="generoId"
              onChange={this.handleChange}
            >
              {this.props.generoCatalog.map((p) => {
                return (
                  <MenuItem key={p.value} value={p.value}>
                    {p.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Edad"
            name="edad"
            value={edad}
            inputProps={{ maxLength: 2 }}
            type="text"
            onChange={this.handleChangeNumber}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={!emailValidator(email) && this.state.submitted}
            required
            label="Correo Electrónico"
            name="email"
            value={email}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
            helperText={
              !emailValidator(email) && this.state.submitted
                ? "Ingrese un correo electrónico válido"
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(telefono)}
            required
            label="Teléfono"
            name="telefono"
            value={telefono}
            onChange={this.handleChange}
            inputProps={{ maxLength: 64 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={3}>
            <Typography variant="caption" color="primary">
              Datos Electorales*
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={
              this.showError(claveElectoral) &&
              this.showError(ocr) &&
              this.showError(folio)
            }
            label="Clave Electoral"
            name="claveElectoral"
            value={claveElectoral}
            onChange={this.handleChange}
            inputProps={{ maxLength: 64 }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={
              this.showError(claveElectoral) &&
              this.showError(ocr) &&
              this.showError(folio)
            }
            label="OCR"
            name="ocr"
            value={ocr}
            inputProps={{ maxLength: 13 }}
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={
              this.showError(claveElectoral) &&
              this.showError(ocr) &&
              this.showError(folio)
            }
            label="Folio"
            name="folio"
            value={folio}
            onChange={this.handleChange}
            inputProps={{ maxLength: 64 }}
          />
        </Grid>

        <Grid item xs={12}>
          <Box mt={3}>
            <Typography variant="caption" color="primary">
              Dirección (Credencial Elector)
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            className={classes.formControl}
            label="Calle"
            name="calle"
            value={calle}
            inputProps={{ maxLength: 128 }}
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Número exterior"
            name="numeroExterior"
            value={numeroExterior}
            inputProps={{ maxLength: 32 }}
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Número interior"
            name="numeroInterior"
            value={numeroInterior}
            inputProps={{ maxLength: 32 }}
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <TextField
            className={classes.formControl}
            label="Colonia"
            name="colonia"
            value={colonia}
            inputProps={{ maxLength: 128 }}
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Código Postal"
            name="codigoPostal"
            value={codigoPostal}
            inputProps={{ maxLength: 5 }}
            type="text"
            onChange={this.handleChangeNumber}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(seccion)}
            disabled={this.state?.id > 0 && !isRegOffline}
            required
            type="text"
            label="Sección"
            name="seccion"
            value={seccion}
            onChange={this.handleChangeNumber}
            inputProps={{ maxLength: 4 }}
          />
        </Grid>
        {isBrowser && (
          <Grid item xs={12}>
            <Box align="right" pt={4}>
              <Button
                className={classes.formButton}
                variant="contained"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
              >
                Cancelar
              </Button>

              <Button
                disabled={this.state.submitted}
                className={classes.formButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.handleSubmit();
                }}
              >
                Guardar
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  }

  renderBrowser() {
    return (
      <Box p={5}>
        <Typography variant="h4">Detalle Activado</Typography>

        {this.renderContent()}
      </Box>
    );
  }

  renderMobile() {
    const { classes } = this.props;

    return (
      <Dialog
        fullScreen
        open={this.state.isOpenDialog}
        onClose={() => this.setState({ isOpenDialog: false })}
        TransitionComponent={Transition}
        scroll="body"
        style={{ paddingTop: this.state.isTakingPhoto ? 0 : 40 }}
      >
        {this.state.isTakingPhoto && false && (
          <Box
            border={1}
            mx={1}
            style={{
              position: "fixed",
              top: "25%",
              bottom: "25%",
              zIndex: 100000000000000000,
              borderColor: "white",
              borderWidth: 5,
            }}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "transparent",
              }}
            >
              Este el es marco de la camara, para obtener solo una parte de la
              foto y sea mas rapido el proceso ocr
            </Typography>
          </Box>
        )}
        {this.state.isTakingPhoto ? (
          <Grid container>
            <Grid
              item
              xs={12}
              style={
                {
                  // padding: theme.spacing(3, 2),
                  // height: 200,
                  // display: "flex",
                  // flexDirection: "column",
                  // justifyContent: "center",
                }
              }
            >
              <Box
                display="flex"
                style={{
                  backgroundColor: "black",
                  height: "100vh",
                  // paddingTop: "25%",
                  //  paddingBottom: "50%",
                }}
              >
                <WebCamera
                  onTakePhoto={(dataUri) => {
                    this.handleTakePhoto(dataUri);
                  }}
                  //isFullscreen={true}
                  imageType={IMAGE_TYPES.PNG}
                  idealFacingMode={FACING_MODES.ENVIRONMENT}
                  isImageMirror={false}
                  isMaxResolution={true}
                  // idealResolution={{
                  //   width: 640,
                  //   height: 480,
                  // }}
                ></WebCamera>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <Typography variant="h6" className={classes.title}>
                  Detalle Activado
                </Typography>
              </Toolbar>
            </AppBar>

            <Box py={2} px={1} mb={10}>
              {this.renderContent()}
              <div style={{ marginBottom: 100 }}></div>
            </Box>
            <AppBar
              position="fixed"
              color="primary"
              className={classes.appBottomBar}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    this.setState({ isOpenDialog: false }, () => {
                      this.props.resultOp(null);
                    });
                  }}
                  aria-label="close"
                >
                  <Typography variant="button" display="block" gutterBottom>
                    cancelar
                  </Typography>
                </IconButton>
                <div className={classes.grow} />

                <IconButton
                  disabled={this.state.submitted}
                  edge="end"
                  color="inherit"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                  aria-label="close"
                >
                  <Typography variant="button" display="block" gutterBottom>
                    Guardar
                  </Typography>
                </IconButton>
              </Toolbar>
            </AppBar>
          </>
        )}
      </Dialog>
    );
  }

  render() {
    return (
      <Box>
        <BrowserView>{this.renderBrowser()}</BrowserView>
        <MobileView>{this.renderMobile()}</MobileView>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    createActivado: state.activadoState.createActivado,
    createActivadoError: state.activadoState.createActivadoError,
    activado: state.activadoState.activado,
    getActivado: state.activadoState.getActivado,
    activadoOffline: state.activadoState.activadoOffline,
    getActivadoOffline: state.activadoState.getActivadoOffline,
    getActivadoError: state.activadoState.getActivadoError,

    updateActivado: state.activadoState.updateActivado,
    updateActivadoError: state.activadoState.updateActivadoError,

    rolCatalog: state.rolState.rolCatalog,
    generoCatalog: state.generoState.generoCatalog,
  };
};

export default connect(mapStatetoProps, {
  createAction,
  clearAction,
  updateAction,
  getCatalogAction,
})(withStyles(useStyles)(withSnackbar(ActivadoDetail)));
