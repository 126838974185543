const defaultState = {
  pregunta: {},
  preguntaOffline: {},
  createPregunta: false,
  createPreguntaError: "",
  delPregunta: false,
  delPreguntaError: "",
  getPregunta: false,
  getPreguntaOffline: false,
  getPreguntaError: "",
  updatePregunta: false,
  updatePreguntaError: "",

  preguntas: [],

  totalList: 0,
};

export const preguntaReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_PREGUNTAS":
      return {
        ...state,
        preguntas: action.payload.data,
        totalList: action.payload.totalRegs,
      };

    case "CREATE_PREGUNTA":
      return {
        ...state,
        pregunta: { ...action.payload },
        createPregunta: true,
      };
    case "CREATE_PREGUNTA_ERROR":
      return { ...state, createPreguntaError: action.payload.error };
    case "UPDATE_PREGUNTA":
      return {
        ...state,
        pregunta: { ...action.payload },
        updatePregunta: true,
      };
    case "UPDATE_PREGUNTA_ERROR":
      return { ...state, updatePreguntaError: action.payload.error };
    case "CLEAR_PREGUNTA":
      return {
        ...state,
        pregunta: {},
        preguntaOffline: {},
        createPregunta: false,
        createPreguntaError: "",
        delPregunta: false,
        delPreguntaError: "",
        getPregunta: false,
        getPreguntaOffline: false,
        getPreguntaError: "",
        updatePregunta: false,
        updatePreguntaError: "",
      };
    case "DEL_PREGUNTA":
      return { ...state, delPregunta: true };
    case "DEL_PREGUNTA_ERROR":
      return { ...state, delPreguntaError: action.payload.error };
    case "GET_PREGUNTA":
      return { ...state, getPregunta: true, pregunta: { ...action.payload } };
    case "GET_PREGUNTA_ERROR":
      return { ...state, getPreguntaError: action.payload.error };
    case "GET_PREGUNTA_OFFLINE":
      return {
        ...state,
        getPreguntaOffline: true,
        preguntaOffline: { ...action.payload },
      };
    default:
      return {
        ...state,
      };
  }
};
