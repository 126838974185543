const defaultState = {
  createCustomer: false,
  createCustomerError: "",
  customer: {},
  delCustomer: false,
  delCustomerError: "",
  getCustomerError: "",

  customers: [],
  clienteCatalog: [],

  totalList: 0,
};

export const customerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_CUSTOMERS":
      return {
        ...state,
        customers: action.payload.data,
        totalList: action.payload.totalRegs,
      };
    case "FETCH_CUSTOMER_CATALOG":
      return {
        ...state,
        clienteCatalog: action.payload,
      };

    case "CREATE_CUSTOMER":
      return {
        ...state,
        customer: { ...action.payload },
        createCustomer: true,
      };
    case "CREATE_CUSTOMER_ERROR":
      return { ...state, createCustomerError: action.payload.error };
    case "UPDATE_CUSTOMER":
      return {
        ...state,
        customer: { ...action.payload },
        updateCustomer: true,
      };
    case "UPDATE_CUSTOMER_ERROR":
      return { ...state, updateCustomerError: action.payload.error };
    case "CLEAR_CUSTOMER":
      return {
        ...state,
        customer: {},
        createCustomer: false,
        createCustomerError: "",
        delCustomer: false,
        delCustomerError: "",
        getCustomer: false,
        getCustomerError: "",
        updateCustomer: false,
        updateCustomerError: "",
      };
    case "DEL_CUSTOMER":
      return { ...state, delCustomer: true };
    case "DEL_CUSTOMER_ERROR":
      return { ...state, delCustomerError: action.payload.error };
    case "GET_CUSTOMER":
      return { ...state, getCustomer: true, customer: { ...action.payload } };
    case "GET_CUSTOMER_ERROR":
      return { ...state, getCustomerError: action.payload.error };
    default:
      return {
        ...state,
      };
  }
};
