import React from "react";
import UsuarioList from "./usuario-list";
import UsuarioDetail from "./usuario-detail";

class Usuarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listVisible: true,
    };
  }

  showDetailItem = () => {
    this.setState({ listVisible: false });
  };

  resultOp = (o) => {
    this.setState({ listVisible: true });
  };

  render() {
    const { listVisible } = this.state;
    if (listVisible)
      return (
        <UsuarioList
          showDetailItem={this.showDetailItem}
          updateItem={this.updateItem}
          clienteId={this.props.clienteId ? this.props.clienteId : ""}
        ></UsuarioList>
      );
    else
      return (
        <UsuarioDetail
          resultOp={this.resultOp}
          clienteId={this.props.clienteId ? this.props.clienteId : ""}
        ></UsuarioDetail>
      );
  }
}

export default Usuarios;
