const defaultState = {
  opcionPregunta: {},
  opcionPreguntaOffline: {},
  createOpcionPregunta: false,
  createOpcionPreguntaError: "",
  delOpcionPregunta: false,
  delOpcionPreguntaError: "",
  getOpcionPregunta: false,
  getOpcionPreguntaOffline: false,
  getOpcionPreguntaError: "",
  updateOpcionPregunta: false,
  updateOpcionPreguntaError: "",

  opcionPreguntas: [],

  totalList: 0,
};

export const opcionPreguntaReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_OPCION_PREGUNTAS":
      return {
        ...state,
        opcionPreguntas: action.payload.data,
        totalList: action.payload.totalRegs,
      };

    case "CREATE_OPCION_PREGUNTA":
      return {
        ...state,
        opcionPregunta: { ...action.payload },
        createOpcionPregunta: true,
      };
    case "CREATE_OPCION_PREGUNTA_ERROR":
      return { ...state, createOpcionPreguntaError: action.payload.error };
    case "UPDATE_OPCION_PREGUNTA":
      return {
        ...state,
        opcionPregunta: { ...action.payload },
        updateOpcionPregunta: true,
      };
    case "UPDATE_OPCION_PREGUNTA_ERROR":
      return { ...state, updateOpcionPreguntaError: action.payload.error };
    case "CLEAR_OPCION_PREGUNTA":
      return {
        ...state,
        opcionPregunta: {},
        opcionPreguntaOffline: {},
        createOpcionPregunta: false,
        createOpcionPreguntaError: "",
        delOpcionPregunta: false,
        delOpcionPreguntaError: "",
        getOpcionPregunta: false,
        getOpcionPreguntaOffline: false,
        getOpcionPreguntaError: "",
        updateOpcionPregunta: false,
        updateOpcionPreguntaError: "",
      };
    case "DEL_OPCION_PREGUNTA":
      return { ...state, delOpcionPregunta: true };
    case "DEL_OPCION_PREGUNTA_ERROR":
      return { ...state, delOpcionPreguntaError: action.payload.error };
    case "GET_OPCION_PREGUNTA":
      return {
        ...state,
        getOpcionPregunta: true,
        opcionPregunta: { ...action.payload },
      };
    case "GET_OPCION_PREGUNTA_ERROR":
      return { ...state, getOpcionPreguntaError: action.payload.error };
    case "GET_OPCION_PREGUNTA_OFFLINE":
      return {
        ...state,
        getOpcionPreguntaOffline: true,
        opcionPreguntaOffline: { ...action.payload },
      };
    default:
      return {
        ...state,
      };
  }
};
