import api from "../../apis/api";
import jwt_decode from "jwt-decode";

export const login = (username, password) => (dispatch) => {
  localStorage.removeItem("token");
  return api
    .post("/Managment/api/Auth/", {
      username: username,
      password: password,
    })
    .then((data) => {
      if (data.data.errors) {
        dispatch({
          type: "LOGIN_ERROR",
          payload: { error: data.data?.errors[0].message },
        });
      } else {
        var decodedData = jwt_decode(data.data.token);
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("lastToken", data.data.token);
        let newData = {
          username: decodedData.nameid,
          userId: decodedData.userId,
          usuarioRolModulos: decodedData.modulos
            ? JSON.parse(decodedData.modulos)
            : [],
          rolId: decodedData.rolId,
          customerId: decodedData.customerId,
          campaniaId: decodedData.campania,
        };
        dispatch({
          type: "LOGIN",
          payload: newData,
        });
      }
    })
    .catch((err) => {
      let error =
        err.success === false ? err.message : "Error al iniciar sesión!";
      dispatch({
        type: "LOGIN_ERROR",
        payload: { error: error },
      });
    });
};

export const recoveryPasswordAction = (login) => (dispatch) => {
  return api
    .get("/Managment/api/Auth/GetRecoveryPassword?login=" + login)
    .then((data) => {
      if (data.data.errors) {
        dispatch({
          type: "RECOVERY_PASSWORD_ERROR",
          payload: { error: data.data?.errors[0].message },
        });
      } else {
        // let newData = {
        //   token: data.data.data,
        // };
        dispatch({
          type: "RECOVERY_PASSWORD",
          payload: {},
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: "RECOVERY_PASSWORD_ERROR",
        payload: { error: "Error recuperar contraseña!" },
      });
    });
};

export const changePasswordAction = (
  login,
  password,
  confirmarPassword,
  token
) => (dispatch) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return api
    .put(
      "/Managment/api/Auth/" + login,
      {
        login,
        password,
        confirmarPassword,
        token,
      },
      config
    )
    .then((data) => {
      if (data.data.errors) {
        dispatch({
          type: "CHANGE_PASSWORD_ERROR",
          payload: { error: data.data?.errors[0].message },
        });
      } else {
        let newData = {
          token: data.data.data,
        };
        dispatch({
          type: "CHANGE_PASSWORD",
          payload: newData,
        });
      }
    })
    .catch((err) => {
      let message = err.message
        ? err.message
        : "Error al establecer la contraseña!";
      dispatch({
        type: "CHANGE_PASSWORD_ERROR",
        payload: { error: message },
      });
    });
};

export const clearLogin = function () {
  return {
    type: "CLEAR_LOGIN",
  };
};

export const clearSendPassword = function () {
  return {
    type: "CLEAR_SEND_PASSWORD",
  };
};

export const setLogedin = function () {
  return {
    type: "SET_LOGED_IN",
  };
};

export const logout = () => {
  return {
    type: "LOGOUT",
  };
};
