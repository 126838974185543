import { Badge, Box, Button, Typography } from "@material-ui/core";
import React from "react";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { getAllAction, clearAction } from "../../actions/action-template";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginRight: "5px",
  },
});

class ActivadoItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaniaId: this.props.campaniaId,
      arbol: [],
      root: this.props.activado?.usuarioId,
      procesado: false,
    };
  }

  obtenerPromovidos = () => {
    const { campaniaId, procesado } = this.state;
    const { activado } = this.props;

    if (procesado) {
      this.setState({ procesado: false, arbol: [] });
    } else {
      let filtro = `campaniaId=${campaniaId}&root=${activado.usuarioId}`;
      trackPromise(this.props.getAllAction("getAllArbol", 0, 0, filtro));
    }
  };

  componentDidUpdate() {
    if (this.props.arbol?.length > 0 && this.props.root === this.state.root) {
      this.props.clearAction("clearArbol");
      let arbol = [...this.props.arbol];
      this.setState({ arbol: arbol, procesado: true });
    }
  }

  renderActivado() {
    return this.state.arbol.map((e) => {
      return (
        <Box ml={2} p={1} key={e.usuarioId}>
          <ConnectedActivadoItem
            campaniaId={this.state.campaniaId}
            activado={e}
            getAllAction={this.props.getAllAction}
            clearAction={this.props.clearAction}
          ></ConnectedActivadoItem>
        </Box>
      );
    });
  }

  render() {
    return (
      <Box borderLeft={0}>
        <Typography variant="overline">
          {this.props.activado?.nombreCompleto}
          {this.props.activado?.totalActivados > 0 && (
            <Button size="small" onClick={this.obtenerPromovidos}>
              <Badge
                overlap="rectangle"
                color="primary"
                badgeContent={this.props.activado.totalActivados}
                style={{ marginRight: 10 }}
              ></Badge>
              {this.state.procesado ? (
                <ExpandLessIcon></ExpandLessIcon>
              ) : (
                <ChevronRightIcon></ChevronRightIcon>
              )}
              {/* 
               
                
              </Badge> */}
            </Button>
          )}
        </Typography>
        {this.renderActivado()}
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    arbol: state.arbolState.arbol,
    root: state.arbolState.root,
  };
};

const ConnectedActivadoItem = connect(mapStatetoProps, {
  getAllAction,
  clearAction,
})(withStyles(useStyles)(withSnackbar(ActivadoItem)));
export default ConnectedActivadoItem;
