const defaultState = {
  createModulo: false,
  createModuloError: "",
  modulo: {},
  delModulo: false,
  delModuloError: "",
  getModuloError: "",

  modulos: [],
  moduloCatalog: [],

  totalList: 0,
};

export const moduloReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_MODULOS":
      return {
        ...state,
        modulos: action.payload.data,
        totalList: action.payload.totalRegs,
      };
    case "FETCH_MODULO_CATALOG":
      return {
        ...state,
        moduloCatalog: action.payload,
      };

    case "CREATE_MODULO":
      return {
        ...state,
        modulo: { ...action.payload },
        createModulo: true,
      };
    case "CREATE_MODULO_ERROR":
      return { ...state, createModuloError: action.payload.error };
    case "UPDATE_MODULO":
      return {
        ...state,
        modulo: { ...action.payload },
        updateModulo: true,
      };
    case "UPDATE_MODULO_ERROR":
      return { ...state, updateModuloError: action.payload.error };
    case "CLEAR_MODULO":
      return {
        ...state,
        modulo: {},
        createModulo: false,
        createModuloError: "",
        delModulo: false,
        delModuloError: "",
        getModulo: false,
        getModuloError: "",
        updateModulo: false,
        updateModuloError: "",
      };
    case "DEL_MODULO":
      return { ...state, delModulo: true };
    case "DEL_MODULO_ERROR":
      return { ...state, delModuloError: action.payload.error };
    case "GET_MODULO":
      return { ...state, getModulo: true, modulo: { ...action.payload } };
    case "GET_MODULO_ERROR":
      return { ...state, getModuloError: action.payload.error };
    default:
      return {
        ...state,
      };
  }
};
