const defaultState = {
  generoCatalog: [],
};

export const generoReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_GENERO_CATALOG":
      return {
        ...state,
        generoCatalog: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
