import React from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  Badge,
  Divider,
  TableContainer,
  TablePagination,
  TextField,
  Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import {
  getAllAction,
  clearAction,
  createAction,
  deleteAction,
  updateAction,
} from "../../actions/action-template";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import { trackPromise } from "react-promise-tracker";
import ReportIcon from "@material-ui/icons/Report";
import ConfirmDialog from "../tools/confirm-dialog";

const useStyles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    width: "95%",
    marginRight: "5px",
  },
  root: {
    marginBottom: "20px",
    width: "100%",
  },
  actions: {
    width: "100%",
  },
  media: {
    minHeight: 300,
  },
});

class MisNoticias extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      misPosts: [],
      pageNumber: 0,
      pageSize: 5,
      totalList: 0,
      obteniendoRegistros: true,
      procesandoInteraccion: false,
      elementoProcesando: "",

      isDeleteDialogOpen: false,
      selectedIdForDelete: "",

      titleConfirm: "Eliminar Publicación",
      textConfirm: "¿Realmente desea eliminar la Publicación?",
    };
  }

  componentDidMount() {
    this.fetchList();
  }

  fetchList() {
    this.setState({ obteniendoRegistros: true }, () => {
      trackPromise(
        this.props.getAllAction(
          "getAllMisPosts",
          this.state.pageNumber,
          this.state.pageSize,
          "notificaciones=" +
            this.props.notificaciones +
            "&publicacionesReportadas=" +
            this.props.publicacionesReportadas
        )
      );
    });
  }

  padStart(d) {
    return d.toString().padStart(2, "0");
  }

  getUTCDate = (date) => {
    let d = new Date(date);
    if (d !== "-") {
      return (
        this.padStart(d.getDate()) +
        "/" +
        this.padStart(d.getMonth() + 1) +
        "/" +
        d.getFullYear()
        //  +
        // " " +
        // d.getHours() +
        // ":" +
        // d.getMinutes()
      );
    } else return d;
  };

  guardaInteraccion = (d, meGusta) => {
    if (d.tieneInteraccion === false) {
      this.setState({ procesandoInteraccion: true }, () => {
        this.props.createAction("postPostInteraccion", {
          usuarioId: "",
          postId: d.id,
          gusta: meGusta,
        });
      });
    } else if (d.tieneInteraccion === true && meGusta === d.interaccion) {
      //eliminar
      this.setState(
        { procesandoInteraccion: true, elementoProcesando: d },
        () => {
          this.props.deleteAction("deletePostInteraccion", d.id);
        }
      );
    } else {
      //actualizar
      this.setState(
        { procesandoInteraccion: true, elementoProcesando: d },
        () => {
          this.props.updateAction("updatePostInteraccion", d.id, {
            postId: d.id,
            gusta: meGusta,
          });
        }
      );
    }
  };

  esVideo(p) {
    if (p && p.toLowerCase().indexOf("youtube") >= 0) return true;
    else return false;
  }

  esImagen(p) {
    let esVideo = this.esVideo(p);
    if (esVideo) return false;
    else if (p) return true;
    else return false;
  }

  handleChange = (e, r) => {
    const { value } = e.target;
    let list = [...this.state.misPosts];
    let post = list.find((e) => e.id === r.id);
    post.motivo = value;
    this.setState({ misPosts: list });
  };

  showError = (value) => {
    return !value ? true : false;
  };

  mostrarReportarPublicacion(r) {
    let list = [...this.state.misPosts];
    let post = list.find((e) => e.id === r.id);
    post.esReportar = true;
    this.setState({
      misPosts: list,
    });
  }

  cancelarReportarPublicacion(r) {
    let list = [...this.state.misPosts];
    let post = list.find((e) => e.id === r.id);
    post.esReportar = false;
    this.setState({
      misPosts: list,
    });
  }

  reportarPublicacion = (r) => {
    if (!r.motivo) {
      this.props.enqueueSnackbar("Debe ingresar el motivo del reporte", {
        variant: "warning",
      });
      return;
    }
    let data = {
      postId: r.id,
      motivo: r.motivo,
      reporteValido: false,
    };
    this.props.createAction("postReportar", data);
  };

  onDeleteDialogClose = (res) => {
    if (res) {
      this.props.deleteAction("deletePost", this.state.selectedIdForDelete);
    }
    this.setState({ isDeleteDialogOpen: false, selectedIdForDelete: "" });
  };

  confirmDelete = (e, r) => {
    let textConfirm = "";
    let titleConfirm = "";

    titleConfirm = "Elimnar Registro";
    textConfirm = "¿Realmente desea eliminar el registro?";
    this.setState({
      isDeleteDialogOpen: true,
      selectedIdForDelete: r.id,
      textConfirm: textConfirm,
      titleConfirm: titleConfirm,
    });
  };

  desactivarUsuario = (e, r) => {
    this.props.deleteAction("deleteUsuario", r.usuarioId, false);
  };
  renderMotivos(r) {
    return r.reportes.map((o) => {
      return (
        <Box key={o.id}>
          <Typography variant="caption" display="block">
            <strong> Fecha:</strong> {this.getUTCDate(o.fechaReporte)}
          </Typography>
          <Typography variant="caption" display="block">
            <strong>Usuario:</strong>
            {o.usuarioReporta}
          </Typography>
          <Typography variant="caption" display="block">
            <strong>Motivo:</strong> {o.motivo}
          </Typography>
        </Box>
      );
    });
  }

  renderItems() {
    const { classes } = this.props;
    if (this.state.misPosts.length > 0) {
      return this.state.misPosts.map((r) => {
        return (
          <Card key={r.id} className={classes.root}>
            <CardActionArea>
              {this.esImagen(r.urlEncabezado) && (
                <CardMedia
                  className={classes.media}
                  image={r.urlEncabezado}
                  title={r.titulo}
                />
              )}
              {this.esVideo(r.urlEncabezado) && (
                <Box
                  className={classes.media}
                  style={{ backgroundColor: "black" }}
                  title={r.titulo}
                  align="center"
                  dangerouslySetInnerHTML={{
                    __html: r.urlEncabezado,
                  }}
                ></Box>
              )}
              <CardContent>
                <Typography gutterBottom variant="h5">
                  {r.titulo}
                </Typography>
                <Typography variant="overline" color="primary">
                  {r.fechaPublicacion} | {r.autor}
                </Typography>
                <Box my={2}>
                  <Divider></Divider>
                </Box>

                {r.contenido.split("\n").map((i, key) => {
                  return (
                    <Typography
                      display="block"
                      variant="caption"
                      key={key}
                      style={{ minHeight: 25 }}
                    >
                      {i}
                    </Typography>
                  );
                })}
              </CardContent>
            </CardActionArea>
            {!r.esNotificacion && !r.publicacionReportada && (
              <CardActions>
                <Grid container>
                  <Grid item xs={6}>
                    {!r.esReportar && (
                      <IconButton
                        aria-label="add to favorites"
                        title="Reportar publicación!"
                        onClick={() => this.mostrarReportarPublicacion(r)}
                      >
                        <ReportIcon />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Box align="right" style={{ width: "100%" }}>
                      <IconButton
                        aria-label="add to favorites"
                        onClick={() => this.guardaInteraccion(r, true)}
                      >
                        <Badge badgeContent={r.totalMeGusta} color="primary">
                          <ThumbUpIcon />
                        </Badge>
                      </IconButton>
                      <IconButton
                        aria-label="add to favorites"
                        onClick={() => this.guardaInteraccion(r, false)}
                      >
                        <Badge badgeContent={r.totalNoMeGusta} color="primary">
                          <ThumbDownIcon />
                        </Badge>
                      </IconButton>
                    </Box>
                  </Grid>
                  {r.esReportar && (
                    <Grid item xs={12}>
                      <Box
                        p={4}
                        m={4}
                        border={0}
                        borderRadius={16}
                        boxShadow={2}
                      >
                        {/* <Typography variant="caption" color="primary">
                          Reportar publicación
                        </Typography> */}
                        <TextField
                          className={classes.formControl}
                          error={this.showError(r.motivo)}
                          required
                          type="text"
                          label="Reportar publicación"
                          value={r.motivo}
                          onChange={(e) => this.handleChange(e, r)}
                        />
                        <Box align="right" mr={2} mt={2}>
                          <Button
                            size="small"
                            color="default"
                            onClick={() => this.cancelarReportarPublicacion(r)}
                          >
                            Cancelar
                          </Button>
                          <Button
                            size="small"
                            color="primary"
                            onClick={() => this.reportarPublicacion(r)}
                            variant="outlined"
                          >
                            Enviar
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </CardActions>
            )}
            {!r.esNotificacion && r.publicacionReportada && (
              <CardActions>
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      p={2}
                      boxShadow={1}
                      style={{ backgroundColor: "#FBE0E0" }}
                    >
                      <Typography>Reporte</Typography>

                      {this.renderMotivos(r)}
                      <Box align="right" mt={1} style={{ width: "100%" }}>
                        <Button
                          size="small"
                          color="secondary"
                          variant="outlined"
                          style={{ margin: 4 }}
                          onClick={(e) => {
                            this.desactivarUsuario(e, r);
                          }}
                        >
                          <Typography variant="caption">
                            Desactivar usuario
                          </Typography>
                        </Button>
                        <Button
                          size="small"
                          color="secondary"
                          variant="outlined"
                          onClick={(e) => this.confirmDelete(e, r)}
                        >
                          <Typography variant="caption">
                            Eliminar publicación
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </CardActions>
            )}
          </Card>
        );
      });
    } else {
      return (
        <Box align="center" p={4} mt={4} component={Paper}>
          <Typography variant="overline" display="block">
            No hay noticias disponibles!
          </Typography>
        </Box>
      );
    }
  }

  componentDidUpdate() {
    const {
      createPostInteraccion,
      createPostInteraccionError,
      postInteraccion,
      delPostInteraccion,
      delPostInteraccionError,
      updatePostInteraccion,
      updatePostInteraccionError,
      createReportar,
      createReportarError,
      fetchedList,
      delPost,
      delPostError,
      delUsuario,
      delUsuarioError,
    } = this.props;

    if (delUsuarioError) {
      this.props.enqueueSnackbar(delUsuarioError, {
        variant: "error",
      });
      this.props.clearAction("clearUsuario");
    } else if (delUsuario) {
      this.props.clearAction("clearUsuario");

      this.props.enqueueSnackbar("El usuario se desactivó correctamente!", {
        variant: "success",
      });
    }

    if (delPostError) {
      this.props.enqueueSnackbar(delPostError, {
        variant: "error",
      });
      this.props.clearAction("clearPost");
    } else if (delPost) {
      this.props.clearAction("clearPost");
      this.fetchList();
      this.props.enqueueSnackbar("El registro se eliminó correctamente!", {
        variant: "success",
      });
    }

    if (createReportarError) {
      this.props.enqueueSnackbar(createReportarError, {
        variant: "error",
      });
      this.props.clearAction("clearReportar");
    } else if (createReportar) {
      this.props.clearAction("clearReportar");
      this.props.enqueueSnackbar("El reporte se envió correctamiente!", {
        variant: "success",
      });
      this.fetchList();
    }

    if (this.state.obteniendoRegistros && fetchedList) {
      let posts = [
        ...this.props.misPosts.map((p) => {
          return { ...p, esReportar: false, motivo: "" };
        }),
      ];
      this.props.clearAction("clearMiPost");
      this.setState({
        obteniendoRegistros: false,
        misPosts: posts,
        totalList: this.props.totalList,
      });
    }

    if (createPostInteraccionError) {
      this.props.enqueueSnackbar(createPostInteraccionError, {
        variant: "error",
      });
      this.props.clearAction("clearPostInteraccion");
    } else if (createPostInteraccion) {
      this.props.clearAction("clearPostInteraccion");
      // this.props.enqueueSnackbar("Los encuesta se envió correctamiente!", {
      //   variant: "success",
      // });
      if (this.state.procesandoInteraccion) {
        let posts = [...this.state.misPosts];
        let post = posts.find((e) => e.id === postInteraccion.postId);
        post.interaccion = postInteraccion.gusta;
        post.tieneInteraccion = true;
        if (postInteraccion.gusta) {
          post.totalMeGusta++;
        } else {
          post.totalNoMeGusta++;
        }
        this.setState({ misPosts: posts, procesandoInteraccion: false });
      }
    }

    if (delPostInteraccionError) {
      this.props.enqueueSnackbar(delPostInteraccionError, {
        variant: "error",
      });
      this.props.clearAction("clearPostInteraccion");
    } else if (delPostInteraccion) {
      if (this.state.procesandoInteraccion) {
        let posts = [...this.state.misPosts];
        let post = posts.find((e) => e.id === this.state.elementoProcesando.id);

        if (post.interaccion) {
          post.totalMeGusta--;
        } else {
          post.totalNoMeGusta--;
        }
        post.tieneInteraccion = false;
        post.interaccion = false;
        this.props.clearAction("clearPostInteraccion");
        this.setState({
          misPosts: posts,
          procesandoInteraccion: false,
          elementoProcesando: "",
        });
      }
    }

    if (updatePostInteraccionError) {
      this.props.enqueueSnackbar(updatePostInteraccionError, {
        variant: "error",
      });
      this.props.clearAction("clearEncuesta");
    } else if (updatePostInteraccion) {
      if (this.state.procesandoInteraccion) {
        let posts = [...this.state.misPosts];
        let post = posts.find((e) => e.id === this.state.elementoProcesando.id);

        if (post.interaccion) {
          post.totalMeGusta--;
          post.totalNoMeGusta++;
        } else {
          post.totalMeGusta++;
          post.totalNoMeGusta--;
        }

        post.interaccion = !post.interaccion;
        //post.tieneInteraccion = true;
        this.props.clearAction("clearPostInteraccion");
        this.setState({
          misPosts: posts,
          procesandoInteraccion: false,
          elementoProcesando: "",
        });
      }
    }
  }

  render() {
    return (
      <Box>
        <Grid container>
          <Grid item xs={false} md={2}></Grid>
          <Grid item xs={12} md={8}>
            <Box mt={3}>{this.renderItems()}</Box>
            <Box mb={4}>
              <TableContainer component={Paper}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  component="div"
                  count={this.state.totalList}
                  rowsPerPage={this.state.pageSize}
                  page={this.state.pageNumber}
                  onChangePage={(e, newPage) => {
                    this.setState({ pageNumber: newPage }, () => {
                      this.fetchList();
                    });
                  }}
                  onChangeRowsPerPage={(e) => {
                    this.setState(
                      { pageSize: parseInt(e.target.value, 10) },
                      () => {
                        this.fetchList();
                      }
                    );
                  }}
                />
              </TableContainer>
            </Box>
          </Grid>
          <Grid item xs={false} md={2}></Grid>
        </Grid>
        <ConfirmDialog
          isDeleteDialogOpen={this.state.isDeleteDialogOpen}
          onDeleteDialogClose={this.onDeleteDialogClose}
          title={this.state.titleConfirm}
          message={this.state.textConfirm}
        ></ConfirmDialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    misPosts: state.misPostsState.misPosts,
    totalList: state.misPostsState.totalList,
    fetchedList: state.misPostsState.fetchedList,

    preferenciaClienteId: state.preferenciasGeneralState.clienteId,
    preferenciaCampaniaId: state.preferenciasGeneralState.campaniaId,

    createPostInteraccion: state.postInteraccionState.createPostInteraccion,
    createPostInteraccionError:
      state.postInteraccionState.createPostInteraccionError,
    postInteraccion: state.postInteraccionState.postInteraccion,

    delPostInteraccion: state.postInteraccionState.delPostInteraccion,
    delPostInteraccionError: state.postInteraccionState.delPostInteraccionError,

    updatePostInteraccion: state.postInteraccionState.updatePostInteraccion,
    updatePostInteraccionError:
      state.postInteraccionState.updatePostInteraccionError,

    reportar: state.reportarState.reportar,
    createReportar: state.reportarState.createReportar,
    createReportarError: state.reportarState.createReportarError,

    delPost: state.postState.delPost,
    delPostError: state.postState.delPostError,

    delUsuario: state.usuarioState.delUsuario,
    delUsuarioError: state.usuarioState.delUsuarioError,
  };
};

export default connect(mapStatetoProps, {
  getAllAction,
  deleteAction,
  clearAction,
  createAction,
  updateAction,
})(withStyles(useStyles)(withSnackbar(MisNoticias)));
