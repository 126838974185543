import { openDB } from "idb";

class IndexedDb {
  constructor(database) {
    this.database = database;
    this.db = null;
  }

  async createObjectStore(tableName) {
    try {
      this.db = await openDB(this.database, 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains(tableName)) {
            db.createObjectStore(tableName, {
              autoIncrement: true,
              keyPath: "id",
            });
          }
        },
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  async getValue(tableName, id) {
    const tx = this.db.transaction(tableName, "readonly");
    const store = tx.objectStore(tableName);
    const result = await store.get(id);
    return result;
  }

  async getAllValue(tableName) {
    const tx = this.db.transaction(tableName, "readonly");
    const store = tx.objectStore(tableName);
    const result = await store.getAll();
    return result;
  }

  async putValue(tableName, value) {
    const tx = this.db.transaction(tableName, "readwrite");
    const store = tx.objectStore(tableName);
    const result = await store.put(value);
    return result;
  }

  // public async putBulkValue(tableName: string, values: object[]) {
  //     const tx = this.db.transaction(tableName, 'readwrite');
  //     const store = tx.objectStore(tableName);
  //     for (const value of values) {
  //         const result = await store.put(value);
  //     }
  //     return this.getAllValue(tableName);
  // }

  async deleteValue(tableName, id) {
    const tx = this.db.transaction(tableName, "readwrite");
    const store = tx.objectStore(tableName);
    const result = await store.get(id);
    if (!result) {
      return result;
    }
    await store.delete(id);
    return id;
  }
}

export default IndexedDb;
