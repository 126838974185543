import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Transition from "../tools/transition";

class ConfirmDialog extends React.Component {
  handleCancel = (e) => {
    e.preventDefault();
    this.props.onDeleteDialogClose(false);
  };

  handleConfirm = (e) => {
    e.preventDefault();
    this.props.onDeleteDialogClose(true);
  };

  render() {
    return (
      <Dialog
        disableBackdropClick
        TransitionComponent={Transition}
        open={this.props.isDeleteDialogOpen}
        onClose={this.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>Cancelar</Button>
          <Button
            onClick={this.handleConfirm}
            color="secondary"
            variant="contained"
            autoFocus
          >
            {this.props.okText ? this.props.okText : "Aceptar"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmDialog;
