import React, { useRef, useState, useEffect } from "react";
import "./map.css";
import MapContext from "./map-context";
import * as ol from "ol";
//import Overlay from "ol/Overlay";
//import ReactDOM from "react-dom";
const Map = ({ children, zoom, center, olay, content }) => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  // on component mount
  useEffect(() => {
    // const overlay = new Overlay({
    //   position: center,
    //   element: olay,
    //   positioning: "center-center",
    //   stopEvent: false,
    // });

    let options = {
      view: new ol.View({ zoom, center }),
      layers: [],
      controls: [],
      overlays: [],
    };
    let mapObject = new ol.Map(options);
    mapObject.setTarget(mapRef.current);
    //mapObject.addOverlay(overlay);
    mapObject.on("click", (evt) => {
      var feature = mapObject.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          return feature;
        }
      );
      if (feature) {
        content.innerHTML = `<div class='contenido-mapa'>${feature.values_.nombreCompleto}</div>
        <div class='contenido-mapa-detalle'>
        <br/><b>Teléfono:</b> ${feature.values_.telefono}
        <br/><b>Email</b>: ${feature.values_.email}
        <br/><b>Dirección</b>: ${feature.values_.direccion}
        <br/><b>Sección</b>: ${feature.values_.seccion}
        </div>`;
        olay.setPosition(evt.coordinate);
        mapObject.addOverlay(olay);
      } else if (olay) mapObject.removeOverlay(olay);
    });
    setMap(mapObject);
    return () => mapObject.setTarget(undefined);
  }, [zoom, center, content, olay]);
  // zoom change handler
  useEffect(() => {
    if (!map) return;
    map.getView().setZoom(zoom);
  }, [map, zoom, center]);
  // center change handler
  useEffect(() => {
    if (!map) return;
    map.getView().setCenter(center);
  }, [map, zoom, center]);
  return (
    <MapContext.Provider value={{ map }}>
      <div ref={mapRef} className="ol-map">
        {children}
      </div>
    </MapContext.Provider>
  );
};
export default Map;
