import React from "react";
import {
  getAllAction,
  clearAction,
  getCatalogAction,
} from "../../actions/action-template";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import {
  Box,
  Grid,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import ActivadoItem from "./activado-item";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginRight: "5px",
  },
});

class Arbol extends React.Component {
  constructor(props) {
    super(props);

    var root = "";
    if (parseInt(this.props.campaniaId) > 0) root = this.props.usuarioId;
    this.state = {
      clienteId: this.props.customerId ?? "",
      campaniaId: this.props.campaniaId ?? 0,
      arbol: [],
      root: root,
    };
    this.props.getCatalogAction("getClienteCatalogo");

    if (this.props.campaniaId) {
      this.props.getCatalogAction(
        "getCampaniaCatalogo",
        `clienteId=${this.props.customerId}&campaniaId=${this.props.campaniaId}`
      );
    } else if (this.props.customerId) {
      this.props.getCatalogAction(
        "getCampaniaCatalogo",
        `clienteId=${this.props.customerId}`
      );
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === "clienteId") {
        this.props.getCatalogAction(
          "getCampaniaCatalogo",
          `clienteId=${value}`
        );
      }
      if (name !== "clienteId") this.obtenerPromovidos();
    });
  };

  obtenerPromovidos() {
    const { campaniaId, root } = this.state;
    let filtro = `campaniaId=${campaniaId}&root=${root}`;
    trackPromise(this.props.getAllAction("getAllArbol", 0, 0, filtro));
  }

  componentDidUpdate() {
    if (
      !this.state.datosCargados &&
      this.props.campaniaCatalog.length === 1 &&
      this.props.customerId
    ) {
      this.setState({ datosCargados: true }, () => {
        this.obtenerPromovidos();
      });
    }

    if (this.props.arbol.length > 0 && this.props.root === this.state.root) {
      let arbol = [...this.props.arbol];
      this.setState({ arbol: arbol });
      this.props.clearAction("clearArbol");
    }
  }

  renderFiltro() {
    const { classes } = this.props;
    const { clienteId, campaniaId } = this.state;
    return (
      <Paper
        style={{
          marginBottom: 20,
          padding: 20,
        }}
      >
        <Grid container>
          {!this.props.customerId && (
            <Grid item xs={6} md={3}>
              <FormControl size="small" className={classes.formControl}>
                <InputLabel id="lblCliente">Cliente</InputLabel>
                <Select
                  labelId="lblCliente"
                  value={clienteId}
                  name="clienteId"
                  onChange={this.handleChange}
                >
                  {this.props.clienteCatalog.map((p) => {
                    return (
                      <MenuItem key={p.value} value={p.value}>
                        {p.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
          {!this.props.campaniaId && (
            <Grid item xs={6} md={3}>
              <FormControl size="small" className={classes.formControl}>
                <InputLabel id="lblCampania">Campaña</InputLabel>
                <Select
                  labelId="lblCampania"
                  value={campaniaId}
                  name="campaniaId"
                  onChange={this.handleChange}
                >
                  {this.props.campaniaCatalog.map((p) => {
                    return (
                      <MenuItem key={p.id} value={p.id}>
                        {p.nombre}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <Box mt={2}>
          <Grid container>
            {this.state.datosCargados && (
              <Grid item xs={12} md={12} align="right">
                <ButtonGroup
                  color="primary"
                  aria-label="Acciones"
                  style={{ marginTop: 10, marginLeft: 10 }}
                  size="small"
                >
                  <Button
                    title="Filtrar"
                    color="primary"
                    onClick={() => {
                      this.obtenerEstadisticas();
                    }}
                  >
                    {/* <CachedIcon /> */}
                    Actualizar
                  </Button>
                  {/* <Button
                  title="Filtrar"
                  color="primary"
                  onClick={() => {
                    this.obtenerListadoActivados();
                  }}
                >
                  <Typography variant="caption">Listado Activistas</Typography>
                </Button> */}
                  <Button
                    title="Filtrar"
                    color="primary"
                    onClick={() => {
                      this.obtenerListadoActivados();
                    }}
                  >
                    <Typography variant="caption">Listado Activados</Typography>
                  </Button>
                </ButtonGroup>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
    );
  }

  renderActivado() {
    return this.state.arbol.map((e) => {
      return (
        <Box component={Paper} p={1} key={e.id} mb={1}>
          <ActivadoItem
            campaniaId={this.state.campaniaId}
            activado={e}
          ></ActivadoItem>
        </Box>
      );
    });
  }

  render() {
    return (
      <Box mx={2}>
        <Typography variant="h2" gutterBottom>
          Árbol de activados
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            {this.renderFiltro()}
          </Grid>
          {this.state.campaniaId && this.state.arbol.length === 0 && (
            <Grid item xs={12}>
              <Typography>No hay activistas en la campaña!</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            {this.renderActivado()}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    campaniaCatalog: state.campaniaState.campaniaCatalog,
    clienteCatalog: state.customerState.clienteCatalog,

    customerId: state.authState.user.customerId,
    campaniaId: state.authState.user.campaniaId,
    usuarioId: state.authState.user.userId,

    arbol: state.arbolState.arbol,
    root: state.arbolState.root,
  };
};

export default connect(mapStatetoProps, {
  getCatalogAction,
  getAllAction,
  clearAction,
})(withStyles(useStyles)(withSnackbar(Arbol)));
