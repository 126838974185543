import React from "react";
import CampaniaList from "./campania-list";
import CampaniaDetail from "./campania-detail";
import CampaniaMetas from "../campania-metas/campania-metas";

class Campanias extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleComponent: 1,
      campania: "",
    };
  }

  showDetailItem = (e, campania) => {
    this.setState({ visibleComponent: e, campania });
  };

  resultOp = (e) => {
    this.setState({ visibleComponent: e });
  };

  render() {
    const { visibleComponent } = this.state;
    if (visibleComponent === 1)
      return (
        <CampaniaList
          showDetailItem={this.showDetailItem}
          updateItem={this.updateItem}
          clienteId={this.props.clienteId}
        ></CampaniaList>
      );
    else if (visibleComponent === 2)
      return (
        <CampaniaDetail
          clienteId={this.props.clienteId}
          resultOp={this.resultOp}
        ></CampaniaDetail>
      );
    else if (visibleComponent === 3)
      return (
        <CampaniaMetas
          campania={this.state.campania}
          resultOp={this.resultOp}
        ></CampaniaMetas>
      );
  }
}

export default Campanias;
