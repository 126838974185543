const defaultState = {
  createDistritoLocal: false,
  createDistritoLocalError: "",
  distritoLocal: {},
  delDistritoLocal: false,
  delDistritoLocalError: "",
  getDistritoLocalError: "",

  distritosLocales: [],
  distritoLocalCatalog: [],

  totalList: 0,
};

export const distritoLocalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_DISTRITOS-LOCALES":
      return {
        ...state,
        distritosLocales: action.payload.data,
        totalList: action.payload.totalRegs,
      };
    case "FETCH_DISTRITO_LOCAL_CATALOG":
      return {
        ...state,
        distritoLocalCatalog: action.payload,
      };

    case "CREATE_DISTRITO-LOCAL":
      return {
        ...state,
        distritoLocal: { ...action.payload },
        createDistritoLocal: true,
      };
    case "CREATE_DISTRITO-LOCAL_ERROR":
      return { ...state, createDistritoLocalError: action.payload.error };
    case "UPDATE_DISTRITO-LOCAL":
      return {
        ...state,
        distritoLocal: { ...action.payload },
        updateDistritoLocal: true,
      };
    case "UPDATE_DISTRITO-LOCAL_ERROR":
      return { ...state, updateDistritoLocalError: action.payload.error };
    case "CLEAR_DISTRITO-LOCAL":
      return {
        ...state,
        distritoLocal: {},
        createDistritoLocal: false,
        createDistritoLocalError: "",
        delDistritoLocal: false,
        delDistritoLocalError: "",
        getDistritoLocal: false,
        getDistritoLocalError: "",
        updateDistritoLocal: false,
        updateDistritoLocalError: "",
      };
    case "DEL_DISTRITO-LOCAL":
      return { ...state, delDistritoLocal: true };
    case "DEL_DISTRITO-LOCAL_ERROR":
      return { ...state, delDistritoLocalError: action.payload.error };
    case "GET_DISTRITO-LOCAL":
      return {
        ...state,
        getDistritoLocal: true,
        distritoLocal: { ...action.payload },
      };
    case "GET_DISTRITO-LOCAL_ERROR":
      return { ...state, getDistritoLocalError: action.payload.error };
    default:
      return {
        ...state,
      };
  }
};
