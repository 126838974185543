import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
//import { ResponsiveLine } from "@nivo/line";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box className="custom-tooltip" component={Paper} p={1}>
        <Typography variant="caption" color="primary">
          {`Fecha: ${label} Activados: ${payload[0].value}`}
        </Typography>
      </Box>
    );
  }

  return null;
};

class LineaTiempo extends React.Component {
  render() {
    let datos = this.props.datos?.rows;

    if (datos && datos.length > 0) {
      return (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={datos[0].data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 40,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="x"
              orientation="bottom"
              angle="45"
              padding={{ bottom: 50 }}
            ></XAxis>
            <YAxis
              label={{
                value: "Activados",
                angle: -90,
                position: "insideLeft",
                textAnchor: "middle",
              }}
            ></YAxis>
            <Tooltip content={<CustomTooltip />} />
            {/* <Legend verticalAlign="top" height={36} /> */}
            <Line
              type="monotone"
              dataKey="y"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="metaTotal" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      );
    } else return <div></div>;
  }
  // render() {
  //   let datos = this.props.datos?.rows;
  //   if (datos)
  //     return (
  //       <ResponsiveLine
  //         data={datos}
  //         margin={{
  //           top: 50,
  //           right: 110,
  //           bottom: 100,
  //           left: 60,
  //         }}
  //         xScale={{
  //           type: "point",
  //         }}
  //         yScale={{
  //           type: "linear",

  //           stacked: false,
  //           min: 0,
  //           max: "auto",
  //         }}
  //         minY="auto"
  //         maxY="auto"
  //         stacked={false}
  //         curve="linear"
  //         axisBottom={{
  //           orient: "bottom",
  //           tickSize: 5,
  //           tickPadding: 5,
  //           tickRotation: 60,
  //           legend: "",
  //           legendOffset: 36,
  //           legendPosition: "center",
  //         }}
  //         axisLeft={{
  //           orient: "left",
  //           tickSize: 5,
  //           tickPadding: 5,
  //           tickRotation: 0,
  //           legend: "",
  //           legendOffset: 12,
  //           legendPosition: "center",
  //         }}
  //         dotSize={10}
  //         dotColor="inherit:darker(0.3)"
  //         dotBorderWidth={2}
  //         dotBorderColor="#ffffff"
  //         enableDotLabel={true}
  //         dotLabel="y"
  //         dotLabelYOffset={-12}
  //         animate={true}
  //         motionStiffness={90}
  //         motionDamping={15}
  //         useMesh={false}
  //         legends={[
  //           {
  //             anchor: "bottom-right",
  //             direction: "column",
  //             justify: false,
  //             translateX: 100,
  //             translateY: 0,
  //             itemsSpacing: 0,
  //             itemDirection: "left-to-right",
  //             itemWidth: 80,
  //             itemHeight: 20,
  //             itemOpacity: 0.75,
  //             symbolSize: 12,
  //             symbolShape: "circle",
  //             symbolBorderColor: "rgba(0, 0, 0, .5)",
  //             effects: [
  //               {
  //                 on: "hover",
  //                 style: {
  //                   itemBackground: "rgba(0, 0, 0, .03)",
  //                   itemOpacity: 1,
  //                 },
  //               },
  //             ],
  //           },
  //         ]}
  //       />
  //     );
  //   else return <div></div>;
  // }
}

export default LineaTiempo;
