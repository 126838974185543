import { Box } from "@material-ui/core";
import React from "react";
import { Route } from "react-router-dom";
import Home from "../components/home";
import Login from "../components/security/auth/login";
import RecoveryPassword from "../components/security/auth/recovery-password";
import SetPassword from "../components/security/auth/set-password";
import Customers from "../components/managment/customers/customers";
import { connect } from "react-redux";

import Entidades from "../components/admin/entidades/entidades";
import DistritosFederales from "../components/admin/distritos-federales/distritos-federales";
import Municipios from "../components/admin/municipios/municipios";
import DistritosLocales from "../components/admin/distritos-locales/distritos-locales";
import Secciones from "../components/admin/secciones/secciones";
import Colonias from "../components/admin/colonias/colonias";
import Roles from "../components/admin/roles/roles";
import Usuarios from "../components/admin/usuarios/usuarios";
import modulos from "../components/tools/modulos";
import Activados from "../components/managment/activados/activados";
import Encuestas from "../components/encuestas/encuestas";
import DatosPersonales from "../components/security/datosPersonales/datos-personales";
import autoRegistro from "../components/security/auto-registro/auto-registro";
import Dashboard from "../components/dashboard/dashbaoard";
import { Offline, Online } from "react-detect-offline";
import OfflineComponent from "../components/off-line/offline";
import Posts from "../components/posts/posts";
import PreferenciasDetail from "../components/preferencias/preferencias-detail";
import MisPreferencias from "../components/preferencias/mis-preferencias";
import Territorio from "../components/territorio/territorio";
import Arbol from "../components/arbol/arbol";
import campaniaTarea from "../components/managment/campania-tarea/campania-tarea-list";

class AppRoutes extends React.Component {
  hasAccess(moduloId) {
    let tieneAccceso =
      this.props.usuarioRolModulos?.find(
        (m) => m.ModuloId === moduloId && m.TieneAcceso === true
      ) != null;
    return tieneAccceso;
  }

  renderOffline() {
    return (
      <div>
        <Route exact path="/" component={OfflineComponent}></Route>
        <Route exact path="/login" component={OfflineComponent}></Route>
        <Route exact path="/activados" component={Activados}></Route>
      </div>
    );
  }

  renderOnline() {
    return (
      <Box>
        <div>
          {this.hasAccess(modulos.Tareas) && (
            <Route exact path="/tareas" component={campaniaTarea}></Route>
          )}
          {this.hasAccess(modulos.Clientes) && (
            <Route exact path="/clientes" component={Customers}></Route>
          )}
          {this.hasAccess(modulos.Activados) && (
            <Route exact path="/activados" component={Activados}></Route>
          )}
          {this.hasAccess(modulos.Encuestas) && (
            <Route exact path="/encuestas" component={Encuestas}></Route>
          )}
          {this.hasAccess(modulos.Posts) && (
            <Route exact path="/publicaciones" component={Posts}></Route>
          )}
          {this.hasAccess(modulos.Territorio) && (
            <Route exact path="/territorio" component={Territorio}></Route>
          )}
          {this.hasAccess(modulos.Arbol) && (
            <Route exact path="/arbol" component={Arbol}></Route>
          )}
          {this.hasAccess(modulos.Preferencias) && (
            <Route
              exact
              path="/preferencias"
              component={PreferenciasDetail}
            ></Route>
          )}
          {this.hasAccess(modulos.Entidades) && (
            <Route exact path="/entidades" component={Entidades}></Route>
          )}
          {this.hasAccess(modulos.DistritosFederales) && (
            <Route
              exact
              path="/distritosf"
              component={DistritosFederales}
            ></Route>
          )}
          {this.hasAccess(modulos.Municipios) && (
            <Route exact path="/municipios" component={Municipios}></Route>
          )}
          {this.hasAccess(modulos.DistritosLocales) && (
            <Route
              exact
              path="/distritosl"
              component={DistritosLocales}
            ></Route>
          )}
          {this.hasAccess(modulos.Secciones) && (
            <Route exact path="/secciones" component={Secciones}></Route>
          )}
          {this.hasAccess(modulos.Colonias) && (
            <Route exact path="/colonias" component={Colonias}></Route>
          )}
          {this.hasAccess(modulos.Roles) && (
            <Route exact path="/roles" component={Roles}></Route>
          )}
          {this.hasAccess(modulos.Usuarios) && (
            <Route exact path="/usuarios" component={Usuarios}></Route>
          )}
          {this.props.loggedIn && (
            <div>
              <Route exact path="/dashboard" component={Dashboard}></Route>
              <Route exact path="/cuenta" component={DatosPersonales}></Route>
              <Route
                exact
                path="/mispreferencias"
                component={MisPreferencias}
              ></Route>
              <Route exact path="/login" component={Home}></Route>
              <Route exact path="/recupera" component={Home}></Route>
              <Route exact path="/cambiopass" component={Home}></Route>
              <Route exact path="/" component={Home}></Route>
            </div>
          )}
        </div>

        {!this.props.loggedIn && (
          <div>
            <Route exact path="/cuenta" component={Home}></Route>
            <Route exact path="/mispreferencias" component={Home}></Route>
            <Route exact path="/login" component={Login}></Route>
            <Route exact path="/registro" component={autoRegistro}></Route>
            <Route exact path="/recupera" component={RecoveryPassword}></Route>
            <Route exact path="/campiopass" component={SetPassword}></Route>
            <Route exact path="/" component={Home}></Route>
          </div>
        )}
      </Box>
    );
  }

  render() {
    return (
      <div>
        <Online>{this.renderOnline()}</Online>
        <Offline>{this.renderOffline()}</Offline>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    username: state.authState.user.username,
    usuarioRolModulos: state.authState.user.usuarioRolModulos,
    loggedIn: state.authState.loggedIn,
  };
};

export default connect(mapStatetoProps, {})(AppRoutes);
