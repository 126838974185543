import api from "../apis/api";

const entidadesApi = {
  getEntidadCatalogo: "/Managment/api/entidades/GetEntidadCatalog",
  getAllEntidades: "/Managment/api/entidades/",
  postEntidad: "/Managment/api/entidades/",
  getEntidad: "/Managment/api/entidades/",
  updateEntidad: "/Managment/api/entidades/",
  deleteEntidad: "/Managment/api/entidades/",
};
const distFedApi = {
  getDistritoFederalCatalogo: "/Managment/api/distritosfederales/GetCatalog",
  getAllDistritosFederales: "/Managment/api/distritosfederales/",
  postDistritoFederal: "/Managment/api/distritosfederales/",
  getDistritoFederal: "/Managment/api/distritosfederales/",
  updateDistritoFederal: "/Managment/api/distritosfederales/",
  deleteDistritoFederal: "/Managment/api/distritosfederales/",
};

const campaniasApi = {
  getCampaniaCatalogo: "/Managment/api/campania/GetCatalog",
  getAllcampanias: "/managment/api/campania/",
  postCampania: "/managment/api/campania/",
  getCampania: "/managment/api/campania/",
  updateCampania: "/managment/api/campania/",
  deleteCampania: "/managment/api/campania/",
};

const municipiosApi = {
  getMunicipioCatalogo: "/Managment/api/municipio/GetCatalog",
  getAllMunicipios: "/Managment/api/municipio/",
  postMunicipio: "/Managment/api/municipio/",
  getMunicipio: "/Managment/api/municipio/",
  updateMunicipio: "/Managment/api/municipio/",
  deleteMunicipio: "/Managment/api/municipio/",
};

const distritoLocalApi = {
  getDistritoLocalCatalogo: "/Managment/api/distritoslocales/GetCatalog",
  getAllDistritosLocales: "/Managment/api/distritoslocales/",
  postDistritoLocal: "/Managment/api/distritoslocales/",
  getDistritoLocal: "/Managment/api/distritoslocales/",
  updateDistritoLocal: "/Managment/api/distritoslocales/",
  deleteDistritoLocal: "/Managment/api/distritoslocales/",
};

const seccionApi = {
  getSeccionCatalogo: "/Managment/api/seccion/GetCatalog",
  getAllSeccion: "/Managment/api/seccion/",
  postSeccion: "/Managment/api/seccion/",
  getSeccion: "/Managment/api/seccion/",
  updateSeccion: "/Managment/api/seccion/",
  deleteSeccion: "/Managment/api/seccion/",
};

const coloniaApi = {
  getColoniaCatalogo: "/Managment/api/colonia/GetCatalog",
  getAllColonias: "/Managment/api/colonia/",
  postColonia: "/Managment/api/colonia/",
  getColonia: "/Managment/api/colonia/",
  updateColonia: "/Managment/api/colonia/",
  deleteColonia: "/Managment/api/colonia/",
};

const usuarioApi = {
  getUsuarioCatalogo: "/Managment/api/usuario/GetCatalog",
  getAllUsuario: "/Managment/api/usuario/",
  postUsuario: "/Managment/api/usuario/",
  getUsuario: "/Managment/api/usuario/",
  updateUsuario: "/Managment/api/usuario/",
  deleteUsuario: "/Managment/api/usuario/",
};

const datosPersonalesApi = {
  getDatosPersonales: "/Managment/api/datosPersonales/",
  updateDatosPersonales: "/Managment/api/datosPersonales/",
};

const rolApi = {
  getRolCatalogo: "/Managment/api/rol/GetRolCatalog",
  getAllRoles: "/Managment/api/rol/",
  postRol: "/Managment/api/rol/",
  getRol: "/Managment/api/rol/",
  updateRol: "/Managment/api/rol/",
  deleteRol: "/Managment/api/rol/",
};

const clienteApi = {
  getClienteCatalogo: "/Managment/api/cliente/GetCatalog",
  getAllClientes: "/Managment/api/cliente/",
  postCliente: "/Managment/api/cliente/",
  getCliente: "/Managment/api/cliente/",
  updateCliente: "/Managment/api/cliente/",
  deleteCliente: "/Managment/api/cliente/",
};

const activadoApi = {
  getActivadoCatalogo: "/Managment/api/Activado/GetCatalog",
  getAllActivados: "/Managment/api/Activado/",
  postActivado: "/Managment/api/Activado/",
  getActivado: "/Managment/api/Activado/",
  updateActivado: "/Managment/api/Activado/",
  deleteActivado: "/Managment/api/Activado/",
};

const campaniaMetaApi = {
  getAllMetaCampanias: "/Managment/api/CampaniaMeta/",
  updateMetaCampania: "/Managment/api/CampaniaMeta/",
  postMetaCampania: "/Managment/api/CampaniaMeta/",
};

const autoRegistroApi = {
  postAutoRegistro: "/Managment/api/Registro/",
};

const campaniaAvanceApi = {
  getAllCampaniaAvance: "/Managment/api/CampaniaAvance/",
};

const encuestaApi = {
  getEncuestaCatalogo: "/Managment/api/Encuesta/GetCatalog",
  getAllEncuestas: "/Managment/api/Encuesta/",
  postEncuesta: "/Managment/api/Encuesta/",
  getEncuesta: "/Managment/api/Encuesta/",
  updateEncuesta: "/Managment/api/Encuesta/",
  deleteEncuesta: "/Managment/api/Encuesta/",
};

const preguntaApi = {
  getPreguntaCatalogo: "/Managment/api/Pregunta/GetCatalog",
  getAllPreguntas: "/Managment/api/Pregunta/",
  postPregunta: "/Managment/api/Pregunta/",
  getPregunta: "/Managment/api/Pregunta/",
  updatePregunta: "/Managment/api/Pregunta/",
  deletePregunta: "/Managment/api/Pregunta/",
};

const opcionPreguntaApi = {
  getOpcionPreguntaCatalogo: "/Managment/api/OpcionPregunta/GetCatalog",
  getAllOpcionPreguntas: "/Managment/api/OpcionPregunta/",
  postOpcionPregunta: "/Managment/api/OpcionPregunta/",
  getOpcionPregunta: "/Managment/api/OpcionPregunta/",
  updateOpcionPregunta: "/Managment/api/OpcionPregunta/",
  deleteOpcionPregunta: "/Managment/api/OpcionPregunta/",
};

const miEncuestaApi = {
  getMiEncuestaCatalogo: "/Managment/api/MisEncuestas/GetCatalog",
  getAllMisEncuestas: "/Managment/api/MisEncuestas/",
  postMiEncuesta: "/Managment/api/MisEncuestas/",
  getMiEncuesta: "/Managment/api/MisEncuestas/",
  updateMiEncuesta: "/Managment/api/MisEncuestas/",
  deleteMiEncuesta: "/Managment/api/MisEncuestas/",
};

const encuestaUsuarioApi = {
  getEncuestaUsuarioCatalogo: "/Managment/api/EncuestaUsuario/GetCatalog",
  getAllEncuestaUsuarios: "/Managment/api/EncuestaUsuario/",
  postEncuestaUsuario: "/Managment/api/EncuestaUsuario/",
  getEncuestaUsuario: "/Managment/api/EncuestaUsuario/",
  updateEncuestaUsuario: "/Managment/api/EncuestaUsuario/",
  deleteEncuestaUsuario: "/Managment/api/EncuestaUsuario/",
};

const postApi = {
  getAllPosts: "/Managment/api/Post/",
  postPost: "/Managment/api/Post/",
  getPost: "/Managment/api/Post/",
  updatePost: "/Managment/api/Post/",
  deletePost: "/Managment/api/Post/",
};

const miPostsApi = {
  getAllMisPosts: "/Managment/api/MisPosts/",
  postMiPost: "/Managment/api/MisPosts/",
  getMiPost: "/Managment/api/MisPosts/",
  updateMiPost: "/Managment/api/MisPosts/",
  deleteMiPost: "/Managment/api/MisPosts/",
};

const postInteraccionApi = {
  getAllPostInteracciones: "/Managment/api/PostInteraccion/",
  postPostInteraccion: "/Managment/api/PostInteraccion/",
  getPostInteraccion: "/Managment/api/PostInteraccion/",
  updatePostInteraccion: "/Managment/api/PostInteraccion/",
  deletePostInteraccion: "/Managment/api/PostInteraccion/",
};

const preferenciaApi = {
  getAllPreferencias: "/Managment/api/Preferencia/",
  postPreferencia: "/Managment/api/Preferencia/",
  getPreferencia: "/Managment/api/Preferencia/",
  updatePreferencia: "/Managment/api/Preferencia/",
  deletePreferencia: "/Managment/api/Preferencia/",
};

const miPreferenciaApi = {
  getMiPreferenciaCatalogo: "/Managment/api/MisPreferencias/GetCatalog",
  getAllMisPreferencias: "/Managment/api/MisPreferencias/",
  postMiPreferencia: "/Managment/api/MisPreferencias/",
  getMiPreferencia: "/Managment/api/MisPreferencias/",
  updateMiPreferencia: "/Managment/api/MisPreferencias/",
  deleteMiPreferencia: "/Managment/api/MisPreferencias/",
};

const encuestaResumenApi = {
  getEncuestaResumenCatalogo: "/Managment/api/EncuestaResumen/GetCatalog",
  getAllEncuestasResumen: "/Managment/api/EncuestaResumen/",
  postEncuestaResumen: "/Managment/api/EncuestaResumen/",
  getEncuestaResumen: "/Managment/api/EncuestaResumen/",
  updateEncuestaResumen: "/Managment/api/EncuestaResumen/",
  deleteEncuestaResumen: "/Managment/api/EncuestaResumen/",
};

const usuarioTerritorioApi = {
  getUsuarioTerritorioCatalogo: "/Managment/api/UsuarioTerritorio/GetCatalog",
  getAllUsuarioTerritorio: "/Managment/api/UsuarioTerritorio/",
};

const storageApi = {
  postStorage: "/Managment/api/Storage/",
};

const arbolApi = {
  getAllArbol: "/Managment/api/arbol/",
};

const campaniaTareasApi = {
  getAllCampaniaTareas: "/Managment/api/CampaniaTarea/",
  updateCampaniaTarea: "/Managment/api/CampaniaTarea/",
};

const reportarApi = {
  postReportar: "/Managment/api/Reportar/",
  getReportar: "/Managment/api/Reportar/",
  updateReportar: "/Managment/api/Reportar/",
  deleteReportar: "/Managment/api/Reportar/",
};

const apiURL = {
  getModuloCatalogo: "/managment/api/modulos/GetCatalog",
  getGeneroCatalogo: "/managment/api/generos/GetCatalog",
  getTipoPreguntaCatalogo: "/managment/api/tipoPregunta/GetCatalog",
  ambitoTerritorialCatalogo: "/managment/api/AmbitoTerritorial/GetCatalog",
  getReporteActivados: "/managment/api/CampaniaReporte/",
  getAgenteCatalogo: "/Managment/api/Agente/GetCatalog",

  ...entidadesApi,
  ...distFedApi,
  ...campaniasApi,
  ...municipiosApi,
  ...distritoLocalApi,
  ...seccionApi,
  ...usuarioApi,
  ...rolApi,
  ...clienteApi,
  ...activadoApi,
  ...coloniaApi,
  ...datosPersonalesApi,
  ...campaniaMetaApi,
  ...autoRegistroApi,
  ...campaniaAvanceApi,
  ...encuestaApi,
  ...preguntaApi,
  ...opcionPreguntaApi,
  ...miEncuestaApi,
  ...encuestaUsuarioApi,
  ...postApi,
  ...miPostsApi,
  ...postInteraccionApi,
  ...preferenciaApi,
  ...miPreferenciaApi,
  ...encuestaResumenApi,
  ...usuarioTerritorioApi,
  ...storageApi,
  ...arbolApi,
  ...campaniaTareasApi,
  ...reportarApi,
};

const entidadSuccesMessages = {
  getEntidadCatalogo: "FETCH_ENTIDAD-CATALOG",
  getAllEntidades: "FETCH_ENTIDADES",
  postEntidad: "CREATE_ENTIDAD",
  getEntidad: "GET_ENTIDAD",
  updateEntidad: "UPDATE_ENTIDAD",
  deleteEntidad: "DEL_ENTIDAD",
  clearEntidad: "CLEAR_ENTIDAD",
};

const distritoFederalSuccessMessages = {
  getDistritoFederalCatalogo: "FETCH_DISTRITO_FEDERAL_CATALOG",
  getAllDistritosFederales: "FETCH_DISTRITOS_FEDERALES",
  postDistritoFederal: "CREATE_DISTRITO_FEDERAL",
  getDistritoFederal: "GET_DISTRITO_FEDERAL",
  updateDistritoFederal: "UPDATE_DISTRITO_FEDERAL",
  deleteDistritoFederal: "DEL_DISTRITO_FEDERAL",
  clearDistritoFederal: "CLEAR_DISTRITO_FEDERAL",
};

const campaniasSuccessMessages = {
  getCampaniaCatalogo: "FETCH_CAMPANIA_CATALOG",

  getAllcampanias: "FETCH_CAMPANIAS",
  postCampania: "CREATE_CAMPANIA",
  clearCampania: "CLEAR_CAMPANIA",
  getCampania: "GET_CAMPANIA",
  updateCampania: "UPDATE_CAMPANIA",
  deleteCampania: "DEL_CAMPANIA",
};

const municipiosSuccessMessages = {
  getMunicipioCatalogo: "FETCH_MUNICIPIO_CATALOG",
  getAllMunicipios: "FETCH_MUNICIPIOS",
  postMunicipio: "CREATE_MUNICIPIO",
  getMunicipio: "GET_MUNICIPIO",
  updateMunicipio: "UPDATE_MUNICIPIO",
  deleteMunicipio: "DEL_MUNICIPIO",
  clearMunicipio: "CLEAR_MUNICIPIO",
};

const distritoLocalSuccessMessages = {
  getDistritoLocalCatalogo: "FETCH_DISTRITO_LOCAL_CATALOG",
  getAllDistritosLocales: "FETCH_DISTRITOS-LOCALES",
  postDistritoLocal: "CREATE_DISTRITO-LOCAL",
  getDistritoLocal: "GET_DISTRITO-LOCAL",
  updateDistritoLocal: "UPDATE_DISTRITO-LOCAL",
  deleteDistritoLocal: "DEL_DISTRITO-LOCAL",
  clearDistritoLocal: "CLEAR_DISTRITO-LOCAL",
};

const seccionSuccessMessages = {
  getSeccionCatalogo: "FETCH_SECCION_CATALOG",
  getAllSeccion: "FETCH_SECCIONES",
  postSeccion: "CREATE_SECCION",
  getSeccion: "GET_SECCION",
  updateSeccion: "UPDATE_SECCION",
  deleteSeccion: "DEL_SECCION",
  clearSeccion: "CLEAR_SECCION",
};

const coloniaSuccessMessages = {
  getColoniaCatalogo: "FETCH_COLONIA_CATALOG",
  getAllColonias: "FETCH_COLONIAS",
  postColonia: "CREATE_COLONIA",
  getColonia: "GET_COLONIA",
  updateColonia: "UPDATE_COLONIA",
  deleteColonia: "DEL_COLONIA",
  clearColonia: "CLEAR_COLONIA",
};

const usuarioSuccessMessages = {
  getUsuarioCatalogo: "FETCH_USUARIOS_CATALOG",
  getAllUsuario: "FETCH_USUARIOS",
  postUsuario: "CREATE_USUARIO",
  getUsuario: "GET_USUARIO",
  updateUsuario: "UPDATE_USUARIO",
  deleteUsuario: "DEL_USUARIO",
  clearUsuario: "CLEAR_USUARIO",
};

const datosPersonalesSuccessMessages = {
  getDatosPersonales: "GET_DATOS_PERSONALES",
  updateDatosPersonales: "UPDATE_DATOS_PERSONALES",
  clearDatosPersonales: "CLEAR_DATOS_PERSONALES",
};

const rolSuccessMessages = {
  getRolCatalogo: "FETCH_ROL-CATALOG",
  getAllRoles: "FETCH_ROLES",
  postRol: "CREATE_ROL",
  getRol: "GET_ROL",
  updateRol: "UPDATE_ROL",
  deleteRol: "DEL_ROL",
  clearRol: "CLEAR_ROL",
};

const clienteSuccessMessages = {
  getClienteCatalogo: "FETCH_CUSTOMER_CATALOG",
  getAllClientes: "FETCH_CUSTOMERS",
  postCliente: "CREATE_CUSTOMER",
  getCliente: "GET_CUSTOMER",
  updateCliente: "UPDATE_CUSTOMER",
  deleteCliente: "DEL_CUSTOMER",
  clearCliente: "CLEAR_CUSTOMER",
};

const activadoSuccessMessages = {
  getActivadoCatalogo: "FETCH_ACTIVADO_CATALOG",
  getAllActivados: "FETCH_ACTIVADOS",
  postActivado: "CREATE_ACTIVADO",
  getActivado: "GET_ACTIVADO",
  updateActivado: "UPDATE_ACTIVADO",
  deleteActivado: "DEL_ACTIVADO",
  clearActivado: "CLEAR_ACTIVADO",
  getActivadoOffline: "GET_ACTIVADO_OFFLINE",
};

const campaniaMetaSuccessMessages = {
  getAllMetaCampanias: "FETCH_CAMPANIA_METAS",
  updateMetaCampania: "UPDATE_CAMPANIA_META",
  postMetaCampania: "CREATE_CAMPANIA_META",
  clearMetaCampania: "CLEAR_CAMPANIA_META",
};

const autoRegistroSuccessMessages = {
  postAutoRegistro: "CREATE_AUTO_REGISTRO",
  clearAutoRegistro: "CLEAR_AUTO_REGISTRO",
};

const campaniaAvanceSuccessMessages = {
  getAllCampaniaAvance: "FETCH_CAMPANIA_AVANCE",
};

const encuestaSuccessMessages = {
  getEncuestaCatalogo: "FETCH_ENCUESTA_CATALOG",
  getAllEncuestas: "FETCH_ENCUESTAS",
  postEncuesta: "CREATE_ENCUESTA",
  getEncuesta: "GET_ENCUESTA",
  updateEncuesta: "UPDATE_ENCUESTA",
  deleteEncuesta: "DEL_ENCUESTA",
  clearEncuesta: "CLEAR_ENCUESTA",
};

const preguntaSuccessMessages = {
  getPreguntaCatalogo: "FETCH_PREGUNTA_CATALOG",
  getAllPreguntas: "FETCH_PREGUNTAS",
  postPregunta: "CREATE_PREGUNTA",
  getPregunta: "GET_PREGUNTA",
  updatePregunta: "UPDATE_PREGUNTA",
  deletePregunta: "DEL_PREGUNTA",
  clearPregunta: "CLEAR_PREGUNTA",
};

const opcionPreguntaSuccessMessages = {
  getOpcionPreguntaCatalogo: "FETCH_OPCION_PREGUNTA_CATALOG",
  getAllOpcionPreguntas: "FETCH_OPCION_PREGUNTAS",
  postOpcionPregunta: "CREATE_OPCION_PREGUNTA",
  getOpcionPregunta: "GET_OPCION_PREGUNTA",
  updateOpcionPregunta: "UPDATE_OPCION_PREGUNTA",
  deleteOpcionPregunta: "DEL_OPCION_PREGUNTA",
  clearOpcionPregunta: "CLEAR_OPCION_PREGUNTA",
};

const misEncuestaSuccessMessages = {
  getMiEncuestaCatalogo: "FETCH_MI_ENCUESTA_CATALOG",
  getAllMisEncuestas: "FETCH_MIS_ENCUESTAS",
  postMiEncuesta: "CREATE_MI_ENCUESTA",
  getMiEncuesta: "GET_MI_ENCUESTA",
  updateMiEncuesta: "UPDATE_MI_ENCUESTA",
  deleteMiEncuesta: "DEL_MI_ENCUESTA",
  clearMiEncuesta: "CLEAR_MI_ENCUESTA",
};

const encuestaUsuarioSuccessMessages = {
  getAllEncuestaUsuarios: "FETCH_ENCUESTA_USUARIOS",
  postEncuestaUsuario: "CREATE_ENCUESTA_USUARIO",
  getEncuestaUsuario: "GET_ENCUESTA_USUARIO",
  updateEncuestaUsuario: "UPDATE_ENCUESTA_USUARIO",
  deleteEncuestaUsuario: "DEL_ENCUESTA_USUARIO",
  clearEncuestaUsuario: "CLEAR_ENCUESTA_USUARIO",
};

const postSuccessMessages = {
  getAllPosts: "FETCH_POSTS",
  postPost: "CREATE_POST",
  getPost: "GET_POST",
  updatePost: "UPDATE_POST",
  deletePost: "DEL_POST",
  clearPost: "CLEAR_POST",
};

const misPostsSuccessMessages = {
  getAllMisPosts: "FETCH_MIS_POSTS",
  postMiPost: "CREATE_MI_POST",
  getMiPost: "GET_MI_POST",
  updateMiPost: "UPDATE_MI_POST",
  deleteMiPost: "DEL_MI_POST",
  clearMiPost: "CLEAR_MI_POST",
};

const postInteraccionSuccessMessages = {
  getAllPostInteracciones: "FETCH_POST_INTERACCIONES",
  postPostInteraccion: "CREATE_POST_INTERACCION",
  getPostInteraccion: "GET_POST_INTERACCION",
  updatePostInteraccion: "UPDATE_POST_INTERACCION",
  deletePostInteraccion: "DEL_POST_INTERACCION",
  clearPostInteraccion: "CLEAR_POST_INTERACCION",
};

const preferenciasSuccessMessages = {
  getAllPreferencias: "FETCH_PREFERENCIAS",
  postPreferencia: "CREATE_PREFERENCIA",
  getPreferencia: "GET_PREFERENCIA",
  updatePreferencia: "UPDATE_PREFERENCIA",
  deletePreferencia: "DEL_PREFERENCIA",
  clearPreferencia: "CLEAR_PREFERENCIA",
};

const misPreferenciasSuccessMessages = {
  getMiPreferenciaCatalogo: "FETCH_MI_PREFERENCIA_CATALOG",
  getAllMisPreferencias: "FETCH_MIS_PREFERENCIAS",
  postMiPreferencia: "CREATE_MI_PREFERENCIA",
  getMiPreferencia: "GET_MI_PREFERENCIA",
  updateMiPreferencia: "UPDATE_MI_PREFERENCIA",
  deleteMiPreferencia: "DEL_MI_PREFERENCIA",
  clearMiPreferencia: "CLEAR_MI_PREFERENCIA",
};

const encuestaResumenSuccessMessages = {
  getEncuestaResumenCatalogo: "FETCH_ENCUESTA_RESUMEN_CATALOG",
  getAllEncuestasResumen: "FETCH_ENCUESTAS_RESUMEN",
  postEncuestaResumen: "CREATE_ENCUESTA_RESUMEN",
  getEncuestaResumen: "GET_ENCUESTA_RESUMEN",
  updateEncuestaResumen: "UPDATE_ENCUESTA_RESUMEN",
  deleteEncuestaResumen: "DEL_ENCUESTA_RESUMEN",
  clearEncuestaResumen: "CLEAR_ENCUESTA_RESUMEN",
};

const usuarioTerritorioSuccessMessages = {
  getUsuarioTerritorioCatalogo: "USUARIO_TERRITORIO_CATALOG",
  getAllUsuarioTerritorio: "FETCH_USUARIOS_TERRITORIO",
  clearUsuarioTerritorio: "CLEAR_USUARIO_TERRITORIO",
};

const storageSuccessMessages = {
  postStorage: "CREATE_STORAGE",
  clearStorage: "CLEAR_STORAGE",
};

const arbolSuccessMessages = {
  getAllArbol: "FETCH_ARBOL",
  clearArbol: "CLEAR_ARBOL",
};

const campaniaTareasSuccessMessages = {
  getAllCampaniaTareas: "FETCH_CAMPANIA_TASKS",
  updateCampaniaTarea: "UPDATE_CAMPANIA_TASK",
  clearCampaniaTarea: "CLEAR_CAMPANIA_TASK",
};

const reportarSuccessMessages = {
  postReportar: "CREATE_REPORTAR",
  getReportar: "GET_REPORTAR",
  updateReportar: "UPDATE_REPORTAR",
  deleteReportar: "DEL_REPORTAR",
  clearReportar: "CLEAR_REPORTAR",
};

const apiSuccessMessage = {
  getModuloCatalogo: "FETCH_MODULO_CATALOG",
  getGeneroCatalogo: "FETCH_GENERO_CATALOG",
  getTipoPreguntaCatalogo: "FETCH_TIPO_PREGUNTA_CATALOG",
  ambitoTerritorialCatalogo: "FETCH_AMBITO_TERRITORIAL_CATALOG",
  getAgenteCatalogo: "FETCH_AGENTE_CATALOG",

  ...entidadSuccesMessages,
  ...distritoFederalSuccessMessages,
  ...campaniasSuccessMessages,
  ...municipiosSuccessMessages,
  ...distritoLocalSuccessMessages,
  ...seccionSuccessMessages,
  ...usuarioSuccessMessages,
  ...rolSuccessMessages,
  ...clienteSuccessMessages,
  ...activadoSuccessMessages,
  ...coloniaSuccessMessages,
  ...datosPersonalesSuccessMessages,
  ...campaniaMetaSuccessMessages,
  ...autoRegistroSuccessMessages,
  ...campaniaAvanceSuccessMessages,
  ...encuestaSuccessMessages,
  ...preguntaSuccessMessages,
  ...opcionPreguntaSuccessMessages,
  ...misEncuestaSuccessMessages,
  ...encuestaUsuarioSuccessMessages,
  ...postSuccessMessages,
  ...misPostsSuccessMessages,
  ...postInteraccionSuccessMessages,
  ...preferenciasSuccessMessages,
  ...misPreferenciasSuccessMessages,
  ...encuestaResumenSuccessMessages,
  ...usuarioTerritorioSuccessMessages,
  ...storageSuccessMessages,
  ...arbolSuccessMessages,
  ...campaniaTareasSuccessMessages,
  ...reportarSuccessMessages,
};

const entidadErrorMessages = {
  getEntidadCatalogo: "FETCH_ENTIDAD-CATALOG_ERROR",
  getAllEntidades: "FETCH_ENTIDADES_ERROR",
  postEntidad: "CREATE_ENTIDAD_ERROR",
  getEntidad: "GET_ENTIDAD_ERROR",
  updateEntidad: "UPDATE_ENTIDAD_ERROR",
  deleteEntidad: "DEL_ENTIDAD_ERROR",
};

const distritoFederalErrorMessages = {
  getDistritoFederalCatalogo: "FETCH_DISTRITO_FEDERAL_CATALOG_ERROR",
  getAllDistritosFederales: "FETCH_DISTRITOS_FEDERALES_ERROR",
  postDistritoFederal: "CREATE_DISTRITO_FEDERAL_ERROR",
  getDistritoFederal: "GET_DISTRITO_FEDERAL_ERROR",
  updateDistritoFederal: "UPDATE_DISTRITO_FEDERAL_ERROR",
  deleteDistritoFederal: "DEL_DISTRITO_FEDERAL_ERROR",
};

const campaniaErrorMessages = {
  getCampaniaCatalogo: "FETCH_CAMPANIA_CATALOG_ERROR",
  getAllcampanias: "FETCH_CAMPANIAS_ERROR",
  postCampania: "CREATE_CAMPANIA_ERROR",
  getCampania: "GET_CAMPANIA_ERROR",
  updateCampania: "UPDATE_CAMPANIA_ERROR",
  deleteCampania: "DEL_CAMPANIA_ERROR",
};

const municipioErrorMessages = {
  getMunicipioCatalogo: "FETCH_MUNICIPIO_CATALOG_ERROR",
  getAllMunicipios: "FETCH_MUNICIPIOS_ERROR",
  postMunicipio: "CREATE_MUNICIPIO_ERROR",
  getMunicipio: "GET_MUNICIPIO_ERROR",
  updateMunicipio: "UPDATE_MUNICIPIO_ERROR",
  deleteMunicipio: "DEL_MUNICIPIO_ERROR",
};

const distritoLocalErrorMessages = {
  getDistritoLocalCatalogo: "",
  getAllDistritosLocales: "FETCH_DISTRITOS-LOCALES_ERROR",
  postDistritoLocal: "CREATE_DISTRITO-LOCAL_ERROR",
  getDistritoLocal: "GET_DISTRITO-LOCAL_ERROR",
  updateDistritoLocal: "UPDATE_DISTRITO-LOCAL_ERROR",
  deleteDistritoLocal: "DEL_DISTRITO-LOCAL_ERROR",
};

const seccionErrorMessages = {
  getSeccionCatalogo: "FETCH_SECCION_CATALOG_ERROR",
  getAllSeccion: "FETCH_SECCIONES_ERROR",
  postSeccion: "CREATE_SECCION_ERROR",
  getSeccion: "GET_SECCION_ERROR",
  updateSeccion: "UPDATE_SECCION_ERROR",
  deleteSeccion: "DEL_SECCION_ERROR",
};

const usuarioErrorMessages = {
  getUsuarioCatalogo: "FETCH_USUARIO_CATALOG_ERROR",
  getAllUsuario: "FETCH_USUARIOS_ERROR",
  postUsuario: "CREATE_USUARIO_ERROR",
  getUsuario: "GET_USUARIO_ERROR",
  updateUsuario: "UPDATE_USUARIO_ERROR",
  deleteUsuario: "DEL_USUARIO_ERROR",
  clearUsuario: "CLEAR_USUARIO",
};

const rolErrorMessages = {
  getRolCatalogo: "FETCH_ROL-CATALOG_ERROR",
  getAllRoles: "FETCH_ROLES_ERROR",
  postRol: "CREATE_ROL_ERROR",
  getRol: "GET_ROL_ERROR",
  updateRol: "UPDATE_ROL_ERROR",
  deleteRol: "DEL_ROL_ERROR",
};

const clienteErrorMessages = {
  getClienteCatalogo: "FETCH_CUSTOMER_CATALOG_ERROR",
  getAllClientes: "FETCH_CUSTOMERS_ERROR",
  postCliente: "CREATE_CUSTOMER_ERROR",
  getCliente: "GET_CUSTOMER_ERROR",
  updateCliente: "UPDATE_CUSTOMER_ERROR",
  deleteCliente: "DEL_CUSTOMER_ERROR",
};

const activadoErrorMessages = {
  getActivadoCatalogo: "FETCH_ACTIVADO_CATALOG_ERROR",
  getAllActivados: "FETCH_ACTIVADOS_ERROR",
  postActivado: "CREATE_ACTIVADO_ERROR",
  getActivado: "GET_ACTIVADO_ERROR",
  updateActivado: "UPDATE_ACTIVADO_ERROR",
  deleteActivado: "DEL_ACTIVADO_ERROR",
  clearActivado: "CLEAR_ACTIVADO_ERROR",
};

const coloniaErrorMessages = {
  getColoniaCatalogo: "FETCH_COLONIA_CATALOG_ERROR",
  getAllColonias: "FETCH_COLONIAS_ERROR",
  postColonia: "CREATE_COLONIA_ERROR",
  getColonia: "GET_COLONIA_ERROR",
  updateColonia: "UPDATE_COLONIA_ERROR",
  deleteColonia: "DEL_COLONIA_ERROR",
  clearColonia: "CLEAR_COLONIA_ERROR",
};

const datosPersonalesErrorMessages = {
  getDatosPersonales: "GET_DATOS_PERSONALES_ERROR",
  updateDatosPersonales: "UPDATE_DATOS_PERSONALES_ERROR",
  clearDatosPersonales: "CLEAR_DATOS_PERSONALES_ERROR",
};

const campaniaMetaErrorMessages = {
  getAllMetaCampanias: "FETCH_CAMPANIA_METAS_ERROR",
  updateMetaCampania: "UPDATE_CAMPANIA_META_ERROR",
  postMetaCampania: "CREATE_CAMPANIA_META_ERROR",
  clearMetaCampania: "CLEAR_CAMPANIA_META_ERROR",
};

const autoRegistroErrorMessages = {
  postAutoRegistro: "CREATE_AUTO_REGISTRO_ERROR",
  clearAutoRegistro: "CLEAR_AUTO_REGISTRO_ERROR",
};

const campaniaAvanceErrorMessages = {
  getAllCampaniaAvance: "FETCH_CAMPANIA_AVANCE_ERROR",
};

const preguntaErrorMessages = {
  getPreguntaCatalogo: "FETCH_PREGUNTA_CATALOG_ERROR",
  getAllPreguntas: "FETCH_PREGUNTAS_ERROR",
  postPregunta: "CREATE_PREGUNTA_ERROR",
  getPregunta: "GET_PREGUNTA_ERROR",
  updatePregunta: "UPDATE_PREGUNTA_ERROR",
  deletePregunta: "DEL_PREGUNTA_ERROR",
  clearPregunta: "CLEAR_PREGUNTA_ERROR",
};

const opcionPreguntaErrorMessages = {
  getÖpcionPreguntaCatalogo: "FETCH_OPCION_PREGUNTA_CATALOG_ERROR",
  getAllÖpcionPreguntas: "FETCH_OPCION_PREGUNTAS_ERROR",
  postÖpcionPregunta: "CREATE_OPCION_PREGUNTA_ERROR",
  getÖpcionPregunta: "GET_OPCION_PREGUNTA_ERROR",
  updateÖpcionPregunta: "UPDATE_OPCION_PREGUNTA_ERROR",
  deleteÖpcionPregunta: "DEL_OPCION_PREGUNTA_ERROR",
  clearÖpcionPregunta: "CLEAR_OPCION_PREGUNTA_ERROR",
};

const encuestaErrorMessages = {
  getEncuestaCatalogo: "FETCH_ENCUESTA_CATALOG_ERROR",
  getAllEncuestas: "FETCH_ENCUESTAS_ERROR",
  postEncuesta: "CREATE_ENCUESTA_ERROR",
  getEncuesta: "GET_ENCUESTA_ERROR",
  updateEncuesta: "UPDATE_ENCUESTA_ERROR",
  deleteEncuesta: "DEL_ENCUESTA_ERROR",
};

const misEncuestaErrorMessages = {
  getMiEncuestaCatalogo: "FETCH_MI_ENCUESTA_CATALOG_ERROR",
  getAllMisEncuestas: "FETCH_MI_ENCUESTAS_ERROR",
  postMiEncuesta: "CREATE_MI_ENCUESTA_ERROR",
  getMiEncuesta: "GET_MI_ENCUESTA_ERROR",
  updateMiEncuesta: "UPDATE_MI_ENCUESTA_ERROR",
  deleteMiEncuesta: "DEL_MI_ENCUESTA_ERROR",
};

const EncuestaUsuarioErrorMessages = {
  getAllEncuestaUsuarios: "FETCH_ENCUESTA_USUARIOS_ERROR",
  postEncuestaUsuario: "CREATE_ENCUESTA_USUARIO_ERROR",
  getEncuestaUsuario: "GET_ENCUESTA_USUARIO_ERROR",
  updateEncuestaUsuario: "UPDATE_ENCUESTA_USUARIO_ERROR",
  deleteEncuestaUsuario: "DEL_ENCUESTA_USUARIO_ERROR",
};

const postErrorMessages = {
  getAllPosts: "FETCH_POSTS_ERROR",
  postPost: "CREATE_POST_ERROR",
  getPost: "GET_POST_ERROR",
  updatePost: "UPDATE_POST_ERROR",
  deletePost: "DEL_POST_ERROR",
};

const misPostErrorMessages = {
  getAllMisPosts: "FETCH_MI_POSTS_ERROR",
  postMiPost: "CREATE_MI_POST_ERROR",
  getMiPost: "GET_MI_POST_ERROR",
  updateMiPost: "UPDATE_MI_POST_ERROR",
  deleteMiPost: "DEL_MI_POST_ERROR",
};

const postInteraccionErrorMessages = {
  getAllPostInteracciones: "FETCH_POST_INTERACCIONES_ERROR",
  postPostInteraccion: "CREATE_POST_INTERACCION_ERROR",
  getPostInteraccion: "GET_POST_INTERACCION_ERROR",
  updatePostInteraccion: "UPDATE_POST_INTERACCION_ERROR",
  deletePostInteraccion: "DEL_POST_INTERACCION_ERROR",
  clearPostInteraccion: "CLEAR_POST_INTERACCION_ERROR",
};

const preferenciaErrorMessages = {
  getAllPreferencias: "FETCH_PREFERENCIAS_ERROR",
  postPreferencia: "CREATE_PREFERENCIA_ERROR",
  getPreferencia: "GET_PREFERENCIA_ERROR",
  updatePreferencia: "UPDATE_PREFERENCIA_ERROR",
  deletePreferencia: "DEL_PREFERENCIA_ERROR",
};

const misPreferenciaErrorMessages = {
  getMiPreferenciaCatalogo: "FETCH_MI_PREFERENCIA_CATALOG_ERROR",
  getAllMisPreferencias: "FETCH_MI_PREFERENCIAS_ERROR",
  postMiPreferencia: "CREATE_MI_PREFERENCIA_ERROR",
  getMiPreferencia: "GET_MI_PREFERENCIA_ERROR",
  updateMiPreferencia: "UPDATE_MI_PREFERENCIA_ERROR",
  deleteMiPreferencia: "DEL_MI_PREFERENCIA_ERROR",
};

const encuestaResumenErrorMessages = {
  getEncuestaResumenCatalogo: "FETCH_ENCUESTA_RESUMEN_CATALOG_ERROR",
  getAllEncuestasResumen: "FETCH_ENCUESTAS_RESUMEN_ERROR",
  postEncuestaResumen: "CREATE_ENCUESTA_RESUMEN_ERROR",
  getEncuestaResumen: "GET_ENCUESTA_RESUMEN_ERROR",
  updateEncuestaResumen: "UPDATE_ENCUESTA_RESUMEN_ERROR",
  deleteEncuestaResumen: "DEL_ENCUESTA_RESUMEN_ERROR",
};

const usuarioTerritorioErrorMessages = {
  getUsuarioTerritorioCatalogo: "USUARIO_TERRITORIO_CATALOG_ERROR",
  getAllUsuarioTerritorio: "FETCH_USUARIOS_TERRITORIO_ERROR",
};

const storageErrorMessages = {
  postStorage: "CREATE_STORAGE_ERROR",
  clearStorage: "CLEAR_STORAGE_ERROR",
};

const arbolErrorMessages = {
  getAllArbol: "FETCH_ARBOL_ERROR",
  clearArbol: "CLEAR_ARBOL_ERROR",
};

const campaniaTareaErrorMessages = {
  getAllCampaniaTareas: "FETCH_CAMPANIA_TASKS_ERROR",
  updateCampaniaTarea: "UPDATE_CAMPANIA_TASK_ERROR",
  clearCampaniaTarea: "CLEAR_CAMPANIA_TASK_ERROR",
};

const reportarErrorMessages = {
  postReportar: "CREATE_REPORTAR_ERROR",
  getReportar: "GET_REPORTAR_ERROR",
  updateReportar: "UPDATE_REPORTAR_ERROR",
  deleteReportar: "DEL_REPORTAR_ERROR",
  clearReportar: "CLEAR_REPORTAR_ERROR",
};

const apiErrorMessage = {
  getModuloCatalogo: "FETCH_MODULO_CATALOG_ERROR",
  getGeneroCatalogo: "FETCH_GENERO_CATALOG_ERROR",
  getTipoPreguntaCatalogo: "FETCH_TIPO_PREGUNTA_CATALOG_ERROR",
  ambitoTerritorialCatalogo: "FETCH_AMBITO_TERRITORIAL_CATALOG_ERROR",
  getAgenteCatalogo: "FETCH_AGENTE_CATALOG_ERROR",

  ...entidadErrorMessages,
  ...distritoFederalErrorMessages,
  ...campaniaErrorMessages,
  ...municipioErrorMessages,
  ...distritoLocalErrorMessages,
  ...seccionErrorMessages,
  ...usuarioErrorMessages,
  ...rolErrorMessages,
  ...clienteErrorMessages,
  ...activadoErrorMessages,
  ...coloniaErrorMessages,
  ...datosPersonalesErrorMessages,
  ...campaniaMetaErrorMessages,
  ...autoRegistroErrorMessages,
  ...campaniaAvanceErrorMessages,
  ...encuestaErrorMessages,
  ...preguntaErrorMessages,
  ...opcionPreguntaErrorMessages,
  ...misEncuestaErrorMessages,
  ...misEncuestaErrorMessages,
  ...EncuestaUsuarioErrorMessages,
  ...postErrorMessages,
  ...misPostErrorMessages,
  ...postInteraccionErrorMessages,
  ...preferenciaErrorMessages,
  ...misPreferenciaErrorMessages,
  ...encuestaResumenErrorMessages,
  ...usuarioTerritorioErrorMessages,
  ...storageErrorMessages,
  ...arbolErrorMessages,
  ...campaniaTareaErrorMessages,
  ...reportarErrorMessages,
};

export const getCatalogAction = (entity, filtro = "") => (dispatch) => {
  let url = apiURL[entity];
  let successMessage = apiSuccessMessage[entity];
  let errorMessage = apiErrorMessage[entity];
  return api
    .get(url + `?${filtro}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((data) => {
      if (!data.data) {
        dispatch({
          type: errorMessage,
          payload: { error: data.data?.errors[0].message },
        });
      } else {
        dispatch({
          type: successMessage,
          payload: data.data,
        });
      }
    })
    .catch((err) => {
      let errors = getErrors(err);

      dispatch({
        type: errorMessage,
        payload: { error: errors },
      });
    });
};

export const createAction = (entity, newObj) => (dispatch) => {
  let url = apiURL[entity];
  let successMessage = apiSuccessMessage[entity];
  let errorMessage = apiErrorMessage[entity];
  return api
    .post(url, newObj, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((data) => {
      if (data.data.errors) {
        dispatch({
          type: errorMessage,
          payload: { error: data.data?.errors[0].message },
        });
      } else {
        dispatch({
          type: successMessage,
          payload: data.data,
        });
      }
    })
    .catch((err) => {
      let errors = getErrors(err);

      dispatch({
        type: errorMessage,
        payload: { error: errors },
      });
    });
};

export const uploadAction = (entity, newObj) => (dispatch) => {
  let url = apiURL[entity];
  let successMessage = apiSuccessMessage[entity];
  let errorMessage = apiErrorMessage[entity];
  return api
    .post(url, newObj, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        contentType: "multipart/form-data",
      },
    })
    .then((data) => {
      if (!data.data.id) {
        dispatch({
          type: errorMessage,
          payload: { error: "Error al subir el archivo!" },
        });
      } else {
        dispatch({
          type: successMessage,
          payload: data.data,
        });
      }
    })
    .catch((err) => {
      let errors = getErrors(err);

      dispatch({
        type: errorMessage,
        payload: { error: errors },
      });
    });
};

export const updateAction = (entity, id, objCustomer) => (dispatch) => {
  let url = apiURL[entity];
  let successMessage = apiSuccessMessage[entity];
  let errorMessage = apiErrorMessage[entity];
  return api
    .put(url + id, objCustomer, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((data) => {
      if (data.data.errors) {
        dispatch({
          type: errorMessage,
          payload: { error: data.data?.errors[0].message },
        });
      } else {
        dispatch({
          type: successMessage,
          payload: data.data,
        });
      }
    })
    .catch((err) => {
      let errors = getErrors(err);

      dispatch({
        type: errorMessage,
        payload: { error: errors },
      });
    });
};

export const getAllAction = (entitty, pageNumber, pageSize, filtro) => (
  dispatch
) => {
  let url = apiURL[entitty];
  let successMessage = apiSuccessMessage[entitty];
  let errorMessage = apiErrorMessage[entitty];
  return api
    .get(url + `?pageNumber=${pageNumber}&pageSize=${pageSize}&${filtro}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((data) => {
      if (!data.data.success) {
        dispatch({
          type: errorMessage,
          payload: { error: data.data?.errors[0].message },
        });
      } else {
        dispatch({
          type: successMessage,
          payload: data.data,
        });
      }
    })
    .catch((err) => {
      let errors = getErrors(err);

      dispatch({
        type: errorMessage,
        payload: { error: errors },
      });
    });
};

export const deleteAction = (entity, id, activo) => (dispatch) => {
  let url = apiURL[entity];
  let successMessage = apiSuccessMessage[entity];
  let errorMessage = apiErrorMessage[entity];

  return api
    .delete(url + `${id}?activo=${activo}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((data) => {
      dispatch({
        type: successMessage,
        payload: data,
      });
    })
    .catch((err) => {
      let errors = getErrors(err);

      dispatch({
        type: errorMessage,
        payload: { error: errors },
      });
    });
};

export const getAction = (entity, id, filtro = "") => (dispatch) => {
  let url = apiURL[entity];
  let successMessage = apiSuccessMessage[entity];
  let errorMessage = apiErrorMessage[entity];
  return api
    .get(url + `${id}?${filtro}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((data) => {
      dispatch({
        type: successMessage,
        payload: data.data,
      });
    })
    .catch((err) => {
      let errors = getErrors(err);

      dispatch({
        type: errorMessage,
        payload: { error: errors },
      });
    });
};

export const getActionOffline = function (entity, id) {
  let successMessage = apiSuccessMessage[entity];

  return {
    type: successMessage,
    payload: { id: id },
  };
};

export const clearAction = function (entity) {
  let successMessage = apiSuccessMessage[entity];

  return {
    type: successMessage,
  };
};

const getErrors = (err) => {
  let errors = "Error!";
  if (typeof err === "object") {
    let errArray = Object.keys(err).map((key) => err[key]);
    if (errArray.length > 0) errors = errArray.join("\n");
  }
  return errors;
};
