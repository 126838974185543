import React from "react";
import {
  createAction,
  clearAction,
  getCatalogAction,
} from "../../../actions/action-template";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { withSnackbar } from "notistack";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import {
  Box,
  AppBar,
  Toolbar,
  Dialog,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import Transition from "../../tools/transition";
import emailValidator from "../../validators/email-validator";
import getGeoReference from "../../../apis/geo-reference-api";
import TerminosCondiciones from "../../tools/terminos";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
  formButton: {
    marginRight: "10px",
  },
  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
});

class AutoRegistro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aceptarTerminos: false,
      submitted: false,
      isOpenDialog: isMobile,
      nombres: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      login: "",
      telefono: "",
      rol: 0,

      seccion: "",
      calle: "",
      numeroExterior: "",
      numeroInterior: "",
      colonia: "",
      codigoPostal: "",
      generoId: "",
      edad: "",
    };
    this.props.getCatalogAction("getGeneroCatalogo");
  }

  showError = (value) => {
    return this.state.submitted && !value ? true : false;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleChangeNumber = (e) => {
    const { name, value } = e.target;
    let number = parseInt(value);
    number = Number.isNaN(number) ? "" : value;
    this.setState({ [name]: number });
  };

  handleSubmit = (e) => {
    if (!this.state.aceptarTerminos) {
      this.props.enqueueSnackbar("Debe aceptar los términos y condiciones!", {
        variant: "warning",
      });
      return;
    }
    const query = new URLSearchParams(this.props.location.search);
    const clienteId = query.get("c");
    const campaniaId = query.get("ca");
    const userId = query.get("u");

    if (!clienteId || !campaniaId || !userId) {
      this.props.enqueueSnackbar(
        "El enlace para el registro de usuario es incorrecto!",
        {
          variant: "warning",
        }
      );
    }

    this.setState({ submitted: true });
    const {
      nombres,
      apellidoPaterno,
      apellidoMaterno,
      telefono,
      seccion,
      calle,
      numeroExterior,
      numeroInterior,
      colonia,
      codigoPostal,
      login,
      generoId,
      edad,
    } = this.state;
    if (
      nombres &&
      apellidoPaterno &&
      telefono &&
      seccion &&
      login &&
      emailValidator(login)
    ) {
      getGeoReference({
        calle,
        numeroExterior,
        numeroInterior,
        colonia,
        codigoPostal,
      }).then((geoReferencia) => {
        this.props.createAction("postAutoRegistro", {
          login,
          clienteId,
          campaniaId,
          activado: {
            nombres,
            apellidoPaterno,
            apellidoMaterno,
            telefono,
            seccion: seccion ? seccion : 0,
            calle,
            numeroExterior,
            numeroInterior,
            colonia,
            codigoPostal,
            email: login,
            EmailUsuarioInvitacion: userId,
            generoId,
            edad: edad === "" ? 0 : edad,
            ...geoReferencia,
          },
        });
      });
    } else {
      this.props.enqueueSnackbar("Debe ingresar los campos marcados con *", {
        variant: "warning",
      });
    }
  };

  componentDidUpdate() {
    const {
      createAutoRegistroError,
      createAutoRegistro,
      autoRegistro,
    } = this.props;

    if (createAutoRegistroError) {
      this.props.enqueueSnackbar(createAutoRegistroError, {
        variant: "error",
      });
      this.props.clearAction("clearAutoRegistro");
    } else if (createAutoRegistro) {
      this.props.clearAction("clearAutoRegistro");

      this.setState(
        {
          isOpenDialog: false,
        },
        () => {
          this.props.history.push("/");

          this.props.enqueueSnackbar(
            "Se ha enviado un correo con instrucciones para ingresar al sistema!",
            {
              variant: "success",
            }
          );
          this.props.enqueueSnackbar(
            `Felicidades te has unidoo a la red ${autoRegistro.nombre}`,
            {
              variant: "success",
            }
          );
        }
      );
    }
  }

  handleChangeCheck = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
  };

  renderContent() {
    const {
      nombres,
      apellidoPaterno,
      apellidoMaterno,
      login,
      telefono,
      seccion,
      calle,
      numeroExterior,
      numeroInterior,
      colonia,
      codigoPostal,
      generoId,
      edad,
    } = this.state;

    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(nombres)}
            required
            label="Nombre(s)"
            name="nombres"
            autoFocus
            value={nombres}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(apellidoPaterno)}
            required
            label="Apellido Paterno"
            name="apellidoPaterno"
            value={apellidoPaterno}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Apellido Materno"
            name="apellidoMaterno"
            value={apellidoMaterno}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl size="small" className={classes.formControl}>
            <InputLabel id="lblGenero">Género</InputLabel>
            <Select
              labelId="lblGenero"
              id="generoId"
              value={generoId}
              name="generoId"
              onChange={this.handleChange}
            >
              {this.props.generoCatalog.map((p) => {
                return (
                  <MenuItem key={p.value} value={p.value}>
                    {p.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Edad"
            name="edad"
            value={edad}
            inputProps={{ maxLength: 2 }}
            type="text"
            onChange={this.handleChangeNumber}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            required
            error={!emailValidator(login) && this.state.submitted}
            label="Correo Electrónico"
            name="login"
            value={login}
            onChange={this.handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(telefono)}
            required
            label="Teléfono"
            name="telefono"
            value={telefono}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>

        <Grid item xs={12}>
          <Box mt={3}>
            <Typography variant="caption" color="primary">
              Dirección (Credencial Elector)
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            className={classes.formControl}
            label="Calle"
            name="calle"
            value={calle}
            inputProps={{ maxLength: 128 }}
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Número exterior"
            name="numeroExterior"
            value={numeroExterior}
            inputProps={{ maxLength: 32 }}
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Número interior"
            name="numeroInterior"
            value={numeroInterior}
            inputProps={{ maxLength: 32 }}
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <TextField
            className={classes.formControl}
            label="Colonia"
            name="colonia"
            value={colonia}
            inputProps={{ maxLength: 128 }}
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Código Postal"
            name="codigoPostal"
            value={codigoPostal}
            inputProps={{ maxLength: 5 }}
            type="text"
            onChange={this.handleChangeNumber}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(seccion)}
            required
            type="text"
            label="Sección"
            name="seccion"
            value={seccion}
            onChange={this.handleChangeNumber}
            inputProps={{ maxLength: 4 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={3}>
            <Grid container>
              <Grid item xs={1} align="right">
                <FormControl style={{ marginTop: 0 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.aceptarTerminos}
                        onChange={this.handleChangeCheck}
                        name="aceptarTerminos"
                      />
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TerminosCondiciones></TerminosCondiciones>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {isBrowser && (
          <Grid item xs={12}>
            <Box align="right" pt={4}>
              <Button
                className={classes.formButton}
                variant="contained"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.history.push("/");
                  });
                }}
              >
                Cancelar
              </Button>

              <Button
                className={classes.formButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.handleSubmit();
                }}
              >
                Guardar
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  }

  renderBrowser() {
    return (
      <Box p={5}>
        {!this.state.clienteId && (
          <Typography variant="h4">Registro de usuario</Typography>
        )}

        {this.renderContent()}
      </Box>
    );
  }

  renderMobile() {
    const { classes } = this.props;
    return (
      <Box>
        <Dialog
          fullScreen
          open={this.state.isOpenDialog}
          onClose={() => this.setState({ isOpenDialog: false })}
          TransitionComponent={Transition}
          scroll="body"
          style={{ paddingTop: 40 }}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Registro de usuario
              </Typography>
            </Toolbar>
          </AppBar>
          <Box py={3} px={1}>
            {this.renderContent()}
            <div style={{ marginBottom: 100 }}></div>
          </Box>
          <AppBar
            position="fixed"
            color="primary"
            className={classes.appBottomBar}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.history.push("/");
                  });
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  cancelar
                </Typography>
              </IconButton>
              <div className={classes.grow} />

              <IconButton
                edge="end"
                color="inherit"
                onClick={() => {
                  this.handleSubmit();
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  Guardar
                </Typography>
              </IconButton>
            </Toolbar>
          </AppBar>
        </Dialog>
      </Box>
    );
  }

  render() {
    return (
      <Box>
        <BrowserView>{this.renderBrowser()}</BrowserView>
        <MobileView>{this.renderMobile()}</MobileView>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    createAutoRegistroError: state.autoRegistroState.createAutoRegistroError,
    createAutoRegistro: state.autoRegistroState.createAutoRegistro,
    autoRegistro: state.autoRegistroState.autoRegistro,
    generoCatalog: state.generoState.generoCatalog,
  };
};

export default connect(mapStatetoProps, {
  clearAction,
  getCatalogAction,
  createAction,
})(withStyles(useStyles)(withSnackbar(AutoRegistro)));
