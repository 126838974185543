const defaultState = {
  createMunicipio: false,
  createMunicipioError: "",
  municipio: {},
  delMunicipio: false,
  delMunicipioError: "",
  getMunicipioError: "",

  municipios: [],
  municipioCatalog: [],

  totalList: 0,
};

export const municipioReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_MUNICIPIOS":
      return {
        ...state,
        municipios: action.payload.data,
        totalList: action.payload.totalRegs,
      };
    case "FETCH_MUNICIPIO_CATALOG":
      return {
        ...state,
        municipioCatalog: action.payload,
      };

    case "CREATE_MUNICIPIO":
      return {
        ...state,
        municipio: { ...action.payload },
        createMunicipio: true,
      };
    case "CREATE_MUNICIPIO_ERROR":
      return { ...state, createMunicipioError: action.payload.error };
    case "UPDATE_MUNICIPIO":
      return {
        ...state,
        municipio: { ...action.payload },
        updateMunicipio: true,
      };
    case "UPDATE_MUNICIPIO_ERROR":
      return { ...state, updateMunicipioError: action.payload.error };
    case "CLEAR_MUNICIPIO":
      return {
        ...state,
        municipio: {},
        createMunicipio: false,
        createMunicipioError: "",
        delMunicipio: false,
        delMunicipioError: "",
        getMunicipio: false,
        getMunicipioError: "",
        updateMunicipio: false,
        updateMunicipioError: "",
      };
    case "DEL_MUNICIPIO":
      return { ...state, delMunicipio: true };
    case "DEL_MUNICIPIO_ERROR":
      return { ...state, delMunicipioError: action.payload.error };
    case "GET_MUNICIPIO":
      return {
        ...state,
        getMunicipio: true,
        municipio: { ...action.payload },
      };
    case "GET_MUNICIPIO_ERROR":
      return { ...state, getMunicipioError: action.payload.error };
    default:
      return {
        ...state,
      };
  }
};
