import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Dialog,
  Typography,
  IconButton,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ButtonGroup,
} from "@material-ui/core";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import Transition from "../tools/transition";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import { connect } from "react-redux";
import {
  createAction,
  clearAction,
  updateAction,
  getCatalogAction,
  deleteAction,
  getAction,
} from "../../actions/action-template";
import PreguntaDetail from "../encuestas/pregunta-detail";
import ConfirmDialog from "../tools/confirm-dialog";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
  formButton: {
    marginRight: "10px",
  },
  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
});

class PreferenciasDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      isOpenDialog: true,
      clienteId:
        this.props.customerId !== ""
          ? this.props.customerId
          : this.props.preferenciaClienteId,
      campaniaId:
        this.props.campaniaId !== ""
          ? this.props.campaniaId
          : this.props.preferenciaCampaniaId > 0
          ? this.props.preferenciaCampaniaId
          : "",

      id: 0,
      nombre: "",
      esPublica: false,
      esPublicada: false,
      inicioVigencia: "",
      finVigencia: "",
      preguntas: [],
      agentes: [],
      esDetallePregunta: false,
      preguntaEdicion: "",

      segmentacion: {
        id: 0,
        entidadId: 0,
        municipioId: 0,
        coloniaId: 0,
        seccionId: 0,
        esHombres: false,
        esMujeres: false,
        edadInicial: 0,
        edadFinal: 0,
      },

      isDeleteDialogOpen: false,
      selectedIdForDelete: "",

      titleConfirm: "Eliminar",
      textConfirm: "¿Realmente desea eliminar?",
    };
    this.props.getCatalogAction("getClienteCatalogo");
    this.props.getCatalogAction("getTipoPreguntaCatalogo");

    if (this.props.campaniaId) {
      this.props.getCatalogAction(
        "getCampaniaCatalogo",
        `clienteId=${this.props.customerId}&campaniaId=${this.props.campaniaId}`
      );
      trackPromise(
        this.props.getAction("getPreferencia", this.props.campaniaId)
      );
    } else if (this.props.customerId) {
      this.props.getCatalogAction(
        "getCampaniaCatalogo",
        `clienteId=${this.props.customerId}`
      );
    }
  }

  showError = (value) => {
    return this.state.submitted && !value ? true : false;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === "clienteId") {
        this.props.getCatalogAction(
          "getCampaniaCatalogo",
          `clienteId=${value}`
        );
      } else if (name === "campaniaId") {
        trackPromise(this.props.getAction("getPreferencia", value));
      }
    });
  };

  padStart(d) {
    if (d) return d.toString().padStart(2, "0");
    else return "";
  }

  confirmDelete = (r) => {
    let textConfirm = "";
    let titleConfirm = "";

    titleConfirm = "Eliminar Pregunta";
    textConfirm = "¿Realmente desea eliminar el registro?";
    if (r.id > 0) {
      this.setState({
        isDeleteDialogOpen: true,
        selectedIdForDelete: r.id,
        textConfirm: textConfirm,
        titleConfirm: titleConfirm,
      });
    } else {
      let preguntas = [...this.state.preguntas];
      let idx = 0;
      preguntas = preguntas.filter((e) => e.idx !== r.idx);
      preguntas = preguntas.map((e) => {
        return { ...e, idx: idx++ };
      });
      this.setState({ preguntas: preguntas });
      this.props.enqueueSnackbar("Los datos se guardaron correctamente", {
        variant: "success",
      });
    }
  };

  onDeleteDialogClose = (res) => {
    if (res) {
      this.props.deleteAction("deletePregunta", this.state.selectedIdForDelete);
    }
    this.setState({ isDeleteDialogOpen: false });
  };

  handleSubmit = (e) => {
    const {
      nombre,
      esPublica,
      campaniaId,
      inicioVigencia,
      finVigencia,
      preguntas,
      segmentacion,
      esPublicada,
    } = this.state;

    if (!campaniaId) {
      this.props.enqueueSnackbar("Debe asignar un cliente y campaña!", {
        variant: "warning",
      });
      return;
    }
    if (segmentacion.edadInicial > segmentacion.edadFinal) {
      this.props.enqueueSnackbar(
        "La edad inicial debe ser menor a la edad final!",
        {
          variant: "warning",
        }
      );
      return;
    }
    if (nombre && campaniaId && inicioVigencia) {
      if (+new Date(inicioVigencia) > +new Date(finVigencia)) {
        this.props.enqueueSnackbar(
          "El incio de la vigencia debe ser menor al fin de vigencia",
          {
            variant: "warning",
          }
        );
        return;
      }

      this.setState({ submitted: true });

      if (!this.state.id) {
        trackPromise(
          this.props.createAction("postPreferencia", {
            nombre,
            esPublica,
            campaniaId,
            inicioVigencia,
            finVigencia,
            preguntas,
            esPublicada,
          }),
          () => {
            this.setState({ submitted: false });
          }
        );
      } else {
        trackPromise(
          this.props.updateAction("updatePreferencia", this.state.id, {
            id: this.state.id,
            nombre,
            esPublica,
            campaniaId,
            inicioVigencia,
            finVigencia,
            preguntas,

            esPublicada,
          }),
          () => {
            this.setState({ submitted: false });
          }
        );
      }
    } else {
      this.props.enqueueSnackbar("Debe ingresar los campos marcados con *", {
        variant: "warning",
      });
    }
  };

  componentDidUpdate() {
    const {
      createPreferencia,
      createPreferenciaError,
      getPreferencia,
      getPreferenciaError,
      updatePreferenciaError,
      updatePreferencia,
      delPregunta,
      delPreguntaError,
      preferencia,
    } = this.props;

    if (delPreguntaError) {
      this.props.enqueueSnackbar(delPreguntaError, {
        variant: "error",
      });
      this.props.clearAction("clearPregunta");
    } else if (delPregunta) {
      this.props.clearAction("clearPregunta");

      let preguntas = [...this.state.preguntas];
      let idx = 0;
      preguntas = preguntas.filter(
        (e) => e.id !== this.state.selectedIdForDelete
      );
      preguntas = preguntas.map((e) => {
        return { ...e, idx: idx++ };
      });

      if (this.state.selectedIdForDelete) {
        this.setState({ preguntas: preguntas, selectedIdForDelete: "" }, () => {
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        });
      }
    }

    if (createPreferenciaError) {
      this.props.enqueueSnackbar(createPreferenciaError, {
        variant: "error",
      });
      this.props.clearAction("clearPreferencia");
      this.setState({ submitted: false });
    } else if (createPreferencia) {
      this.props.clearAction("clearPreferencia");

      this.setState(
        {
          isOpenDialog: false,
          submitted: false,
        },
        () => {
          //this.props.resultOp(preferencia);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (updatePreferenciaError) {
      this.props.enqueueSnackbar(updatePreferenciaError, {
        variant: "error",
      });
      this.props.clearAction("clearPreferencia");
      this.setState({ submitted: false });
    } else if (updatePreferencia) {
      this.props.clearAction("clearPreferencia");

      this.setState(
        {
          isOpenDialog: false,
          submitted: false,
        },
        () => {
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (getPreferenciaError) {
      this.props.enqueueSnackbar(getPreferenciaError, {
        variant: "error",
      });
      this.props.clearAction("clearPreferencia");
    } else if (getPreferencia) {
      this.props.clearAction("clearPreferencia");
      let idx = 0;
      this.setState({
        nombre: preferencia.nombre,
        inicioVigencia: this.getFormatDate(preferencia.inicioVigencia),
        finVigencia: this.getFormatDate(preferencia.finVigencia),
        campaniaId: preferencia.campaniaId,
        esPublica: preferencia.esPublica,
        esPublicada: preferencia.esPublicada,
        id: preferencia.id,
        preguntas: preferencia.preguntas.map((p) => {
          return { ...p, idx: idx++ };
        }),
      });
    }
  }

  getFormatDate = (date) => {
    let d = new Date(date);
    if (d !== "-") {
      return (
        d.getFullYear() +
        "-" +
        this.padStart(d.getMonth() + 1) +
        "-" +
        this.padStart(d.getDate())
      );
    } else return d;
  };

  handleCheckChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  resultPregunta = (e) => {
    if (e) {
      let preguntas = this.state.preguntas;
      if (e.id > 0) {
        let preg = preguntas.find((p) => parseInt(e.id) === parseInt(p.id));
        preg.nombre = e.nombre;
        preg.esRespuestaMultiple = e.esRespuestaMultiple;
        preg.estatus = e.estatus;
        preg.tipoPreguntaId = e.tipoPreguntaId;
        preg.opciones = e.opciones;
      } else {
        e.idx = preguntas.length;
        preguntas.push(e);
      }
      this.setState({
        preguntas: preguntas,
        esDetallePregunta: false,
        preguntaEdicion: "",
      });
    } else this.setState({ esDetallePregunta: false, preguntaEdicion: "" });
  };

  handleChangeRow = (i, e) => {
    const { name, value, checked } = e.target;

    let preguntas = [...this.state.preguntas];
    let item = preguntas[i];
    if (name === "estatus") item[name] = checked;
    else item[name] = value;

    this.setState({ preguntas: preguntas });
  };

  getTipoPregunta(r) {
    let tipo = this.props.tipoPreguntaCatalog.find(
      (e) => parseInt(e.value) === parseInt(r.tipoPreguntaId)
    );
    if (tipo) return tipo.name;
    else return "";
  }

  renderContent() {
    const { campaniaId, clienteId } = this.state;
    const { classes } = this.props;
    return (
      <Grid container>
        {!this.props.customerId && !this.state.id && (
          <Grid item xs={12} md={3}>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="lblCliente">Cliente</InputLabel>
              <Select
                labelId="lblCliente"
                value={clienteId}
                name="clienteId"
                onChange={this.handleChange}
              >
                {this.props.clienteCatalog.map((p) => {
                  return (
                    <MenuItem key={p.value} value={p.value}>
                      {p.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
        {!this.props.campaniaId && !this.state.id && (
          <Grid item xs={12} md={3}>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="lblCampania">Campaña</InputLabel>
              <Select
                labelId="lblCampania"
                value={campaniaId}
                name="campaniaId"
                onChange={this.handleChange}
              >
                {this.props.campaniaCatalog.map((p) => {
                  return (
                    <MenuItem key={p.id} value={p.id}>
                      {p.nombre}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}

        {!this.state.esDetallePregunta && this.state.campaniaId && (
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography variant="button" color="primary">
                Preguntas
              </Typography>
              <Button
                onClick={() => {
                  this.setState({ esDetallePregunta: true });
                }}
              >
                <AddBoxIcon></AddBoxIcon>
                Agregar
              </Button>
            </Box>
          </Grid>
        )}
        {!this.state.esDetallePregunta && this.state.preguntas.length > 0 && (
          <Grid item xs={12}>
            <TableContainer>
              <Table
                size="small"
                className={classes.table}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Preguntas</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Activa</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.preguntas.map((row) => {
                    return (
                      <TableRow key={row.idx}>
                        <TableCell>{row.nombre}</TableCell>
                        <TableCell>{this.getTipoPregunta(row)}</TableCell>
                        <TableCell>
                          <Switch
                            checked={row.estatus}
                            onChange={(e) => {
                              this.handleChangeRow(row.idx, e);
                            }}
                            name="estatus"
                          />
                        </TableCell>
                        <TableCell align="right">
                          <ButtonGroup
                            size="small"
                            aria-label="small outlined button group"
                          >
                            <Button
                              color="secondary"
                              title="Eliminar"
                              onClick={() => {
                                this.confirmDelete(row);
                              }}
                            >
                              <CancelIcon></CancelIcon>
                            </Button>
                            <Button
                              color="primary"
                              title="Editar"
                              onClick={() => {
                                this.setState({
                                  esDetallePregunta: true,
                                  preguntaEdicion: row,
                                });
                              }}
                            >
                              <EditIcon></EditIcon>
                            </Button>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        {this.state.esDetallePregunta && (
          <Grid item xs={12}>
            <Box mb={2}>
              <PreguntaDetail
                resultPregunta={this.resultPregunta}
                encuestaId={this.state.id}
                preguntaEdicion={this.state.preguntaEdicion}
                esEditable={true}
              ></PreguntaDetail>
            </Box>
          </Grid>
        )}

        {isBrowser && !this.state.esDetallePregunta && this.state.campaniaId && (
          <Grid item xs={12}>
            <Box align="right" pt={4}>
              <Button
                className={classes.formButton}
                variant="contained"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.history.push("/");
                  });
                }}
              >
                Cancelar
              </Button>

              {
                <Button
                  disabled={this.state.submitted}
                  className={classes.formButton}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  Guardar
                </Button>
              }
            </Box>
          </Grid>
        )}
      </Grid>
    );
  }

  renderBrowser() {
    return (
      <Box px={2}>
        <Typography variant="h2">Preferencias</Typography>
        {this.renderContent()}
      </Box>
    );
  }

  renderMobile() {
    const { classes } = this.props;
    return (
      <Box>
        <Dialog
          fullScreen
          open={this.state.isOpenDialog}
          onClose={() => this.setState({ isOpenDialog: false })}
          TransitionComponent={Transition}
          scroll="body"
          style={{ paddingTop: 40 }}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Preferencias
              </Typography>
            </Toolbar>
          </AppBar>
          <Box py={3} px={1}>
            {this.renderContent()}
            <div style={{ marginBottom: 100 }}></div>
          </Box>
          <AppBar
            position="fixed"
            color="primary"
            className={classes.appBottomBar}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.history.push("/");
                  });
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  cancelar
                </Typography>
              </IconButton>
              <div className={classes.grow} />
              {
                <IconButton
                  disabled={this.state.submitted}
                  edge="end"
                  color="inherit"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                  aria-label="close"
                >
                  <Typography variant="button" display="block" gutterBottom>
                    Guardar
                  </Typography>
                </IconButton>
              }
            </Toolbar>
          </AppBar>
        </Dialog>
      </Box>
    );
  }

  render() {
    return (
      <Box>
        <BrowserView>{this.renderBrowser()}</BrowserView>
        <MobileView>{this.renderMobile()}</MobileView>
        <ConfirmDialog
          isDeleteDialogOpen={this.state.isDeleteDialogOpen}
          onDeleteDialogClose={this.onDeleteDialogClose}
          title={this.state.titleConfirm}
          message={this.state.textConfirm}
        ></ConfirmDialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    // createPreferencia: state.preferenciaState.createPreferencia,
    // createPreferenciaError: state.preferenciaState.createPreferenciaError,

    preferencia: state.preferenciaState.preferencia,

    getPreferencia: state.preferenciaState.getPreferencia,
    getPreferenciaError: state.preferenciaState.getPreferenciaError,

    updatePreferencia: state.preferenciaState.updatePreferencia,
    updatePreferenciaError: state.preferenciaState.updatePreferenciaError,

    tipoPreguntaCatalog: state.tipoPreguntaState.tipoPreguntaCatalog,

    campaniaCatalog: state.campaniaState.campaniaCatalog,
    generoCatalog: state.generoState.generoCatalog,

    clienteCatalog: state.customerState.clienteCatalog,
    campaniaAvances: state.avanceState.campaniaAvances,

    delPregunta: state.preguntaState.delPregunta,
    delPreguntaError: state.preguntaState.delPreguntaError,

    customerId: state.authState.user.customerId,
    campaniaId: state.authState.user.campaniaId,

    preferenciaClienteId: state.preferenciasGeneralState.clienteId,
    preferenciaCampaniaId: state.preferenciasGeneralState.campaniaId,
  };
};

export default connect(mapStatetoProps, {
  createAction,
  clearAction,
  updateAction,
  deleteAction,
  getCatalogAction,
  getAction,
})(withStyles(useStyles)(withSnackbar(PreferenciasDetail)));
