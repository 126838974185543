import React from "react";
import {
  Grid,
  Typography,
  FormControl,
  TextField,
  Checkbox,
  Select,
  InputLabel,
  MenuItem,
  Input,
  ListItemText,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import {
  getAllAction,
  clearAction,
  createAction,
} from "../../actions/action-template";

const useStyles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
  root: {
    width: "100%",
  },
  actions: {
    width: "100%",
  },
  media: {
    height: 140,
  },
});

class EncuestaPreferencias extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaniaId: "",
      encuestas: [],
      pageNumber: 0,
      pageSize: 10,
      recargado: false,
    };
  }
  componentDidMount() {
    if (this.props.usuarioCampaniaId > 0) {
      this.forceUpdate();
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  fetchList() {
    this.props.getAllAction(
      "getAllMisPreferencias",
      this.state.pageNumber,
      this.state.pageSize,
      `campaniaId=${this.props.campaniaId}`
    );
  }

  handleChangePreguntaCerrada = (p, e) => {
    let encuestas = [...this.state.encuestas];

    let encuesta = encuestas.find((e) => e.id === p.encuestaId);
    let pregunta = encuesta.preguntas.find((e) => e.id === p.id);
    const { value } = e.target;

    pregunta.respuesta = value;

    this.setState({ encuestas: encuestas }, () =>
      this.enviarRespuestas(encuesta)
    );
  };

  handleCheckChangeCerrada = (p, e) => {
    const { value } = e.target;

    let encuestas = [...this.state.encuestas];
    let encuesta = encuestas.find((e) => e.id === p.encuestaId);
    let pregunta = encuesta.preguntas.find((e) => e.id === p.id);
    let opcion = pregunta.opciones.find((e) => e.id === value[0]);
    opcion.seleccionada = !opcion.seleccionada;

    this.setState({ encuestas: encuestas }, () =>
      this.enviarRespuestas(encuesta)
    );
  };

  handleCheckChangeCerrada1Opcion = (p, e) => {
    const { value } = e.target;
    let encuestas = [...this.state.encuestas];

    let encuesta = encuestas.find((e) => e.id === p.encuestaId);
    let pregunta = encuesta.preguntas.find((e) => e.id === p.id);
    // if (!op) {
    pregunta.respuesta = parseInt(value);

    this.setState({ encuestas: encuestas }, () =>
      this.enviarRespuestas(encuesta)
    );
  };

  enviarRespuestas = (e) => {
    //  let valido = true;
    let respuestas = [];
    Object.entries(e.preguntas).forEach(([k, v]) => {
      v.error = false;
      //pregunta abierta

      if (v.tipoPreguntaId === 1) {
        if (!v.respuesta) {
          v.error = true;
          //      valido = false;
        } else {
          respuestas.push({
            encuestaId: e.id,
            preguntaId: v.id,
            opcionId: 0,
            respuesta: v.respuesta,
          });
        }
      }

      //pregunta cerrada multiple
      else if (v.tipoPreguntaId === 2 && v.esRespuestaMultiple) {
        if (
          v.opciones.filter(
            (o) =>
              (o.seleccionada === true && !o.requiereExtenderRespuesta) ||
              (o.seleccionada === true &&
                o.requiereExtenderRespuesta &&
                o.respuesta)
          ).length === 0
        ) {
          v.error = true;
          //     valido = false;
        } else {
          v.opciones
            .filter((o) => o.seleccionada)
            .map((o) => {
              respuestas.push({
                encuestaId: e.id,
                preguntaId: v.id,
                opcionId: o.id,
                respuesta: o.respuesta,
              });
              return o;
            });
        }
      }

      //pregunta cerrada
      else if (v.tipoPreguntaId === 2 && v.esRespuestaMultiple === false) {
        if (!v.respuesta) {
          v.error = true;
          //       valido = false;
        } else {
          let op = v.opciones.find((o) => o.id === v.respuesta);

          if (op.requiereExtenderRespuesta && !op.respuesta) {
            v.error = true;
            //       valido = false;
          } else {
            respuestas.push({
              encuestaId: e.id,
              preguntaId: v.id,
              opcionId: v.respuesta,
              respuesta: op?.respuesta,
            });
          }
        }
      }
    });

    // if (!valido) {
    //   this.props.enqueueSnackbar("Debe contestar todas las preguntas", {
    //     variant: "warning",
    //   });
    //   this.forceUpdate();
    // } else {
    this.props.actualizarPreferencias(respuestas);

    //   this.setState({ notificacion: false }, () =>
    //     this.props.createAction("postEncuestaUsuario", datos)
    //   );
    //}
  };

  renderPreguntaAbierta(r) {
    const { classes } = this.props;
    return (
      <Grid item xs={12} md={4} key={r.id}>
        <TextField
          size="small"
          className={classes.formControl}
          //error={r.error}
          name="respuesta"
          label={
            <Typography color={r.error === true ? "secondary" : "primary"}>
              {r.nombre}
            </Typography>
          }
          value={r.respuesta}
          onChange={(e) => this.handleChangePreguntaCerrada(r, e)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    );
  }

  renderOpcionesMultiples(r) {
    const { classes } = this.props;
    r.respuestas = [];
    return (
      <Grid item xs={12} md={4} key={r.id}>
        <FormControl className={classes.formControl}>
          <InputLabel id={"lbl" + r.id}>{r.nombre}</InputLabel>
          <Select
            labelId={"lbl" + r.id}
            multiple
            value={r.respuestas}
            onChange={(e) => this.handleCheckChangeCerrada(r, e)}
            input={<Input />}
            // renderValue={(selected) => selected.join(", ")}
            //   MenuProps={MenuProps}
          >
            {r.opciones.map((p) => (
              <MenuItem key={p.id} value={p.id}>
                <Checkbox checked={p.seleccionada} />
                <ListItemText primary={p.nombre} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={o.seleccionada}
                onChange={(e) => this.handleCheckChangeCerrada(r, o, e)}
                name="check"
              />
            }
            label={<Typography variant="caption"> {o.nombre}</Typography>}
          />
          {o.requiereExtenderRespuesta && o.seleccionada && (
            <Box mb={2} ml={4}>
              <TextField
                size="small"
                className={classes.formControl}
                error={o.error}
                name="respuesta"
                label={
                  <Typography
                    color={r.error === true ? "secondary" : "primary"}
                  ></Typography>
                }
                value={o.respuesta}
                onChange={(e) => this.handleCheckChangeCerrada(r, o, e, true)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          )} */}
      </Grid>
    );
  }
  renderOpciones(r) {
    const { classes } = this.props;

    return (
      <Grid item xs={12} md={4} key={r.id}>
        <FormControl size="small" className={classes.formControl}>
          <InputLabel id={"lbl" + r.id}>{r.nombre}</InputLabel>
          <Select
            labelId={"lbl" + r.id}
            value={r.respuesta}
            name={r.id?.toString()}
            onChange={(e) => this.handleCheckChangeCerrada1Opcion(r, e)}
          >
            <MenuItem key={""} value={""}>
              Seleccione
            </MenuItem>
            {r.opciones.map((p) => {
              return (
                <MenuItem key={p.id} value={p.id}>
                  {p.nombre}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    );
    // return r.opciones.map((o) => {
    //   return (
    //     <Grid item xs={12} md={4} key={r.id}>
    //       <FormControlLabel
    //         value={o.id}
    //         control={<Radio />}
    //         label={<Typography variant="caption">{o.nombre}</Typography>}
    //       />

    //       {o.requiereExtenderRespuesta && r.respuesta === o.id && (
    //         <Box mb={2} ml={4}>
    //           <TextField
    //             size="small"
    //             className={classes.formControl}
    //             error={r.error}
    //             name="respuesta"
    //             label={
    //               <Typography
    //                 color={r.error === true ? "secondary" : "primary"}
    //               ></Typography>
    //             }
    //             value={o.respuesta}
    //             onChange={(e) => this.handleCheckChangeCerrada1Opcion(r, e, o)}
    //             InputLabelProps={{
    //               shrink: true,
    //             }}
    //           />
    //         </Box>
    //       )}
    //     </Grid>
    //   );
    // });
  }

  renderPreguntaCerrada(r) {
    if (r.esRespuestaMultiple) return this.renderOpcionesMultiples(r);
    else return this.renderOpciones(r);
    // return (
    //   <Grid item xs={12} md={4} key={r.id}>
    //     <FormControl component="fieldset" className={classes.formControl}>
    //       <Typography color={r.error === true ? "secondary" : "primary"}>
    //         {r.nombre}
    //       </Typography>

    //       {r.esRespuestaMultiple ? (
    //         <FormGroup>{this.renderOpcionesMultiples(r, editable)}</FormGroup>
    //       ) : (
    //         <RadioGroup
    //           value={parseInt(r.respuesta)}
    //           onChange={(e) => this.handleCheckChangeCerrada1Opcion(r, e)}
    //         >
    //           {this.renderOpciones(r, editable)}
    //         </RadioGroup>
    //       )}
    //     </FormControl>
    //   </Grid>
    // );
  }

  render() {
    if (this.state.encuestas.length > 0) {
      return this.state.encuestas.map((r) => {
        return (
          <Grid container key={r.id}>
            {r.preguntas.map((p) => {
              if (p.tipoPreguntaId === 1)
                return this.renderPreguntaAbierta(p, r.editable);
              else if (p.tipoPreguntaId === 2)
                return this.renderPreguntaCerrada(p, r.editable);
              else return <div>por implementar!</div>;
            })}
          </Grid>
        );
      });
    } else return <div></div>;
  }

  componentDidUpdate() {
    const {
      createEncuestaUsuario,
      createEncuestaUsuarioError,
      encuestaUsuario,
    } = this.props;

    if (this.state.campaniaId !== this.props.campaniaId) {
      this.setState(
        { campaniaId: this.props.campaniaId, encuestas: [] },
        () => {
          this.fetchList();
        }
      );
    }

    if (
      this.state.encuestas.length === 0 &&
      this.props.misEncuestas?.length > 0
    ) {
      let encuestas = this.props.misEncuestas.map((e) => {
        let respuestas = this.props.misRespuestas;
        if (respuestas) {
          e.editable = false;
          e.preguntas = e.preguntas.map((p) => {
            let resPreg = respuestas.filter((o) => o.preguntaId === p.id);
            if (resPreg.length) {
              if (p.tipoPreguntaId === 1) {
                //abierta
                p.respuesta = resPreg[0].respuesta;
              } else if (p.tipoPreguntaId === 2) {
                if (p.esRespuestaMultiple) {
                  p.opciones = p.opciones.map((op) => {
                    let reOP = resPreg.find((_o) => _o.opcionId === op.id);
                    if (reOP) op.seleccionada = true;
                    if (op.requiereExtenderRespuesta && reOP)
                      op.respuesta = reOP.respuesta;
                    return { ...op };
                  });
                } else {
                  p.respuesta = resPreg[0].opcionId;
                  let op = p.opciones.find((_o) => _o.id === p.respuesta);

                  if (op.requiereExtenderRespuesta) {
                    op.respuesta = resPreg[0].respuesta;
                  }
                }
              }
            }
            return { ...p };
          });
        } else {
          e.editable = true;
        }
        return { ...e };
      });
      this.props.clearAction("clearMiPreferencia");

      this.setState({
        encuestas: encuestas,
      });
    }

    if (createEncuestaUsuarioError) {
      this.props.enqueueSnackbar(createEncuestaUsuarioError, {
        variant: "error",
      });
      this.props.clearAction("clearEncuestaUsuario");
    } else if (createEncuestaUsuario) {
      this.props.clearAction("clearEncuestaUsuario");
      if (this.state.notificacion === false) {
        this.props.enqueueSnackbar("Los encuesta se envió correctamiente!", {
          variant: "success",
        });
      }
      let encuestas = [...this.state.encuestas];
      let enc = encuestas.find((e) => e.id === encuestaUsuario.encuestaId);
      enc.editable = false;

      this.setState({ encuestas: encuestas, notificacion: true }, () => {});
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    misEncuestas: state.misPreferenciaState.misPreferencias,
    //misRespuestas: state.misPreferenciaState.misRespuestas,
    totalList: state.misPreferenciaState.totalList,

    preferenciaClienteId: state.preferenciasGeneralState.clienteId,
    preferenciaCampaniaId: state.preferenciasGeneralState.campaniaId,

    createEncuestaUsuario: state.encuestaUsuarioState.createEncuestaUsuario,
    createEncuestaUsuarioError:
      state.encuestaUsuarioState.createEncuestaUsuarioError,
    encuestaUsuario: state.encuestaUsuarioState.encuestaUsuario,
    usuarioCampaniaId: state.authState.user.campaniaId,
  };
};

export default connect(mapStatetoProps, {
  getAllAction,
  clearAction,

  createAction,
})(withStyles(useStyles)(withSnackbar(EncuestaPreferencias)));
