import React from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  FormControl,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import {
  getAllAction,
  clearAction,
  createAction,
  updateAction,
} from "../../actions/action-template";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    width: "95%",
    marginRight: "5px",
  },
  root: {
    width: "100%",
  },
  actions: {
    width: "100%",
  },
  media: {
    height: 140,
  },
});

class MisPreferencias extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      encuestas: [],
      respuestas: "",
      pageNumber: 0,
      pageSize: 10,
    };

    this.fetchList();
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  fetchList() {
    trackPromise(
      this.props.getAllAction(
        "getAllMisPreferencias",
        this.state.pageNumber,
        this.state.pageSize
      )
    );
  }

  handleChangePreguntaCerrada = (p, e) => {
    let encuestas = [...this.state.encuestas];

    let encuesta = encuestas.find((e) => e.id === p.encuestaId);
    let pregunta = encuesta.preguntas.find((e) => e.id === p.id);
    const { value } = e.target;

    pregunta.respuesta = value;
    //
    // i.respuesta = i;
    // let opciones = [...this.state.opciones];
    // let item = opciones.find((x) => x.idx === i);
    // if (name === "requiereExtenderRespuesta") item[name] = checked;
    // else item[name] = value;

    this.setState({ encuestas: encuestas });
  };

  handleCheckChangeCerrada = (p, o, e, mastexto) => {
    const { name, value, checked } = e.target;
    let encuestas = [...this.state.encuestas];

    let encuesta = encuestas.find((e) => e.id === p.encuestaId);
    let pregunta = encuesta.preguntas.find((e) => e.id === p.id);
    let opcion = pregunta.opciones.find((e) => e.id === o.id);
    if (name === "check") opcion.seleccionada = checked;
    else opcion.respuesta = value;
    this.setState({ encuestas: encuestas });
  };

  handleCheckChangeCerrada1Opcion = (p, e, op) => {
    const { value } = e.target;
    let encuestas = [...this.state.encuestas];

    let encuesta = encuestas.find((e) => e.id === p.encuestaId);
    let pregunta = encuesta.preguntas.find((e) => e.id === p.id);
    if (!op) {
      pregunta.respuesta = parseInt(value);
    } else {
      let _op = pregunta.opciones.find((o) => o.id === op.id);
      _op.respuesta = value;
    }
    this.setState({ encuestas: encuestas });
  };

  enviarRespuestas = (e) => {
    let valido = true;
    let datos = {
      encuestaId: e.id,
      usuarioId: "",
      respuestas: [],
    };

    Object.entries(e.preguntas).forEach(([k, v]) => {
      v.error = false;
      //pregunta abierta

      if (v.tipoPreguntaId === 1) {
        if (!v.respuesta) {
          v.error = true;
          valido = false;
        } else {
          datos.respuestas.push({
            preguntaId: v.id,
            opcionId: 0,
            respuesta: v.respuesta,
          });
        }
      }

      //pregunta cerrada multiple
      else if (v.tipoPreguntaId === 2 && v.esRespuestaMultiple) {
        if (
          v.opciones.filter(
            (o) =>
              (o.seleccionada === true && !o.requiereExtenderRespuesta) ||
              (o.seleccionada === true &&
                o.requiereExtenderRespuesta &&
                o.respuesta)
          ).length === 0
        ) {
          v.error = true;
          valido = false;
        } else {
          v.opciones
            .filter((o) => o.seleccionada)
            .map((o) => {
              datos.respuestas.push({
                preguntaId: v.id,
                opcionId: o.id,
                respuesta: o.respuesta,
              });
              return o;
            });
        }
      }

      //pregunta cerrada
      else if (v.tipoPreguntaId === 2 && v.esRespuestaMultiple === false) {
        if (!v.respuesta) {
          v.error = true;
          valido = false;
        } else {
          let op = v.opciones.find((o) => o.id === v.respuesta);

          if (op.requiereExtenderRespuesta && !op.respuesta) {
            v.error = true;
            valido = false;
          } else {
            datos.respuestas.push({
              preguntaId: v.id,
              opcionId: v.respuesta,
              respuesta: op?.respuesta,
            });
          }
        }
      }
    });

    if (!valido) {
      this.props.enqueueSnackbar("Debe contestar todas las preguntas", {
        variant: "warning",
      });
      this.forceUpdate();
    } else {
      this.setState({ notificacion: false, submitted: true }, () => {
        if (this.state.respuestas?.id > 0) datos.id = this.state.respuestas.id;
        //if (!this.state.respuestas?.id > 0)
        trackPromise(
          this.props.createAction("postEncuestaUsuario", datos),
          () => {
            this.setState({ submitted: false });
          }
        );
        // else {
        //   datos.usuarioId = this.state.respuestas.usuarioId;
        //   this.props.updateAction(
        //     "updateEncuestaUsuario",
        //     this.state.respuestas.id,
        //     datos
        //   );
        // }
      });
    }
  };

  renderPreguntaAbierta(r, editable) {
    const { classes } = this.props;
    return (
      <Box mb={2} key={r.id}>
        <TextField
          size="small"
          className={classes.formControl}
          //error={r.error}
          name="respuesta"
          label={
            <Typography color={r.error === true ? "secondary" : "primary"}>
              {r.nombre}
            </Typography>
          }
          value={r.respuesta}
          onChange={(e) => this.handleChangePreguntaCerrada(r, e)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
    );
  }

  renderOpcionesMultiples(r, editable) {
    const { classes } = this.props;
    return r.opciones.map((o) => {
      return (
        <Box key={o.id}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={o.seleccionada}
                onChange={(e) => this.handleCheckChangeCerrada(r, o, e)}
                name="check"
              />
            }
            label={<Typography variant="caption"> {o.nombre}</Typography>}
          />
          {o.requiereExtenderRespuesta && o.seleccionada && (
            <Box mb={2} ml={4}>
              <TextField
                size="small"
                className={classes.formControl}
                error={o.error}
                name="respuesta"
                label={
                  <Typography
                    color={r.error === true ? "secondary" : "primary"}
                  ></Typography>
                }
                value={o.respuesta}
                onChange={(e) => this.handleCheckChangeCerrada(r, o, e, true)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          )}
        </Box>
      );
    });
  }
  renderOpciones(r, editable) {
    const { classes } = this.props;
    return r.opciones.map((o) => {
      return (
        <Box key={o.id}>
          <FormControlLabel
            value={o.id}
            control={<Radio />}
            label={<Typography variant="caption">{o.nombre}</Typography>}
          />

          {o.requiereExtenderRespuesta && r.respuesta === o.id && (
            <Box mb={2} ml={4}>
              <TextField
                size="small"
                className={classes.formControl}
                error={r.error}
                name="respuesta"
                label={
                  <Typography
                    color={r.error === true ? "secondary" : "primary"}
                  ></Typography>
                }
                value={o.respuesta}
                onChange={(e) => this.handleCheckChangeCerrada1Opcion(r, e, o)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          )}
        </Box>
      );
    });
  }

  renderPreguntaCerrada(r, editable) {
    const { classes } = this.props;
    return (
      <Box mb={2} key={r.id} component={Paper} p={2}>
        <FormControl component="fieldset" className={classes.formControl}>
          <Typography color={r.error === true ? "secondary" : "primary"}>
            {r.nombre}
          </Typography>

          {r.esRespuestaMultiple ? (
            <FormGroup>{this.renderOpcionesMultiples(r, editable)}</FormGroup>
          ) : (
            <RadioGroup
              value={parseInt(r.respuesta)}
              onChange={(e) => this.handleCheckChangeCerrada1Opcion(r, e)}
            >
              {this.renderOpciones(r, editable)}
            </RadioGroup>
          )}
        </FormControl>
      </Box>
    );
  }

  renderItems() {
    const { classes } = this.props;
    if (this.state.encuestas.length > 0) {
      return this.state.encuestas.map((r) => {
        return (
          <Box className={classes.root} p={2} mb={3} key={r.id}>
            <Typography gutterBottom variant="h4" component="h2">
              {r.nombre}
            </Typography>
            {r.preguntas.map((p) => {
              if (p.tipoPreguntaId === 1)
                //abierta
                return this.renderPreguntaAbierta(p, r.editable);
              else if (p.tipoPreguntaId === 2)
                //cerrada
                return this.renderPreguntaCerrada(p, r.editable);
              else return <div>por implementar!</div>;
            })}

            <Box align="right" className={classes.actions} mr={2}>
              <Button
                disabled={this.state.submitted}
                size="small"
                color="primary"
                onClick={() => this.enviarRespuestas(r)}
                variant="outlined"
              >
                Guardar
              </Button>
            </Box>
          </Box>
        );
      });
    } else {
      return (
        <Box align="center" p={4} mt={4} component={Paper}>
          <Typography variant="overline" display="block">
            No hay preferencias!
          </Typography>
        </Box>
      );
    }
  }

  render() {
    return (
      <Box>
        <Grid container>
          <Grid item xs={false} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Box mt={3}>{this.renderItems()}</Box>
          </Grid>
          <Grid item xs={false} md={3}></Grid>
        </Grid>
      </Box>
    );
  }

  componentDidUpdate() {
    const {
      createEncuestaUsuario,
      createEncuestaUsuarioError,
      encuestaUsuario,
      updateEncuestaUsuario,
      updateEncuestaUsuarioError,
    } = this.props;

    if (
      this.state.encuestas.length === 0 &&
      this.props.misEncuestas?.length > 0
    ) {
      let _respuestas = "";
      let encuestas = this.props.misEncuestas.map((e) => {
        let respuestas = this.props.misRespuestas.find(
          (r) => r.encuestaId === e.id
        );
        _respuestas = respuestas;
        if (respuestas) {
          e.editable = false;
          e.preguntas = e.preguntas.map((p) => {
            let resPreg = respuestas.respuestas.filter(
              (o) => o.preguntaId === p.id
            );
            if (resPreg.length) {
              if (p.tipoPreguntaId === 1) {
                //abierta
                p.respuesta = resPreg[0].respuesta;
              } else if (p.tipoPreguntaId === 2) {
                if (p.esRespuestaMultiple) {
                  p.opciones = p.opciones.map((op) => {
                    let reOP = resPreg.find((_o) => _o.opcionId === op.id);
                    if (reOP) op.seleccionada = true;
                    if (op.requiereExtenderRespuesta && reOP)
                      op.respuesta = reOP.respuesta;
                    return { ...op };
                  });
                } else {
                  p.respuesta = resPreg[0].opcionId;
                  let op = p.opciones.find((_o) => _o.id === p.respuesta);

                  if (op.requiereExtenderRespuesta) {
                    op.respuesta = resPreg[0].respuesta;
                  }
                }
              }
            }
            return { ...p };
          });
        } else {
          e.editable = true;
        }
        return { ...e };
      });
      this.setState({ encuestas: encuestas, respuestas: _respuestas });
    }

    if (createEncuestaUsuarioError) {
      this.props.enqueueSnackbar(createEncuestaUsuarioError, {
        variant: "error",
      });
      this.props.clearAction("clearEncuestaUsuario");
    } else if (createEncuestaUsuario) {
      this.props.clearAction("clearEncuestaUsuario");
      if (this.state.notificacion === false) {
        this.props.enqueueSnackbar("Los datos se guardaron correctamiente!", {
          variant: "success",
        });
      }
      let encuestas = [...this.state.encuestas];
      let enc = encuestas.find((e) => e.id === encuestaUsuario.encuestaId);
      enc.editable = false;
      this.props.history.push("/");

      this.setState({ encuestas: encuestas, notificacion: true }, () => {});
    }

    if (updateEncuestaUsuarioError) {
      this.props.enqueueSnackbar(updateEncuestaUsuarioError, {
        variant: "error",
      });
      this.props.clearAction("clearEncuestaUsuario");
    } else if (updateEncuestaUsuario) {
      this.props.clearAction("clearEncuestaUsuario");
      if (this.state.notificacion === false) {
        this.props.enqueueSnackbar("Los datos se guardaron correctamiente!", {
          variant: "success",
        });
      }
      this.props.history.push("/");

      this.setState({ notificacion: true }, () => {});
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    misEncuestas: state.misPreferenciaState.misPreferencias,
    misRespuestas: state.misPreferenciaState.misRespuestas,
    totalList: state.misPreferenciaState.totalList,

    preferenciaClienteId: state.preferenciasGeneralState.clienteId,
    preferenciaCampaniaId: state.preferenciasGeneralState.campaniaId,

    updateEncuestaUsuario: state.encuestaUsuarioState.updateEncuestaUsuario,
    updateEncuestaUsuarioError:
      state.encuestaUsuarioState.updateEncuestaUsuarioError,

    createEncuestaUsuario: state.encuestaUsuarioState.createEncuestaUsuario,
    createEncuestaUsuarioError:
      state.encuestaUsuarioState.createEncuestaUsuarioError,
    encuestaUsuario: state.encuestaUsuarioState.encuestaUsuario,
  };
};

export default connect(mapStatetoProps, {
  getAllAction,
  clearAction,
  updateAction,
  createAction,
})(withStyles(useStyles)(withSnackbar(MisPreferencias)));
