import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "80%",
  },
}));

export default function TerminosCondiciones() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleOpen}>Aceptar los Términos y Condiciones</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Términos y Condiciones</h2>
            <div id="transition-modal-description">
              <ul>
                <li>
                  <b>Naturaleza de los Términos y Condiciones de Uso: </b>
                  <p>
                    El presente documento constituye un contrato de adhesión
                    para el uso del sitio web MARCA.COM.MX (en adelante EL SITIO
                    WEB). Es posible que usted acceda al sitio antes referido,
                    por medio de los nombres de dominio MARCA.MX y/o
                    PROTECCIONDEDATOSPERSONALES.ORG los cuales han sido
                    direccionados al sitio principal MARCA.COM.MX y por ende, le
                    son aplicables los presentes términos y condiciones de uso
                    que celebran: por una parte, EMPRESA. (En adelante
                    “MARCA®”), en su calidad de administrador oficial de la
                    presente tienda virtual y por la otra, el usuario,
                    sujetándose, ambas partes, a lo establecido en este
                    documento.
                  </p>
                </li>

                <li>
                  <strong>Aceptación</strong>
                  <ol>
                    <li>
                      Al ingresar y utilizar el sitio web, propiedad de MARCA®,
                      y administrado por el mismo, el usuario está aceptando los
                      Términos y Condiciones de Uso contenidos en este contrato
                      y declara expresamente su aceptación utilizando para tal
                      efecto medios electrónicos, en términos de lo dispuesto
                      por el artículo 1803 fracción II y demás relativos del
                      Código Civil Federal. Así mismo a los artículos 1°
                      fracción VIII Y 76 BIS de la Ley Federal de Protección al
                      Consumidor.
                    </li>
                    <li>
                      En caso de no aceptar en forma absoluta y completa los
                      términos y condiciones de este contrato, el usuario deberá
                      abstenerse de acceder, utilizar y observar el sitio web.
                    </li>
                    <li>
                      En caso de que el usuario acceda, utilice y observe el
                      sitio web se considerará como una absoluta y expresa
                      aceptación de los Términos y Condiciones de Uso aquí
                      estipulados.
                    </li>
                    <li>
                      La sola utilización de dicha página de Internet le otorga
                      al público en general la condición de usuario (en adelante
                      referido como el “usuario” o los “usuarios”) e implica la
                      aceptación, plena e incondicional de todas y cada una de
                      las condiciones generales y particulares incluidas en
                      estos Términos y Condiciones de Uso publicados por MARCA®
                      en el momento mismo en que el usuario acceda al sitio web.
                    </li>
                    <li>
                      Cualquier modificación a los presentes Términos y
                      Condiciones de Uso será realizada cuando el administrador
                      de la misma, lo considere apropiado, siendo exclusiva
                      responsabilidad del usuario asegurarse de tomar
                      conocimiento de tales modificaciones.
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
            <Box align="center">
              <Button variant="outlined" onClick={handleClose}>
                Cerrar
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
