import React from "react";
import CustomerList from "./customer-list";
import CustomerDetail from "./customer-detail";

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listVisible: true,
    };
  }

  showDetailItem = () => {
    this.setState({ listVisible: false });
  };

  resultOp = (o) => {
    this.setState({ listVisible: true });
  };

  render() {
    const { listVisible } = this.state;
    if (listVisible)
      return (
        <CustomerList
          showDetailItem={this.showDetailItem}
          updateItem={this.updateItem}
        ></CustomerList>
      );
    else return <CustomerDetail resultOp={this.resultOp}></CustomerDetail>;
  }
}

export default Customers;
