import React from "react";
import RolList from "./rol-list";
import RolDetail from "./rol-detail";

class Roles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listVisible: true,
    };
  }

  showDetailItem = () => {
    this.setState({ listVisible: false });
  };

  resultOp = (o) => {
    this.setState({ listVisible: true });
  };

  render() {
    const { listVisible } = this.state;
    if (listVisible)
      return (
        <RolList
          showDetailItem={this.showDetailItem}
          updateItem={this.updateItem}
        ></RolList>
      );
    else return <RolDetail resultOp={this.resultOp}></RolDetail>;
  }
}

export default Roles;
