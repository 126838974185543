import React from "react";
import MunicipioList from "./municipio-list";
import MunicipioDetail from "./municipio-detail";

class Municipios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listVisible: true,
    };
  }

  showDetailItem = () => {
    this.setState({ listVisible: false });
  };

  resultOp = (o) => {
    this.setState({ listVisible: true });
  };

  render() {
    const { listVisible } = this.state;
    if (listVisible)
      return (
        <MunicipioList
          showDetailItem={this.showDetailItem}
          updateItem={this.updateItem}
        ></MunicipioList>
      );
    else return <MunicipioDetail resultOp={this.resultOp}></MunicipioDetail>;
  }
}

export default Municipios;
