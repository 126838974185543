const defaultState = {
  createSeccion: false,
  createSeccionError: "",
  seccion: {},
  delSeccion: false,
  delSeccionError: "",
  getSeccionError: "",

  secciones: [],
  seccionCatalog: [],

  totalList: 0,
};

export const seccionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_SECCIONES":
      return {
        ...state,
        secciones: action.payload.data,
        totalList: action.payload.totalRegs,
      };
    case "FETCH_SECCION_CATALOG":
      return {
        ...state,
        seccionCatalog: action.payload,
      };

    case "CREATE_SECCION":
      return {
        ...state,
        seccion: { ...action.payload },
        createSeccion: true,
      };
    case "CREATE_SECCION_ERROR":
      return { ...state, createSeccionError: action.payload.error };
    case "UPDATE_SECCION":
      return {
        ...state,
        seccion: { ...action.payload },
        updateSeccion: true,
      };
    case "UPDATE_SECCION_ERROR":
      return { ...state, updateSeccionError: action.payload.error };
    case "CLEAR_SECCION":
      return {
        ...state,
        seccion: {},
        createSeccion: false,
        createSeccionError: "",
        delSeccion: false,
        delSeccionError: "",
        getSeccion: false,
        getSeccionError: "",
        updateSeccion: false,
        updateSeccionError: "",
      };
    case "DEL_SECCION":
      return { ...state, delSeccion: true };
    case "DEL_SECCION_ERROR":
      return { ...state, delSeccionError: action.payload.error };
    case "GET_SECCION":
      return {
        ...state,
        getSeccion: true,
        seccion: { ...action.payload },
      };
    case "GET_SECCION_ERROR":
      return { ...state, getSeccionError: action.payload.error };
    default:
      return {
        ...state,
      };
  }
};
