import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Dialog,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  FormLabel,
} from "@material-ui/core";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import Transition from "../tools/transition";

import { connect } from "react-redux";
import {
  createAction,
  clearAction,
  updateAction,
  getCatalogAction,
} from "../../actions/action-template";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
  formButton: {
    marginRight: "10px",
  },
  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
});

class PostDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clienteId: this.props.customerId ?? "",
      campaniaId: this.props.campaniaId ?? 0,
      campania: "",

      submitted: false,
      isOpenDialog: true,

      id: 0,
      titulo: "",
      contenido: "",
      urlEncabezado: "",
      esPublicado: false,
      esNotificacion: false,
      segmentacion: {
        id: 0,
        entidadId: 0,
        municipioId: 0,
        coloniaId: 0,
        seccionId: 0,
        esHombres: false,
        esMujeres: false,
        edadInicial: 0,
        edadFinal: 0,
      },
      ambitoTerritorialCargado: false,
    };

    if (this.props.campaniaId) {
      this.props.getCatalogAction(
        "getCampaniaCatalogo",
        `clienteId=${this.props.customerId}&campaniaId=${this.props.campaniaId}`
      );
    } else if (this.props.customerId) {
      this.props.getCatalogAction(
        "getCampaniaCatalogo",
        `clienteId=${this.props.customerId}`
      );
    }
  }

  showError = (value) => {
    return this.state.submitted && !value ? true : false;
  };

  handleChange = (e) => {
    const { name, value, checked } = e.target;

    let _val = value;
    if (name === "esNotificacion") _val = checked;

    this.setState({ [name]: _val }, () => {
      if (name === "clienteId") {
        this.props.getCatalogAction(
          "getCampaniaCatalogo",
          `clienteId=${value}`
        );
      } else if (name === "campaniaId") {
        this.establecerAmbitoTerritorial(value);

        this.props.getCatalogAction("getAgenteCatalogo", `campaniaId=${value}`);
      }
    });
  };

  handleChangeSegmentacion = (e) => {
    if (this.state.esPublicada) return;

    const { name, value, checked } = e.target;
    let segmentacion = this.state.segmentacion;
    segmentacion[name] = value;
    if (name === "esHombres" || name === "esMujeres")
      segmentacion[name] = checked;
    this.setState({ segmentacion: segmentacion }, () => {
      if (name === "entidadId") {
        this.props.getCatalogAction(
          "getMunicipioCatalogo",
          `entidadId=${this.state.segmentacion.entidadId}`
        );
      }
    });
  };

  establecerAmbitoTerritorial = () => {
    let campania = this.props.campaniaCatalog.find(
      (e) => parseInt(e.id) === parseInt(this.state.campaniaId)
    );
    let ambito = { ...this.state.segmentacion };

    if (campania) {
      ambito.entidadId = campania?.entidadId ?? 0;
      if (campania.ambitoTerritorialId === 5)
        ambito.municipioId = campania?.municipioId ?? 0;
      if (campania.ambitoTerritorialId === 6)
        ambito.coloniaId = campania?.coloniaId ?? 0;
      if (campania.ambitoTerritorialId === 4)
        ambito.seccionId = campania?.seccionId ?? 0;
    }
    this.setState(
      {
        ambitoTerritorialCargado: true,
        segmentacion: ambito,
        campania: campania,
      },
      () => {
        this.props.getCatalogAction(
          "getMunicipioCatalogo",
          `entidadId=${this.state.segmentacion.entidadId}`
        );
      }
    );
  };

  handlePublicar = (e) => {
    this.setState({ publicando: true }, () => {
      this.handleSubmit(e);
    });
  };

  handleSubmit = (e) => {
    const {
      titulo,
      contenido,

      campaniaId,
      urlEncabezado,
      esNotificacion,
      segmentacion,
      publicando,
    } = this.state;
    if (!campaniaId) {
      this.props.enqueueSnackbar("Debe asignar un cliente y campaña!", {
        variant: "warning",
      });
      return;
    }
    if (parseInt(segmentacion.edadInicial) > parseInt(segmentacion.edadFinal)) {
      this.props.enqueueSnackbar(
        "La edad inicial debe ser menor a la edad final!",
        {
          variant: "warning",
        }
      );
      return;
    }
    if (titulo && contenido) {
      this.setState({ submitted: true });

      if (!this.state.id) {
        trackPromise(
          this.props.createAction("postPost", {
            titulo,
            contenido,
            esPublicado: publicando,
            campaniaId,
            urlEncabezado,
            segmentacion,
            esNotificacion,
          }),
          () => {
            this.setState({ submitted: false });
          }
        );
      } else {
        trackPromise(
          this.props.updateAction("updatePost", this.state.id, {
            id: this.state.id,
            titulo,
            contenido,
            esPublicado: publicando,
            campaniaId,
            urlEncabezado,
            segmentacion,
            esNotificacion,
          }),
          () => {
            this.setState({ submitted: false });
          }
        );
      }
    } else {
      this.props.enqueueSnackbar("Debe ingresar los campos marcados con *", {
        variant: "warning",
      });
    }
  };

  componentDidUpdate() {
    const {
      createPost,
      createPostError,
      post,
      getPost,
      getPostError,
      updatePostError,
      updatePost,
    } = this.props;

    if (
      this.props.campaniaId &&
      !this.state.ambitoTerritorialCargado &&
      this.props.campaniaCatalog.length > 0 &&
      this.state.id === 0
    ) {
      this.establecerAmbitoTerritorial();
    }

    if (createPostError) {
      this.props.enqueueSnackbar(createPostError, {
        variant: "error",
      });
      this.props.clearAction("clearPost");
      this.setState({ submitted: false });
    } else if (createPost) {
      this.props.clearAction("clearPost");

      this.setState(
        {
          isOpenDialog: false,
          esPublicado: this.state.publicando,
        },
        () => {
          this.props.resultOp(post);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (updatePostError) {
      this.props.enqueueSnackbar(updatePostError, {
        variant: "error",
      });
      this.props.clearAction("clearPost");
      this.setState({ submitted: false });
    } else if (updatePost) {
      this.props.clearAction("clearPost");

      this.setState(
        {
          isOpenDialog: false,
          esPublicado: this.state.publicando,
        },
        () => {
          this.props.resultOp(post);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (getPostError) {
      this.props.enqueueSnackbar(getPostError, {
        variant: "error",
      });
      this.props.clearAction("clearPost");
    } else if (getPost) {
      this.props.clearAction("clearPost");
      let data = { ...post };

      data.segmentacion = {
        id: post.segmentacion?.id ?? 0,
        entidadId: post.segmentacion?.entidadId ?? 0,
        municipioId: post.segmentacion?.municipioId ?? 0,
        coloniaId: post.segmentacion?.coloniaId ?? 0,
        seccionId: post.segmentacion?.seccionId ?? 0,
        esHombres: post.segmentacion?.esHombres ?? false,
        esMujeres: post.segmentacion?.esMujeres ?? false,
        edadInicial: post.segmentacion?.edadInicial ?? 0,
        edadFinal: post.segmentacion?.edadFinal ?? 0,
        postId: post.segmentacion?.postId ?? 0,
      };
      this.setState(
        {
          ...data,
        },
        () => {
          if (this.state.segmentacion?.entidadId > 0) {
            this.props.getCatalogAction(
              "getMunicipioCatalogo",
              `entidadId=${this.state.segmentacion.entidadId}`
            );
          }
        }
      );
    }
  }

  handleChangeNumber = (e) => {
    if (this.state.esPublicada) return;

    const { name, value } = e.target;
    let segmentacion = this.state.segmentacion;

    let number = parseInt(value);
    number = Number.isNaN(number) ? "" : value;
    segmentacion[name] = number;
    this.setState({ segmentacion: segmentacion });
  };

  renderContent() {
    const {
      titulo,
      contenido,
      esPublicado,
      campaniaId,
      clienteId,
      urlEncabezado,
      segmentacion,
      esNotificacion,
    } = this.state;
    const { classes } = this.props;

    return (
      <Grid container>
        {!this.props.customerId && !this.state.id && (
          <Grid item xs={12} md={3}>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="lblCliente">Cliente</InputLabel>
              <Select
                autoFocus
                labelId="lblCliente"
                value={clienteId}
                name="clienteId"
                onChange={this.handleChange}
              >
                {this.props.clienteCatalog.map((p) => {
                  return (
                    <MenuItem key={p.value} value={p.value}>
                      {p.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
        {!this.props.campaniaId && !this.state.id && (
          <Grid item xs={12} md={3}>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="lblCampania">Campaña</InputLabel>
              <Select
                labelId="lblCampania"
                value={campaniaId}
                name="campaniaId"
                onChange={this.handleChange}
              >
                {this.props.campaniaCatalog.map((p) => {
                  return (
                    <MenuItem key={p.id} value={p.id}>
                      {p.nombre}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} md={10}>
          <TextField
            className={classes.formControl}
            error={this.showError(titulo)}
            required
            autoFocus
            label="Titulo"
            name="titulo"
            value={titulo}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl style={{ marginTop: 20 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={esNotificacion}
                  onChange={this.handleChange}
                  name="esNotificacion"
                />
              }
              label={<Typography variant="caption">Notificación</Typography>}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Url imagen o video"
            name="urlEncabezado"
            value={urlEncabezado}
            onChange={this.handleChange}
            inputProps={{ maxLength: 512 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            error={this.showError(contenido)}
            required
            label="Contenido"
            name="contenido"
            value={contenido}
            multiline
            rows={4}
            variant="outlined"
            onChange={this.handleChange}
            inputProps={{ maxLength: 4096 }}
          />
        </Grid>

        {this.state.esNotificacion && (
          <Grid item xs={12}>
            <Box mt={3}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="button" color="primary">
                    Segmentación
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl
                    size="small"
                    className={classes.formControl}
                    // error={this.showError(segmentacion?.entidadId)}
                    // required
                    disabled={true}
                  >
                    <InputLabel id="lblEntidad">Entidad</InputLabel>
                    <Select
                      labelId="lblEntidad"
                      id="entidadId"
                      value={segmentacion?.entidadId}
                      name="entidadId"
                      onChange={this.handleChangeSegmentacion}
                    >
                      {this.props.entidadCatalog.map((p) => {
                        return (
                          <MenuItem key={p.value} value={p.value}>
                            {p.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl
                    size="small"
                    className={classes.formControl}
                    // error={this.showError(segmentacion?.municipioId)}
                    // required
                    disabled={this.state.campania?.ambitoTerritorialId === 5}
                  >
                    <InputLabel id="lblMunicipio">Municipio</InputLabel>
                    <Select
                      labelId="lblMunicipio"
                      id="municipioId"
                      value={segmentacion?.municipioId}
                      name="municipioId"
                      onChange={this.handleChangeSegmentacion}
                    >
                      {this.props.municipioCatalog?.map((p) => {
                        return (
                          <MenuItem key={p.value} value={p.value}>
                            {p.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className={classes.formControl}
                    // error={this.showError(segmentacion?.seccionId)}
                    type="text"
                    label="Sección"
                    name="seccionId"
                    value={segmentacion?.seccionId}
                    onChange={this.handleChangeNumber}
                    inputProps={{ maxLength: 4 }}
                    disabled={this.state.campania?.ambitoTerritorialId === 4}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormControl style={{ marginTop: 20 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={segmentacion?.esHombres}
                          onChange={this.handleChangeSegmentacion}
                          name="esHombres"
                        />
                      }
                      label={<Typography variant="caption">Hombres</Typography>}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl style={{ marginTop: 20 }}>
                    <FormLabel component="legend">
                      <Typography variant="caption"></Typography>
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={segmentacion?.esMujeres}
                          onChange={this.handleChangeSegmentacion}
                          name="esMujeres"
                        />
                      }
                      label={<Typography variant="caption">Mujeres</Typography>}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    className={classes.formControl}
                    label="Edad Inicial"
                    name="edadInicial"
                    value={segmentacion?.edadInicial}
                    onChange={this.handleChangeNumber}
                    inputProps={{ maxLength: 3 }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    className={classes.formControl}
                    label="Edad Final"
                    name="edadFinal"
                    value={segmentacion?.edadFinal}
                    onChange={this.handleChangeNumber}
                    inputProps={{ maxLength: 3 }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}

        {isBrowser && (
          <Grid item xs={12}>
            <Box align="right" pt={4}>
              <Button
                className={classes.formButton}
                variant="contained"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
              >
                cancelar
              </Button>
              {!esPublicado && (
                <Button
                  disabled={this.state.submitted}
                  className={classes.formButton}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  Guardar
                </Button>
              )}
              {!esPublicado && (
                <Button
                  disabled={this.state.submitted}
                  className={classes.formButton}
                  variant="outlined"
                  onClick={() => {
                    this.handlePublicar();
                  }}
                >
                  Publicar
                </Button>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    );
  }

  renderBrowser() {
    return (
      <Box p={10}>
        <Typography variant="h4">Detalle Publicación</Typography>
        {this.renderContent()}
      </Box>
    );
  }

  renderMobile() {
    const { classes } = this.props;
    return (
      <Box>
        <Dialog
          fullScreen
          open={this.state.isOpenDialog}
          onClose={() => this.setState({ isOpenDialog: false })}
          TransitionComponent={Transition}
          scroll="body"
          style={{ paddingTop: 40 }}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Detalle Publicación
              </Typography>
            </Toolbar>
          </AppBar>
          <Box py={3} px={1}>
            {this.renderContent()}
            <div style={{ marginBottom: 100 }}></div>
          </Box>
          <AppBar
            position="fixed"
            color="primary"
            className={classes.appBottomBar}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  cancelar
                </Typography>
              </IconButton>
              <div className={classes.grow} />
              {!this.state.esPublicado && (
                <IconButton
                  disabled={this.state.submitted}
                  edge="end"
                  color="inherit"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                  aria-label="close"
                >
                  <Typography variant="button" display="block" gutterBottom>
                    Guardar
                  </Typography>
                </IconButton>
              )}
              {!this.state.esPublicado && (
                <IconButton
                  disabled={this.state.submitted}
                  edge="end"
                  color="inherit"
                  onClick={() => {
                    this.handlePublicar();
                  }}
                  aria-label="close"
                >
                  <Typography variant="button" display="block" gutterBottom>
                    Publicar
                  </Typography>
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
        </Dialog>
      </Box>
    );
  }

  render() {
    return (
      <Box>
        <BrowserView>{this.renderBrowser()}</BrowserView>
        <MobileView>{this.renderMobile()}</MobileView>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    createPost: state.postState.createPost,
    createPostError: state.postState.createPostError,
    post: state.postState.post,
    getPost: state.postState.getPost,
    getPostError: state.postState.getPostError,

    updatePost: state.postState.updatePost,
    updatePostError: state.postState.updatePostError,

    campaniaCatalog: state.campaniaState.campaniaCatalog,
    clienteCatalog: state.customerState.clienteCatalog,
    customerId: state.authState.user.customerId,
    campaniaId: state.authState.user.campaniaId,

    entidadCatalog: state.entidadState.entidadCatalog,
    municipioCatalog: state.municipioState.municipioCatalog,
    seccionCatalog: state.seccionState.seccionCatalog,
  };
};

export default connect(mapStatetoProps, {
  createAction,
  clearAction,
  updateAction,
  getCatalogAction,
})(withStyles(useStyles)(withSnackbar(PostDetail)));
