const defaultState = {
  createActivado: false,
  createActivadoError: "",
  activado: {},
  delActivado: false,
  delActivadoError: "",
  getActivadoError: "",

  activados: [],

  totalList: 0,
};

export const activadoReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_ACTIVADOS":
      return {
        ...state,
        activados: action.payload.data,
        totalList: action.payload.totalRegs,
      };

    case "CREATE_ACTIVADO":
      return {
        ...state,
        activado: { ...action.payload },
        createActivado: true,
      };
    case "CREATE_ACTIVADO_ERROR":
      return { ...state, createActivadoError: action.payload.error };
    case "UPDATE_ACTIVADO":
      return {
        ...state,
        activado: { ...action.payload },
        updateActivado: true,
      };
    case "UPDATE_ACTIVADO_ERROR":
      return { ...state, updateActivadoError: action.payload.error };
    case "CLEAR_ACTIVADO":
      return {
        ...state,
        activado: {},
        activadoOffline: {},
        createActivado: false,
        createActivadoError: "",
        delActivado: false,
        delActivadoError: "",
        getActivado: false,
        getActivadoOffline: false,
        getActivadoError: "",
        updateActivado: false,
        updateActivadoError: "",
      };
    case "DEL_ACTIVADO":
      return { ...state, delActivado: true };
    case "DEL_ACTIVADO_ERROR":
      return { ...state, delActivadoError: action.payload.error };
    case "GET_ACTIVADO":
      return { ...state, getActivado: true, activado: { ...action.payload } };
    case "GET_ACTIVADO_ERROR":
      return { ...state, getActivadoError: action.payload.error };
    case "GET_ACTIVADO_OFFLINE":
      return {
        ...state,
        getActivadoOffline: true,
        activadoOffline: { ...action.payload },
      };
    default:
      return {
        ...state,
      };
  }
};
