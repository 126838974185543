const defaultState = {
  campaniaTarea: {},
  updateCampaniaTarea: false,
  updateCampaniaTareaError: "",

  fechCampaniaTareas: false,
  campaniaTareas: [],
  totalList: 0,
};

export const campaniaTareaReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_CAMPANIA_TASKS":
      return {
        ...state,
        campaniaTareas: action.payload.data,
        fechCampaniaTareas: true,
        totalList: action.payload.totalRegs,
      };

    case "UPDATE_CAMPANIA_TASK":
      return {
        ...state,
        campaniaTarea: { ...action.payload },
        updateCampaniaTarea: true,
      };
    case "UPDATE_CAMPANIA_TASK_ERROR":
      return { ...state, updateCampaniaTareaError: action.payload.error };
    case "CLEAR_CAMPANIA_TASK":
      return {
        ...state,
        campaniaTarea: {},
        updateCampaniaTarea: false,
        updateCampaniaTareaError: "",

        fechCampaniaTareas: false,
        campaniaTareas: [],
      };

    default:
      return {
        ...state,
      };
  }
};
