const defaultState = {
  createStorage: false,
  createStorageError: "",
  storage: {},
};

export const storageReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "CREATE_STORAGE":
      return {
        ...state,
        storage: { ...action.payload },
        createStorage: true,
      };
    case "CREATE_STORAGE_ERROR":
      return { ...state, createStorageError: action.payload.error };

    case "CLEAR_STORAGE":
      return {
        ...state,
        storage: {},
        createStorage: false,
        createStorageError: "",
      };

    default:
      return {
        ...state,
      };
  }
};
