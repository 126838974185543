import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";

import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Divider,
} from "@material-ui/core";
import { HashRouter as Router } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AppRoutes from "../routes/app-routes";
import LateralMenu from "../components/menu/lateral-menu";
import { isBrowser } from "react-device-detect";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import MenuColors from "../theme/menu-colors";
import TopToolBar from "../components/menu/top-toolbar";
import LoadingIndicator from "./tools/loading-indicator";

const drawerWidth = 240;

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: MenuColors.main,
    color: MenuColors.contrastText,
    overflow: "auto",
    height: "100vh",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: isBrowser,
    };
  }
  render() {
    const { classes } = this.props;
    const { open } = this.state;
    return (
      <Router>
        <Box className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => {
                  this.setState({ open: true });
                }}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>

              <Typography variant="h6" noWrap className={classes.title}>
                Red de Campo
              </Typography>
              <TopToolBar></TopToolBar>
            </Toolbar>
          </AppBar>

          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={() => this.setState({ open: false })}>
                {this.state.open ? (
                  <ChevronLeftIcon style={{ color: MenuColors.contrastText }} />
                ) : (
                  <ChevronRightIcon
                    style={{ color: MenuColors.contrastText }}
                  />
                )}
              </IconButton>
            </div>
            <Divider />

            <LateralMenu
              onMenuClick={(e) => {
                if (!isBrowser) {
                  this.setState({ open: false });
                }
              }}
            ></LateralMenu>
          </Drawer>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            <Box>
              <LoadingIndicator />
              <AppRoutes></AppRoutes>
            </Box>
          </main>
        </Box>
      </Router>
    );
  }
}

export default withStyles(useStyles)(App);
