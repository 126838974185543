import { Box, Grid } from "@material-ui/core";
import React from "react";
import ReactDOM from "react-dom";

import Map from "../mapa/map";
import Layers from "../mapa/layers";
import TileLayer from "../mapa/tile-layer";
import VectorLayer from "../mapa/vector-layer";
import { fromLonLat } from "ol/proj";
import Controls from "../mapa/controls";

import ZoomControl from "../mapa/zoom-control";
import * as olSource from "ol/source";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import VectorSource from "ol/source/Vector";
import { Icon, Style } from "ol/style";
import { getCatalogAction, clearAction } from "../../actions/action-template";
import Overlay from "ol/Overlay";

import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import TerritorioFiltro from "./territorio-filtro";
import TileWMS from "ol/source/TileWMS";

class Territorio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: [-99.1487162, 21.0906914],
      zoom: 5,
      features: [],
      overlay: null,
    };
  }
  componentDidMount() {
    let container = ReactDOM.findDOMNode(this).querySelector("#popup");
    let content = ReactDOM.findDOMNode(this).querySelector("#popup-content");

    let olay = new Overlay({
      element: container,
    });

    this.setState({ overlay: olay, content });
  }

  osm() {
    return new olSource.OSM();
  }

  componentDidUpdate() {
    const { usuariosTerritorio, fetchUsuariosTerritorio } = this.props;

    if (fetchUsuariosTerritorio) {
      this.props.clearAction("clearUsuarioTerritorio");
      var iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 46],
          anchorXUnits: "fraction",
          anchorYUnits: "pixels",
          src:
            "https://icons.iconarchive.com/icons/paomedia/small-n-flat/24/map-marker-icon.png",
        }),
      });

      let features = usuariosTerritorio
        .filter((d) => d.geoReferenciado)
        .map((d) => {
          let f = new Feature({
            type: "geoMarker",
            geometry: new Point(fromLonLat([d.longitud, d.latitud])),
            ...d,
          });
          f.setStyle(iconStyle);
          return f;
        });
      this.setState({ features: features });
    }
  }

  render() {
    var vectorSource = new VectorSource({
      features: this.state.features,
    });

    var wmsLayer = new TileWMS({
      url: "http://www.conabio.gob.mx/informacion/explorer/wms",
      params: { LAYERS: "dest_2015gw", TILED: true },

      transition: 0,
    }); //http://www.conabio.gob.mx/informacion/gis/?vns=gis_root/dipol/estata/dest_2015gw

    return (
      <Grid container>
        <Grid item md={9}>
          <Box>
            <div id="popup" className="ol-popup">
              {/* <a href="#" id="popup-closer" className="ol-popup-closer"></a> */}
              <div id="popup-content"></div>
            </div>
            <Map
              center={fromLonLat(this.state.center)}
              zoom={this.state.zoom}
              olay={this.state.overlay}
              content={this.state.content}
            >
              <Layers>
                <TileLayer source={this.osm()} zIndex={0} />
                <TileLayer source={wmsLayer} zIndex={1} />
                <VectorLayer source={vectorSource} zIndex={2}></VectorLayer>
              </Layers>
              <Controls>
                {/* <FullScreenControl /> */}
                <ZoomControl />
              </Controls>
            </Map>
          </Box>
        </Grid>
        <Grid item md={3}>
          <TerritorioFiltro></TerritorioFiltro>
        </Grid>
      </Grid>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    customerId: state.authState.user.customerId,
    campaniaId: state.authState.user.campaniaId,

    usuariosTerritorio: state.usuarioTerritorioState.usuariosTerritorio,
    fetchUsuariosTerritorio:
      state.usuarioTerritorioState.fetchUsuariosTerritorio,
  };
};

export default connect(mapStatetoProps, {
  getCatalogAction,
  clearAction,
})(withSnackbar(Territorio));
