import React from "react";
import {
  Box,
  Grid,
  Typography,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  Paper,
  ButtonGroup,
  Button,
  TablePagination,
  TextField,
  Divider,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { connect } from "react-redux";

import ConfirmDialog from "../../tools/confirm-dialog";
import { withSnackbar } from "notistack";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { BrowserView, MobileView } from "react-device-detect";
import InfoIcon from "@material-ui/icons/Info";
import {
  establecerCliente,
  establecerCampania,
} from "../../../actions/preferencias/preferencias-actions";

import {
  getAllAction,
  deleteAction,
  clearAction,
  getAction,
  getActionOffline,
  createAction,
  getCatalogAction,
} from "../../../actions/action-template";
import MapIcon from "@material-ui/icons/Map";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  fabButton: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
  formControl: {
    width: "95%",
    marginRight: "5px",
  },
});

class ActivadoList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 0,
      pageSize: 10,
      nombreFiltro: "",
      apFiltro: "",
      amFiltro: "",
      isDeleteDialogOpen: false,
      selectedIdForDelete: "",

      titleConfirm: "Desactivar",
      textConfirm: "¿Realmente desea desactivar?",
      dbReady: false,
      offlineRegs: [],
      isOnline: this.props.isOnline,
      esSincronizando: false,
      mandarMensajeFinSinc: false,

      clienteId:
        this.props.customerId !== ""
          ? this.props.customerId
          : this.props.preferenciaClienteId,
      campaniaId:
        this.props.campaniaId !== ""
          ? this.props.campaniaId
          : this.props.preferenciaCampaniaId > 0
          ? this.props.preferenciaCampaniaId
          : "",
    };
    this.props.getCatalogAction("getClienteCatalogo");
    this.fetchList();
    this.props.db.getAllValue("activados").then((data) => {
      this.setState({ offlineRegs: data });
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === "clienteId") {
        this.props.establecerCliente(value);
        this.props.getCatalogAction(
          "getCampaniaCatalogo",
          `clienteId=${value}`
        );
      } else if (name === "campaniaId") {
        this.props.establecerCampania(value);
      }
    });
  };

  getAllOffline(pageNumber, pageSize, nombreFiltro, amFiltro, apFiltro) {
    this.props.db.getAllValue("activados").then((data) => {
      this.setState({ offlineRegs: data });
    });
  }

  fetchList() {
    const {
      pageNumber,
      pageSize,
      nombreFiltro,
      amFiltro,
      apFiltro,
      campaniaId,
    } = this.state;
    let filtro = `campaniaId=${campaniaId}&nombre=${nombreFiltro}&ap=${apFiltro}&am=${amFiltro}`;
    if (this.state.isOnline)
      trackPromise(
        this.props.getAllAction("getAllActivados", pageNumber, pageSize, filtro)
      );
    else {
      this.getAllOffline(
        pageNumber,
        pageSize,
        nombreFiltro,
        amFiltro,
        apFiltro
      );
      if (this.state.esSincronizando) {
        this.sincronizarDatos();
      }
    }
  }

  onDeleteDialogClose = (res) => {
    const { isOnline } = this.state;

    if (res && isOnline) {
      this.props.deleteAction("deleteActivado", this.state.selectedIdForDelete);
    } else if (res && !isOnline) {
      this.props.db
        .deleteValue("activados", this.state.selectedIdForDelete)
        .then((e) => {
          this.fetchList();
        });
    } else {
      this.setState({ isDeleteDialogOpen: false, selectedIdForDelete: "" });
    }
  };

  padStart(d) {
    return d.toString().padStart(2, "0");
  }

  getUTCDate = (date) => {
    let d = new Date(date);
    if (d !== "-") {
      return (
        this.padStart(d.getDate()) +
        "/" +
        this.padStart(d.getMonth() + 1) +
        "/" +
        d.getFullYear()
      );
    } else return d;
  };

  clearFilter = () => {
    this.setState(
      {
        nombreFiltro: "",
        apFiltro: "",
        amFiltro: "",
      },
      () => {
        this.fetchList();
      }
    );
  };

  confirmDelete = (r) => {
    let titleConfirm = "Eliminar Registro";
    let textConfirm = "¿Realmente desea eliminar el registro?";

    this.setState({
      isDeleteDialogOpen: true,
      selectedIdForDelete: r.id,
      textConfirm: textConfirm,
      titleConfirm: titleConfirm,
    });
  };

  renderItemsMobile() {
    const { isOnline } = this.state;
    let datos = isOnline ? this.props.activados : this.state.offlineRegs;

    return datos.map((r) => {
      return (
        <Box p={1} key={r.id}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="caption"
                style={{ fontWeight: "bold" }}
                display="inline"
              >
                Nombre(s):&nbsp;
              </Typography>
              <Typography variant="caption">{r.nombreCompleto}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="caption"
                style={{ fontWeight: "bold" }}
                display="inline"
              >
                Teléfono:&nbsp;
              </Typography>
              <Typography variant="caption">{r.telefono}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="caption"
                style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                display="inline"
              >
                E-mail:&nbsp;
              </Typography>
              <Typography variant="caption" display="inline">
                {r.email}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="caption"
                style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                display="inline"
              >
                Fecha Registro:&nbsp;
              </Typography>
              <Typography variant="caption" display="inline">
                {this.getFormatDate(r.creado)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="caption"
                style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                display="inline"
              >
                Clave Electoral:&nbsp;
              </Typography>
              <Typography variant="caption" display="inline">
                {r.claveElectoral}
              </Typography>
            </Grid>
            {r.erroresSincronizacion && (
              <Grid item xs={12}>
                <Typography variant="caption" color="secondary">
                  {r.erroresSincronizacion}
                </Typography>
              </Grid>
            )}
            <Grid item align="right" xs={12}>
              <ButtonGroup
                size="small"
                aria-label="small outlined button group"
              >
                <Button
                  size="small"
                  color="secondary"
                  title={"Eliminar"}
                  onClick={() => {
                    this.confirmDelete(r);
                  }}
                >
                  <CancelIcon></CancelIcon>
                </Button>
                <Button
                  size="small"
                  color="primary"
                  title="Editar"
                  onClick={() => {
                    if (isOnline) {
                      this.props.getAction("getActivado", r.id);
                    } else {
                      this.props.getActionOffline("getActivadoOffline", r.id);
                    }
                    this.props.showDetailItem();
                  }}
                >
                  <EditIcon></EditIcon>
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <Box mt={1}>
                <Divider></Divider>
              </Box>
            </Grid>
          </Grid>
        </Box>
      );
    });
  }

  abrirMapa(d) {
    window.open(
      `https://www.openstreetmap.org/?mlon=${d.longitud}&mlat=${d.latitud}#map=19/${d.latitud}/${d.longitud}`,
      "_blank"
    );
  }

  renderItemsBrowser() {
    const { isOnline } = this.state;
    let datos = isOnline ? this.props.activados : this.state.offlineRegs;

    return datos.map((r) => {
      return (
        <TableRow key={r.id}>
          <TableCell>
            {r.erroresSincronizacion && (
              <Tooltip title={r.erroresSincronizacion} color="secondary">
                <IconButton size="small" aria-label="delete">
                  <InfoIcon size="small" />
                </IconButton>
              </Tooltip>
            )}
            {r.nombreCompleto}
          </TableCell>
          <TableCell>{r.telefono}</TableCell>
          <TableCell>{r.email}</TableCell>
          <TableCell>{r.claveElectoral}</TableCell>
          <TableCell align="center">{this.getFormatDate(r.creado)}</TableCell>

          <TableCell>{r.seccion}</TableCell>

          <TableCell>
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button
                color="secondary"
                title={"Eliminar"}
                onClick={() => {
                  this.confirmDelete(r);
                }}
              >
                <CancelIcon></CancelIcon>
              </Button>
              {r.geoReferenciado && (
                <Button
                  color="primary"
                  title={"Ver en mapa"}
                  onClick={() => {
                    this.abrirMapa(r);
                  }}
                >
                  <MapIcon></MapIcon>
                </Button>
              )}
              <Button
                color="primary"
                title="Editar"
                onClick={() => {
                  if (isOnline) {
                    this.props.getAction("getActivado", r.id);
                  } else {
                    this.props.getActionOffline("getActivadoOffline", r.id);
                  }
                  this.props.showDetailItem();
                }}
              >
                <EditIcon></EditIcon>
              </Button>
            </ButtonGroup>
          </TableCell>
        </TableRow>
      );
    });
  }

  renderFiltro() {
    const {
      nombreFiltro,
      apFiltro,
      amFiltro,
      clienteId,
      campaniaId,
    } = this.state;

    const { classes } = this.props;
    return (
      <Grid container>
        {!this.props.customerId && (
          <Grid item xs={6} md={2}>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="lblCliente">Cliente</InputLabel>
              <Select
                labelId="lblCliente"
                value={clienteId}
                name="clienteId"
                onChange={this.handleChange}
              >
                {this.props.clienteCatalog.map((p) => {
                  return (
                    <MenuItem key={p.value} value={p.value}>
                      {p.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
        {!this.props.campaniaId && (
          <Grid item xs={6} md={2}>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="lblCampania">Campaña</InputLabel>
              <Select
                labelId="lblCampania"
                value={campaniaId}
                name="campaniaId"
                onChange={this.handleChange}
              >
                {this.props.campaniaCatalog.map((p) => {
                  return (
                    <MenuItem key={p.id} value={p.id}>
                      {p.nombre}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={6} sm={2}>
          <TextField
            size="small"
            name="nombreFiltro"
            label="Nombre(s)"
            value={nombreFiltro}
            onChange={this.handleChange}
            style={{ marginRight: 5 }}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <TextField
            size="small"
            name="apFiltro"
            label="Apellido Paterno"
            value={apFiltro}
            onChange={this.handleChange}
            style={{ marginRight: 5 }}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <TextField
            size="small"
            name="amFiltro"
            label="Apellido Materno"
            value={amFiltro}
            onChange={this.handleChange}
            style={{ marginRight: 5 }}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <ButtonGroup
            color="primary"
            aria-label="Acciones"
            style={{ marginTop: 10, marginLeft: 10 }}
            size="small"
          >
            <Button
              title="Filtrar"
              color="primary"
              onClick={() => {
                this.fetchList();
              }}
            >
              <SearchIcon />
            </Button>
            <Button
              title="Limpiar filtros"
              color="primary"
              onClick={this.clearFilter}
            >
              <AutorenewIcon />
            </Button>
            <Button
              title="Nuevo activado"
              color="primary"
              onClick={this.props.showDetailItem}
            >
              <AddIcon />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  }

  sincronizarDatos = () => {
    let offlineRegs = [...this.state.offlineRegs];
    let a = offlineRegs.find((e) => e.sincronizado === false);
    if (a) {
      a.sincronizando = true;
      this.setState(
        {
          offlineRegs: offlineRegs,
          selectedIdForDelete: a.id,
          esSincronizando: true,
          mandarMensajeFinSinc: true,
        },
        () => {
          this.props.createAction("postActivado", {
            nombres: a.nombres,
            apellidoPaterno: a.apellidoPaterno,
            apellidoMaterno: a.apellidoMaterno,
            email: a.email,
            telefono: a.telefono,
            claveElectoral: a.claveElectoral,
            ocr: a.ocr,
            folio: a.folio,
            seccion: a.seccion,
            calle: a.calle,
            numeroExterior: a.numeroExterior,
            numeroInterior: a.numeroInterior,
            colonia: a.colonia,
            codigoPostal: a.codigoPostal,
            generoId: a.generoId,
            edad: a.edad === "" ? 0 : a.edad,
            idSincronizacion: a.id,
          });
        }
      );
    } else {
      if (this.state.mandarMensajeFinSinc) {
        this.setState(
          {
            esSincronizando: false,
            mandarMensajeFinSinc: false,
            isOnline: offlineRegs.length === 0,
          },
          () => {
            this.props.enqueueSnackbar("Sincronización completa!", {
              variant: "success",
            });
          }
        );
      }
    }
  };

  renderOfflineDatosPendientes() {
    if (this.props.isOnline && this.state.offlineRegs?.length > 0) {
      return (
        <Box m={1} align="right">
          {this.state.isOnline && (
            <Button
              color="secondary"
              onClick={() => {
                this.setState({ isOnline: false });
              }}
            >{`Tiene ${this.state.offlineRegs?.length} registro(s) por sincronizar`}</Button>
          )}

          {!this.state.isOnline && (
            <div>
              <Button
                color="secondary"
                onClick={() => {
                  this.setState({ isOnline: true });
                }}
              >
                Regresar
              </Button>

              <Button color="primary" onClick={this.sincronizarDatos}>
                Sincronizar
              </Button>
            </div>
          )}
        </Box>
      );
    }
  }

  getFormatDate = (date) => {
    let d = new Date(date);
    if (d !== "-") {
      return (
        d.getFullYear() +
        "-" +
        this.padStart(d.getMonth() + 1) +
        "-" +
        this.padStart(d.getDate())
      );
    } else return d;
  };

  renderBrowser() {
    const { classes } = this.props;
    const { isOnline } = this.state;

    return (
      <Box mb={4}>
        <Typography variant="h2" gutterBottom>
          Activados
        </Typography>
        <Paper
          style={{
            marginBottom: 20,
            padding: 20,
          }}
        >
          {this.renderFiltro()}
        </Paper>
        {this.renderOfflineDatosPendientes()}
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nombre(s)</TableCell>
                <TableCell>Teléfono</TableCell>
                <TableCell>Correo Electrónico</TableCell>
                <TableCell align="center">Clave Electoral</TableCell>
                <TableCell>Registro</TableCell>
                <TableCell>Sección</TableCell>

                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{this.renderItemsBrowser(isOnline)}</TableBody>
          </Table>
          {this.state.isOnline && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20]}
              component="div"
              count={this.props.totalList}
              rowsPerPage={this.state.pageSize}
              page={this.state.pageNumber}
              onChangePage={(e, newPage) => {
                this.setState({ pageNumber: newPage }, () => {
                  this.fetchList(isOnline);
                });
              }}
              onChangeRowsPerPage={(e) => {
                this.setState(
                  { pageSize: parseInt(e.target.value, 10) },
                  () => {
                    this.fetchList(isOnline);
                  }
                );
              }}
            />
          )}
        </TableContainer>
      </Box>
    );
  }

  renderMobile() {
    const { isOnline } = this.state;
    return (
      <Box>
        <Typography variant="h6" gutterBottom>
          Activados
        </Typography>
        <Box component={Paper} m={0} p={1}>
          {this.renderFiltro()}
        </Box>
        {this.renderOfflineDatosPendientes()}

        <Box mt={2} component={Paper}>
          <TableContainer>
            {this.renderItemsMobile(isOnline)}
            {this.state.isOnline && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20]}
                component="div"
                count={this.props.totalList}
                rowsPerPage={this.state.pageSize}
                page={this.state.pageNumber}
                onChangePage={(e, newPage) => {
                  this.setState({ pageNumber: newPage }, () => {
                    this.fetchList(isOnline);
                  });
                }}
                onChangeRowsPerPage={(e) => {
                  this.setState(
                    { pageSize: parseInt(e.target.value, 10) },
                    () => {
                      this.fetchList(isOnline);
                    }
                  );
                }}
              />
            )}
          </TableContainer>
        </Box>
      </Box>
    );
  }

  render() {
    const { isOnline } = this.state;

    return (
      <Box mx={2}>
        <BrowserView>{this.renderBrowser(isOnline)}</BrowserView>
        <MobileView>{this.renderMobile(isOnline)}</MobileView>
        <ConfirmDialog
          isDeleteDialogOpen={this.state.isDeleteDialogOpen}
          onDeleteDialogClose={this.onDeleteDialogClose}
          title={this.state.titleConfirm}
          message={this.state.textConfirm}
        ></ConfirmDialog>
      </Box>
    );
  }

  componentDidUpdate() {
    const {
      delActivado,
      delActivadoError,
      createActivado,
      createActivadoError,
    } = this.props;

    if (createActivadoError) {
      let offlineRegs = [...this.state.offlineRegs];
      let activado = offlineRegs.find((e) => e.sincronizando);

      if (activado) {
        activado.erroresSincronizacion = createActivadoError;
        activado.sincronizado = true;
        activado.sincronizando = false;
        this.props.clearAction("clearActivado");

        this.props.db.putValue("activados", activado).then((e) => {
          this.setState({ offlineRegs: offlineRegs }, () => {
            this.sincronizarDatos();
          });
        });
      } else {
        this.props.clearAction("clearActivado");
        this.sincronizarDatos();
      }
    } else if (createActivado) {
      let activado = this.state.offlineRegs.find((e) => e.sincronizando);

      if (activado) {
        this.props.clearAction("clearActivado");
        this.onDeleteDialogClose(true);
      } else {
        this.props.clearAction("clearActivado");
        this.sincronizarDatos();
      }
    }

    if (delActivadoError) {
      this.props.enqueueSnackbar(delActivadoError, {
        variant: "error",
      });
      this.props.clearAction("clearActivado");
    } else if (delActivado) {
      this.props.clearAction("clearActivado");
      this.fetchList();
      this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
        variant: "success",
      });
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    activados: state.activadoState.activados,
    totalList: state.activadoState.totalList,

    delActivado: state.activadoState.delActivado,
    delActivadoError: state.activadoState.delActivadoError,
    rolCatalog: state.rolState.rolCatalog,

    createActivado: state.activadoState.createActivado,
    createActivadoError: state.activadoState.createActivadoError,
    activado: state.activadoState.activado,

    campaniaCatalog: state.campaniaState.campaniaCatalog,
    clienteCatalog: state.customerState.clienteCatalog,

    customerId: state.authState.user.customerId,
    campaniaId: state.authState.user.campaniaId,
    preferenciaClienteId: state.preferenciasGeneralState.clienteId,
    preferenciaCampaniaId: state.preferenciasGeneralState.campaniaId,
  };
};

export default connect(mapStatetoProps, {
  getAllAction,
  deleteAction,
  clearAction,
  getAction,
  getActionOffline,
  createAction,
  establecerCliente,
  establecerCampania,
  getCatalogAction,
})(withStyles(useStyles)(withSnackbar(ActivadoList)));
