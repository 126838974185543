import React from "react";
import {
  updateAction,
  clearAction,
  getAction,
  getCatalogAction,
} from "../../../actions/action-template";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { withSnackbar } from "notistack";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import {
  Box,
  AppBar,
  Toolbar,
  Dialog,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Avatar,
} from "@material-ui/core";
import Transition from "../../tools/transition";
import Ratings from "react-ratings-declarative";
//import CropFreeIcon from "@material-ui/icons/CropFree";
import QRCode from "qrcode.react";
import emailValidator from "../../validators/email-validator";
import getGeoReference from "../../../apis/geo-reference-api";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FileUploader from "../../tools/file-uploader";

let apiURL = process.env.REACT_APP_SERVER_URL;

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
  formButton: {
    marginRight: "10px",
  },
  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
});

class DatosPersonales extends React.Component {
  constructor(props) {
    super(props);
    let mostrarQR = props.customerId !== "" && props.campaniaId !== "";
    let urlRegistro = "";
    if (mostrarQR) {
      urlRegistro = `${apiURL}#/registro?c=${props.customerId}&ca=${props.campaniaId}&u=${props.userId}`;
    }
    this.state = {
      submitted: false,
      isOpenDialog: isMobile,
      nombres: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      login: "",
      telefono: "",
      id: "",
      rol: "",

      seccion: "",
      calle: "",
      numeroExterior: "",
      numeroInterior: "",
      colonia: "",
      activadoId: "",
      codigoPostal: "",
      generoId: "",
      edad: "",
      mostrarQR: mostrarQR,
      urlRegistro: urlRegistro,
      nombreFoto: "",
      blobFotoId: "",
    };

    this.props.getCatalogAction("getGeneroCatalogo");
    this.props.getAction("getDatosPersonales", this.props.userId);
  }

  showError = (value) => {
    return this.state.submitted && !value ? true : false;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleChangeNumber = (e) => {
    const { name, value } = e.target;
    let number = parseInt(value);
    number = Number.isNaN(number) ? "" : value;
    this.setState({ [name]: number });
  };

  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const {
      nombres,
      apellidoPaterno,
      apellidoMaterno,
      telefono,
      seccion,
      calle,
      numeroExterior,
      numeroInterior,
      colonia,
      activadoId,
      codigoPostal,
      generoId,
      edad,
      nombreFoto,
      blobFotoId,
    } = this.state;
    if (nombres && apellidoPaterno && telefono && seccion) {
      getGeoReference({
        calle,
        numeroExterior,
        numeroInterior,
        colonia,
        codigoPostal,
      }).then((geoReferencia) => {
        this.props.updateAction("updateDatosPersonales", this.state.id, {
          id: this.state.id,
          activado: {
            id: activadoId,
            nombres,
            apellidoPaterno,
            apellidoMaterno,
            telefono,
            seccion,
            calle,
            numeroExterior,
            numeroInterior,
            colonia,
            codigoPostal,
            generoId,
            edad: edad === "" ? 0 : edad,
            ...geoReferencia,
            nombreFoto,
            blobFotoId,
          },
        });
      });
    } else {
      this.props.enqueueSnackbar("Debe ingresar los campos marcados con *", {
        variant: "warning",
      });
    }
  };

  componentDidUpdate() {
    const {
      datosPersonales,
      getDatosPersonales,
      getDatosPersonalesError,
      updateDatosPersonales,
      updateDatosPersonalesError,
      createStorage,
      createStorageError,
      storage,
    } = this.props;

    if (createStorageError) {
      this.props.enqueueSnackbar(createStorageError, {
        variant: "error",
      });
      this.props.clearAction("clearStorage");
    } else if (createStorage) {
      this.props.clearAction("clearStorage");
      this.setState({
        nombreFoto: storage.fileName,
        blobFotoId: storage.id,
      });
    }

    if (updateDatosPersonalesError) {
      this.props.enqueueSnackbar(updateDatosPersonalesError, {
        variant: "error",
      });
      this.props.clearAction("clearDatosPersonales");
    } else if (updateDatosPersonales) {
      this.props.clearAction("clearDatosPersonales");
      this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
        variant: "success",
      });
      this.props.history.push("/");
    }

    if (getDatosPersonalesError) {
      this.props.enqueueSnackbar(getDatosPersonalesError, {
        variant: "error",
      });
      this.props.clearAction("clearDatosPersonales");
    } else if (getDatosPersonales) {
      this.props.clearAction("clearDatosPersonales");
      this.setState({
        id: datosPersonales.id,
        login: datosPersonales.login,
        estatus: datosPersonales.estatus,
        rol: datosPersonales.rol,
        nombres: datosPersonales.activado?.nombres,
        apellidoPaterno: datosPersonales.activado?.apellidoPaterno,
        apellidoMaterno: datosPersonales.activado?.apellidoMaterno,
        telefono: datosPersonales.activado?.telefono,
        activadoId: datosPersonales.activado?.id,

        seccion: datosPersonales.activado?.seccion ?? "",
        calle: datosPersonales.activado?.calle ?? "",
        numeroExterior: datosPersonales.activado?.numeroExterior ?? "",
        numeroInterior: datosPersonales.activado?.numeroInterior ?? "",
        colonia: datosPersonales.activado?.colonia ?? "",
        codigoPostal: datosPersonales.activado?.codigoPostal ?? "",
        estrellas: datosPersonales.activado?.estrellas ?? "",
        puntos: datosPersonales.activado?.puntos ?? "",
        generoId: datosPersonales.activado?.generoId ?? "",
        edad: datosPersonales.activado?.edad ?? "",
        blobFotoId: datosPersonales.activado?.blobFotoId ?? "",
      });
    }
  }

  renderContent() {
    const {
      nombres,
      apellidoPaterno,
      apellidoMaterno,
      login,
      telefono,
      rol,
      seccion,
      calle,
      numeroExterior,
      numeroInterior,
      colonia,
      codigoPostal,
      estrellas,
      puntos,
      generoId,
      edad,
    } = this.state;

    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} align="right">
          {this.state.blobFotoId && (
            <Box pr={3} mb={2}>
              <Avatar
                className={classes.large}
                alt="Perfil"
                src={`${apiURL}Managment/api/Storage/${this.state.blobFotoId}`}
              />
              <Button
                style={{ marginRight: 15 }}
                color="primary"
                onClick={() => {
                  this.setState({ blobFotoId: "", nombreFoto: "" });
                }}
              >
                Quitar imagen
              </Button>
            </Box>
          )}
          {!this.state.blobFotoId && (
            <Box
              style={{ maxWidth: 400 }}
              component={Paper}
              mb={2}
              align="center"
            >
              <FileUploader
                uploadActionAPI="postStorage"
                accept="image/png, image/jpeg"
                instrucciones="Seleccione una fotografía para su perfil"
              ></FileUploader>
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <Box align="right" style={{ marginRight: 10, marginBottom: 5 }}>
            <Typography variant="button" style={{ marginRight: 20 }}>
              Puntos: {puntos}
            </Typography>
            <Ratings
              rating={estrellas}
              widgetDimensions="20px"
              widgetSpacings="0px"
            >
              <Ratings.Widget widgetRatedColor="green" />
              <Ratings.Widget widgetRatedColor="green" />
              <Ratings.Widget widgetRatedColor="green" />
              <Ratings.Widget widgetRatedColor="green" />
              <Ratings.Widget widgetRatedColor="green" />
            </Ratings>
            {/* <IconButton
              title="Mostrar QR"
              color="primary"
              aria-label="Ver QR"
              component="span"
              onClick={() => {
                this.setState({ mostrarQR: true });
              }}
            >
              <CropFreeIcon />
            </IconButton> */}
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(nombres)}
            required
            label="Nombre(s)"
            name="nombres"
            autoFocus
            value={nombres}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(apellidoPaterno)}
            required
            label="Apellido Paterno"
            name="apellidoPaterno"
            value={apellidoPaterno}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Apellido Materno"
            name="apellidoMaterno"
            value={apellidoMaterno}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            disabled={true}
            className={classes.formControl}
            error={!emailValidator(login) && this.state.submitted}
            required
            label="Correo Electrónico"
            name="login"
            value={login}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(telefono)}
            required
            label="Teléfono"
            name="telefono"
            value={telefono}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Rol"
            name="rol"
            value={rol}
            disabled={true}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl size="small" className={classes.formControl}>
            <InputLabel id="lblGenero">Género</InputLabel>
            <Select
              labelId="lblGenero"
              id="generoId"
              value={generoId}
              name="generoId"
              onChange={this.handleChange}
            >
              {this.props.generoCatalog.map((p) => {
                return (
                  <MenuItem key={p.value} value={p.value}>
                    {p.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Edad"
            name="edad"
            value={edad}
            inputProps={{ maxLength: 2 }}
            type="text"
            onChange={this.handleChangeNumber}
          />
        </Grid>

        <Grid item xs={12}>
          <Box mt={3}>
            <Typography variant="caption" color="primary">
              Dirección (Credencial Elector)
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            className={classes.formControl}
            label="Calle"
            name="calle"
            value={calle}
            inputProps={{ maxLength: 128 }}
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Número exterior"
            name="numeroExterior"
            value={numeroExterior}
            inputProps={{ maxLength: 32 }}
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Número interior"
            name="numeroInterior"
            value={numeroInterior}
            inputProps={{ maxLength: 32 }}
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <TextField
            className={classes.formControl}
            label="Colonia"
            name="colonia"
            value={colonia}
            inputProps={{ maxLength: 128 }}
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Código Postal"
            name="codigoPostal"
            value={codigoPostal}
            inputProps={{ maxLength: 5 }}
            type="text"
            onChange={this.handleChangeNumber}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(seccion)}
            required
            type="text"
            label="Sección"
            name="seccion"
            value={seccion}
            onChange={this.handleChangeNumber}
            inputProps={{ maxLength: 4 }}
          />
        </Grid>

        {this.state.mostrarQR && (
          <Grid item xs={12} align="center">
            <Box p={1} m={3}>
              {/* <Typography variant="caption" display="block">
                Link para registro de activados
              </Typography> */}
              <QRCode value={this.state.urlRegistro} renderAs="svg" />
              <Box>
                <CopyToClipboard
                  text={this.state.urlRegistro}
                  onCopy={() =>
                    this.props.enqueueSnackbar(
                      "El enlace se copio correctamente!",
                      {
                        variant: "success",
                      }
                    )
                  }
                >
                  <Button color="primary" title="Copiar link auto registro">
                    Link para registro de activados &nbsp;&nbsp;&nbsp;
                    <FileCopyIcon></FileCopyIcon>
                  </Button>
                </CopyToClipboard>
              </Box>
              {/* <Input type="text" value={this.state.urlRegistro}></Input> */}
            </Box>
          </Grid>
        )}

        {isBrowser && (
          <Grid item xs={12}>
            <Box align="right" pt={4}>
              <Button
                className={classes.formButton}
                variant="contained"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.history.push("/");
                  });
                }}
              >
                Cancelar
              </Button>

              <Button
                className={classes.formButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.handleSubmit();
                }}
              >
                Guardar
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  }

  renderBrowser() {
    return (
      <Box p={5}>
        {/* {!this.state.clienteId && (
          <Typography variant="h4">Mis datos</Typography>
        )} */}

        {this.renderContent()}
      </Box>
    );
  }

  renderMobile() {
    const { classes } = this.props;
    return (
      <Box>
        <Dialog
          fullScreen
          open={this.state.isOpenDialog}
          onClose={() => this.setState({ isOpenDialog: false })}
          TransitionComponent={Transition}
          scroll="body"
          style={{ paddingTop: 40 }}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Mis datos
              </Typography>
            </Toolbar>
          </AppBar>
          <Box py={3} px={1}>
            {this.renderContent()}
            <div style={{ marginBottom: 100 }}></div>
          </Box>
          <AppBar
            position="fixed"
            color="primary"
            className={classes.appBottomBar}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.history.push("/");
                  });
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  cancelar
                </Typography>
              </IconButton>
              <div className={classes.grow} />

              <IconButton
                edge="end"
                color="inherit"
                onClick={() => {
                  this.handleSubmit();
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  Guardar
                </Typography>
              </IconButton>
            </Toolbar>
          </AppBar>
        </Dialog>
      </Box>
    );
  }

  render() {
    return (
      <Box>
        <BrowserView>{this.renderBrowser()}</BrowserView>
        <MobileView>{this.renderMobile()}</MobileView>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    username: state.authState.user.username,
    userId: state.authState.user.userId,
    loggedIn: state.authState.loggedIn,

    datosPersonales: state.datosPersonalesState.datosPersonales,
    getDatosPersonales: state.datosPersonalesState.getDatosPersonales,
    getDatosPersonalesError: state.datosPersonalesState.getDatosPersonalesError,

    updateDatosPersonales: state.datosPersonalesState.updateDatosPersonales,
    updateDatosPersonalesError:
      state.datosPersonalesState.updateDatosPersonalesError,

    generoCatalog: state.generoState.generoCatalog,

    customerId: state.authState.user.customerId,
    campaniaId: state.authState.user.campaniaId,

    storage: state.storageState.storage,
    createStorage: state.storageState.createStorage,
    createStorageError: state.storageState.createStorageError,
  };
};

export default connect(mapStatetoProps, {
  clearAction,
  getAction,
  updateAction,
  getCatalogAction,
})(withStyles(useStyles)(withSnackbar(DatosPersonales)));
