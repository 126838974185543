import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Dialog,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
} from "@material-ui/core";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import Transition from "../../tools/transition";
import emailValidator from "../../validators/email-validator";

import { connect } from "react-redux";
import {
  createAction,
  clearAction,
  updateAction,
} from "../../../actions/action-template";
import getGeoReference from "../../../apis/geo-reference-api";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
  formButton: {
    marginRight: "10px",
  },
  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
});

class UsuarioDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      isOpenDialog: true,

      nombres: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      login: "",
      telefono: "",
      id: "",
      rolId: "",
      clienteId: this.props.clienteId,
      activadoId: "",

      seccion: "",
      calle: "",
      numeroExterior: "",
      numeroInterior: "",
      colonia: "",
      codigoPostal: "",
      campaniaId: "",
      generoId: "",
      edad: "",
    };
  }

  showError = (value) => {
    return this.state.submitted && !value ? true : false;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleChangeNumber = (e) => {
    const { name, value } = e.target;
    let number = parseInt(value);
    number = Number.isNaN(number) ? "" : value;
    this.setState({ [name]: number });
  };

  handleSubmit = (e) => {
    const {
      nombres,
      apellidoPaterno,
      apellidoMaterno,
      login,
      telefono,
      activadoId,
      rolId,
      clienteId,

      seccion,
      calle,
      numeroExterior,
      numeroInterior,
      colonia,
      codigoPostal,
      campaniaId,
      generoId,
      edad,
    } = this.state;

    let validaCliente = true;
    if (clienteId && !campaniaId) {
      validaCliente = false;
    }
    if (
      nombres &&
      apellidoPaterno &&
      login &&
      emailValidator(login) &&
      telefono &&
      rolId &&
      validaCliente &&
      seccion
    ) {
      this.setState({ submitted: true });

      getGeoReference({
        calle,
        numeroExterior,
        numeroInterior,
        colonia,
        codigoPostal,
      }).then((geoReferencia) => {
        if (!this.state.id) {
          trackPromise(
            this.props.createAction("postUsuario", {
              login,

              rolId,
              clienteId,
              campaniaId: campaniaId ? campaniaId : 0,
              activado: {
                nombres,
                apellidoPaterno,
                apellidoMaterno,
                telefono,
                email: login,
                seccion,
                calle,
                numeroExterior,
                numeroInterior,
                colonia,
                codigoPostal,
                generoId,
                edad: edad === "" ? 0 : edad,
                ...geoReferencia,
              },
            })
          );
        } else {
          trackPromise(
            this.props.updateAction("updateUsuario", this.state.id, {
              id: this.state.id,
              login,
              estatus: this.state.estatus,
              rolId,
              clienteId,
              campaniaId: campaniaId ? campaniaId : 0,
              activado: {
                id: activadoId,
                nombres,
                apellidoPaterno,
                apellidoMaterno,
                telefono,
                email: login,
                seccion,
                calle,
                numeroExterior,
                numeroInterior,
                colonia,
                codigoPostal,
                generoId,
                edad: edad === "" ? 0 : edad,
                ...geoReferencia,
              },
            })
          );
        }
      });
    } else {
      this.props.enqueueSnackbar("Debe ingresar los campos marcados con *", {
        variant: "warning",
      });
    }
  };

  componentDidUpdate() {
    const {
      createUsuario,
      createUsuarioError,
      usuario,
      getUsuario,
      getUsuarioError,
      updateUsuarioError,
      updateUsuario,
    } = this.props;

    if (createUsuarioError) {
      this.props.clearAction("clearUsuario");
      this.setState({ submitted: false }, () => {
        this.props.enqueueSnackbar(createUsuarioError, {
          variant: "error",
        });
      });
    } else if (createUsuario) {
      this.props.clearAction("clearUsuario");

      this.setState(
        {
          isOpenDialog: false,
        },
        () => {
          this.props.resultOp(usuario);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (updateUsuarioError) {
      this.props.enqueueSnackbar(updateUsuarioError, {
        variant: "error",
      });
      this.props.clearAction("clearUsuario");
      this.setState({ submitted: false });
    } else if (updateUsuario) {
      this.props.clearAction("clearUsuario");

      this.setState(
        {
          isOpenDialog: false,
        },
        () => {
          this.props.resultOp(usuario);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (getUsuarioError) {
      this.props.enqueueSnackbar(getUsuarioError, {
        variant: "error",
      });
      this.props.clearAction("clearUsuario");
    } else if (getUsuario) {
      this.props.clearAction("clearUsuario");
      this.setState({
        id: usuario.id,
        login: usuario.login,
        estatus: usuario.estatus,
        rolId: usuario.rolId,
        nombres: usuario.activado?.nombres,
        apellidoPaterno: usuario.activado?.apellidoPaterno,
        apellidoMaterno: usuario.activado?.apellidoMaterno,
        telefono: usuario.activado?.telefono,
        activadoId: usuario.activado?.id,

        seccion: usuario.activado?.seccion,
        calle: usuario.activado?.calle,
        numeroExterior: usuario.activado?.numeroExterior,
        numeroInterior: usuario.activado?.numeroInterior,
        colonia: usuario.activado?.colonia,
        codigoPostal: usuario.activado?.codigoPostal,
        campaniaId: usuario.campaniaId,
        clienteId: usuario.clienteId,
        generoId: usuario.activado?.generoId,
        edad: usuario.activado?.edad,
      });
    }
  }

  renderContent() {
    const {
      nombres,
      apellidoPaterno,
      apellidoMaterno,
      login,
      telefono,
      id,
      rolId,

      seccion,
      calle,
      numeroExterior,
      numeroInterior,
      colonia,
      codigoPostal,
      campaniaId,
      generoId,
      edad,
    } = this.state;
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(nombres)}
            required
            label="Nombre(s)"
            name="nombres"
            autoFocus
            value={nombres}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(apellidoPaterno)}
            required
            label="Apellido Paterno"
            name="apellidoPaterno"
            value={apellidoPaterno}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Apellido Materno"
            name="apellidoMaterno"
            value={apellidoMaterno}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl size="small" className={classes.formControl}>
            <InputLabel id="lblGenero">Género</InputLabel>
            <Select
              labelId="lblGenero"
              id="generoId"
              value={generoId}
              name="generoId"
              onChange={this.handleChange}
            >
              {this.props.generoCatalog.map((p) => {
                return (
                  <MenuItem key={p.value} value={p.value}>
                    {p.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Edad"
            name="edad"
            value={edad}
            inputProps={{ maxLength: 2 }}
            type="text"
            onChange={this.handleChangeNumber}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            disabled={id !== ""}
            className={classes.formControl}
            error={!emailValidator(login) && this.state.submitted}
            required
            label="Correo Electrónico"
            name="login"
            value={login}
            onChange={this.handleChange}
            helperText={
              !emailValidator(login) && this.state.submitted
                ? "Ingrese un correo electrónico válido"
                : ""
            }
            inputProps={{ maxLength: 128 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(telefono)}
            required
            label="Teléfono"
            name="telefono"
            value={telefono}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl
            size="small"
            className={classes.formControl}
            required
            error={this.showError(rolId)}
          >
            <InputLabel id="lblRol">Rol</InputLabel>
            <Select
              labelId="lblRol"
              id="rolId"
              value={rolId}
              name="rolId"
              onChange={this.handleChange}
            >
              {this.props.rolCatalog
                .filter((e) =>
                  this.state.clienteId ? parseInt(e.value) > 2 : true
                )
                .map((p) => {
                  return (
                    <MenuItem key={p.value} value={p.value}>
                      {p.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>

        {this.state.clienteId && (
          <Grid item xs={12} md={4}>
            <FormControl
              size="small"
              className={classes.formControl}
              required
              error={this.showError(campaniaId)}
              disabled={this.state.id}
            >
              <InputLabel id="lblCampania">Campaña</InputLabel>
              <Select
                labelId="lblCampania"
                value={campaniaId}
                name="campaniaId"
                onChange={this.handleChange}
              >
                {this.props.campaniaCatalog.map((p) => {
                  return (
                    <MenuItem key={p.id} value={p.id}>
                      {p.nombre}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box mt={3}>
            <Typography variant="caption" color="primary">
              Dirección (Credencial Elector)
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            className={classes.formControl}
            label="Calle"
            name="calle"
            value={calle}
            inputProps={{ maxLength: 128 }}
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Número exterior"
            name="numeroExterior"
            value={numeroExterior}
            inputProps={{ maxLength: 32 }}
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Número interior"
            name="numeroInterior"
            value={numeroInterior}
            inputProps={{ maxLength: 32 }}
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <TextField
            className={classes.formControl}
            label="Colonia"
            name="colonia"
            value={colonia}
            inputProps={{ maxLength: 128 }}
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            label="Código Postal"
            name="codigoPostal"
            value={codigoPostal}
            inputProps={{ maxLength: 5 }}
            type="text"
            onChange={this.handleChangeNumber}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(seccion)}
            required
            type="text"
            label="Sección"
            name="seccion"
            value={seccion}
            onChange={this.handleChange}
            inputProps={{ maxLength: 4 }}
          />
        </Grid>

        {isBrowser && (
          <Grid item xs={12}>
            <Box align="right" pt={4}>
              <Button
                className={classes.formButton}
                variant="contained"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
              >
                Cancelar
              </Button>

              <Button
                disabled={this.state.submitted}
                className={classes.formButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.handleSubmit();
                }}
              >
                Guardar
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  }

  renderBrowser() {
    return (
      <Box p={5} component={Paper}>
        {!this.state.clienteId && (
          <Typography variant="h4">Detalle Usuario</Typography>
        )}

        {this.renderContent()}
      </Box>
    );
  }

  renderMobile() {
    const { classes } = this.props;
    return (
      <Box>
        <Dialog
          fullScreen
          open={this.state.isOpenDialog}
          onClose={() => this.setState({ isOpenDialog: false })}
          TransitionComponent={Transition}
          scroll="body"
          style={{ paddingTop: 40 }}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Detalle Usuario
              </Typography>
            </Toolbar>
          </AppBar>
          <Box py={3} px={1}>
            {this.renderContent()}

            <div style={{ marginBottom: 100 }}></div>
          </Box>
          <AppBar
            position="fixed"
            color="primary"
            className={classes.appBottomBar}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  cancelar
                </Typography>
              </IconButton>
              <div className={classes.grow} />

              <IconButton
                disabled={this.state.submitted}
                edge="end"
                color="inherit"
                onClick={() => {
                  this.handleSubmit();
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  Guardar
                </Typography>
              </IconButton>
            </Toolbar>
          </AppBar>
        </Dialog>
      </Box>
    );
  }

  render() {
    return (
      <Box>
        <BrowserView>{this.renderBrowser()}</BrowserView>
        <MobileView>{this.renderMobile()}</MobileView>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    createUsuario: state.usuarioState.createUsuario,
    createUsuarioError: state.usuarioState.createUsuarioError,
    usuario: state.usuarioState.usuario,
    getUsuario: state.usuarioState.getUsuario,
    getUsuarioError: state.usuarioState.getUsuarioError,

    updateUsuario: state.usuarioState.updateUsuario,
    updateUsuarioError: state.usuarioState.updateUsuarioError,

    rolCatalog: state.rolState.rolCatalog,
    campaniaCatalog: state.campaniaState.campaniaCatalog,
    generoCatalog: state.generoState.generoCatalog,
  };
};

export default connect(mapStatetoProps, {
  createAction,
  clearAction,
  updateAction,
})(withStyles(useStyles)(withSnackbar(UsuarioDetail)));
