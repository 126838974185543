import {
  Box,
  Grid,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  FormLabel,
  Typography,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import CancelIcon from "@material-ui/icons/Cancel";
import ConfirmDialog from "../tools/confirm-dialog";
import { clearAction, deleteAction } from "../../actions/action-template";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
  formButton: {
    marginRight: "10px",
  },
});

class PreguntaDetail extends React.Component {
  constructor(props) {
    super(props);
    let p = this.props.preguntaEdicion;
    if (p && p.opciones.length > 0) {
      let idx = 0;
      p.opciones = p.opciones.map((e) => {
        return { ...e, idx: idx++ };
      });
    }
    this.state = {
      submitted: false,

      id: p ? p.id : 0,
      encuestaId: this.props.encuestaId,
      nombre: p ? p.nombre : "",
      tipoPreguntaId: p ? p.tipoPreguntaId.toString() : "",
      esRespuestaMultiple: p ? p.esRespuestaMultiple : false,
      estatus: p ? p.estatus : true,
      opciones: p ? p.opciones : [],
      esDetalleRespuesta: p ? p.esDetalleRespuesta : false,
    };
  }

  showError = (value) => {
    return this.state.submitted && !value ? true : false;
  };

  handleChange = (e) => {
    if (!this.props.esEditable) return;
    const { name, value } = e.target;

    if (name === "tipoPreguntaId" && value === "1") {
      this.setState({ [name]: value, esRespuestaMultiple: false });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const {
      nombre,
      tipoPreguntaId,
      esRespuestaMultiple,
      opciones,
      id,
      encuestaId,
      estatus,
    } = this.state;

    if (nombre && tipoPreguntaId) {
      if (tipoPreguntaId === "2" && opciones.length < 2) {
        this.props.enqueueSnackbar("Debe ingresar al menos 2 respuestas", {
          variant: "warning",
        });
        return;
      }
      this.props.resultPregunta({
        id,
        encuestaId,
        nombre,
        tipoPreguntaId,
        esRespuestaMultiple,
        estatus,
        opciones,
      });
    } else {
      this.props.enqueueSnackbar("Debe ingresar los campos marcados con *", {
        variant: "warning",
      });
    }
  };

  handleCheckChange = (event) => {
    if (!this.props.esEditable) return;
    this.setState({ [event.target.name]: event.target.checked });
  };

  agregarOpcion = (e) => {
    if (!this.props.esEditable) return;
    let opciones = [...this.state.opciones];
    opciones.push({
      id: 0,
      idx: opciones.length,
      nombre: "",
      requiereExtenderRespuesta: false,
      preguntaId: this.state.id,
    });
    this.setState({ opciones: opciones });
  };

  handleChangeRow = (i, e) => {
    if (!this.props.esEditable) return;
    const { name, value, checked } = e.target;

    let opciones = [...this.state.opciones];
    let item = opciones.find((x) => x.idx === i);
    if (name === "requiereExtenderRespuesta") item[name] = checked;
    else item[name] = value;

    this.setState({ opciones: opciones });
  };

  confirmDelete = (r) => {
    if (!this.props.esEditable) return;
    let textConfirm = "";
    let titleConfirm = "";

    titleConfirm = "Eliminar Opción";
    textConfirm = "¿Realmente desea eliminar el registro?";
    if (r.id > 0) {
      this.setState({
        isDeleteDialogOpen: true,
        selectedIdForDelete: r.id,
        textConfirm: textConfirm,
        titleConfirm: titleConfirm,
      });
    } else {
      let opciones = [...this.state.opciones];
      let idx = 0;
      opciones = opciones.filter((e) => e.idx !== r.idx);
      opciones = opciones.map((e) => {
        return { ...e, idx: idx++ };
      });
      this.setState({ opciones: opciones });
      this.props.enqueueSnackbar("Los datos se guardaron correctamente", {
        variant: "success",
      });
    }
  };

  onDeleteDialogClose = (res) => {
    if (res) {
      this.props.deleteAction(
        "deleteOpcionPregunta",
        this.state.selectedIdForDelete
      );
    }
    this.setState({ isDeleteDialogOpen: false });
  };

  renderOpciones() {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={9}>
          Respuestas
        </Grid>
        <Grid item xs={2}>
          Requiere Detalle
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={12}>
          {this.state.opciones.map((row) => {
            return (
              <Grid container key={row.idx}>
                <Grid item xs={9}>
                  <TextField
                    size="small"
                    className={classes.formControl}
                    //  error={this.showError(finVigencia)}
                    required
                    name="nombre"
                    value={row.nombre}
                    onChange={(e) => this.handleChangeRow(row.idx, e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControl component="fieldset" style={{ marginTop: 10 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={row.requiereExtenderRespuesta}
                          onChange={(e) => this.handleChangeRow(row.idx, e)}
                          name="requiereExtenderRespuesta"
                        />
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <Box mt={2}>
                    <Button
                      color="secondary"
                      title="Eliminar"
                      onClick={() => {
                        this.confirmDelete(row);
                      }}
                    >
                      <CancelIcon></CancelIcon>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;
    const { nombre, tipoPreguntaId, esRespuestaMultiple, estatus } = this.state;

    return (
      <Box p={2} border={1} borderColor="primary.main" borderRadius={5}>
        <Typography>Detalle Pregunta</Typography>
        <Grid container>
          <Grid item xs={8}>
            <TextField
              className={classes.formControl}
              error={this.showError(nombre)}
              required
              label="Contenido"
              name="nombre"
              autoFocus
              value={nombre}
              onChange={this.handleChange}
              inputProps={{ maxLength: 128 }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl
              size="small"
              className={classes.formControl}
              error={this.showError(tipoPreguntaId)}
              required
            >
              <InputLabel id="lblTipo">Tipo</InputLabel>
              <Select
                labelId="lblTipo"
                name="tipoPreguntaId"
                value={tipoPreguntaId}
                onChange={this.handleChange}
              >
                {this.props.tipoPreguntaCatalog.map((p) => {
                  return (
                    <MenuItem key={p.value} value={p.value}>
                      {p.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl component="fieldset" style={{ marginTop: 10 }}>
              <FormLabel component="legend">
                <Typography variant="caption">Estatus</Typography>
              </FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    checked={estatus}
                    onChange={this.handleCheckChange}
                    name="estatus"
                  />
                }
                label={<Typography variant="caption">Activa</Typography>}
              />
            </FormControl>
          </Grid>
          {tipoPreguntaId === "2" && (
            <Grid item xs={12} md={4}>
              <FormControl component="fieldset" style={{ marginTop: 10 }}>
                <FormLabel component="legend">
                  <Typography variant="caption">Tipo Respuestas</Typography>
                </FormLabel>
                <FormControlLabel
                  control={
                    <Switch
                      checked={esRespuestaMultiple}
                      onChange={this.handleCheckChange}
                      name="esRespuestaMultiple"
                    />
                  }
                  label={
                    <Typography variant="caption">Opción Multiple</Typography>
                  }
                />
              </FormControl>
            </Grid>
          )}
          {tipoPreguntaId === "2" && (
            <Grid item xs={12}>
              <Box mt={2}>
                <Button color="primary" onClick={this.agregarOpcion}>
                  Agregar Respuesta
                </Button>
              </Box>
            </Grid>
          )}
          {this.state.opciones.length > 0 && (
            <Grid item xs={12}>
              {this.renderOpciones()}
            </Grid>
          )}

          <Grid item xs={12}>
            <Box align="right" pt={4}>
              <Button
                className={classes.formButton}
                variant="contained"
                onClick={() => {
                  this.props.resultPregunta(null);
                }}
              >
                <ClearIcon></ClearIcon>
              </Button>
              {this.props.esEditable && (
                <Button
                  className={classes.formButton}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  <CheckIcon></CheckIcon>
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
        <ConfirmDialog
          isDeleteDialogOpen={this.state.isDeleteDialogOpen}
          onDeleteDialogClose={this.onDeleteDialogClose}
          title={this.state.titleConfirm}
          message={this.state.textConfirm}
        ></ConfirmDialog>
      </Box>
    );
  }

  componentDidUpdate() {
    const { delOpcionPregunta, delOpcionPreguntaError } = this.props;

    if (delOpcionPreguntaError) {
      this.props.enqueueSnackbar(delOpcionPreguntaError, {
        variant: "error",
      });
      this.props.clearAction("clearOpcionPregunta");
    } else if (delOpcionPregunta) {
      this.props.clearAction("clearOpcionPregunta");

      let opciones = [...this.state.opciones];
      let idx = 0;
      opciones = opciones.filter(
        (e) => e.id !== this.state.selectedIdForDelete
      );
      opciones = opciones.map((e) => {
        return { ...e, idx: idx++ };
      });

      if (this.state.selectedIdForDelete) {
        this.setState({ opciones: opciones, selectedIdForDelete: "" }, () => {
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        });
      }
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    tipoPreguntaCatalog: state.tipoPreguntaState.tipoPreguntaCatalog,

    delOpcionPregunta: state.opcionPreguntaState.delOpcionPregunta,
    delOpcionPreguntaError: state.opcionPreguntaState.delOpcionPreguntaError,
  };
};

export default connect(mapStatetoProps, { deleteAction, clearAction })(
  withStyles(useStyles)(withSnackbar(PreguntaDetail))
);
