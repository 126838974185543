const defaultState = {
  misPreferencias: [],
  misRespuestas: [],
  totalList: 0,
  createMiPreferencia: false,
  createMiPreferenciaError: "",
  delMiPreferencia: false,
  delMiPreferenciaError: "",
  updateMiPreferencia: false,
  updateMiPreferenciaError: "",
};

export const misPreferenciasReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_MIS_PREFERENCIAS":
      return {
        ...state,
        misPreferencias: action.payload.data.encuestas.data,
        misRespuestas: action.payload.data.respuestas,
        totalList: action.payload.data.encuestas.totalRegs,
      };

    case "CREATE_MI_PREFERENCIA":
      return {
        ...state,
        preferencia: { ...action.payload },
        createMiPreferencia: true,
      };
    case "CREATE_MI_PREFERENCIA_ERROR":
      return { ...state, createMiPreferenciaError: action.payload.error };
    case "UPDATE_MI_PREFERENCIA":
      return {
        ...state,
        preferencia: { ...action.payload },
        updateMiPreferencia: true,
      };
    case "UPDATE_MI_PREFERENCIA_ERROR":
      return { ...state, updateMiPreferenciaError: action.payload.error };
    case "CLEAR_MI_PREFERENCIA":
      return {
        ...state,
        preferencia: {},
        misPreferencias: [],
        createMiPreferencia: false,
        createMiPreferenciaError: "",
        delMiPreferencia: false,
        delMiPreferenciaError: "",
        getMiPreferencia: false,
        getMiPreferenciaError: "",
        updateMiPreferencia: false,
        updateMiPreferenciaError: "",
      };
    case "DEL_MI_PREFERENCIA":
      return { ...state, delMiPreferencia: true };
    case "DEL_MI_PREFERENCIA_ERROR":
      return { ...state, delMiPreferenciaError: action.payload.error };

    default:
      return {
        ...state,
      };
  }
};
