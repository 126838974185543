import React from "react";
import {
  Box,
  Typography,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  Paper,
  Button,
  TextField,
  TablePagination,
  Grid,
  ButtonGroup,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import {
  getAllAction,
  clearAction,
  updateAction,
} from "../../../actions/action-template";
import { isBrowser } from "react-device-detect";
import SearchIcon from "@material-ui/icons/Search";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import FileUploader from "../../tools/file-uploader";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
let apiURL = process.env.REACT_APP_SERVER_URL;

const useStyles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
});

class CampaniaMetaList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 0,
      pageSize: 10,
      seccionFiltro: "",
      lista: [],
      campaniaId: this.props.campaniaId,
      uploadFile: false,
    };
    this.fetchList();
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    if (parseInt(value) < 0) return;
    let datos = name.split("-");
    let lista = [...this.state.lista];
    let reg = lista.find(
      (r) =>
        r.campaniaId === parseInt(datos[0]) &&
        r.distritoFederalId === parseInt(datos[1]) &&
        r.distritoLocalId === parseInt(datos[2]) &&
        r.entidadId === parseInt(datos[3]) &&
        r.municipioId === parseInt(datos[4]) &&
        r.seccionId === parseInt(datos[5])
    );
    if (reg) reg.meta = value;
    this.setState({ lista: lista });
  };

  fetchList() {
    const { pageNumber, pageSize, seccionFiltro } = this.state;
    let filtro = `campaniaId=${this.props.campania.id}&seccion=${
      seccionFiltro ? seccionFiltro : 0
    }`;

    this.props.getAllAction(
      "getAllMetaCampanias",
      pageNumber,
      pageSize,
      filtro
    );
  }

  clearFilter = () => {
    this.setState({ seccionFiltro: "" }, () => {
      this.fetchList();
    });
  };

  save = (e) => {
    this.props.updateAction("updateMetaCampania", e.campaniaId, e);
  };

  handleChangeInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  getKey(r) {
    let key = `${r.campaniaId}-${r.distritoFederalId}-${r.distritoLocalId}-${r.entidadId}-${r.municipioId}-${r.seccionId}`;
    return key;
  }

  renderItems() {
    const { classes } = this.props;

    return this.state.lista.map((r) => {
      return (
        <TableRow key={this.getKey(r)}>
          <TableCell>{r.entidadNombre}</TableCell>
          <TableCell>{r.municipioNombre}</TableCell>
          <TableCell>{r.seccionId}</TableCell>
          <TableCell>
            <TextField
              size="small"
              className={classes.formControl}
              type="number"
              label=""
              name={`${r.campaniaId}-${r.distritoFederalId}-${r.distritoLocalId}-${r.entidadId}-${r.municipioId}-${r.seccionId}`}
              value={r.meta}
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              onBlur={() => {
                this.save(r);
              }}
            />
          </TableCell>
          {/* <TableCell>
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button
                size="small"
                color="primary"
                title="Guardar"
                onClick={() => {
                  this.save(r);
                }}
              >
                <SaveIcon></SaveIcon>
              </Button>
            </ButtonGroup>
          </TableCell> */}
        </TableRow>
      );
    });
  }
  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.fetchList();
    }
  };
  getExcel = () => {
    window.open(
      `${apiURL}managment/api/CampaniaMeta/${this.props.campania.id}`,
      "_self"
    );
  };

  render() {
    const { classes } = this.props;
    let urlExcel = `${apiURL}managment/api/CampaniaMeta/${this.props.campania.id}`;
    return (
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Metas
        </Typography>
        <Paper
          style={{
            marginBottom: 20,
            padding: 20,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <TextField
                size="small"
                name="seccionFiltro"
                label="Sección"
                value={this.state.seccionFiltro}
                onChange={this.handleChangeInput}
                style={{ marginRight: 5 }}
                onKeyDown={this.handleKeyDown}
              />

              <ButtonGroup
                color="primary"
                aria-label="Acciones"
                style={{ marginTop: 10, marginLeft: 10 }}
                size="small"
              >
                <Button
                  title="Filtrar"
                  color="primary"
                  onClick={() => {
                    this.fetchList();
                  }}
                >
                  <SearchIcon />
                </Button>
                <Button
                  title="Limpiar filtros"
                  color="primary"
                  onClick={this.clearFilter}
                >
                  <AutorenewIcon />
                </Button>
                <Box
                  align="center"
                  border={1}
                  borderColor="primary.main"
                  style={{ paddingTop: 5 }}
                  title="Descargar plantilla de metas"
                >
                  <a
                    href={urlExcel}
                    download
                    color="primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <GetAppIcon color="primary" />
                  </a>
                </Box>
                <Button
                  title="Publicar plantilla de metas"
                  color="primary"
                  onClick={() => {
                    this.setState({ uploadFile: true });
                  }}
                >
                  <PublishIcon />
                </Button>
              </ButtonGroup>
            </Grid>
            {this.state.uploadFile && (
              <Grid item xs={12} align="center">
                <Box mt={2} style={{ maxWidth: 400 }} component={Paper}>
                  <FileUploader
                    uploadActionAPI="postMetaCampania"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    instrucciones="Seleccione el archivo para subir las metas"
                  ></FileUploader>
                </Box>
              </Grid>
            )}
          </Grid>
        </Paper>
        <TableContainer component={Paper}>
          <Table
            size="small"
            className={classes.table}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Entidad</TableCell>
                <TableCell>Municipio</TableCell>
                <TableCell>Sección</TableCell>
                <TableCell>Meta</TableCell>
                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>{this.renderItems()}</TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={this.props.totalList}
            rowsPerPage={this.state.pageSize}
            page={this.state.pageNumber}
            onChangePage={(e, newPage) => {
              this.setState({ pageNumber: newPage }, () => {
                this.fetchList();
              });
            }}
            onChangeRowsPerPage={(e) => {
              this.setState({ pageSize: parseInt(e.target.value, 10) }, () => {
                this.fetchList();
              });
            }}
          />
        </TableContainer>

        {isBrowser && (
          <Box align="right" pt={4}>
            <Button
              className={classes.formButton}
              variant="contained"
              onClick={() => {
                this.setState({ isOpenDialog: false }, () => {
                  this.props.resultOp(1);
                });
              }}
            >
              Regresar
            </Button>
          </Box>
        )}
      </Box>
    );
  }

  componentDidUpdate() {
    const {
      updateCampaniaMetaError,
      updateCampaniaMeta,
      campaniaMetas,
      fechCampanias,
      createCampaniaMeta,
      createCampaniaMetaError,
    } = this.props;

    if (fechCampanias) {
      let lista = [...campaniaMetas];
      this.props.clearAction("clearMetaCampania");

      this.setState({ lista: lista });
    }

    if (updateCampaniaMetaError) {
      this.props.enqueueSnackbar(updateCampaniaMetaError, {
        variant: "error",
      });
      this.props.clearAction("clearUsuario");
    } else if (updateCampaniaMeta) {
      this.props.clearAction("clearMetaCampania");
      // this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
      //   variant: "success",
      // });
    }

    if (createCampaniaMetaError) {
      this.props.enqueueSnackbar(createCampaniaMetaError, {
        variant: "error",
      });
      this.props.clearAction("clearUsuario");
    } else if (createCampaniaMeta) {
      this.props.clearAction("clearMetaCampania");

      this.setState({ uploadFile: false }, () => {
        this.props.enqueueSnackbar("Los datos se actualizaron correctamente!", {
          variant: "success",
        });
        this.fetchList();
      });
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    campaniaMetas: state.metasState.campaniaMetas,
    fechCampanias: state.metasState.fechCampanias,
    totalList: state.metasState.totalList,

    updateCampaniaMeta: state.metasState.updateCampaniaMeta,
    delCampaniaMetaError: state.metasState.delCampaniaMetaError,

    createCampaniaMeta: state.metasState.createCampaniaMeta,
    createCampaniaMetaError: state.metasState.createCampaniaMetaError,
  };
};

export default connect(mapStatetoProps, {
  getAllAction,
  clearAction,

  updateAction,
})(withStyles(useStyles)(withSnackbar(CampaniaMetaList)));
