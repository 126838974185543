import axios from "axios";
let apiURL = process.env.REACT_APP_SERVER_URL;
const instance = axios.create({
  baseURL: apiURL,
});

export function SetupServiceNetwork(store) {
  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (
        error.response?.data?.errors?.length > 0 &&
        (error.response?.data?.errors[0]?.message ===
          "Context creation failed: jwt expired" ||
          error.response?.data?.errors[0]?.message ===
            "Context creation failed: jwt malformed")
      ) {
        localStorage.clear();
        store.dispatch({ type: "LOGOUT" });
        window.location.href = "./#/login";
        //window.location.reload();
      }
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
  );
}

export default instance;
