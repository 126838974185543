import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { LinearProgress } from "@material-ui/core";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return promiseInProgress ? (
    <LinearProgress
      style={{
        position: "fixed",
        zIndex: 9900000000,
        width: "100%",
      }}
    />
  ) : (
    <div></div>
  );
};

export default LoadingIndicator;
