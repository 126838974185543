import React from "react";
import { Link } from "react-router-dom";

import {
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  AppBar,
  Toolbar,
  Dialog,
  IconButton,
  Box,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  clearLogin,
  changePasswordAction,
} from "../../../actions/auth/auth-actions";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import newPasswordValidator from "../../validators/new-password-validator";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import Transition from "../../tools/transition";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  root: {
    minWidth: 400,
    maxWidth: 400,
  },
  content: {
    padding: 30,
  },
  main: {
    minHeight: "80vh",
  },
  spacingTop: {
    paddingTop: 30,
  },
  spacingRight: {
    marginRight: theme.spacing(1),
  },
  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
});

class SetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      password: "",
      comparePassword: "",

      isOpenDialog: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  showError(value) {
    return this.state.submitted && !value ? true : false;
  }

  handleSubmit(e) {
    e.preventDefault();
    const { password, comparePassword } = this.state;

    const query = new URLSearchParams(this.props.location.search);
    const token = query.get("t");
    const login = query.get("u");

    this.setState({ submitted: true });

    if (password && comparePassword) {
      trackPromise(
        this.props.changePasswordAction(login, password, comparePassword, token)
      ).then(() => {
        this.setState({ submitted: false });
      });
    } else {
      this.props.enqueueSnackbar("Debe ingresar los campos marcados con *", {
        variant: "warning",
      });
    }
  }

  componentDidUpdate() {
    const { changePasswordError, changePassword } = this.props;
    if (changePasswordError) {
      this.props.enqueueSnackbar(changePasswordError, {
        variant: "warning",
      });
      this.props.clearLogin();
    }
    if (changePassword) {
      this.props.enqueueSnackbar("Se ha cambiado su contraseña!", {
        variant: "success",
      });
      this.props.clearLogin();
      this.props.history.push("/login");
    }
  }

  renderContent() {
    const { password, comparePassword } = this.state;
    const { classes } = this.props;
    return (
      <form noValidate autoComplete="off">
        <TextField
          error={this.showError(this.state.password)}
          margin="normal"
          required
          fullWidth
          label="Constraseña"
          type="password"
          autoComplete="current-password"
          name="password"
          value={password}
          onChange={this.handleChange}
        />
        <TextField
          error={
            this.showError(this.state.password) ||
            (!newPasswordValidator(
              this.state.password,
              this.state.comparePassword
            ) &&
              this.state.submitted)
          }
          margin="normal"
          required
          fullWidth
          label="Repetir constraseña"
          type="password"
          name="comparePassword"
          value={comparePassword}
          onChange={this.handleChange}
          helperText={
            !newPasswordValidator(
              this.state.password,
              this.state.comparePassword
            ) && this.state.submitted
              ? "Las contraseñas son diferentes, intente de nuevo!"
              : ""
          }
        />
        {isBrowser && (
          <Grid
            container
            direction="row"
            justify="center"
            className={classes.spacingTop}
          >
            <Button
              component={Link}
              to="/login"
              type="button"
              className={classes.spacingRight}
            >
              Cancelar
            </Button>
            <Button
              disabled={this.state.submitted}
              type="submit"
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Enviar
            </Button>
          </Grid>
        )}
      </form>
    );
  }

  renderBrowser() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <CardHeader title="Establecer contraseña"></CardHeader>
        <CardContent className={classes.content}>
          {this.renderContent()}
        </CardContent>
      </Card>
    );
  }

  renderMobile() {
    const { classes } = this.props;
    return (
      // <Box m={4}>
      //   <Typography variant="h5" gutterBottom>
      //
      //   </Typography>
      //   {this.renderContent()}
      // </Box>

      <Dialog
        fullScreen
        open={this.state.isOpenDialog}
        onClose={() => this.setState({ isOpenDialog: false })}
        TransitionComponent={Transition}
        scroll="body"
        style={{ paddingTop: 40 }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Establecer contraseña
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justify="center"
          className={classes.main}
        >
          <Grid item>
            <Box p={4}>
              {this.renderContent()}
              <div style={{ marginBottom: 100 }}></div>
            </Box>
          </Grid>
        </Grid>

        <AppBar
          position="fixed"
          color="primary"
          className={classes.appBottomBar}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              component={Link}
              to="/login"
              aria-label="close"
            >
              <Typography variant="button" display="block" gutterBottom>
                Cancelar
              </Typography>
            </IconButton>
            <div className={classes.grow} />

            <IconButton
              disabled={this.state.submitted}
              edge="end"
              color="inherit"
              onClick={this.handleSubmit}
              aria-label="close"
            >
              <Typography variant="button" display="block" gutterBottom>
                Enviar
              </Typography>
            </IconButton>
          </Toolbar>
        </AppBar>
      </Dialog>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justify="center"
        className={classes.main}
      >
        <Grid item>
          <BrowserView>{this.renderBrowser()}</BrowserView>
          <MobileView>{this.renderMobile()}</MobileView>
        </Grid>
      </Grid>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    changePassword: state.authState.changePassword,
    changePasswordError: state.authState.changePasswordError,
  };
};

export default connect(mapStatetoProps, {
  clearLogin,
  changePasswordAction,
})(withStyles(useStyles)(withSnackbar(SetPassword)));
