export const establecerCliente = function (clienteId) {
  return {
    type: "ESTABLECER_CLIENTE",
    payload: clienteId,
  };
};

export const establecerCampania = function (campaniaId) {
  return {
    type: "ESTABLECER_CAMPANIA",
    payload: campaniaId,
  };
};
