import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Dialog,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import Transition from "../../tools/transition";

import { connect } from "react-redux";
import {
  createAction,
  clearAction,
  updateAction,
} from "../../../actions/action-template";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
  formButton: {
    marginRight: "10px",
  },
  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
});

class DistritoLocalDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      isOpenDialog: true,

      entidadId: "",

      id: "",
    };
  }

  showError = (value) => {
    return this.state.submitted && !value ? true : false;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { id, entidadId } = this.state;
    if (entidadId) {
      if (!this.state.id) {
        this.props.createAction("postDistritoLocal", {
          entidadId,
          id,
        });
      } else {
        this.props.updateAction("updateDistritoLocal", this.state.id, {
          id: this.state.id,
          entidadId,
        });
      }
    } else {
      this.props.enqueueSnackbar("Debe ingresar los campos marcados con *", {
        variant: "warning",
      });
    }
  };

  componentDidUpdate() {
    const {
      createDistritoLocal,
      createDistritoLocalError,
      distritoLocal,
      getDistritoLocal,
      getDistritoLocalError,
      updateDistritoLocalError,
      updateDistritoLocal,
    } = this.props;

    if (createDistritoLocalError) {
      this.props.enqueueSnackbar(createDistritoLocalError, {
        variant: "error",
      });
      this.props.clearAction("clearDistritoLocal");
    } else if (createDistritoLocal) {
      this.props.clearAction("clearDistritoLocal");

      this.setState(
        {
          isOpenDialog: false,
        },
        () => {
          this.props.resultOp(distritoLocal);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (updateDistritoLocalError) {
      this.props.enqueueSnackbar(updateDistritoLocalError, {
        variant: "error",
      });
      this.props.clearAction("clearDistritoLocal");
    } else if (updateDistritoLocal) {
      this.props.clearAction("clearDistritoLocal");

      this.setState(
        {
          isOpenDialog: false,
        },
        () => {
          this.props.resultOp(distritoLocal);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (getDistritoLocalError) {
      this.props.enqueueSnackbar(getDistritoLocalError, {
        variant: "error",
      });
      this.props.clearAction("clearDistritoLocal");
    } else if (getDistritoLocal) {
      this.props.clearAction("clearDistritoLocal");
      this.setState({
        id: distritoLocal.id,

        entidadId: distritoLocal.entidadId,
      });
    }
  }

  renderContent() {
    const { entidadId, id } = this.state;
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item xs={12} md={6}>
          <FormControl size="small" className={classes.formControl}>
            <InputLabel id="lblEntidad">Entidad</InputLabel>
            <Select
              disabled
              labelId="lblEntidad"
              id="entidadId"
              value={entidadId}
              name="entidadId"
              onChange={this.handleChange}
            >
              {this.props.entidadCatalog.map((p) => {
                return (
                  <MenuItem key={p.value} value={p.value}>
                    {p.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            className={classes.formControl}
            error={this.showError(id)}
            required
            label="Distrito Local"
            name="id"
            autoFocus
            value={id}
            onChange={this.handleChange}
            disabled
          />
        </Grid>

        {isBrowser && (
          <Grid item xs={12}>
            <Box align="right" pt={4}>
              <Button
                className={classes.formButton}
                variant="contained"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
              >
                cancelar
              </Button>

              <Button
                className={classes.formButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.handleSubmit();
                }}
              >
                Guardar
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  }

  renderBrowser() {
    return (
      <Box p={10}>
        <Typography variant="h4">Detalle Distrito Local</Typography>
        {this.renderContent()}
      </Box>
    );
  }

  renderMobile() {
    const { classes } = this.props;
    return (
      <Box>
        <Dialog
          fullScreen
          open={this.state.isOpenDialog}
          onClose={() => this.setState({ isOpenDialog: false })}
          TransitionComponent={Transition}
          scroll="body"
          style={{ paddingTop: 40 }}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Detalle Distrito Local
              </Typography>
            </Toolbar>
          </AppBar>
          <Box py={3} px={1}>
            {this.renderContent()}
            <div style={{ marginBottom: 100 }}></div>
          </Box>
          <AppBar
            position="fixed"
            color="primary"
            className={classes.appBottomBar}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  cancelar
                </Typography>
              </IconButton>
              <div className={classes.grow} />

              <IconButton
                edge="end"
                color="inherit"
                onClick={() => {
                  this.handleSubmit();
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  Guardar
                </Typography>
              </IconButton>
            </Toolbar>
          </AppBar>
        </Dialog>
      </Box>
    );
  }

  render() {
    return (
      <Box>
        <BrowserView>{this.renderBrowser()}</BrowserView>
        <MobileView>{this.renderMobile()}</MobileView>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    createDistritoLocal: state.distritoLocalState.createDistritoLocal,
    createDistritoLocalError: state.distritoLocalState.createDistritoLocalError,
    distritoLocal: state.distritoLocalState.distritoLocal,
    getDistritoLocal: state.distritoLocalState.getDistritoLocal,
    getDistritoLocalError: state.distritoLocalState.getDistritoLocalError,

    updateDistritoLocal: state.distritoLocalState.updateDistritoLocal,
    updateDistritoLocalError: state.distritoLocalState.updateDistritoLocalError,

    entidadCatalog: state.entidadState.entidadCatalog,
  };
};

export default connect(mapStatetoProps, {
  createAction,
  clearAction,
  updateAction,
})(withStyles(useStyles)(withSnackbar(DistritoLocalDetail)));
