import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Dialog,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import Transition from "../../tools/transition";

import { connect } from "react-redux";
import {
  createAction,
  clearAction,
  updateAction,
} from "../../../actions/action-template";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
  formButton: {
    marginRight: "10px",
  },
  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
});

class ColoniaDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      isOpenDialog: true,

      entidadId: "",
      municipioId: "",
      control: "",
      nombre: "",
      id: "",
      tipo: "",
      codigoPostal: "",
    };
  }

  showError = (value) => {
    return this.state.submitted && !value ? true : false;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const {
      id,
      entidadId,
      nombre,
      municipioId,
      control,
      tipo,
      codigoPostal,
    } = this.state;
    if (entidadId && nombre) {
      if (!this.state.id) {
        this.props.createAction("postColonia", {
          entidadId,
          id,
          municipioId,
          control,
          nombre,
          tipo,
          codigoPostal,
        });
      } else {
        this.props.updateAction("updateColonia", this.state.id, {
          id: this.state.id,
          entidadId,
          municipioId,
          control,
          nombre,
          tipo,
          codigoPostal,
        });
      }
    } else {
      this.props.enqueueSnackbar("Debe ingresar los campos marcados con *", {
        variant: "warning",
      });
    }
  };

  componentDidUpdate() {
    const {
      createColonia,
      createColoniaError,
      colonia,
      getColonia,
      getColoniaError,
      updateColoniaError,
      updateColonia,
    } = this.props;

    if (createColoniaError) {
      this.props.enqueueSnackbar(createColoniaError, {
        variant: "error",
      });
      this.props.clearAction("clearColonia");
    } else if (createColonia) {
      this.props.clearAction("clearColonia");

      this.setState(
        {
          isOpenDialog: false,
        },
        () => {
          this.props.resultOp(colonia);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (updateColoniaError) {
      this.props.enqueueSnackbar(updateColoniaError, {
        variant: "error",
      });
      this.props.clearAction("clearColonia");
    } else if (updateColonia) {
      this.props.clearAction("clearColonia");

      this.setState(
        {
          isOpenDialog: false,
        },
        () => {
          this.props.resultOp(colonia);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (getColoniaError) {
      this.props.enqueueSnackbar(getColoniaError, {
        variant: "error",
      });
      this.props.clearAction("clearColonia");
    } else if (getColonia) {
      this.props.clearAction("clearColonia");
      this.setState({
        id: colonia.id,
        entidadId: colonia.entidadId,
        municipioId: colonia.municipioId,
        control: colonia.control,
        nombre: colonia.nombre,
        tipo: colonia.tipo,
        codigoPostal: colonia.codigoPostal,
      });
    }
  }

  renderContent() {
    const { entidadId, nombre } = this.state;
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item xs={12} md={6}>
          <FormControl size="small" className={classes.formControl}>
            <InputLabel id="lblEntidad">Entidad</InputLabel>
            <Select
              disabled
              labelId="lblEntidad"
              id="entidadId"
              value={entidadId}
              name="entidadId"
              onChange={this.handleChange}
            >
              {this.props.entidadCatalog.map((p) => {
                return (
                  <MenuItem key={p.value} value={p.value}>
                    {p.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        {/* <Grid item xs={12} md={6}>
          <TextField
            className={classes.formControl}
            error={this.showError(control)}
            required
            label="Control"
            name="control"
            value={control}
            onChange={this.handleChange}
            disabled
          />
        </Grid> */}

        <Grid item xs={12} md={6}>
          <TextField
            className={classes.formControl}
            error={this.showError(nombre)}
            required
            label="Nombre"
            name="nombre"
            value={nombre}
            onChange={this.handleChange}
            inputProps={{ maxLength: 256 }}
          />
        </Grid>

        {isBrowser && (
          <Grid item xs={12}>
            <Box align="right" pt={4}>
              <Button
                className={classes.formButton}
                variant="contained"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
              >
                cancelar
              </Button>

              <Button
                className={classes.formButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.handleSubmit();
                }}
              >
                Guardar
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  }

  renderBrowser() {
    return (
      <Box p={10}>
        <Typography variant="h4">Detalle Sección</Typography>
        {this.renderContent()}
      </Box>
    );
  }

  renderMobile() {
    const { classes } = this.props;
    return (
      <Box>
        <Dialog
          fullScreen
          open={this.state.isOpenDialog}
          onClose={() => this.setState({ isOpenDialog: false })}
          TransitionComponent={Transition}
          scroll="body"
          style={{ paddingTop: 40 }}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Detalle Sección
              </Typography>
            </Toolbar>
          </AppBar>
          <Box py={3} px={1}>
            {this.renderContent()}
            <div style={{ marginBottom: 100 }}></div>
          </Box>
          <AppBar
            position="fixed"
            color="primary"
            className={classes.appBottomBar}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  cancelar
                </Typography>
              </IconButton>
              <div className={classes.grow} />

              <IconButton
                edge="end"
                color="inherit"
                onClick={() => {
                  this.handleSubmit();
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  Guardar
                </Typography>
              </IconButton>
            </Toolbar>
          </AppBar>
        </Dialog>
      </Box>
    );
  }

  render() {
    return (
      <Box>
        <BrowserView>{this.renderBrowser()}</BrowserView>
        <MobileView>{this.renderMobile()}</MobileView>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    createColonia: state.coloniaState.createColonia,
    createColoniaError: state.coloniaState.createColoniaError,
    colonia: state.coloniaState.colonia,
    getColonia: state.coloniaState.getColonia,
    getColoniaError: state.coloniaState.getColoniaError,

    updateColonia: state.coloniaState.updateColonia,
    updateColoniaError: state.coloniaState.updateColoniaError,
    entidadCatalog: state.entidadState.entidadCatalog,
  };
};

export default connect(mapStatetoProps, {
  createAction,
  clearAction,
  updateAction,
})(withStyles(useStyles)(withSnackbar(ColoniaDetail)));
