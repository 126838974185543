import React from "react";
import {
  Box,
  Grid,
  Typography,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  Paper,
  ButtonGroup,
  Button,
  TablePagination,
  TextField,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { connect } from "react-redux";

import ConfirmDialog from "../../tools/confirm-dialog";
import { withSnackbar } from "notistack";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import {
  getAllAction,
  deleteAction,
  clearAction,
  getAction,
} from "../../../actions/action-template";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
});

class CustomerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 0,
      pageSize: 10,
      nombreFiltro: "",
      apFiltro: "",
      amFiltro: "",
      isDeleteDialogOpen: false,
      selectedIdForDelete: "",

      titleConfirm: "Desactivar Cliente",
      textConfirm: "¿Realmente desea desactivar el cliente?",
    };
    this.fetchList();
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  fetchList() {
    const {
      pageNumber,
      pageSize,
      nombreFiltro,
      amFiltro,
      apFiltro,
    } = this.state;
    let filtro = `nombre=${nombreFiltro}&ap=${apFiltro}&am=${amFiltro}`;
    trackPromise(
      this.props.getAllAction("getAllClientes", pageNumber, pageSize, filtro)
    );
  }

  onDeleteDialogClose = (res) => {
    if (res) {
      let o = this.props.customers.find(
        (e) => e.id === this.state.selectedIdForDelete
      );
      this.props.deleteAction(
        "deleteCliente",
        this.state.selectedIdForDelete,
        !o.estatus
      );
    }
    this.setState({ isDeleteDialogOpen: false, selectedIdForDelete: "" });
  };

  padStart(d) {
    return d.toString().padStart(2, "0");
  }

  getUTCDate = (date) => {
    let d = new Date(date);
    if (d !== "-") {
      return (
        this.padStart(d.getDate()) +
        "/" +
        this.padStart(d.getMonth() + 1) +
        "/" +
        d.getFullYear()
        //  +
        // " " +
        // d.getHours() +
        // ":" +
        // d.getMinutes()
      );
    } else return d;
  };

  clearFilter = () => {
    this.setState(
      {
        nombreFiltro: "",
        apFiltro: "",
        amFiltro: "",
      },
      () => {
        this.fetchList();
      }
    );
  };

  confirmDelete = (r) => {
    let textConfirm = "";
    let titleConfirm = "";

    if (r.estatus) {
      titleConfirm = "Desactivar Cliente";
      textConfirm = "¿Realmente desea desactivar el cliente?";
    } else {
      titleConfirm = "Activar Cliente";
      textConfirm = "¿Realmente desea activar el cliente?";
    }
    this.setState({
      isDeleteDialogOpen: true,
      selectedIdForDelete: r.id,
      textConfirm: textConfirm,
      titleConfirm: titleConfirm,
    });
  };

  renderItems() {
    return this.props.customers.map((r) => {
      return (
        <TableRow key={r.id}>
          <TableCell>{r.nombreCompleto}</TableCell>
          <TableCell>{r.telefono}</TableCell>
          <TableCell>{r.email}</TableCell>
          <TableCell>{this.getUTCDate(r.inicioVigencia)}</TableCell>
          <TableCell>{this.getUTCDate(r.finVigencia)}</TableCell>
          <TableCell>{r.estatus ? "Activo" : "Inactivo"}</TableCell>
          <TableCell>
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button
                color="secondary"
                title={!r.estatus ? "Activar" : "Desactivar"}
                onClick={() => {
                  this.confirmDelete(r);
                }}
              >
                {!r.estatus ? (
                  <CheckCircleIcon></CheckCircleIcon>
                ) : (
                  <CancelIcon></CancelIcon>
                )}
              </Button>
              <Button
                color="primary"
                title="Editar"
                onClick={() => {
                  this.props.getAction("getCliente", r.id);
                  this.props.showDetailItem();
                }}
              >
                <EditIcon></EditIcon>
              </Button>
            </ButtonGroup>
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { classes } = this.props;
    const { nombreFiltro, apFiltro, amFiltro } = this.state;
    return (
      <Box mx={2} mb={4}>
        <Typography variant="h2" gutterBottom>
          Clientes
        </Typography>
        <Paper
          style={{
            marginBottom: 20,
            padding: 20,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <TextField
                size="small"
                name="nombreFiltro"
                label="Nombre"
                value={nombreFiltro}
                onChange={this.handleChange}
                style={{ marginRight: 5 }}
              />
              <TextField
                size="small"
                name="apFiltro"
                label="Apellido Paterno"
                value={apFiltro}
                onChange={this.handleChange}
                style={{ marginRight: 5 }}
              />
              <TextField
                size="small"
                name="amFiltro"
                label="Apellido Materno"
                value={amFiltro}
                onChange={this.handleChange}
                style={{ marginRight: 5 }}
              />

              <ButtonGroup
                color="primary"
                aria-label="Acciones"
                style={{ marginTop: 10, marginLeft: 10 }}
                size="small"
              >
                <Button
                  title="Filtrar"
                  color="primary"
                  onClick={() => {
                    this.fetchList();
                  }}
                >
                  <SearchIcon />
                </Button>
                <Button
                  title="Limpiar filtros"
                  color="primary"
                  onClick={this.clearFilter}
                >
                  <AutorenewIcon />
                </Button>

                <Button
                  title="Nuevo cliente"
                  color="primary"
                  onClick={this.props.showDetailItem}
                >
                  <AddIcon />
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Paper>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Telefono</TableCell>
                <TableCell>Correo Electrónico</TableCell>
                <TableCell>Vigencia Inicio</TableCell>
                <TableCell>Vigencia Fin</TableCell>
                <TableCell>Estatus</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{this.renderItems()}</TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={this.props.totalList}
            rowsPerPage={this.state.pageSize}
            page={this.state.pageNumber}
            onChangePage={(e, newPage) => {
              this.setState({ pageNumber: newPage }, () => {
                this.fetchList();
              });
            }}
            onChangeRowsPerPage={(e) => {
              this.setState({ pageSize: parseInt(e.target.value, 10) }, () => {
                this.fetchList();
              });
            }}
          />
        </TableContainer>

        <ConfirmDialog
          isDeleteDialogOpen={this.state.isDeleteDialogOpen}
          onDeleteDialogClose={this.onDeleteDialogClose}
          title={this.state.titleConfirm}
          message={this.state.textConfirm}
        ></ConfirmDialog>
      </Box>
    );
  }

  componentDidUpdate() {
    const { delCustomer, delCustomerError } = this.props;

    if (delCustomerError) {
      this.props.enqueueSnackbar(delCustomerError, {
        variant: "error",
      });
      this.props.clearAction("clearCliente");
    } else if (delCustomer) {
      this.props.clearAction("clearCliente");
      this.fetchList();
      this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
        variant: "success",
      });
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    customers: state.customerState.customers,
    totalList: state.customerState.totalList,

    delCustomer: state.customerState.delCustomer,
    delCustomerError: state.customerState.delCustomerError,
  };
};

export default connect(mapStatetoProps, {
  getAllAction,
  deleteAction,
  clearAction,
  getAction,
})(withStyles(useStyles)(withSnackbar(CustomerList)));
