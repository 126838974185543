import React from "react";
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
} from "@material-ui/core";
import {
  getAllAction,
  clearAction,
  getCatalogAction,
} from "../../actions/action-template";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginBottom: 10,
  },
});

class TerritorioFiltro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: [-99.1487162, 21.0906914],
      zoom: 5,
      clienteId:
        this.props.customerId !== ""
          ? this.props.customerId
          : this.props.preferenciaClienteId,
      campaniaId:
        this.props.campaniaId !== ""
          ? this.props.campaniaId
          : this.props.preferenciaCampaniaId > 0
          ? this.props.preferenciaCampaniaId
          : "",
      activistaId: "",
      activistas: [],
      campaniaSeleccionadaId: "",

      ambitoTerritorialId: "",
      entidadId: "",
      municipioId: "",
      distritoFederalId: "",
      distritoLocalId: "",
      seccionId: "",
    };
    this.props.getCatalogAction("getClienteCatalogo");
    this.props.getCatalogAction("getEntidadCatalogo");

    if (this.props.campaniaId) {
      this.props.getCatalogAction(
        "getCampaniaCatalogo",
        `clienteId=${this.props.customerId}&campaniaId=${this.props.campaniaId}`
      );
      let filtro = `campaniaId=${this.state.campaniaId}`;
      trackPromise(
        this.props.getAllAction("getAllUsuarioTerritorio", 0, 0, filtro)
      );
      this.props.getCatalogAction("getUsuarioTerritorioCatalogo", filtro);
    } else if (this.props.customerId) {
      this.props.getCatalogAction(
        "getCampaniaCatalogo",
        `clienteId=${this.props.customerId}`
      );
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === "clienteId") {
        this.props.getCatalogAction(
          "getCampaniaCatalogo",
          `clienteId=${value}`
        );
      }
      if (name === "campaniaId") {
        let filtro = `campaniaId=${this.state.campaniaId}`;
        //   this.props.getAllAction("getAllUsuarioTerritorio", 0, 0, filtro);
        this.props.getCatalogAction("getUsuarioTerritorioCatalogo", filtro);
      }
      if (
        name === "activistaId" ||
        name === "seccionId" ||
        name === "ambitoTerritorialId"
      ) {
        let filtro = `campaniaId=${this.state.campaniaId}&activistaId=${this.state.activistaId}&entidadId=${this.state.entidadId}&municipioId=${this.state.municipioId}&distritoFederalId=${this.state.distritoFederalId}&distritoLocalId=${this.state.distritoLocalId}&seccionId=${this.state.seccionId}`;
        this.props.getAllAction("getAllUsuarioTerritorio", 0, 0, filtro);
      }

      if (name === "entidadId") {
        this.props.getCatalogAction(
          "getMunicipioCatalogo",
          `entidadId=${this.state.segmentacion.entidadId}`
        );
      }
    });
  };

  render() {
    const { classes } = this.props;
    const {
      clienteId,
      campaniaId,
      activistaId,
      entidadId,
      municipioId,
      seccionId,
      distritoFederalId,
      distritoLocalId,
      ambitoTerritorialId,
    } = this.state;
    return (
      <Box p={2}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="overline" color="primary">
              Filtros
            </Typography>
          </Grid>
          {!this.props.customerId && (
            <Grid item xs={12}>
              <FormControl size="small" className={classes.formControl}>
                <InputLabel id="lblCliente">Cliente</InputLabel>
                <Select
                  labelId="lblCliente"
                  value={clienteId}
                  name="clienteId"
                  onChange={this.handleChange}
                >
                  {this.props.clienteCatalog.map((p) => {
                    return (
                      <MenuItem key={p.value} value={p.value}>
                        {p.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
          {!this.props.campaniaId && (
            <Grid item xs={12}>
              <FormControl size="small" className={classes.formControl}>
                <InputLabel id="lblCampania">Campaña</InputLabel>
                <Select
                  labelId="lblCampania"
                  value={campaniaId}
                  name="campaniaId"
                  onChange={this.handleChange}
                >
                  {this.props.campaniaCatalog.map((p) => {
                    return (
                      <MenuItem key={p.id} value={p.id}>
                        {p.nombre}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="lblActivista">Activista</InputLabel>
              <Select
                labelId="lblActivista"
                value={activistaId}
                name="activistaId"
                onChange={this.handleChange}
              >
                {this.state.activistas.map((p) => {
                  return (
                    <MenuItem key={p.value} value={p.value}>
                      {p.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="lblEntidad">Entidad</InputLabel>
              <Select
                disabled
                labelId="lblEntidad"
                value={entidadId}
                name="entidadId"
                onChange={this.handleChange}
              >
                {this.props.entidadCatalog.map((p) => {
                  return (
                    <MenuItem key={p.value} value={p.value}>
                      {p.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          {ambitoTerritorialId === 5 && (
            <Grid item xs={12}>
              <FormControl size="small" className={classes.formControl}>
                <InputLabel id="lblMunicipio">Municipio</InputLabel>
                <Select
                  disabled
                  labelId="lblMunicipio"
                  value={municipioId}
                  name="municipioId"
                  onChange={this.handleChange}
                >
                  {this.props.municipioCatalog.map((p) => {
                    return (
                      <MenuItem key={p.value} value={p.value}>
                        {p.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}

          {ambitoTerritorialId === 2 && (
            <Grid item xs={12}>
              <FormControl size="small" className={classes.formControl}>
                <InputLabel id="lblDFederal">Distrito Federal</InputLabel>
                <Select
                  disabled
                  labelId="lblDFederal"
                  value={distritoFederalId}
                  name="distritoFederalId"
                  onChange={this.handleChange}
                >
                  {this.props.distritoFederalCatalog.map((p) => {
                    return (
                      <MenuItem key={p.value} value={p.value}>
                        {p.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}

          {ambitoTerritorialId === 3 && (
            <Grid item xs={12}>
              <FormControl size="small" className={classes.formControl}>
                <InputLabel id="lblDLocal">Distrito Local</InputLabel>
                <Select
                  disabled
                  labelId="lblDLocal"
                  value={distritoLocalId}
                  name="distritoLocalId"
                  onChange={this.handleChange}
                >
                  {this.props.distritoLocalCatalog.map((p) => {
                    return (
                      <MenuItem key={p.value} value={p.value}>
                        {p.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}

          {/* <Grid item xs={12}>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="lblSeccion">Sección</InputLabel>
              <Select
                disabled={ambitoTerritorialId === 4}
                labelId="lblSeccion"
                value={seccionId}
                name="seccionId"
                onChange={this.handleChange}
              >
                {this.props.seccionCatalog.length > 1 && (
                  <MenuItem key={0} value={0}>
                    Todas
                  </MenuItem>
                )}
                {this.props.seccionCatalog.map((p) => {
                  return (
                    <MenuItem key={p.value} value={p.value}>
                      {p.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid> */}

          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              type="text"
              label="Sección"
              name="seccionId"
              value={seccionId}
              onChange={this.handleChange}
              inputProps={{ maxLength: 4 }}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }

  componentDidUpdate() {
    const {
      usuarioTerritorioCatalog,
      getUsuarioTerritorioCatalog,
    } = this.props;
    if (
      this.props.campaniaCatalog.length > 0 &&
      this.state.campaniaSeleccionadaId !== this.state.campaniaId
    ) {
      let campania = this.props.campaniaCatalog.find(
        (c) => parseInt(c.id) === parseInt(this.state.campaniaId)
      );
      let {
        ambitoTerritorialId,
        entidadId,
        municipioId,
        distritoFederalId,
        distritoLocalId,
        seccionId,
      } = campania;
      this.setState(
        {
          ambitoTerritorialId,
          entidadId,
          municipioId,
          distritoFederalId,
          distritoLocalId,
          seccionId,
          campaniaSeleccionadaId: this.state.campaniaId,
        },
        () => {
          if (campania.ambitoTerritorialId === 5) {
            this.props.getCatalogAction(
              "getMunicipioCatalogo",
              `entidadId=${campania.entidadId}&municipioId=${campania.municipioId}`
            );
            // this.props.getCatalogAction(
            //   "getSeccionCatalogo",
            //   `entidadId=${campania.entidadId}&municipioId=${campania.municipioId}`
            // );
          } else if (
            campania.ambitoTerritorialId === 4 ||
            campania.ambitoTerritorialId === 1
          ) {
            // this.props.getCatalogAction(
            //   "getSeccionCatalogo",
            //   `entidadId=${campania.entidadId}&municipioId=${campania.municipioId}&seccionId=${campania.seccionId}`
            // );
          } else if (campania.ambitoTerritorialId === 2) {
            this.props.getCatalogAction(
              "getDistritoFederalCatalogo",
              `entidadId=${campania.entidadId}&distritoFederalId=${campania.distritoFederalId}`
            );
            // this.props.getCatalogAction(
            //   "getSeccionCatalogo",
            //   `entidadId=${campania.entidadId}&municipioId=${campania.municipioId}&distritoFederalId=${campania.distritoFederalId}`
            // );
          } else if (campania.ambitoTerritorialId === 3) {
            this.props.getCatalogAction(
              "getDistritoLocalCatalogo",
              `entidadId=${campania.entidadId}&distritoLocalId=${campania.distritoLocalId}`
            );
            // this.props.getCatalogAction(
            //   "getSeccionCatalogo",
            //   `entidadId=${campania.entidadId}&municipioId=${campania.municipioId}&distritoLocalId=${campania.distritoLocalId}`
            // );
          }

          this.handleChange({
            target: {
              name: "ambitoTerritorialId",
              value: ambitoTerritorialId,
            },
          });
        }
      );
    }

    if (getUsuarioTerritorioCatalog) {
      this.props.clearAction("clearUsuarioTerritorio");
      let activistas = [...usuarioTerritorioCatalog];

      this.setState({ activistas: activistas });
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    entidadCatalog: state.entidadState.entidadCatalog,
    municipioCatalog: state.municipioState.municipioCatalog,
    distritoFederalCatalog: state.distritoFederalState.distritoFederalCatalog,
    distritoLocalCatalog: state.distritoLocalState.distritoLocalCatalog,
    seccionCatalog: state.seccionState.seccionCatalog,
    campaniaCatalog: state.campaniaState.campaniaCatalog,
    clienteCatalog: state.customerState.clienteCatalog,
    //campaniaAvances: state.avanceState.campaniaAvances,

    customerId: state.authState.user.customerId,
    campaniaId: state.authState.user.campaniaId,
    preferenciaClienteId: state.preferenciasGeneralState.clienteId,
    preferenciaCampaniaId: state.preferenciasGeneralState.campaniaId,

    usuarioTerritorioCatalog:
      state.usuarioTerritorioState.usuarioTerritorioCatalog,
    getUsuarioTerritorioCatalog:
      state.usuarioTerritorioState.getUsuarioTerritorioCatalog,
  };
};

export default connect(mapStatetoProps, {
  getCatalogAction,
  getAllAction,
  clearAction,
})(withStyles(useStyles)(withSnackbar(TerritorioFiltro)));
