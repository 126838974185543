import jwt_decode from "jwt-decode";

const defaultState = {
  loggedIn: false,
  loginResult: false,
  error: "",

  changePassword: false,
  changePasswordError: "",
  recoveryPassword: false,
  recoveryPasswordError: "",
  user: {
    username: "",
    userId: "",
    modulos: [],
    customerId: "",
    campaniaId: "",
  },
};

export const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: { ...action.payload },
        loginResult: true,
      };
    case "SET_LOGED_IN":
      return {
        ...state,
        loggedIn: true,
      };
    case "LOGIN_ERROR":
      return { ...state, error: action.payload.error };
    case "CLEAR_LOGIN":
      return { ...defaultState, loggedIn: state.loggedIn, user: state.user };
    case "LOGOUT":
      localStorage.clear();
      return { ...defaultState };
    case "RECOVERY_PASSWORD":
      return {
        ...state,
        // user: { ...action.payload },
        recoveryPasswordError: "",
        recoveryPassword: true,
      };
    case "RECOVERY_PASSWORD_ERROR":
      return { ...state, recoveryPasswordError: action.payload.error };
    case "CLEAR_SEND_PASSWORD":
      return {
        ...state,
        recoveryPasswordError: "",
        recoveryPassword: false,
      };
    case "CHANGE_PASSWORD":
      return {
        user: { ...action.payload },
        changePassword: true,
      };
    case "CHANGE_PASSWORD_ERROR":
      return { ...state, changePasswordError: action.payload.error };
    default:
      let token = localStorage.getItem("token");
      let validToken = false;
      let newData = {};

      if (token) {
        var decodedData = jwt_decode(token);
        let cTs = Math.floor(Date.now() / 1000);
        validToken = decodedData.exp >= cTs;

        if (!validToken) {
          localStorage.clear();
        } else {
          newData = {
            username: decodedData.nameid,
            userId: decodedData.userId,
            usuarioRolModulos: decodedData.modulos
              ? JSON.parse(decodedData.modulos)
              : [],
            customerId: decodedData.customerId,
            campaniaId: decodedData.campania,
          };
        }
      }

      return {
        ...state,
        loggedIn: validToken,
        user: newData,
      };
  }
};
