const defaultState = {
  createAgente: false,
  createAgenteError: "",
  agente: {},
  delAgente: false,
  delAgenteError: "",
  getAgenteError: "",

  agentes: [],
  agenteCatalog: [],

  totalList: 0,
};

export const agenteReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_AGENTES":
      return {
        ...state,
        agentes: action.payload.data,
        totalList: action.payload.totalRegs,
      };
    case "FETCH_AGENTE_CATALOG":
      return {
        ...state,
        agenteCatalog: action.payload,
      };

    case "CREATE_AGENTE":
      return {
        ...state,
        agente: { ...action.payload },
        createAgente: true,
      };
    case "CREATE_AGENTE_ERROR":
      return { ...state, createAgenteError: action.payload.error };
    case "UPDATE_AGENTE":
      return {
        ...state,
        agente: { ...action.payload },
        updateAgente: true,
      };
    case "UPDATE_AGENTE_ERROR":
      return { ...state, updateAgenteError: action.payload.error };
    case "CLEAR_AGENTE":
      return {
        ...state,
        agente: {},
        createAgente: false,
        createAgenteError: "",
        delAgente: false,
        delAgenteError: "",
        getAgente: false,
        getAgenteError: "",
        updateAgente: false,
        updateAgenteError: "",
      };
    case "DEL_AGENTE":
      return { ...state, delAgente: true };
    case "DEL_AGENTE_ERROR":
      return { ...state, delAgenteError: action.payload.error };
    case "GET_AGENTE":
      return { ...state, getAgente: true, agente: { ...action.payload } };
    case "GET_AGENTE_ERROR":
      return { ...state, getAgenteError: action.payload.error };
    default:
      return {
        ...state,
      };
  }
};
