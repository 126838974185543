import React from "react";
import {
  Box,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  Paper,
  ButtonGroup,
  Button,
  TablePagination,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { connect } from "react-redux";
import {
  getAllAction,
  deleteAction,
  clearAction,
  getAction,
} from "../../../actions/action-template";
import ConfirmDialog from "../../tools/confirm-dialog";
import { withSnackbar } from "notistack";
//import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
// import SearchIcon from "@material-ui/icons/Search";
// import AutorenewIcon from "@material-ui/icons/Autorenew";
import { getCatalogAction } from "../../../actions/action-template";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
});

class CampaniaList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 0,
      pageSize: 10,
      nombreFiltro: "",

      isDeleteDialogOpen: false,
      selectedIdForDelete: "",
      clienteId: this.props.clienteId,

      titleConfirm: "Eliminar Campaña",
      textConfirm: "¿Realmente desea eliminar la campaña?",
    };
    this.fetchList();
    this.props.getCatalogAction("ambitoTerritorialCatalogo");
    this.props.getCatalogAction("getEntidadCatalogo");
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  fetchList() {
    const { pageNumber, pageSize, nombreFiltro } = this.state;
    let filtro = `clienteId=${this.state.clienteId}&nombre=${nombreFiltro}`;
    trackPromise(
      this.props.getAllAction("getAllcampanias", pageNumber, pageSize, filtro)
    );
  }

  onDeleteDialogClose = (res) => {
    if (res) {
      let o = this.props.campanias.find(
        (e) => e.id === this.state.selectedIdForDelete
      );
      this.props.deleteAction(
        "deleteCampania",
        this.state.selectedIdForDelete,
        !o.estatus
      );
    }
    this.setState({ isDeleteDialogOpen: false, selectedIdForDelete: "" });
  };

  padStart(d) {
    return d.toString().padStart(2, "0");
  }

  getUTCDate = (date) => {
    let d = new Date(date);
    if (d !== "-") {
      return (
        this.padStart(d.getDate()) +
        "/" +
        this.padStart(d.getMonth() + 1) +
        "/" +
        d.getFullYear()
      );
    } else return d;
  };

  clearFilter = () => {
    this.setState(
      {
        nombreFiltro: "",
      },
      () => {
        this.fetchList();
      }
    );
  };

  confirmDelete = (r) => {
    let textConfirm = "";
    let titleConfirm = "";

    titleConfirm = "Eliminar Campaña";
    textConfirm = "¿Realmente desea desactivar la campaña?";

    this.setState({
      isDeleteDialogOpen: true,
      selectedIdForDelete: r.id,
      textConfirm: textConfirm,
      titleConfirm: titleConfirm,
    });
  };

  renderItems() {
    return this.props.campanias.map((r) => {
      return (
        <TableRow key={r.id}>
          <TableCell>{r.nombre}</TableCell>
          <TableCell>{this.getUTCDate(r.inicioVigencia)}</TableCell>
          <TableCell>{this.getUTCDate(r.finVigencia)}</TableCell>
          <TableCell>{r.ambitoTerritorial}</TableCell>

          <TableCell>
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button
                color="primary"
                title="Metas"
                onClick={() => {
                  this.props.showDetailItem(3, r);
                }}
              >
                <PlaylistAddCheckIcon></PlaylistAddCheckIcon>
              </Button>
              <Button
                color="secondary"
                title={"Eliminar"}
                onClick={() => {
                  this.confirmDelete(r);
                }}
              >
                <CancelIcon></CancelIcon>
              </Button>
              <Button
                color="primary"
                title="Editar"
                onClick={() => {
                  this.props.getAction("getCampania", r.id);
                  this.props.showDetailItem(2);
                }}
              >
                <EditIcon></EditIcon>
              </Button>
            </ButtonGroup>
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { classes } = this.props;
    // const { nombreFiltro, apFiltro, amFiltro } = this.state;
    return (
      <Box mb={4} align="right">
        <Box mb={2}>
          <Button
            title="Nueva campaña"
            color="primary"
            onClick={() => this.props.showDetailItem(2)}
          >
            <AddIcon /> Nueva campaña
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Vigencia Inicio</TableCell>
                <TableCell>Vigencia Fin</TableCell>
                <TableCell>Ambito Territorial</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{this.renderItems()}</TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={this.props.totalList}
            rowsPerPage={this.state.pageSize}
            page={this.state.pageNumber}
            onChangePage={(e, newPage) => {
              this.setState({ pageNumber: newPage }, () => {
                this.fetchList();
              });
            }}
            onChangeRowsPerPage={(e) => {
              this.setState({ pageSize: parseInt(e.target.value, 10) }, () => {
                this.fetchList();
              });
            }}
          />
        </TableContainer>

        <ConfirmDialog
          isDeleteDialogOpen={this.state.isDeleteDialogOpen}
          onDeleteDialogClose={this.onDeleteDialogClose}
          title={this.state.titleConfirm}
          message={this.state.textConfirm}
        ></ConfirmDialog>
      </Box>
    );
  }

  componentDidUpdate() {
    const { delCampania, delCampaniaError } = this.props;

    if (delCampaniaError) {
      this.props.enqueueSnackbar(delCampaniaError, {
        variant: "error",
      });
      this.props.clearAction("clearCampania");
    } else if (delCampania) {
      this.props.clearAction("clearCampania");
      this.fetchList();
      this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
        variant: "success",
      });
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    campanias: state.campaniaState.campanias,
    totalList: state.campaniaState.totalList,

    delCampania: state.campaniaState.delCampania,
    delCampaniaError: state.campaniaState.delCampaniaError,

    userId: state.authState.user.userId,
  };
};

export default connect(mapStatetoProps, {
  getAllAction,
  deleteAction,
  clearAction,
  getAction,
  getCatalogAction,
})(withStyles(useStyles)(withSnackbar(CampaniaList)));
