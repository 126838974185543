import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { clearAction, uploadAction } from "../../actions/action-template";
import { connect } from "react-redux";

class FileUploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSelected: false,
      selectedFile: "",
    };
  }

  changeHandler = (event) => {
    this.setState({
      isSelected: true,
      selectedFile: event.target.files[0],
    });
  };

  handleSubmission = () => {
    const formData = new FormData();

    formData.append("File", this.state.selectedFile);
    this.props.uploadAction(this.props.uploadActionAPI, formData);
  };

  render() {
    const { isSelected } = this.state;
    return (
      <Box p={1} align="left">
        <form encType="multipart/form-data">
          {!isSelected && (
            <Box>
              <input
                type="file"
                name="file"
                onChange={this.changeHandler}
                accept={this.props.accept}
                // multiple={false}
              />
            </Box>
          )}

          {isSelected ? (
            <div>
              <p>Archivo: {this.state.selectedFile?.name}</p>
            </div>
          ) : (
            <Box m={1}>
              <Typography variant="caption" color="primary" display="block">
                {this.props.instrucciones}
              </Typography>
            </Box>
          )}
          <div>
            {isSelected && (
              <Button
                color="secondary"
                onClick={() => {
                  this.setState({
                    selectedFile: "",
                    isSelected: false,
                  });
                }}
              >
                Cambiar
              </Button>
            )}
            {isSelected && (
              <Button onClick={this.handleSubmission} color="primary">
                Enviar
              </Button>
            )}
          </div>
        </form>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    //   username: state.authState.user.username,
    //   userId: state.authState.user.userId,
    //   loggedIn: state.authState.loggedIn,
  };
};

export default connect(mapStatetoProps, {
  clearAction,
  uploadAction,
})(FileUploader);
