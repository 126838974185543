const defaultState = {
  distritoFederal: {},
  createDistritoFederal: false,
  createDistritoFederalError: "",
  delDistritoFederal: false,
  delDistritoFederalError: "",
  getDistritoFederal: false,
  getDistritoFederalError: "",
  updateDistritoFederal: false,
  updateDistritoFederalError: "",
  distritosFederales: [],
  distritoFederalCatalog: [],

  totalList: 0,
};

export const distritoFederalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_DISTRITOS_FEDERALES":
      return {
        ...state,
        distritosFederales: action.payload.data,
        totalList: action.payload.totalRegs,
      };
    case "FETCH_DISTRITO_FEDERAL_CATALOG":
      return {
        ...state,
        distritoFederalCatalog: action.payload,
      };
    case "CREATE_DISTRITO_FEDERAL":
      return {
        ...state,
        distritoFederal: { ...action.payload },
        createDistritoFederal: true,
      };
    case "CREATE_DISTRITO_FEDERAL_ERROR":
      return { ...state, createDistritoFederalError: action.payload.error };
    case "UPDATE_DISTRITO_FEDERAL":
      return {
        ...state,
        distritoFederal: { ...action.payload },
        updateDistritoFederal: true,
      };
    case "UPDATE_DISTRITO_FEDERAL_ERROR":
      return { ...state, updateDistritoFederalError: action.payload.error };
    case "CLEAR_DISTRITO_FEDERAL":
      return {
        ...state,
        distritoFederal: {},
        createDistritoFederal: false,
        createDistritoFederalError: "",
        delDistritoFederal: false,
        delDistritoFederalError: "",
        getDistritoFederal: false,
        getDistritoFederalError: "",
        updateDistritoFederal: false,
        updateDistritoFederalError: "",
      };
    case "DEL_DISTRITO_FEDERAL":
      return { ...state, delDistritoFederal: true };
    case "DEL_DISTRITO_FEDERAL_ERROR":
      return { ...state, delDistritoFederalError: action.payload.error };
    case "GET_DISTRITO_FEDERAL":
      return {
        ...state,
        getDistritoFederal: true,
        distritoFederal: { ...action.payload },
      };
    case "GET_DISTRITO_FEDERAL_ERROR":
      return { ...state, getDistritoFederalError: action.payload.error };
    default:
      return {
        ...state,
      };
  }
};
