const defaultState = {
  misEncuestas: [],
  misRespuestas: [],
  totalList: 0,
  createMiEncuesta: false,
  createMiEncuestaError: "",
  delMiEncuesta: false,
  delMiEncuestaError: "",
  updateMiEncuesta: false,
  updateMiEncuestaError: "",
};

export const miEncuestaReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_MIS_ENCUESTAS":
      return {
        ...state,
        misEncuestas: action.payload.data.encuestas.data,
        misRespuestas: action.payload.data.respuestas,
        totalList: action.payload.data.encuestas.totalRegs,
      };

    case "CREATE_MI_ENCUESTA":
      return {
        ...state,
        encuesta: { ...action.payload },
        createMiEncuesta: true,
      };
    case "CREATE_MI_ENCUESTA_ERROR":
      return { ...state, createMiEncuestaError: action.payload.error };

    case "CLEAR_MI_ENCUESTA":
      return {
        ...state,
        encuesta: {},
        createMiEncuesta: false,
        createMiEncuestaError: "",

        misEncuestas: [],
        misRespuestas: [],
        totalList: 0,
      };

    default:
      return {
        ...state,
      };
  }
};
