import {
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Box,
  AppBar,
  Toolbar,
  Dialog,
  Typography,
  IconButton,
} from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { withSnackbar } from "notistack";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import emailValidator from "../../validators/email-validator";
import {
  login,
  clearLogin,
  setLogedin,
} from "../../../actions/auth/auth-actions";
import { connect } from "react-redux";
import Transition from "../../tools/transition";
import { trackPromise } from "react-promise-tracker";

const useStyles = {
  root: {
    minWidth: 400,
    maxWidth: 400,
  },
  content: {},
  main: {
    minHeight: "85vh",
  },
  spacingTop: {
    paddingTop: 30,
  },

  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      submitted: false,
      isOpenDialog: true,
    };
  }

  showError = (value) => {
    return this.state.submitted && !value ? true : false;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    const { username, password } = this.state;
    if (username && password && emailValidator(username)) {
      trackPromise(this.props.login(username, password)).then(() => {
        this.setState({ submitted: false });
      });
    } else {
      this.props.enqueueSnackbar("Debe ingresar los campos marcados con *", {
        variant: "warning",
      });
    }
  };

  componentDidUpdate() {
    const { error, loggedIn, loginResult } = this.props;

    if (error) {
      this.props.enqueueSnackbar(error, {
        variant: "warning",
      });
      this.props.clearLogin();
    }
    if (loggedIn) {
      this.props.history.push("/");
    }
    if (loginResult) {
      this.props.enqueueSnackbar("Inicio de sesión exitoso, bienvenido!", {
        variant: "success",
      });
      this.props.setLogedin();
      this.props.history.push("/");
    }
  }

  renderContent() {
    const { username, password } = this.state;

    return (
      <Box>
        <TextField
          error={
            this.showError(this.state.username) &&
            !emailValidator(this.state.username)
          }
          margin="normal"
          required
          fullWidth
          label="Email"
          name="username"
          autoComplete="email"
          autoFocus
          helperText={
            !emailValidator(this.state.username) && this.state.submitted
              ? "Ingrese un correo electrónico válido"
              : "Ingrese su correo electrónico"
          }
          value={username}
          onChange={this.handleChange}
        />
        <TextField
          error={this.showError(this.state.password)}
          margin="normal"
          required
          fullWidth
          label="Constraseña"
          type="password"
          autoComplete="current-password"
          name="password"
          value={password}
          onChange={this.handleChange}
        />
        {isBrowser && (
          <Box align="center" mt={3}>
            <Button
              disabled={this.state.submitted}
              type="Button"
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Ingresar
            </Button>
          </Box>
        )}
        {isBrowser && (
          <Box align="left" mt={2}>
            <Button component={Link} to="/recupera" color="primary">
              ¿Olvidó su contraseña?
            </Button>
          </Box>
        )}
      </Box>
    );
  }

  renderBrowser() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <CardHeader title="Iniciar sesión"></CardHeader>
        <CardContent className={classes.content}>
          {this.renderContent()}
        </CardContent>
      </Card>
    );
  }

  renderMobile() {
    const { classes } = this.props;
    return (
      <Dialog
        fullScreen
        open={this.state.isOpenDialog}
        onClose={() => this.setState({ isOpenDialog: false })}
        TransitionComponent={Transition}
        scroll="body"
        style={{ paddingTop: 40 }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Iniciar sesión
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justify="center"
          className={classes.main}
        >
          <Grid item>
            <Box p={4}>
              {this.renderContent()}
              <div style={{ marginBottom: 100 }}></div>
            </Box>
          </Grid>
        </Grid>

        <AppBar
          position="fixed"
          color="primary"
          className={classes.appBottomBar}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              component={Link}
              to="/recupera"
              aria-label="close"
            >
              <Typography variant="button" display="block" gutterBottom>
                ¿Olvidó su contraseña?
              </Typography>
            </IconButton>
            <div className={classes.grow} />

            <IconButton
              disabled={this.state.submitted}
              edge="end"
              color="inherit"
              onClick={this.handleSubmit}
              aria-label="close"
            >
              <Typography variant="button" display="block" gutterBottom>
                Ingresar
              </Typography>
            </IconButton>
          </Toolbar>
        </AppBar>
      </Dialog>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justify="center"
        className={classes.main}
      >
        <Grid item>
          <BrowserView>{this.renderBrowser()}</BrowserView>
          <MobileView>{this.renderMobile()}</MobileView>
        </Grid>
      </Grid>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    error: state.authState.error,
    loggedIn: state.authState.loggedIn,
    loginResult: state.authState.loginResult,
  };
};

export default connect(mapStatetoProps, {
  login,
  clearLogin,
  setLogedin,
})(withStyles(useStyles)(withSnackbar(Login)));
