const defaultState = {
  clienteId: "",
  campaniaId: 0,
};

export const preferenciasGeneralReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "ESTABLECER_CLIENTE":
      return {
        ...state,
        clienteId: action.payload,
      };
    case "ESTABLECER_CAMPANIA":
      return { ...state, campaniaId: action.payload };
    default:
      return {
        ...state,
      };
  }
};
