const defaultState = {
  createAmbitoTerritorial: false,
  createAmbitoTerritorialError: "",
  ambitoTerritorial: {},
  delAmbitoTerritorial: false,
  delAmbitoTerritorialError: "",
  getAmbitoTerritorialError: "",

  ambitosTerritoriales: [],
  ambitoTerritorialCatalog: [],

  totalList: 0,
};

export const ambitoTerritorialReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_AMBITOS_TERRITORIALES":
      return {
        ...state,
        ambitosTerritoriales: action.payload.data,
        totalList: action.payload.totalRegs,
      };
    case "FETCH_AMBITO_TERRITORIAL_CATALOG":
      return {
        ...state,
        ambitoTerritorialCatalog: action.payload,
      };

    case "CREATE_AMBITO_TERRITORIAL":
      return {
        ...state,
        ambitoTerritorial: { ...action.payload },
        createAmbitoTerritorial: true,
      };
    case "CREATE_AMBITO_TERRITORIAL_ERROR":
      return { ...state, createAmbitoTerritorialError: action.payload.error };
    case "UPDATE_AMBITO_TERRITORIAL":
      return {
        ...state,
        ambitoTerritorial: { ...action.payload },
        updateAmbitoTerritorial: true,
      };
    case "UPDATE_AMBITO_TERRITORIAL_ERROR":
      return { ...state, updateAmbitoTerritorialError: action.payload.error };
    case "CLEAR_AMBITO_TERRITORIAL":
      return {
        ...state,
        ambitoTerritorial: {},
        createAmbitoTerritorial: false,
        createAmbitoTerritorialError: "",
        delAmbitoTerritorial: false,
        delAmbitoTerritorialError: "",
        getAmbitoTerritorial: false,
        getAmbitoTerritorialError: "",
        updateAmbitoTerritorial: false,
        updateAmbitoTerritorialError: "",
      };
    case "DEL_AMBITO_TERRITORIAL":
      return { ...state, delAmbitoTerritorial: true };
    case "DEL_AMBITO_TERRITORIAL_ERROR":
      return { ...state, delAmbitoTerritorialError: action.payload.error };
    case "GET_AMBITO_TERRITORIAL":
      return {
        ...state,
        getAmbitoTerritorial: true,
        ambitoTerritorial: { ...action.payload },
      };
    case "GET_AMBITO_TERRITORIAL_ERROR":
      return { ...state, getAmbitoTerritorialError: action.payload.error };
    default:
      return {
        ...state,
      };
  }
};
