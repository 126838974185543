import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Dialog,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
  Tabs,
  Tab,
} from "@material-ui/core";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import Transition from "../../tools/transition";
import emailValidator from "../../validators/email-validator";

import { connect } from "react-redux";
import TabPanel from "../../tools/tab-panel";
import Campanias from "../campanias/campanias";
import Usuarios from "../../admin/usuarios/usuarios";
import {
  createAction,
  clearAction,
  updateAction,
} from "../../../actions/action-template";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
  formButton: {
    marginRight: "10px",
  },
  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
});

class CustomerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      isOpenDialog: true,
      tabValue: 0,
      nombres: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      email: "",
      telefono: "",
      inicioVigencia: "",
      finVigencia: "",
      id: "",
    };
  }

  showError = (value) => {
    return this.state.submitted && !value ? true : false;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  padStart(d) {
    return d.toString().padStart(2, "0");
  }

  getFormatDate = (date) => {
    let d = new Date(date);
    if (d !== "-") {
      return (
        d.getFullYear() +
        "-" +
        this.padStart(d.getMonth() + 1) +
        "-" +
        this.padStart(d.getDate())
      );
    } else return d;
  };

  handleSubmit = (e) => {
    const {
      nombres,
      apellidoPaterno,
      apellidoMaterno,
      email,
      telefono,
      inicioVigencia,
      finVigencia,
    } = this.state;
    if (
      nombres &&
      apellidoPaterno &&
      apellidoMaterno &&
      email &&
      emailValidator(email) &&
      telefono &&
      inicioVigencia &&
      finVigencia
    ) {
      if (+new Date(inicioVigencia) > +new Date(finVigencia)) {
        this.props.enqueueSnackbar(
          "El incio de la vigencia debe ser menor al fin de vigencia",
          {
            variant: "warning",
          }
        );
        return;
      }
      this.setState({ submitted: true });

      if (!this.state.id) {
        trackPromise(
          this.props.createAction("postCliente", {
            nombres,
            apellidoPaterno,
            apellidoMaterno,
            email,
            telefono,
            inicioVigencia,
            finVigencia,
          }),
          () => {
            this.setState({ submitted: false });
          }
        );
      } else {
        trackPromise(
          this.props.updateAction("updateCliente", this.state.id, {
            id: this.state.id,
            nombres,
            apellidoPaterno,
            apellidoMaterno,
            email,
            telefono,
            inicioVigencia,
            finVigencia,
            estatus: this.state.estatus,
          }),
          () => {
            this.setState({ submitted: false });
          }
        );
      }
    } else {
      this.props.enqueueSnackbar("Debe ingresar los campos marcados con *", {
        variant: "warning",
      });
    }
  };

  componentDidUpdate() {
    const {
      createCustomer,
      createCustomerError,
      customer,
      getCustomer,
      getCustomerError,
      updateCustomerError,
      updateCustomer,
    } = this.props;

    if (createCustomerError) {
      this.props.enqueueSnackbar(createCustomerError, {
        variant: "error",
      });
      this.props.clearAction("clearCliente");
      this.setState({ submitted: true });
    } else if (createCustomer) {
      this.props.clearAction("clearCliente");

      this.setState(
        {
          isOpenDialog: false,
        },
        () => {
          this.props.resultOp(customer);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (updateCustomerError) {
      this.props.enqueueSnackbar(updateCustomerError, {
        variant: "error",
      });
      this.props.clearAction("clearCliente");
      this.setState({ submitted: true });
    } else if (updateCustomer) {
      this.props.clearAction("clearCliente");

      this.setState(
        {
          isOpenDialog: false,
        },
        () => {
          this.props.resultOp(customer);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (getCustomerError) {
      this.props.enqueueSnackbar(getCustomerError, {
        variant: "error",
      });
      this.props.clearAction("clearCliente");
    } else if (getCustomer) {
      this.props.clearAction("clearCliente");
      this.setState({
        id: customer.id,
        nombres: customer.nombres,
        apellidoPaterno: customer.apellidoPaterno,
        apellidoMaterno: customer.apellidoMaterno,
        email: customer.email,
        telefono: customer.telefono,
        inicioVigencia: this.getFormatDate(customer.inicioVigencia),
        finVigencia: this.getFormatDate(customer.finVigencia),
        estatus: customer.estatus,
      });
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  renderDatos() {
    const {
      nombres,
      apellidoPaterno,
      apellidoMaterno,
      email,
      telefono,
      inicioVigencia,
      finVigencia,
    } = this.state;
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(nombres)}
            required
            label="Nombre(s)"
            name="nombres"
            autoFocus
            value={nombres}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(apellidoPaterno)}
            required
            label="Apellido Paterno"
            name="apellidoPaterno"
            value={apellidoPaterno}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(apellidoMaterno)}
            required
            label="Apellido Materno"
            name="apellidoMaterno"
            value={apellidoMaterno}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={!emailValidator(email) && this.state.submitted}
            required
            label="Correo Electrónico"
            name="email"
            value={email}
            onChange={this.handleChange}
            helperText={
              !emailValidator(email) && this.state.submitted
                ? "Ingrese un correo electrónico válido"
                : ""
            }
            inputProps={{ maxLength: 128 }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(telefono)}
            required
            label="Teléfono"
            name="telefono"
            value={telefono}
            onChange={this.handleChange}
            inputProps={{ maxLength: 64 }}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(inicioVigencia)}
            required
            type="date"
            label="Vigencia Inicio"
            name="inicioVigencia"
            value={inicioVigencia}
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            className={classes.formControl}
            error={this.showError(finVigencia)}
            required
            type="date"
            label="Vigencia Fin"
            name="finVigencia"
            value={finVigencia}
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {isBrowser && (
            <Box align="right" pt={4}>
              <Button
                className={classes.formButton}
                variant="contained"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
              >
                cancelar
              </Button>

              <Button
                disabled={this.state.submitted}
                className={classes.formButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.handleSubmit();
                }}
              >
                Guardar
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    );
  }

  renderContent() {
    const { tabValue } = this.state;

    return (
      <Box mt={2}>
        <Tabs value={tabValue} onChange={this.handleTabChange}>
          <Tab label="Datos" id={0} />
          {this.state.id && <Tab label="Campañas" id={1} />}
          {this.state.id && <Tab label="Usuarios" id={2} />}
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          {this.renderDatos()}
        </TabPanel>
        {this.state.id && (
          <TabPanel value={tabValue} index={1}>
            <Campanias clienteId={this.state.id}></Campanias>
          </TabPanel>
        )}
        {this.state.id && (
          <TabPanel value={tabValue} index={2}>
            <Usuarios clienteId={this.state.id}></Usuarios>
          </TabPanel>
        )}
      </Box>
    );
  }

  renderBrowser() {
    return (
      <Box p={2}>
        <Typography variant="h4">Detalle Cliente</Typography>
        {this.renderContent()}
      </Box>
    );
  }

  renderMobile() {
    const { classes } = this.props;
    return (
      <Box>
        <Dialog
          fullScreen
          open={this.state.isOpenDialog}
          onClose={() => this.setState({ isOpenDialog: false })}
          TransitionComponent={Transition}
          scroll="body"
          style={{ paddingTop: 40 }}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Detalle Cliente
              </Typography>
            </Toolbar>
          </AppBar>
          <Box py={3} px={1}>
            {this.renderContent()}
            <div style={{ marginBottom: 100 }}></div>
          </Box>
          <AppBar
            position="fixed"
            color="primary"
            className={classes.appBottomBar}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  cancelar
                </Typography>
              </IconButton>
              <div className={classes.grow} />

              <IconButton
                disabled={this.state.submitted}
                edge="end"
                color="inherit"
                onClick={() => {
                  this.handleSubmit();
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  Guardar
                </Typography>
              </IconButton>
            </Toolbar>
          </AppBar>
        </Dialog>
      </Box>
    );
  }

  render() {
    return (
      <Box>
        <BrowserView>{this.renderBrowser()}</BrowserView>
        <MobileView>{this.renderMobile()}</MobileView>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    createCustomer: state.customerState.createCustomer,
    createCustomerError: state.customerState.createCustomerError,
    customer: state.customerState.customer,
    getCustomer: state.customerState.getCustomer,
    getCustomerError: state.customerState.getCustomerError,

    updateCustomer: state.customerState.updateCustomer,
    updateCustomerError: state.customerState.updateCustomerError,
  };
};

export default connect(mapStatetoProps, {
  createAction,
  clearAction,
  updateAction,
})(withStyles(useStyles)(withSnackbar(CustomerDetail)));
