import React from "react";

import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { Box, Grid, Tabs, Tab } from "@material-ui/core";
import TabPanel from "./tools/tab-panel";
import MisEncuestas from "./mis-encuestas/mis-encuestas-list";
import MisNoticias from "./mis-noticias/mis-noticias";
import modulos from "./tools/modulos";

const useStyles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
});

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenDialog: true,
      tabValue: 0,
    };
  }
  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };
  renderContent() {
    const { tabValue } = this.state;

    return (
      <Box>
        <Tabs value={tabValue} onChange={this.handleTabChange} centered>
          <Tab label="Noticias" id={0} />
          <Tab label="Encuestas" id={1} />
          <Tab label="Notificaciones" id={2} />
          {this.hasAccess(modulos.PublicacionesReportadas) && (
            <Tab label="Noticias Reportadas" id={3} />
          )}
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <MisNoticias
            notificaciones={false}
            publicacionesReportadas={false}
          ></MisNoticias>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <MisEncuestas></MisEncuestas>
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <MisNoticias
            notificaciones={true}
            publicacionesReportadas={false}
          ></MisNoticias>
        </TabPanel>
        {this.hasAccess(modulos.PublicacionesReportadas) && (
          <TabPanel value={tabValue} index={3}>
            <MisNoticias
              notificaciones={false}
              publicacionesReportadas={true}
            ></MisNoticias>
          </TabPanel>
        )}
      </Box>
    );
  }
  hasAccess(moduloId) {
    let tieneAccceso =
      this.props.usuarioRolModulos?.find(
        (m) => m.ModuloId === moduloId && m.TieneAcceso === true
      ) != null;
    return tieneAccceso;
  }

  render() {
    if (this.props.loggedIn)
      return (
        <Grid container>
          <Grid item xs={12}>
            {this.renderContent()}
          </Grid>
        </Grid>
      );
    else return <div></div>;
  }
}

const mapStatetoProps = (state) => {
  return {
    username: state.authState.user.username,
    loggedIn: state.authState.loggedIn,
    usuarioRolModulos: state.authState.user.usuarioRolModulos,
  };
};

export default connect(mapStatetoProps, {})(withStyles(useStyles)(Home));
