const defaultState = {
  campaniaMeta: {},
  updateCampaniaMeta: false,
  updateCampaniaMetaError: "",

  createCampaniaMeta: false,

  fechCampanias: false,
  campaniaMetas: [],
  totalList: 0,
};

export const metasReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_CAMPANIA_METAS":
      return {
        ...state,
        campaniaMetas: action.payload.data,
        fechCampanias: true,
        totalList: action.payload.totalRegs,
      };

    case "CREATE_CAMPANIA_META":
      return {
        ...state,
        createCampaniaMeta: true,
      };
    case "CREATE_CAMPANIA_META_ERROR":
      return { ...state, createCampaniaMetaError: action.payload.error };

    case "UPDATE_CAMPANIA_META":
      return {
        ...state,
        campaniaMeta: { ...action.payload },
        updateCampaniaMeta: true,
      };
    case "UPDATE_CAMPANIA_META_ERROR":
      return { ...state, updateCampaniaMetaError: action.payload.error };
    case "CLEAR_CAMPANIA_META":
      return {
        ...state,
        campaniaMeta: {},
        fechCampanias: false,
        updateCampaniaMeta: false,
        updateCampaniaMetaError: "",
        createCampaniaMeta: false,
        createCampaniaMetaError: "",
        campaniaMetas: [],
      };

    default:
      return {
        ...state,
      };
  }
};
