const AmbitosTerritoriales = {
  Entidad: 1,
  DistritoFederal: 2,
  DistritoLocal: 3,
  Seccion: 4,
  Municipio: 5,
  Colonia: 6,
};

export default AmbitosTerritoriales;
