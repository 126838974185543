const defaultState = {
  createAutoRegistro: false,
  createAutoRegistroError: "",
  autoRegistro: {},
};

export const autoRegistroReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "CREATE_AUTO_REGISTRO":
      return {
        ...state,
        autoRegistro: { ...action.payload },
        createAutoRegistro: true,
      };
    case "CREATE_AUTO_REGISTRO_ERROR":
      return { ...state, createAutoRegistroError: action.payload.error };

    case "CLEAR_AUTO_REGISTRO":
      return {
        ...state,
        createAutoRegistro: false,
        createAutoRegistroError: "",
      };
    default:
      return {
        ...state,
      };
  }
};
