const defaultState = {
  encuestaUsuario: {},
  encuestaUsuarios: [],
  totalList: 0,
  createEncuestaUsuario: false,
  createEncuestaUsuarioError: "",
  delEncuestaUsuario: false,
  delEncuestaUsuarioError: "",
  getEncuestaUsuario: false,
  getEncuestaUsuarioError: "",
  updateEncuestaUsuario: false,
  updateEncuestaUsuarioError: "",
};

export const encuestaUsuarioReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_ENCUESTA_USUARIOS":
      return {
        ...state,
        encuestaUsuarios: action.payload.data,
        totalList: action.payload.totalRegs,
      };

    case "CREATE_ENCUESTA_USUARIO":
      return {
        ...state,
        encuestaUsuario: { ...action.payload },
        createEncuestaUsuario: true,
      };
    case "CREATE_ENCUESTA_USUARIO_ERROR":
      return { ...state, createEncuestaUsuarioError: action.payload.error };
    case "UPDATE_ENCUESTA_USUARIO":
      return {
        ...state,
        encuestaUsuario: { ...action.payload },
        updateEncuestaUsuario: true,
      };
    case "UPDATE_ENCUESTA_USUARIO_ERROR":
      return { ...state, updateEncuestaUsuarioError: action.payload.error };
    case "CLEAR_ENCUESTA_USUARIO":
      return {
        ...state,
        encuestaUsuario: {},
        createEncuestaUsuario: false,
        createEncuestaUsuarioError: "",
        delEncuestaUsuario: false,
        delEncuestaUsuarioError: "",
        getEncuestaUsuario: false,
        getEncuestaUsuarioError: "",
        updateEncuestaUsuario: false,
        updateEncuestaUsuarioError: "",
      };
    case "DEL_ENCUESTA_USUARIO":
      return { ...state, delEncuestaUsuario: true };
    case "DEL_ENCUESTA_USUARIO_ERROR":
      return { ...state, delEncuestaUsuarioError: action.payload.error };
    case "GET_ENCUESTA_USUARIO":
      return {
        ...state,
        getEncuestaUsuario: true,
        encuestaUsuario: { ...action.payload },
      };
    case "GET_ENCUESTA_USUARIO_ERROR":
      return { ...state, getEncuestaUsuarioError: action.payload.error };
    default:
      return {
        ...state,
      };
  }
};
