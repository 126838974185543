const defaultState = {
  usuarioTerritorio: {},
  getUsuarioTerritorio: false,
  getUsuarioTerritorioError: "",
  fetchUsuariosTerritorio: false,
  usuariosTerritorio: [],
  usuarioTerritorioCatalog: [],
  getUsuarioTerritorioCatalog: false,
  totalList: 0,
};

export const usuarioTerritorioReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_USUARIOS_TERRITORIO":
      return {
        ...state,
        usuariosTerritorio: action.payload.data,
        fetchUsuariosTerritorio: true,
        totalList: action.payload.totalRegs,
      };
    case "USUARIO_TERRITORIO_CATALOG":
      return {
        ...state,
        usuarioTerritorioCatalog: action.payload,
        getUsuarioTerritorioCatalog: true,
      };
    case "CLEAR_USUARIO_TERRITORIO":
      return {
        ...state,
        usuarioTerritorio: {},
        getUsuarioTerritorio: false,
        getUsuarioTerritorioError: "",
        fetchUsuariosTerritorio: false,
        usuarioTerritorioCatalog: [],
        getUsuarioTerritorioCatalog: false,
      };
    case "GET_USUARIO_TERRITORIO":
      return {
        ...state,
        getUsuarioTerritorio: true,
        usuarioTerritorio: { ...action.payload },
      };
    case "GET_USUARIO_TERRITORIO_ERROR":
      return { ...state, getUsuarioTerritorioError: action.payload.error };
    default:
      return {
        ...state,
      };
  }
};
