import React from "react";
import {
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  FormLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getCatalogAction } from "../../actions/action-template";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
});

class SegmentacionFiltro extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clienteId: this.props.customerId ?? "",
      campaniaId: this.props.campaniaId ?? 0,
      entidadId: 0,
      municipioId: 0,
      coloniaId: 0,
      seccionId: 0,
      esHombres: false,
      esMujeres: false,
      edadInicial: 0,
      edadFinal: 0,

      esFiltro: false,
    };

    this.props.getCatalogAction("getEntidadCatalogo");
  }

  actualizaFiltro() {
    const {
      entidadId,
      municipioId,
      //   coloniaId,
      seccionId,
      esHombres,
      esMujeres,
      edadInicial,
      edadFinal,
    } = this.state;
    this.props.actualizaSegmentacion({
      entidadId,
      municipioId,
      //   coloniaId,
      seccionId,
      esHombres,
      esMujeres,
      edadInicial,
      edadFinal,
    });
  }

  handleChangeNumber = (e) => {
    const { name, value } = e.target;
    let number = parseInt(value);
    number = Number.isNaN(number) ? "" : value;
    this.setState({ [name]: number }, () => {
      this.actualizaFiltro();
    });
  };

  handleChangeSegmentacion = (e) => {
    const { name, value, checked } = e.target;

    if (name === "esHombres" || name === "esMujeres")
      this.setState({ [name]: checked });
    else
      this.setState({ [name]: value }, () => {
        if (name === "entidadId") {
          this.props.getCatalogAction(
            "getMunicipioCatalogo",
            `entidadId=${this.state.segmentacion.entidadId}`
          );
        }
        this.actualizaFiltro();
      });
  };

  componentDidUpdate() {
    if (this.props.segmentacion && !this.state.sOriginal) {
      this.props.getCatalogAction(
        "getMunicipioCatalogo",
        `entidadId=${this.props.segmentacion.entidadId}&municipioId=${this.props.segmentacion.municipioId}`
      );

      //   } else if (campania.ambitoTerritorialId === 4) {
      //     this.props.getCatalogAction(
      //       "getSeccionCatalogo",
      //       `entidadId=${campania.entidadId}&municipioId=${campania.municipioId}&seccionId=${campania.seccionId}`
      //     );
      //   } else if (campania.ambitoTerritorialId === 2) {
      //     this.props.getCatalogAction(
      //       "getDistritoFederalCatalogo",
      //       `entidadId=${campania.entidadId}&distritoFederalId=${campania.distritoFederalId}`
      //     );
      //     this.props.getCatalogAction(
      //       "getSeccionCatalogo",
      //       `entidadId=${campania.entidadId}&municipioId=${campania.municipioId}&distritoFederalId=${campania.distritoFederalId}`
      //     );
      //   } else if (campania.ambitoTerritorialId === 3) {
      //     this.props.getCatalogAction(
      //       "getDistritoLocalCatalogo",
      //       `entidadId=${campania.entidadId}&distritoLocalId=${campania.distritoLocalId}`
      //     );
      //     this.props.getCatalogAction(
      //       "getSeccionCatalogo",
      //       `entidadId=${campania.entidadId}&municipioId=${campania.municipioId}&distritoLocalId=${campania.distritoLocalId}`
      //     );
      //   }
      this.setState({
        entidadId: this.props.segmentacion.entidadId,
        municipioId: this.props.segmentacion.municipioId,
        seccionId: this.props.segmentacion.seccionId,
        coloniaId: this.props.segmentacion.coloniaId,
        esHombres: this.props.segmentacion.esHombres,
        esMujeres: this.props.segmentacion.esMujeres,
        edadInicial: this.props.segmentacion.edadInicial,
        edadFinal: this.props.segmentacion.edadFinal,
        sOriginal: { ...this.props.segmentacion },
      });
    }
  }

  render() {
    const {
      entidadId,
      municipioId,
      //   coloniaId,
      seccionId,
      esHombres,
      esMujeres,
      edadInicial,
      edadFinal,
    } = this.state;

    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="button" color="primary">
            Segmentación
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl
            size="small"
            className={classes.formControl}
            disabled={true}
          >
            <InputLabel id="lblEntidad">Entidad</InputLabel>
            <Select
              labelId="lblEntidad"
              id="entidadId"
              value={entidadId}
              name="entidadId"
              onChange={this.handleChangeSegmentacion}
            >
              {this.props.entidadCatalog.map((p) => {
                return (
                  <MenuItem key={p.value} value={p.value}>
                    {p.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl
            size="small"
            className={classes.formControl}
            disabled={this.state.sOriginal?.municipioId > 0}
          >
            <InputLabel id="lblMunicipio">Municipio</InputLabel>
            <Select
              labelId="lblMunicipio"
              id="municipioId"
              value={municipioId}
              name="municipioId"
              onChange={this.handleChangeSegmentacion}
            >
              {this.props.municipioCatalog?.map((p) => {
                return (
                  <MenuItem key={p.value} value={p.value}>
                    {p.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            className={classes.formControl}
            type="text"
            label="Sección"
            name="seccionId"
            value={seccionId}
            onChange={this.handleChangeNumber}
            inputProps={{ maxLength: 4 }}
            disabled={this.state.sOriginal?.seccionId > 0}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <FormControl
            style={{ marginTop: 20 }}
            disabled={this.state.sOriginal?.esHombres}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={esHombres}
                  onChange={this.handleChangeSegmentacion}
                  name="esHombres"
                />
              }
              label={<Typography variant="caption">Hombres</Typography>}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl
            style={{ marginTop: 20 }}
            disabled={this.state.sOriginal?.esMujeres}
          >
            <FormLabel component="legend">
              <Typography variant="caption"></Typography>
            </FormLabel>
            <FormControlLabel
              control={
                <Switch
                  checked={esMujeres}
                  onChange={this.handleChangeSegmentacion}
                  name="esMujeres"
                />
              }
              label={<Typography variant="caption">Mujeres</Typography>}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            className={classes.formControl}
            label="Edad Inicial"
            name="edadInicial"
            autoFocus
            value={edadInicial}
            onChange={this.handleChangeNumber}
            inputProps={{ maxLength: 3 }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            className={classes.formControl}
            label="Edad Final"
            name="edadFinal"
            autoFocus
            value={edadFinal}
            onChange={this.handleChangeNumber}
            inputProps={{ maxLength: 3 }}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    campaniaCatalog: state.campaniaState.campaniaCatalog,

    clienteCatalog: state.customerState.clienteCatalog,

    customerId: state.authState.user.customerId,
    campaniaId: state.authState.user.campaniaId,

    entidadCatalog: state.entidadState.entidadCatalog,
    municipioCatalog: state.municipioState.municipioCatalog,
    seccionCatalog: state.seccionState.seccionCatalog,
  };
};

export default connect(mapStatetoProps, {
  getCatalogAction,
})(withStyles(useStyles)(SegmentacionFiltro));
