const defaultState = {
  createCampania: false,
  createCampaniaError: "",
  campania: {},
  delCampania: false,
  delCampaniaError: "",
  getCampaniaError: "",

  campanias: [],
  campaniaCatalog: [],
  totalList: 0,
};

export const campaniaReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_CAMPANIAS":
      return {
        ...state,
        campanias: action.payload.data,
        totalList: action.payload.totalRegs,
      };
    case "FETCH_CAMPANIA_CATALOG":
      return {
        ...state,
        campaniaCatalog: action.payload,
      };
    case "CREATE_CAMPANIA":
      return {
        ...state,
        campania: { ...action.payload },
        createCampania: true,
      };
    case "CREATE_CAMPANIA_ERROR":
      return { ...state, createCampaniaError: action.payload.error };
    case "UPDATE_CAMPANIA":
      return {
        ...state,
        campania: { ...action.payload },
        updateCampania: true,
      };
    case "UPDATE_CAMPANIA_ERROR":
      return { ...state, updateCampaniaError: action.payload.error };
    case "CLEAR_CAMPANIA":
      return {
        ...state,
        campania: {},
        createCampania: false,
        createCampaniaError: "",
        delCampania: false,
        delCampaniaError: "",
        getCampania: false,
        getCampaniaError: "",
        updateCampania: false,
        updateCampaniaError: "",
      };
    case "DEL_CAMPANIA":
      return { ...state, delCampania: true };
    case "DEL_CAMPANIA_ERROR":
      return { ...state, delCampaniaError: action.payload.error };
    case "GET_CAMPANIA":
      return { ...state, getCampania: true, campania: { ...action.payload } };
    case "GET_CAMPANIA_ERROR":
      return { ...state, getCampaniaError: action.payload.error };
    default:
      return {
        ...state,
      };
  }
};
