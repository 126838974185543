import React from "react";
import { clearAction, getAction } from "../../actions/action-template";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import {
  Box,
  Typography,
  Grid,
  Paper,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import BarChart from "../charts/bar-chart";
import PieChartC from "../charts/pie-chart";
import PorcentajeView from "../dashboard/porcentaje-view";
import TotalView from "../charts/total-view";
import SegmentacionFiltro from "./segmentacion-filtro";
import ListAltIcon from "@material-ui/icons/ListAlt";
let apiURL = process.env.REACT_APP_SERVER_URL;

const data02 = [
  { name: "A1", value: 100 },
  { name: "A2", value: 300 },
  { name: "B1", value: 100 },
  { name: "B2", value: 80 },
  { name: "B3", value: 40 },
  { name: "B4", value: 30 },
  { name: "B5", value: 50 },
  { name: "C1", value: 100 },
  { name: "C2", value: 200 },
  { name: "D1", value: 150 },
  { name: "D2", value: 50 },
];

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    //marginTop: theme.spacing(2),
  },
  formButton: {
    marginRight: "10px",
  },
  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
});

class EncuestaResumen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      diasTanscurridos: "",
      diasFaltantes: "",
      edades: [],
      generos: [],
      totalContestadas: 0,
      totalUsuarios: 0,
      porcentajeContestadas: 0,
    };
  }

  componentDidUpdate() {
    const { encuestaResumen, getEncuestaResumen, getEncuestaResumenError } =
      this.props;

    if (getEncuestaResumenError) {
      this.props.enqueueSnackbar(getEncuestaResumenError, {
        variant: "error",
      });
      this.props.clearAction("clearEncuestaResumen");
    } else if (getEncuestaResumen) {
      this.props.clearAction("clearEncuestaResumen");
      this.setState({
        ...encuestaResumen,
      });
    }
  }

  actualizaSegmentacion = (seg) => {
    let _seg = { ...this.state.segmentacion, ...seg };

    this.setState({
      segmentacion: _seg,
    });
  };

  renderFiltro() {
    return (
      <SegmentacionFiltro
        segmentacion={this.state.segmentacion}
        actualizaSegmentacion={this.actualizaSegmentacion}
      ></SegmentacionFiltro>
    );
  }

  obtenerEstadisticas() {
    const {
      entidadId,
      municipioId,
      seccionId,
      esHombres,
      esMujeres,
      edadInicial,
      edadFinal,
    } = this.state.segmentacion;
    let filtro = `esFiltro=true&entidadId=${entidadId}
    &municipioId=${municipioId}&seccionId=${seccionId}
    &esHombres=${esHombres}&esMujeres=${esMujeres}&edadInicial=${edadInicial}&edadFinal=${edadFinal}`;
    this.props.getAction("getEncuestaResumen", this.state.encuestaId, filtro);
  }

  renderPreguntasCharts() {
    const { preguntas } = this.state;

    if (preguntas && preguntas.length > 0) {
      return (
        <Grid container>
          {preguntas.map((p) => {
            return (
              <Grid item xs={6} key={p.id}>
                <Box component={Paper} m={4} p={2}>
                  <Grid container>
                    <Grid item xs={12} spacing={2}>
                      <Box p={2} style={{ height: 400 }}>
                        <Typography
                          variant="h6"
                          color="primary"
                          display="inline"
                        >
                          {p.pregunta}
                        </Typography>
                        <Typography variant="caption"> ({p.tipo})</Typography>
                        <Typography
                          variant="overline"
                          color="primary"
                          display="block"
                        >
                          Por respuesta
                        </Typography>
                        <PieChartC
                          data={p.respuestas}
                          columnLeyend={true}
                        ></PieChartC>
                      </Box>
                    </Grid>
                    {p.respuestasGenero.length > 0 && (
                      <Grid item xs={12}>
                        <Box p={2} style={{ height: 400 }}>
                          <Typography variant="overline" color="primary">
                            Por Género
                          </Typography>

                          <PieChartC
                            data={p.respuestasGenero}
                            columnLeyend={true}
                          ></PieChartC>
                        </Box>
                      </Grid>
                    )}
                    {p.respuestasEdad.length > 0 && (
                      <Grid item xs={12}>
                        <Box p={2} style={{ height: 400 }}>
                          <Typography variant="overline" color="primary">
                            Por Edad
                          </Typography>

                          <PieChartC
                            data={p.respuestasEdad}
                            columnLeyend={true}
                          ></PieChartC>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      );
    } else return <div></div>;
  }

  render() {
    const { nombre } = this.state;
    let urlDescarga = `${apiURL}managment/api/EncuestaRespuestasResumen/${this.props.encuestaId}`;
    return (
      <Grid
        container
        spacing={2}
        style={{ margin: 20, marginBottom: 50, paddingRight: 40 }}
      >
        <Grid item xs={12}>
          {this.renderFiltro()}
        </Grid>
        <Grid item xs={12} md={12} align="right">
          <ButtonGroup
            color="primary"
            aria-label="Acciones"
            style={{ marginTop: 10, marginLeft: 10 }}
            size="small"
          >
            <Button
              title="Filtrar"
              color="primary"
              onClick={() => {
                this.obtenerEstadisticas();
              }}
            >
              {/* <CachedIcon /> */}
              Actualizar
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            {nombre}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Box align="center" component={Paper}>
            <TotalView
              titulo="Días Transcurridos"
              valor={this.state.diasTanscurridos}
            ></TotalView>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <Box align="center" component={Paper}>
            <TotalView
              titulo="Días Faltantess"
              valor={this.state.diasFaltantes}
            ></TotalView>
          </Box>
        </Grid>
        <Grid item xs={false} md={2}></Grid>
        <Grid item xs={12} md={2}>
          <Box align="center" component={Paper}>
            <TotalView
              titulo="Encuestas"
              valor={this.state.totalUsuarios}
            ></TotalView>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <Box align="center" component={Paper}>
            <TotalView
              titulo="Contestadas"
              valor={this.state.totalContestadas}
            ></TotalView>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <Box align="center" component={Paper}>
            <PorcentajeView
              titulo="Total %"
              valor={this.state.porcentajeContestadas}
            ></PorcentajeView>
          </Box>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <Box align="right" m={2}>
            <TextField
              style={{ marginRight: 10 }}
              id="outlined-basic"
              label="Días transcurridos"
              variant="outlined"
              value={diasTanscurridos}
              disabled
            />
            <TextField
              style={{ marginRight: 10 }}
              id="outlined-basic"
              label="Días faltantes"
              variant="outlined"
              value={diasFaltantes}
              disabled
            />
          </Box>
        </Grid> */}

        <Grid item xs={12} md={6} align="center">
          <BarChart
            keys={["total"]}
            index="edad"
            data={this.state.edades}
            leyendaAbajo="Edad"
            leyendaLateral="Total"
          ></BarChart>
        </Grid>
        <Grid item xs={12} md={6} align="center">
          <PieChartC data={this.state.generos}></PieChartC>
        </Grid>

        <Grid item xs={12}>
          <Box title="Listado de respuestas">
            <Typography variant="h4" display="inline">
              Preguntas{" "}
            </Typography>
            <a
              href={urlDescarga}
              download
              color="primary"
              target="_blank"
              rel="noreferrer"
            >
              <ListAltIcon color="primary" />
            </a>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {this.renderPreguntasCharts()}
        </Grid>
      </Grid>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    encuestaResumen: state.encuestaResumenState.encuestaResumen,
    getEncuestaResumen: state.encuestaResumenState.getEncuestaResumen,
    getEncuestaResumenError: state.encuestaResumenState.getEncuestaResumenError,
  };
};

export default connect(mapStatetoProps, {
  clearAction,
  getAction,
})(withStyles(useStyles)(withSnackbar(withSnackbar(EncuestaResumen))));
