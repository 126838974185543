import React from "react";
import {
  Box,
  Typography,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  Paper,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import {
  getAllAction,
  clearAction,
  updateAction,
  getCatalogAction,
} from "../../../actions/action-template";

const useStyles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
});

class CampaniaTareasList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clienteId:
        this.props.customerId !== ""
          ? this.props.customerId
          : this.props.preferenciaClienteId,
      campaniaId:
        this.props.campaniaId !== ""
          ? this.props.campaniaId
          : this.props.preferenciaCampaniaId > 0
          ? this.props.preferenciaCampaniaId
          : "",
      lista: [],
      pageNumber: 0,
      pageSize: 1000,
    };
    //  this.fetchList();

    this.props.getCatalogAction("getClienteCatalogo");

    if (this.props.campaniaId) {
      this.props.getCatalogAction(
        "getCampaniaCatalogo",
        `clienteId=${this.props.customerId}&campaniaId=${this.props.campaniaId}`
      );
      this.fetchList();
    } else if (this.props.customerId) {
      this.props.getCatalogAction(
        "getCampaniaCatalogo",
        `clienteId=${this.props.customerId}`
      );
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === "clienteId") {
        this.props.getCatalogAction(
          "getCampaniaCatalogo",
          `clienteId=${value}`
        );
      } else if (name === "campaniaId") {
        this.fetchList();
      }
    });
  };

  handleChangeRow = (e) => {
    const { name, value } = e.target;
    if (parseInt(value) < 0) return;
    let datos = name.split("-");
    let lista = [...this.state.lista];
    let reg = lista.find(
      (r) =>
        r.campaniaId === parseInt(datos[0]) && r.tareaId === parseInt(datos[1])
    );
    if (reg && parseInt(value) > 0 && parseInt(value) < 256) {
      reg.puntos = value;
    } else reg.puntos = 0;
    this.setState({ lista: lista });
  };

  fetchList() {
    const { pageNumber, pageSize } = this.state;
    let filtro = `campaniaId=${this.state.campaniaId}`;

    this.props.getAllAction(
      "getAllCampaniaTareas",
      pageNumber,
      pageSize,
      filtro
    );
  }

  save = (e) => {
    if (e.puntos !== e.puntosOriginal)
      this.props.updateAction("updateCampaniaTarea", e.campaniaId, e);
  };

  getKey(r) {
    let key = `${r.campaniaId}-${r.tareaId}`;
    return key;
  }

  renderItems() {
    const { classes } = this.props;

    return this.state.lista.map((r) => {
      return (
        <TableRow key={this.getKey(r)}>
          <TableCell>{r.descripcionTarea}</TableCell>
          <TableCell>
            <TextField
              size="small"
              className={classes.formControl}
              label=""
              name={`${r.campaniaId}-${r.tareaId}`}
              value={r.puntos}
              onChange={this.handleChangeRow}
              InputLabelProps={{
                shrink: true,
              }}
              onBlur={() => {
                this.save(r);
              }}
            />
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { campaniaId, clienteId } = this.state;

    const { classes } = this.props;

    return (
      <Box mx={2}>
        <Box mb={4}>
          <Typography variant="h2" gutterBottom>
            Tareas
          </Typography>
          <Grid container>
            {!this.props.customerId && !this.state.id && (
              <Grid item xs={12} md={3}>
                <FormControl size="small" className={classes.formControl}>
                  <InputLabel id="lblCliente">Cliente</InputLabel>
                  <Select
                    labelId="lblCliente"
                    value={clienteId}
                    name="clienteId"
                    onChange={this.handleChange}
                  >
                    {this.props.clienteCatalog.map((p) => {
                      return (
                        <MenuItem key={p.value} value={p.value}>
                          {p.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {!this.props.campaniaId && !this.state.id && (
              <Grid item xs={12} md={3}>
                <FormControl size="small" className={classes.formControl}>
                  <InputLabel id="lblCampania">Campaña</InputLabel>
                  <Select
                    labelId="lblCampania"
                    value={campaniaId}
                    name="campaniaId"
                    onChange={this.handleChange}
                  >
                    {this.props.campaniaCatalog.map((p) => {
                      return (
                        <MenuItem key={p.id} value={p.id}>
                          {p.nombre}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {this.state.lista.length > 0 && (
              <Grid item xs={12}>
                <Box mt={3}>
                  <TableContainer component={Paper}>
                    <Table
                      size="small"
                      className={classes.table}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Tarea</TableCell>

                          <TableCell>Puntos</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>{this.renderItems()}</TableBody>
                    </Table>
                    {/* <TablePagination
                      rowsPerPageOptions={[5, 10, 15, 20]}
                      component="div"
                      count={this.props.totalList}
                      rowsPerPage={this.state.pageSize}
                      page={this.state.pageNumber}
                      onChangePage={(e, newPage) => {
                        this.setState({ pageNumber: newPage }, () => {
                          this.fetchList();
                        });
                      }}
                      onChangeRowsPerPage={(e) => {
                        this.setState(
                          { pageSize: parseInt(e.target.value, 10) },
                          () => {
                            this.fetchList();
                          }
                        );
                      }}
                    /> */}
                  </TableContainer>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    );
  }

  componentDidUpdate() {
    const {
      updateCampaniaTareaError,
      updateCampaniaTarea,
      campaniaTareas,
      fechCampaniaTareas,
    } = this.props;

    if (fechCampaniaTareas) {
      let lista = [
        ...campaniaTareas.map((e) => {
          return { ...e, puntosOriginal: e.puntos };
        }),
      ];
      this.props.clearAction("clearCampaniaTarea");

      this.setState({ lista: lista });
    }

    if (updateCampaniaTareaError) {
      this.props.enqueueSnackbar(updateCampaniaTareaError, {
        variant: "error",
      });
      this.props.clearAction("clearCampaniaTarea");
    } else if (updateCampaniaTarea) {
      this.props.clearAction("clearCampaniaTarea");
      this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
        variant: "success",
      });
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    campaniaTareas: state.campaniaTareaState.campaniaTareas,
    fechCampaniaTareas: state.campaniaTareaState.fechCampaniaTareas,
    totalList: state.campaniaTareaState.totalList,

    updateCampaniaTarea: state.campaniaTareaState.updateCampaniaTarea,
    updateCampaniaTareaError: state.campaniaTareaState.updateCampaniaTareaError,

    clienteCatalog: state.customerState.clienteCatalog,
    campaniaCatalog: state.campaniaState.campaniaCatalog,

    customerId: state.authState.user.customerId,
    campaniaId: state.authState.user.campaniaId,
  };
};

export default connect(mapStatetoProps, {
  getAllAction,
  clearAction,
  getCatalogAction,
  updateAction,
})(withStyles(useStyles)(withSnackbar(CampaniaTareasList)));
