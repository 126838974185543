import {
  Grid,
  Box,
  Paper,
  Typography,
  SvgIcon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ButtonGroup,
  TextField,
  Button,
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import PorcentajeView from "./porcentaje-view";
import LineaTiempo from "./linea-tiempo";
import {
  getAllAction,
  clearAction,
  getCatalogAction,
} from "../../actions/action-template";
import { trackPromise } from "react-promise-tracker";

let apiURL = process.env.REACT_APP_SERVER_URL;

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginRight: "5px",
  },
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clienteId: this.props.customerId ?? "",
      campaniaId: this.props.campaniaId ?? 0,
      entidadId: 0,
      municipioId: 0,
      seccionId: 0,
      distritoFederalId: 0,
      distritoLocalId: 0,
      codigoPostal: "",
      colonia: "",
      datos: [],
      datosCargados: false,
      campaniaSeleccionada: "",
      ambitoTerritorialId: "",
      fechaInicio: "",
      fechaFin: "",
    };
    this.props.getCatalogAction("getClienteCatalogo");
    this.props.getCatalogAction("getEntidadCatalogo");
    if (this.props.campaniaId) {
      this.props.getCatalogAction(
        "getCampaniaCatalogo",
        `clienteId=${this.props.customerId}&campaniaId=${this.props.campaniaId}`
      );
    } else if (this.props.customerId) {
      this.props.getCatalogAction(
        "getCampaniaCatalogo",
        `clienteId=${this.props.customerId}`
      );
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === "clienteId") {
        this.props.getCatalogAction(
          "getCampaniaCatalogo",
          `clienteId=${value}`
        );
      }
      if (name !== "clienteId") this.obtenerEstadisticas();
    });
  };

  obtenerEstadisticas() {
    const {
      campaniaId,
      entidadId,
      seccionId,
      fechaInicio,
      fechaFin,
    } = this.state;
    let fechas = "";

    if (fechaInicio) {
      fechas = "&fechaInicial=" + fechaInicio;
    }
    if (fechaFin) {
      fechas += "&fechaFinal=" + fechaFin;
    }
    let filtro = `campaniaId=${campaniaId}&entidadId=${entidadId}&seccionId=${seccionId}${fechas}`;

    trackPromise(this.props.getAllAction("getAllCampaniaAvance", 0, 0, filtro));

    let campania = this.props.campaniaCatalog.find(
      (e) => parseInt(e.id) === parseInt(campaniaId)
    );

    if (campania.ambitoTerritorialId === 5) {
      this.props.getCatalogAction(
        "getMunicipioCatalogo",
        `entidadId=${campania.entidadId}&municipioId=${campania.municipioId}`
      );
      this.props.getCatalogAction(
        "getSeccionCatalogo",
        `entidadId=${campania.entidadId}&municipioId=${campania.municipioId}`
      );
    } else if (campania.ambitoTerritorialId === 4) {
      this.props.getCatalogAction(
        "getSeccionCatalogo",
        `entidadId=${campania.entidadId}&municipioId=${campania.municipioId}&seccionId=${campania.seccionId}`
      );
    } else if (campania.ambitoTerritorialId === 2) {
      this.props.getCatalogAction(
        "getDistritoFederalCatalogo",
        `entidadId=${campania.entidadId}&distritoFederalId=${campania.distritoFederalId}`
      );
      this.props.getCatalogAction(
        "getSeccionCatalogo",
        `entidadId=${campania.entidadId}&municipioId=${campania.municipioId}&distritoFederalId=${campania.distritoFederalId}`
      );
    } else if (campania.ambitoTerritorialId === 3) {
      this.props.getCatalogAction(
        "getDistritoLocalCatalogo",
        `entidadId=${campania.entidadId}&distritoLocalId=${campania.distritoLocalId}`
      );
      this.props.getCatalogAction(
        "getSeccionCatalogo",
        `entidadId=${campania.entidadId}&municipioId=${campania.municipioId}&distritoLocalId=${campania.distritoLocalId}`
      );
    }

    this.setState({
      datosCargados: true,
      campaniaSeleccionada: campania,
      entidadId: campania.entidadId,
      municipioId: campania.municipioId,
      //  seccionId: campania.seccionId,
      distritoFederalId: campania.distritoFederalId,
      distritoLocalId: campania.distritoLocalId,
      ambitoTerritorialId: campania.ambitoTerritorialId,
    });
  }

  componentDidUpdate() {
    if (
      !this.state.datosCargados &&
      this.props.campaniaCatalog.length === 1 &&
      this.props.customerId
    ) {
      this.obtenerEstadisticas();
    }
  }

  obtenerListadoActivados() {
    //this.props.getAllAction("getReporteActivados");
    window.open(
      `${apiURL}managment/api/CampaniaReporte/?campaniaId=${this.state.campaniaId}&seccionId=${this.state.seccionId}`,
      "_self"
    );
  }
  renderFiltro() {
    const { classes } = this.props;
    const {
      clienteId,
      campaniaId,
      entidadId,
      municipioId,
      seccionId,
      distritoFederalId,
      distritoLocalId,
      ambitoTerritorialId,
      fechaInicio,
      fechaFin,
    } = this.state;
    return (
      <Paper
        style={{
          marginBottom: 20,
          padding: 20,
        }}
      >
        <Grid container>
          {!this.props.customerId && (
            <Grid item xs={6} md={3}>
              <FormControl size="small" className={classes.formControl}>
                <InputLabel id="lblCliente">Cliente</InputLabel>
                <Select
                  labelId="lblCliente"
                  value={clienteId}
                  name="clienteId"
                  onChange={this.handleChange}
                >
                  {this.props.clienteCatalog.map((p) => {
                    return (
                      <MenuItem key={p.value} value={p.value}>
                        {p.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
          {!this.props.campaniaId && (
            <Grid item xs={6} md={3}>
              <FormControl size="small" className={classes.formControl}>
                <InputLabel id="lblCampania">Campaña</InputLabel>
                <Select
                  labelId="lblCampania"
                  value={campaniaId}
                  name="campaniaId"
                  onChange={this.handleChange}
                >
                  {this.props.campaniaCatalog.map((p) => {
                    return (
                      <MenuItem key={p.id} value={p.id}>
                        {p.nombre}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={6} md={3}>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="lblEntidad">Entidad</InputLabel>
              <Select
                disabled
                labelId="lblEntidad"
                value={entidadId}
                name="entidadId"
                onChange={this.handleChange}
              >
                {this.props.entidadCatalog.map((p) => {
                  return (
                    <MenuItem key={p.value} value={p.value}>
                      {p.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          {ambitoTerritorialId === 5 && (
            <Grid item xs={6} md={3}>
              <FormControl size="small" className={classes.formControl}>
                <InputLabel id="lblMunicipio">Municipio</InputLabel>
                <Select
                  disabled
                  labelId="lblMunicipio"
                  value={municipioId}
                  name="municipioId"
                  onChange={this.handleChange}
                >
                  {this.props.municipioCatalog.map((p) => {
                    return (
                      <MenuItem key={p.value} value={p.value}>
                        {p.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}

          {ambitoTerritorialId === 2 && (
            <Grid item xs={6} md={3}>
              <FormControl size="small" className={classes.formControl}>
                <InputLabel id="lblDFederal">Distrito Federal</InputLabel>
                <Select
                  disabled
                  labelId="lblDFederal"
                  value={distritoFederalId}
                  name="distritoFederalId"
                  onChange={this.handleChange}
                >
                  {this.props.distritoFederalCatalog.map((p) => {
                    return (
                      <MenuItem key={p.value} value={p.value}>
                        {p.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}

          {ambitoTerritorialId === 3 && (
            <Grid item xs={6} md={3}>
              <FormControl size="small" className={classes.formControl}>
                <InputLabel id="lblDLocal">Distrito Local</InputLabel>
                <Select
                  disabled
                  labelId="lblDLocal"
                  value={distritoLocalId}
                  name="distritoLocalId"
                  onChange={this.handleChange}
                >
                  {this.props.distritoLocalCatalog.map((p) => {
                    return (
                      <MenuItem key={p.value} value={p.value}>
                        {p.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={6} md={3}>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="lblSeccion">Sección</InputLabel>
              <Select
                labelId="lblSeccion"
                value={seccionId}
                name="seccionId"
                onChange={this.handleChange}
              >
                {this.props.seccionCatalog.length > 1 && (
                  <MenuItem key={0} value={0}>
                    Todas
                  </MenuItem>
                )}
                {this.props.seccionCatalog.map((p) => {
                  return (
                    <MenuItem key={p.value} value={p.value}>
                      {p.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} md={3}>
            <TextField
              className={classes.formControl}
              type="date"
              label="Fecha inicial"
              name="fechaInicio"
              value={fechaInicio}
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              className={classes.formControl}
              type="date"
              label="Fecha Final"
              name="fechaFin"
              value={fechaFin}
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <Grid container>
            {this.state.datosCargados && (
              <Grid item xs={12} md={12} align="right">
                <ButtonGroup
                  color="primary"
                  aria-label="Acciones"
                  style={{ marginTop: 10, marginLeft: 10 }}
                  size="small"
                >
                  <Button
                    title="Filtrar"
                    color="primary"
                    onClick={() => {
                      this.obtenerEstadisticas();
                    }}
                  >
                    {/* <CachedIcon /> */}
                    Actualizar
                  </Button>
                  {/* <Button
                  title="Filtrar"
                  color="primary"
                  onClick={() => {
                    this.obtenerListadoActivados();
                  }}
                >
                  <Typography variant="caption">Listado Activistas</Typography>
                </Button> */}
                  <Button
                    title="Filtrar"
                    color="primary"
                    onClick={() => {
                      this.obtenerListadoActivados();
                    }}
                  >
                    <Typography variant="caption">Listado Activados</Typography>
                  </Button>
                </ButtonGroup>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
    );
  }

  obtenerPorcentaje(total, parcial) {
    let porcentaje = total > 0 ? parcial / total : 0;
    return (porcentaje * 100).toFixed(0);
  }

  render() {
    return (
      <Box mx={2}>
        <Typography variant="h2" gutterBottom>
          Avance
        </Typography>
        {this.renderFiltro()}
        {this.state.datosCargados && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="overline" color="primary">
                Activismo
              </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <Box display="flex" component={Paper} p={1} boxShadow={4}>
                <Box justifyContent="flex-start" alignSelf="center" p={0}>
                  <SvgIcon
                    viewBox="0 0 150 150"
                    style={{
                      width: 40,
                      height: 40,
                    }}
                  >
                    <g xmlns="http://www.w3.org/2000/svg">
                      <polygon points="91,45 83,45 83,73 73,73 73,119 61,119 61,73 51,73 51,45 43,45 43,81 53,81 53,127 81,127 81,81 91,81  " />
                      <path d="M53,15c0,7.7,6.3,14,14,14s14-6.3,14-14S74.7,1,67,1S53,7.3,53,15z M73,15c0,3.3-2.7,6-6,6s-6-2.7-6-6s2.7-6,6-6   S73,11.7,73,15z" />
                      <path d="M19,25c0,7.7,6.3,14,14,14s14-6.3,14-14s-6.3-14-14-14S19,17.3,19,25z M39,25c0,3.3-2.7,6-6,6s-6-2.7-6-6s2.7-6,6-6   S39,21.7,39,25z" />
                      <polygon points="113,83 103,83 103,119 87,119 87,127 111,127 111,91 121,91 121,51 113,51  " />
                      <path d="M87,25c0,7.7,6.3,14,14,14s14-6.3,14-14s-6.3-14-14-14S87,17.3,87,25z M101,19c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6   S97.7,19,101,19z" />
                      <polygon points="31,83 21,83 21,51 13,51 13,91 23,91 23,127 47,127 47,119 31,119  " />
                    </g>
                  </SvgIcon>
                </Box>
                <Box justifyContent="flex-end" p={1} align="center">
                  <Typography variant="caption" display="block">
                    Total Activados
                  </Typography>
                  <Typography variant="button">
                    {this.props.campaniaAvances?.resumen?.total}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} md={2}>
              <Box display="flex" component={Paper} p={1} boxShadow={4}>
                <Box justifyContent="flex-start" alignSelf="center" p={0}>
                  <SvgIcon
                    viewBox="0 0 200 500"
                    style={{
                      width: 40,
                      height: 40,
                    }}
                  >
                    <path d="M 95.872 81.671C 118.422 81.671 136.706 63.387 136.706 40.835C 136.706 18.284 118.422 0 95.872 0C 73.319 0 55.038 18.284 55.038 40.835C 55.038 63.387 73.319 81.671 95.872 81.671z" />
                    <path d="M 95.872 40.835" />
                    <path d="M 52.173 90.683C 23.256 90.683 0 114.267 0 143.683L 0 269.058C 0 293.433 35.673 293.433 35.673 269.058L 35.673 154.416L 44.118 154.416L 44.118 468.323C 44.118 500.913 91.621 499.954 91.621 468.323L 91.621 286.104L 99.803 286.104L 99.803 468.323C 99.803 499.954 147.567 500.913 147.567 468.323L 147.567 154.416L 155.814 154.416L 155.814 269.058C 155.814 293.621 191.301 293.62 191.237 269.058L 191.237 144.433C 191.237 117.308 170.173 90.739 138.423 90.739L 52.173 90.683z" />
                  </SvgIcon>
                </Box>
                <Box justifyContent="flex-end" p={1} align="center">
                  <Typography variant="caption" display="block">
                    Hombres
                  </Typography>
                  <Typography variant="button">
                    {this.props.campaniaAvances?.resumen?.hombres}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} md={2}>
              <Box display="flex" component={Paper} p={1} boxShadow={4}>
                <Box justifyContent="flex-start" alignSelf="center" p={0}>
                  <SvgIcon
                    viewBox="0 0 200 500"
                    style={{
                      width: 40,
                      height: 40,
                    }}
                  >
                    <path d="M 113.129 80.035C 135.23 80.035 153.147 62.119 153.147 40.017C 153.147 17.918 135.23 0 113.129 0C 91.0312 0 73.1122 17.918 73.1122 40.017C 73.1122 62.119 91.0312 80.035 113.129 80.035z" />
                    <path d="M 113.129 40.017" />
                    <path d="M 107.212 333.911L 107.228 472.514C 107.228 497.7 69.4942 497.7 69.4942 472.514L 69.4782 334.87L 18.8082 334.87L 72.9782 146.923L 64.5682 146.923L 33.0582 252.95C 25.5582 276.075 -6.19176 266.575 1.07224 241.812L 36.0582 126.623C 39.8082 113.487 56.4212 90.2 85.1762 90.2L 111.691 90.2L 111.691 90.2L 140.228 90.2C 168.728 90.2 185.415 113.683 189.728 126.623L 224.728 241.7C 231.728 266.45 200.228 276.7 192.728 252.575L 161.228 146.923L 152.122 146.923L 206.728 334.87L 155.978 334.87L 155.978 472.7C 155.978 497.7 118.401 497.575 118.401 472.7L 118.401 333.911L 107.212 333.911z" />
                  </SvgIcon>
                </Box>
                <Box justifyContent="flex-end" p={1} align="center">
                  <Typography variant="caption" display="block">
                    Mujeres
                  </Typography>
                  <Typography variant="button">
                    {this.props.campaniaAvances?.resumen?.mujeres}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <Box display="flex" component={Paper} p={1} boxShadow={4}>
                <Box justifyContent="flex-start" alignSelf="center" p={0}>
                  <SvgIcon
                    viewBox="0 0 100 100"
                    style={{
                      width: 40,
                      height: 40,
                    }}
                  >
                    <path d="M48,0A24.0275,24.0275,0,0,0,24,24a6,6,0,0,0,12,0,12,12,0,0,1,24,0c0,5.2031-3.0586,8.3965-8.0859,13.0371C47.2617,41.32,42,46.1719,42,54a6,6,0,0,0,12,0c0-2.4434,2.2969-4.6875,6.0469-8.1445C65.0859,41.2031,72,34.834,72,24A24.0275,24.0275,0,0,0,48,0Z" />
                    <path d="M48,72A12,12,0,1,0,60,84,12.0119,12.0119,0,0,0,48,72Z" />
                  </SvgIcon>
                </Box>
                <Box justifyContent="flex-end" p={1} align="center">
                  <Typography variant="caption" display="block">
                    Sin género
                  </Typography>
                  <Typography variant="button">
                    {this.props.campaniaAvances?.resumen?.generoSinEspecificar}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} md={2}>
              <Box display="flex" component={Paper} p={1} boxShadow={4}>
                <Box justifyContent="flex-start" alignSelf="center" p={0}>
                  <SvgIcon
                    viewBox="0 0 100 100"
                    style={{
                      width: 40,
                      height: 40,
                    }}
                  >
                    <g xmlns="http://www.w3.org/2000/svg">
                      <circle cx="64.2" cy="13" r="8" />
                      <path d=" M 83.6 24 C 81.6 23 79.2 23.7 78.2 25.7 L 74.7 32.3 L 59.3 22.6 C 58.7 22.2 58 22 57.2 22 L 40.2 22 C 38.7 22 37.4 22.8 36.7 24.1 L 30.2 36.1 C 29.1 38 29.9 40.5 31.8 41.5 C 32.4 41.8 33.1 42 33.7 42 C 35.1 42 36.5 41.2 37.2 39.9 L 42.6 30 L 48.5 30 L 30.8 63 L 14.2 63 C 12 63 10.2 64.8 10.2 67 C 10.2 69.2 12 71 14.2 71 L 33.2 71 C 34.7 71 36 70.2 36.7 68.9 L 43.7 56 L 55.2 66.7 L 54.3 86.8 C 54.1 89 55.8 90.9 58 91 C 58.1 91 58.1 91 58.2 91 C 60.3 91 62.1 89.3 62.2 87.2 L 63.2 65.2 C 63.3 64 62.8 62.9 61.9 62.1 L 52.2 53.1 L 62.4 34.1 L 74 41.4 C 74.9 42 76.1 42.2 77.2 41.9 C 78.3 41.6 79.2 40.9 79.7 39.9 L 85.2 29.4 C 86.3 27.5 85.6 25.1 83.6 24 Z" />
                    </g>
                  </SvgIcon>
                </Box>
                <Box justifyContent="flex-end" p={1} align="center">
                  <Typography variant="caption" display="block">
                    Edad 18-30
                  </Typography>
                  <Typography variant="button">
                    {this.props.campaniaAvances?.resumen?.edad18a30}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} md={2}>
              <Box display="flex" component={Paper} p={1} boxShadow={4}>
                <Box justifyContent="flex-start" alignSelf="center" p={0}>
                  <SvgIcon
                    viewBox="0 0 35 35"
                    style={{
                      width: 40,
                      height: 40,
                    }}
                  >
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M14.5,23.084L13.361,21h1.613h1.665L15.5,23.084L16.695,27H27c0-7-8-5-9-8v-2c0.45-0.223,1.737-1.755,1.872-2.952  c0.354-0.027,0.91-0.352,1.074-1.635c0.088-0.689-0.262-1.076-0.474-1.198c0,0,0.528-1.003,0.528-2.214c0-2.428-0.953-4.5-3-4.5  c0,0-0.711-1.5-3-1.5c-4.242,0-6,2.721-6,6c0,1.104,0.528,2.214,0.528,2.214c-0.212,0.122-0.562,0.51-0.474,1.198  c0.164,1.283,0.72,1.608,1.074,1.635C10.263,15.245,11.55,16.777,12,17v2c-1,3-9,1-9,8h10.305L14.5,23.084z"
                    />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M19.5,21H17v-2h0.889c0.38,0,0.728,0.216,0.896,0.556L19.5,21z"
                    />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M10.5,21H13v-2h-0.889c-0.38,0-0.728,0.216-0.896,0.556L10.5,21z"
                    />
                  </SvgIcon>
                </Box>
                <Box justifyContent="flex-end" p={1} align="center">
                  <Typography variant="caption" display="block">
                    Edad 30-50
                  </Typography>
                  <Typography variant="button">
                    {this.props.campaniaAvances?.resumen?.edad30a50}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} md={2}>
              <Box display="flex" component={Paper} p={1} boxShadow={4}>
                <Box justifyContent="flex-start" alignSelf="center" p={0}>
                  <SvgIcon
                    viewBox="0 0 100 100"
                    style={{
                      width: 40,
                      height: 40,
                    }}
                  >
                    <path d=" M 68.5 47 C 68.1 47 67.8 47 67.4 47.1 C 67.1 45.9 66.3 44.8 65 44.3 L 56.5 40.9 L 51 28.2 L 51 28.2 C 49.9 25.2 47 23 43.5 23 C 41.7 23 40.1 23.6 38.8 24.5 L 38.8 24.5 L 25.3 33.6 C 24.5 34.2 23.9 35 23.6 36 L 20.6 49 C 20.1 51.2 21.4 53.3 23.6 53.8 C 23.9 53.9 24.2 53.9 24.5 53.9 C 26.3 53.9 28 52.6 28.4 50.8 L 31 39.4 L 33.5 37.7 C 32.5 44.5 32.4 49.9 32.4 50.8 L 31.4 68.3 L 27.5 86 C 27 88.2 28.4 90.3 30.5 90.8 C 30.8 90.9 31.1 90.9 31.4 90.9 C 33.2 90.9 34.9 89.6 35.3 87.8 L 39.3 69.8 C 39.3 69.6 39.4 69.4 39.4 69.2 L 39.9 59.4 L 46.4 66.6 L 47.3 85.2 C 47.4 87.3 49.2 89 51.3 89 C 51.4 89 51.4 89 51.5 89 C 53.7 88.9 55.4 87 55.3 84.8 L 54.3 64.8 C 54.3 63.9 53.9 63 53.3 62.3 L 46.3 54.6 L 46.3 38.4 C 46.3 38.4 46.4 38.4 46.4 38.3 L 49.6 45.6 C 50 46.6 50.8 47.3 51.8 47.7 L 61.7 51.7 C 61.4 52.4 61.3 53.2 61.3 54 C 61.3 56.2 62.3 58.1 63.9 59.4 C 63.9 59.4 64 59.5 64 59.5 C 64.3 59.8 64.8 60 65.3 60 C 66.4 60 67.3 59.1 67.3 58 C 67.3 57.3 67 56.7 66.5 56.4 C 66 55.8 65.5 55 65.5 54 C 65.5 52.3 66.8 51 68.5 51 C 70.2 51 71.5 52.3 71.5 54 L 71.5 60.3 L 71.5 63 L 71.5 89 C 71.5 90.1 72.4 91 73.5 91 C 74.6 91 75.5 90.1 75.5 89 L 75.5 54 C 75.5 50.1 72.4 47 68.5 47 Z" />
                    <circle cx="49" cy="14" r="8" />
                  </SvgIcon>
                </Box>
                <Box justifyContent="flex-end" p={1} align="center">
                  <Typography variant="caption" display="block">
                    Edad {">"} 50
                  </Typography>
                  <Typography variant="button">
                    {this.props.campaniaAvances?.resumen?.edadMasDe50}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} md={2}>
              <Box display="flex" component={Paper} p={1} boxShadow={4}>
                <Box justifyContent="flex-start" alignSelf="center" p={0}>
                  <SvgIcon
                    viewBox="0 0 100 100"
                    style={{
                      width: 40,
                      height: 40,
                    }}
                  >
                    <path d="M48,0A24.0275,24.0275,0,0,0,24,24a6,6,0,0,0,12,0,12,12,0,0,1,24,0c0,5.2031-3.0586,8.3965-8.0859,13.0371C47.2617,41.32,42,46.1719,42,54a6,6,0,0,0,12,0c0-2.4434,2.2969-4.6875,6.0469-8.1445C65.0859,41.2031,72,34.834,72,24A24.0275,24.0275,0,0,0,48,0Z" />
                    <path d="M48,72A12,12,0,1,0,60,84,12.0119,12.0119,0,0,0,48,72Z" />
                  </SvgIcon>
                </Box>
                <Box justifyContent="flex-end" p={1} align="center">
                  <Typography variant="caption" display="block">
                    Sin edad
                  </Typography>
                  <Typography variant="button">
                    {this.props.campaniaAvances?.resumen?.edadSinEspecificar}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} md={2}>
              <Box display="flex" component={Paper} p={1} boxShadow={4}>
                <Box justifyContent="flex-start" alignSelf="center" p={0}>
                  <SvgIcon
                    viewBox="0 0 25 25"
                    style={{
                      width: 40,
                      height: 40,
                    }}
                  >
                    <path d="M2,20v2h8V20a4,4,0,0,0-1.62-3.2A3,3,0,0,0,9,15a3,3,0,0,0-6,0,3,3,0,0,0,.62,1.8A4,4,0,0,0,2,20Z" />
                    <path d="M20.38,6.8A3,3,0,0,0,21,5a3,3,0,0,0-6,0,3,3,0,0,0,.62,1.8A4,4,0,0,0,14,10v2h8V10A4,4,0,0,0,20.38,6.8Z" />
                    <polygon points="5 11 7 11 7 7 13 7 13 5 5 5 5 11" />
                    <polygon points="17 17 11 17 11 19 19 19 19 13 17 13 17 17" />
                  </SvgIcon>
                </Box>
                <Box justifyContent="flex-end" p={1} align="center">
                  <Typography variant="caption" display="block">
                    Activistas
                  </Typography>
                  <Typography variant="button">
                    {this.props.campaniaAvances?.resumen?.totalActivistas}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4} md={6}>
              {/* <Box component={Paper} p={1} boxShadow={4}>
              <PorcentajeView
                titulo="Sección 70"
                valor={this.obtenerPorcentaje(
                  this.props.campaniaAvances?.resumen?.total,
                  this.props.campaniaAvances?.resumen?.hombres
                )}
              ></PorcentajeView>
            </Box> */}
            </Grid>
            <Grid item xs={4} md={2}>
              <Box component={Paper} p={1} boxShadow={4}>
                <PorcentajeView
                  titulo="Hombres"
                  valor={this.obtenerPorcentaje(
                    this.props.campaniaAvances?.resumen?.total,
                    this.props.campaniaAvances?.resumen?.hombres
                  )}
                ></PorcentajeView>
              </Box>
            </Grid>
            <Grid item xs={4} md={2}>
              <Box component={Paper} p={1} boxShadow={4}>
                <PorcentajeView
                  titulo="Mujeres"
                  valor={this.obtenerPorcentaje(
                    this.props.campaniaAvances?.resumen?.total,
                    this.props.campaniaAvances?.resumen?.mujeres
                  )}
                ></PorcentajeView>
              </Box>
            </Grid>

            <Grid item xs={4} md={2}>
              <Box component={Paper} p={1} boxShadow={4}>
                <PorcentajeView
                  titulo="Sin género"
                  valor={this.obtenerPorcentaje(
                    this.props.campaniaAvances?.resumen?.total,
                    this.props.campaniaAvances?.resumen?.generoSinEspecificar
                  )}
                ></PorcentajeView>
              </Box>
            </Grid>

            <Grid item xs={4} md={2}>
              <Box component={Paper} p={1} boxShadow={4}>
                <PorcentajeView
                  titulo="Edad 18-30"
                  valor={this.obtenerPorcentaje(
                    this.props.campaniaAvances?.resumen?.total,
                    this.props.campaniaAvances?.resumen?.edad18a30
                  )}
                ></PorcentajeView>
              </Box>
            </Grid>
            <Grid item xs={4} md={2}>
              <Box component={Paper} p={1} boxShadow={4}>
                <PorcentajeView
                  titulo="Edad 30-50"
                  valor={this.obtenerPorcentaje(
                    this.props.campaniaAvances?.resumen?.total,
                    this.props.campaniaAvances?.resumen?.edad30a50
                  )}
                ></PorcentajeView>
              </Box>
            </Grid>

            <Grid item xs={4} md={2}>
              <Box component={Paper} p={1} boxShadow={4}>
                <PorcentajeView
                  titulo="Edad > 50"
                  valor={this.obtenerPorcentaje(
                    this.props.campaniaAvances?.resumen?.total,
                    this.props.campaniaAvances?.resumen?.edadMasDe50
                  )}
                ></PorcentajeView>
              </Box>
            </Grid>

            <Grid item xs={4} md={2}>
              <Box component={Paper} p={1} boxShadow={4}>
                <PorcentajeView
                  titulo="Sin edad"
                  valor={this.obtenerPorcentaje(
                    this.props.campaniaAvances?.resumen?.total,
                    this.props.campaniaAvances?.resumen?.edadSinEspecificar
                  )}
                ></PorcentajeView>
              </Box>
            </Grid>
            <Grid item xs={4} md={2}>
              <Box component={Paper} p={1} boxShadow={4}>
                <PorcentajeView
                  titulo="Activistas"
                  valor={this.obtenerPorcentaje(
                    this.props.campaniaAvances?.resumen?.total,
                    this.props.campaniaAvances?.resumen?.totalActivistas
                  )}
                ></PorcentajeView>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                component={Paper}
                p={1}
                boxShadow={4}
                style={{ height: 500 }}
              >
                <LineaTiempo datos={this.props.campaniaAvances}></LineaTiempo>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline" color="primary">
                Encuestas
              </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <Box display="flex" component={Paper} p={1} boxShadow={4}>
                <Box justifyContent="flex-start" alignSelf="center" p={0}>
                  <SvgIcon
                    viewBox="0 0 150 150"
                    style={{
                      width: 40,
                      height: 40,
                    }}
                  >
                    <g xmlns="http://www.w3.org/2000/svg">
                      <polygon points="91,45 83,45 83,73 73,73 73,119 61,119 61,73 51,73 51,45 43,45 43,81 53,81 53,127 81,127 81,81 91,81  " />
                      <path d="M53,15c0,7.7,6.3,14,14,14s14-6.3,14-14S74.7,1,67,1S53,7.3,53,15z M73,15c0,3.3-2.7,6-6,6s-6-2.7-6-6s2.7-6,6-6   S73,11.7,73,15z" />
                      <path d="M19,25c0,7.7,6.3,14,14,14s14-6.3,14-14s-6.3-14-14-14S19,17.3,19,25z M39,25c0,3.3-2.7,6-6,6s-6-2.7-6-6s2.7-6,6-6   S39,21.7,39,25z" />
                      <polygon points="113,83 103,83 103,119 87,119 87,127 111,127 111,91 121,91 121,51 113,51  " />
                      <path d="M87,25c0,7.7,6.3,14,14,14s14-6.3,14-14s-6.3-14-14-14S87,17.3,87,25z M101,19c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6   S97.7,19,101,19z" />
                      <polygon points="31,83 21,83 21,51 13,51 13,91 23,91 23,127 47,127 47,119 31,119  " />
                    </g>
                  </SvgIcon>
                </Box>
                <Box justifyContent="flex-end" p={1} align="center">
                  <Typography variant="caption" display="block">
                    Total Encuestas
                  </Typography>
                  <Typography variant="button">
                    {this.props.campaniaAvances?.resumenEncuestas?.total}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4} md={2}>
              <Box component={Paper} p={1} boxShadow={4}>
                <PorcentajeView
                  titulo={`Activas(${this.props.campaniaAvances?.resumenEncuestas?.activas})`}
                  valor={this.obtenerPorcentaje(
                    this.props.campaniaAvances?.resumenEncuestas?.total,
                    this.props.campaniaAvances?.resumenEncuestas?.activas
                  )}
                  size={52}
                ></PorcentajeView>
              </Box>
            </Grid>

            <Grid item xs={4} md={2}>
              <Box component={Paper} p={1} boxShadow={4}>
                <PorcentajeView
                  titulo={`Vencidas(${this.props.campaniaAvances?.resumenEncuestas?.vencidas})`}
                  valor={this.obtenerPorcentaje(
                    this.props.campaniaAvances?.resumenEncuestas?.total,
                    this.props.campaniaAvances?.resumenEncuestas?.vencidas
                  )}
                  size={52}
                ></PorcentajeView>
              </Box>
            </Grid>

            <Grid item xs={6} md={2}>
              <Box display="flex" component={Paper} p={1} boxShadow={4}>
                <Box justifyContent="flex-start" alignSelf="center" p={0}>
                  <SvgIcon
                    viewBox="0 0 150 150"
                    style={{
                      width: 40,
                      height: 40,
                    }}
                  >
                    <g xmlns="http://www.w3.org/2000/svg">
                      <polygon points="91,45 83,45 83,73 73,73 73,119 61,119 61,73 51,73 51,45 43,45 43,81 53,81 53,127 81,127 81,81 91,81  " />
                      <path d="M53,15c0,7.7,6.3,14,14,14s14-6.3,14-14S74.7,1,67,1S53,7.3,53,15z M73,15c0,3.3-2.7,6-6,6s-6-2.7-6-6s2.7-6,6-6   S73,11.7,73,15z" />
                      <path d="M19,25c0,7.7,6.3,14,14,14s14-6.3,14-14s-6.3-14-14-14S19,17.3,19,25z M39,25c0,3.3-2.7,6-6,6s-6-2.7-6-6s2.7-6,6-6   S39,21.7,39,25z" />
                      <polygon points="113,83 103,83 103,119 87,119 87,127 111,127 111,91 121,91 121,51 113,51  " />
                      <path d="M87,25c0,7.7,6.3,14,14,14s14-6.3,14-14s-6.3-14-14-14S87,17.3,87,25z M101,19c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6   S97.7,19,101,19z" />
                      <polygon points="31,83 21,83 21,51 13,51 13,91 23,91 23,127 47,127 47,119 31,119  " />
                    </g>
                  </SvgIcon>
                </Box>
                <Box justifyContent="flex-end" p={1} align="center">
                  <Typography variant="caption" display="block">
                    Encuestas esperadas
                  </Typography>
                  <Typography variant="button">
                    {
                      this.props.campaniaAvances?.resumenEncuestas
                        ?.respuestasEsperadas
                    }
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* <Grid item xs={6} md={2}>
              <Box display="flex" component={Paper} p={1} boxShadow={4}>
                <Box justifyContent="flex-start" alignSelf="center" p={0}>
                  <SvgIcon
                    viewBox="0 0 150 150"
                    style={{
                      width: 40,
                      height: 40,
                    }}
                  >
                    <g xmlns="http://www.w3.org/2000/svg">
                      <polygon points="91,45 83,45 83,73 73,73 73,119 61,119 61,73 51,73 51,45 43,45 43,81 53,81 53,127 81,127 81,81 91,81  " />
                      <path d="M53,15c0,7.7,6.3,14,14,14s14-6.3,14-14S74.7,1,67,1S53,7.3,53,15z M73,15c0,3.3-2.7,6-6,6s-6-2.7-6-6s2.7-6,6-6   S73,11.7,73,15z" />
                      <path d="M19,25c0,7.7,6.3,14,14,14s14-6.3,14-14s-6.3-14-14-14S19,17.3,19,25z M39,25c0,3.3-2.7,6-6,6s-6-2.7-6-6s2.7-6,6-6   S39,21.7,39,25z" />
                      <polygon points="113,83 103,83 103,119 87,119 87,127 111,127 111,91 121,91 121,51 113,51  " />
                      <path d="M87,25c0,7.7,6.3,14,14,14s14-6.3,14-14s-6.3-14-14-14S87,17.3,87,25z M101,19c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6   S97.7,19,101,19z" />
                      <polygon points="31,83 21,83 21,51 13,51 13,91 23,91 23,127 47,127 47,119 31,119  " />
                    </g>
                  </SvgIcon>
                </Box>
                <Box justifyContent="flex-end" p={1} align="center">
                  <Typography variant="caption" display="block">
                    Respuestas Recibidas
                  </Typography>
                  <Typography variant="button">
                    {
                      this.props.campaniaAvances?.resumenEncuestas
                        ?.respuestasRecibidas
                    }
                  </Typography>
                </Box>
              </Box>
            </Grid> */}

            <Grid item xs={4} md={2}>
              <Box component={Paper} p={1} boxShadow={4}>
                <PorcentajeView
                  titulo={`Respuestas Recibidas(${this.props.campaniaAvances?.resumenEncuestas?.respuestasRecibidas})`}
                  valor={this.obtenerPorcentaje(
                    this.props.campaniaAvances?.resumenEncuestas
                      ?.respuestasEsperadas,
                    this.props.campaniaAvances?.resumenEncuestas
                      ?.respuestasRecibidas
                  )}
                  size={52}
                ></PorcentajeView>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    entidadCatalog: state.entidadState.entidadCatalog,
    municipioCatalog: state.municipioState.municipioCatalog,
    distritoFederalCatalog: state.distritoFederalState.distritoFederalCatalog,
    distritoLocalCatalog: state.distritoLocalState.distritoLocalCatalog,
    seccionCatalog: state.seccionState.seccionCatalog,
    campaniaCatalog: state.campaniaState.campaniaCatalog,
    clienteCatalog: state.customerState.clienteCatalog,
    campaniaAvances: state.avanceState.campaniaAvances,

    customerId: state.authState.user.customerId,
    campaniaId: state.authState.user.campaniaId,
  };
};

export default connect(mapStatetoProps, {
  getCatalogAction,
  getAllAction,
  clearAction,
})(withStyles(useStyles)(withSnackbar(Dashboard)));
