import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Dialog,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  FormControlLabel,
  Switch,
  FormLabel,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  ButtonGroup,
} from "@material-ui/core";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import Transition from "../tools/transition";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import { connect } from "react-redux";
import {
  createAction,
  clearAction,
  updateAction,
  getCatalogAction,
  deleteAction,
} from "../../actions/action-template";
import PreguntaDetail from "./pregunta-detail";
import ConfirmDialog from "../tools/confirm-dialog";
import AddBoxIcon from "@material-ui/icons/AddBox";
import EncuestaPreferencias from "./encuesta-preferencias";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
  formButton: {
    marginRight: "10px",
  },
  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  tableCell: {
    // backgroundColor: "gray",
    // color: theme.palette.common.white,
  },
});

class EncuestaDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      isOpenDialog: true,
      clienteId: this.props.customerId ?? "",
      campaniaId: this.props.campaniaId ?? 0,
      campania: "",
      id: 0,
      nombre: "",
      esPublica: false,
      esPublicada: false,
      inicioVigencia: "",
      finVigencia: "",
      preguntas: [],
      agentes: [],
      esDetallePregunta: false,
      preguntaEdicion: "",

      segmentacion: {
        id: 0,
        entidadId: 0,
        municipioId: 0,
        coloniaId: 0,
        seccionId: 0,
        esHombres: false,
        esMujeres: false,
        edadInicial: 0,
        edadFinal: 0,
      },

      preferencias: [],
      ambitoTerritorialCargado: false,

      isDeleteDialogOpen: false,
      selectedIdForDelete: "",

      titleConfirm: "Eliminar",
      textConfirm: "¿Realmente desea eliminar?",
    };
    this.props.getCatalogAction("getEntidadCatalogo");

    if (this.props.campaniaId) {
      this.props.getCatalogAction(
        "getCampaniaCatalogo",
        `clienteId=${this.props.customerId}&campaniaId=${this.props.campaniaId}`
      );
      this.props.getCatalogAction(
        "getAgenteCatalogo",
        `campaniaId=${this.props.campaniaId}`
      );
    } else if (this.props.customerId) {
      this.props.getCatalogAction(
        "getCampaniaCatalogo",
        `clienteId=${this.props.customerId}`
      );
    }
  }

  actualizarPreferencias = (preferencias) => {
    this.setState({ preferencias: preferencias });
  };

  showError = (value) => {
    return this.state.submitted && !value ? true : false;
  };

  handleChangeSegmentacion = (e) => {
    if (this.state.esPublicada) return;

    const { name, value, checked } = e.target;
    let segmentacion = this.state.segmentacion;
    segmentacion[name] = value;
    if (name === "esHombres" || name === "esMujeres")
      segmentacion[name] = checked;
    this.setState({ segmentacion: segmentacion }, () => {
      if (name === "entidadId") {
        this.props.getCatalogAction(
          "getMunicipioCatalogo",
          `entidadId=${this.state.segmentacion.entidadId}`
        );
      }
    });
  };

  establecerAmbitoTerritorial() {
    let campania = this.props.campaniaCatalog.find(
      (e) => parseInt(e.id) === parseInt(this.state.campaniaId)
    );
    let ambito = { ...this.state.segmentacion };

    if (campania) {
      ambito.entidadId = campania?.entidadId ?? 0;
      if (campania.ambitoTerritorialId === 5)
        ambito.municipioId = campania?.municipioId ?? 0;
      if (campania.ambitoTerritorialId === 6)
        ambito.coloniaId = campania?.coloniaId ?? 0;
      if (campania.ambitoTerritorialId === 4)
        ambito.seccionId = campania?.seccionId ?? 0;
    }
    this.setState(
      {
        ambitoTerritorialCargado: true,
        segmentacion: ambito,
        campania: campania,
      },
      () => {
        this.props.getCatalogAction(
          "getMunicipioCatalogo",
          `entidadId=${this.state.segmentacion.entidadId}`
        );
      }
    );
  }
  handleChange = (e) => {
    if (this.state.esPublicada) return;
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === "clienteId") {
        this.props.getCatalogAction(
          "getCampaniaCatalogo",
          `clienteId=${value}`
        );
      } else if (name === "campaniaId") {
        this.setState({ preferencias: [] });
        this.establecerAmbitoTerritorial(value);
        this.props.getCatalogAction("getAgenteCatalogo", `campaniaId=${value}`);
      }
    });
  };

  padStart(d) {
    if (d) return d.toString().padStart(2, "0");
    else return "";
  }

  confirmDelete = (r) => {
    if (this.state.esPublicada) return;

    let textConfirm = "";
    let titleConfirm = "";

    titleConfirm = "Eliminar Pregunta";
    textConfirm = "¿Realmente desea eliminar el registro?";
    if (r.id > 0) {
      this.setState({
        isDeleteDialogOpen: true,
        selectedIdForDelete: r.id,
        textConfirm: textConfirm,
        titleConfirm: titleConfirm,
      });
    } else {
      let preguntas = [...this.state.preguntas];
      let idx = 0;
      preguntas = preguntas.filter((e) => e.idx !== r.idx);
      preguntas = preguntas.map((e) => {
        return { ...e, idx: idx++ };
      });
      this.setState({ preguntas: preguntas });
      this.props.enqueueSnackbar("Los datos se guardaron correctamente", {
        variant: "success",
      });
    }
  };

  onDeleteDialogClose = (res) => {
    if (res) {
      this.props.deleteAction("deletePregunta", this.state.selectedIdForDelete);
    }
    this.setState({ isDeleteDialogOpen: false });
  };

  handlePublicar = (e) => {
    this.setState({ publicando: true }, () => {
      this.handleSubmit(e);
    });
  };
  handleSubmit = (e) => {
    const {
      nombre,
      esPublica,
      campaniaId,
      inicioVigencia,
      finVigencia,
      preguntas,
      agentes,
      segmentacion,
      publicando,
      preferencias,
    } = this.state;

    if (!campaniaId) {
      this.props.enqueueSnackbar("Debe asignar un cliente y campaña!", {
        variant: "warning",
      });
      return;
    }
    if (parseInt(segmentacion.edadInicial) > parseInt(segmentacion.edadFinal)) {
      this.props.enqueueSnackbar(
        "La edad inicial debe ser menor a la edad final!",
        {
          variant: "warning",
        }
      );
      return;
    }
    if (nombre && campaniaId && inicioVigencia) {
      if (+new Date(inicioVigencia) > +new Date(finVigencia)) {
        this.props.enqueueSnackbar(
          "El incio de la vigencia debe ser menor al fin de vigencia",
          {
            variant: "warning",
          }
        );
        return;
      }
      let _agentes = agentes.filter((a) => a.seleccionado);
      this.setState({ submitted: true });

      if (!this.state.id) {
        trackPromise(
          this.props.createAction("postEncuesta", {
            nombre,
            esPublica,
            campaniaId,
            inicioVigencia,
            finVigencia,
            preguntas,
            agentes: _agentes,
            segmentacion,
            esPublicada: publicando,
            preferencias,
          }),
          () => {
            this.setState({ submitted: false });
          }
        );
      } else {
        trackPromise(
          this.props.updateAction(
            "updateEncuesta",
            this.state.id,
            {
              id: this.state.id,
              nombre,
              esPublica,
              campaniaId,
              inicioVigencia,
              finVigencia,
              preguntas,
              agentes: _agentes,
              segmentacion,
              esPublicada: publicando,
              preferencias,
            },
            () => {
              this.setState({ submitted: true });
            }
          )
        );
      }
    } else {
      this.props.enqueueSnackbar("Debe ingresar los campos marcados con *", {
        variant: "warning",
      });
    }
  };

  estableceAgentes = () => {
    let agentes = this.state.agentes;
    let agentesEncuesta = this.state.agentesEncuesta;
    if (agentes?.length > 0 && agentesEncuesta?.length > 0) {
      let actualizarEstado = false;
      agentes = agentes.map((a) => {
        let activo =
          agentesEncuesta.find((e) => e.usuarioId === a.usuarioId) != null;
        a.seleccionado = activo;
        if (activo) actualizarEstado = true;
        return { ...a };
      });
      if (actualizarEstado) this.setState({ agentes: agentes });
    }
  };

  componentDidUpdate() {
    const {
      createEncuesta,
      createEncuestaError,
      encuesta,
      getEncuesta,
      getEncuestaError,
      updateEncuestaError,
      updateEncuesta,
      delPregunta,
      delPreguntaError,

      agenteCatalog,
    } = this.props;

    if (
      this.props.campaniaId &&
      !this.state.ambitoTerritorialCargado &&
      this.props.campaniaCatalog.length > 0 &&
      this.state.id === 0
    ) {
      this.establecerAmbitoTerritorial();
    }
    if (
      this.state.campaniaId &&
      agenteCatalog.length > 0 &&
      this.state.agentes.length === 0
    ) {
      let agents = agenteCatalog.map((a) => {
        return {
          ...a,
          usuarioId: a.value,
          encuestaId: this.state.id,
          seleccionado: false,
        };
      });
      this.setState({ agentes: agents }, () => this.estableceAgentes());
    }

    if (delPreguntaError) {
      this.props.enqueueSnackbar(delPreguntaError, {
        variant: "error",
      });
      this.props.clearAction("clearPregunta");
    } else if (delPregunta) {
      this.props.clearAction("clearPregunta");

      let preguntas = [...this.state.preguntas];
      let idx = 0;
      preguntas = preguntas.filter(
        (e) => e.id !== this.state.selectedIdForDelete
      );
      preguntas = preguntas.map((e) => {
        return { ...e, idx: idx++ };
      });

      if (this.state.selectedIdForDelete) {
        this.setState({ preguntas: preguntas, selectedIdForDelete: "" }, () => {
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        });
      }
    }

    if (createEncuestaError) {
      this.props.enqueueSnackbar(createEncuestaError, {
        variant: "error",
      });
      this.props.clearAction("clearEncuesta");
      this.setState({ submitted: false });
    } else if (createEncuesta) {
      this.props.clearAction("clearEncuesta");

      this.setState(
        {
          isOpenDialog: false,
          esPublicada: this.state.publicando,
        },
        () => {
          this.props.resultOp(encuesta);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (updateEncuestaError) {
      this.props.enqueueSnackbar(updateEncuestaError, {
        variant: "error",
      });
      this.props.clearAction("clearEncuesta");
      this.setState({ submitted: false });
    } else if (updateEncuesta) {
      this.props.clearAction("clearEncuesta");

      this.setState(
        {
          isOpenDialog: false,
          esPublicada: this.state.publicando,
        },
        () => {
          this.props.resultOp(encuesta);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (getEncuestaError) {
      this.props.enqueueSnackbar(getEncuestaError, {
        variant: "error",
      });
      this.props.clearAction("clearEncuesta");
    } else if (getEncuesta) {
      this.props.clearAction("clearEncuesta");
      let idx = 0;
      this.setState(
        {
          nombre: encuesta.nombre,
          inicioVigencia: this.getFormatDate(encuesta.inicioVigencia),
          finVigencia: this.getFormatDate(encuesta.finVigencia),
          campaniaId: encuesta.campaniaId,
          esPublica: encuesta.esPublica,
          esPublicada: encuesta.esPublicada,
          id: encuesta.id,
          preguntas: encuesta.preguntas.map((p) => {
            return { ...p, idx: idx++ };
          }),
          agentesEncuesta: encuesta.agentes,
          segmentacion: {
            id: encuesta.segmentacion?.id ?? 0,
            entidadId: encuesta.segmentacion?.entidadId ?? 0,
            municipioId: encuesta.segmentacion?.municipioId ?? 0,
            coloniaId: encuesta.segmentacion?.coloniaId ?? 0,
            seccionId: encuesta.segmentacion?.seccionId ?? 0,
            esHombres: encuesta.segmentacion?.esHombres ?? false,
            esMujeres: encuesta.segmentacion?.esMujeres ?? false,
            edadInicial: encuesta.segmentacion?.edadInicial ?? 0,
            edadFinal: encuesta.segmentacion?.edadFinal ?? 0,
            encuestaId: encuesta.segmentacion?.encuestaId ?? 0,
          },
          preferencias: encuesta.preferencias,
        },
        () => {
          if (this.state.agentes.length === 0)
            this.props.getCatalogAction(
              "getAgenteCatalogo",
              `campaniaId=${this.state.campaniaId}`
            );
          else this.estableceAgentes();
          if (this.state.segmentacion?.entidadId > 0) {
            this.props.getCatalogAction(
              "getMunicipioCatalogo",
              `entidadId=${this.state.segmentacion.entidadId}`
            );
          }
        }
      );
    }
  }

  getFormatDate = (date) => {
    let d = new Date(date);
    if (d !== "-") {
      return (
        d.getFullYear() +
        "-" +
        this.padStart(d.getMonth() + 1) +
        "-" +
        this.padStart(d.getDate())
      );
    } else return d;
  };

  handleCheckChange = (event) => {
    if (this.state.esPublicada) return;

    this.setState({ [event.target.name]: event.target.checked });
  };

  resultPregunta = (e) => {
    if (e) {
      let preguntas = this.state.preguntas;
      if (e.id > 0) {
        let preg = preguntas.find((p) => parseInt(e.id) === parseInt(p.id));
        preg.nombre = e.nombre;
        preg.esRespuestaMultiple = e.esRespuestaMultiple;
        preg.estatus = e.estatus;
        preg.tipoPreguntaId = e.tipoPreguntaId;
        preg.opciones = e.opciones;
      } else {
        e.idx = preguntas.length;
        preguntas.push(e);
      }
      this.setState({
        preguntas: preguntas,
        esDetallePregunta: false,
        preguntaEdicion: "",
      });
    } else this.setState({ esDetallePregunta: false, preguntaEdicion: "" });
  };

  handleChangeRow = (i, e) => {
    if (this.state.esPublicada) return;

    const { name, value, checked } = e.target;

    let preguntas = [...this.state.preguntas];
    let item = preguntas[i];
    if (name === "estatus") item[name] = checked;
    else item[name] = value;

    this.setState({ preguntas: preguntas });
  };

  handleChangeRowAgentes = (val, e) => {
    if (this.state.esPublicada) return;

    let agentes = [...this.state.agentes];
    let item = agentes.find((a) => a.value === val);
    item.seleccionado = true;

    this.setState({ agentes: agentes });
  };

  getTipoPregunta(r) {
    let tipo = this.props.tipoPreguntaCatalog.find(
      (e) => parseInt(e.value) === parseInt(r.tipoPreguntaId)
    );
    if (tipo) return tipo.name;
    else return "";
  }

  handleChangeNumber = (e) => {
    if (this.state.esPublicada) return;

    const { name, value } = e.target;
    let segmentacion = this.state.segmentacion;

    let number = parseInt(value);
    number = Number.isNaN(number) ? "" : value;
    segmentacion[name] = number;
    this.setState({ segmentacion: segmentacion });
  };

  renderContent() {
    const {
      nombre,
      esPublica,
      campaniaId,
      inicioVigencia,
      finVigencia,
      clienteId,
      segmentacion,
    } = this.state;
    const { classes } = this.props;

    return (
      <Grid container>
        {!this.props.customerId && !this.state.id && (
          <Grid item xs={12} md={3}>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="lblCliente">Cliente</InputLabel>
              <Select
                labelId="lblCliente"
                value={clienteId}
                name="clienteId"
                onChange={this.handleChange}
              >
                {this.props.clienteCatalog.map((p) => {
                  return (
                    <MenuItem key={p.value} value={p.value}>
                      {p.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
        {!this.props.campaniaId && !this.state.id && (
          <Grid item xs={12} md={3}>
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="lblCampania">Campaña</InputLabel>
              <Select
                labelId="lblCampania"
                value={campaniaId}
                name="campaniaId"
                onChange={this.handleChange}
              >
                {this.props.campaniaCatalog.map((p) => {
                  return (
                    <MenuItem key={p.id} value={p.id}>
                      {p.nombre}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.formControl}
            error={this.showError(nombre)}
            required
            label="Nombre"
            name="nombre"
            autoFocus
            value={nombre}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl component="fieldset" style={{ marginTop: 10 }}>
            <FormLabel component="legend">
              <Typography variant="caption">Tipo encuesta</Typography>
            </FormLabel>
            <FormControlLabel
              control={
                <Switch
                  checked={esPublica}
                  onChange={this.handleCheckChange}
                  name="esPublica"
                />
              }
              label={<Typography variant="caption">Solo Campaña</Typography>}
            />
          </FormControl>
        </Grid>

        {this.props.campaniaId && <Grid item xs={12} md={3}></Grid>}
        <Grid item xs={6} md={3}>
          <TextField
            className={classes.formControl}
            error={this.showError(inicioVigencia)}
            required
            type="date"
            label="Vigencia Inicio"
            name="inicioVigencia"
            value={inicioVigencia}
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            className={classes.formControl}
            error={this.showError(finVigencia)}
            required
            type="date"
            label="Vigencia Fin"
            name="finVigencia"
            value={finVigencia}
            onChange={this.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box my={3}>
            <Divider></Divider>
          </Box>
        </Grid>
        {!this.state.esDetallePregunta && (
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography variant="button" color="primary">
                Preguntas
              </Typography>
              <Button
                onClick={() => {
                  this.setState({ esDetallePregunta: true });
                }}
              >
                <AddBoxIcon></AddBoxIcon>
                Agregar
              </Button>
            </Box>
          </Grid>
        )}
        {!this.state.esDetallePregunta && this.state.preguntas.length > 0 && (
          <Grid item xs={12}>
            <Box mb={2} boxShadow={2}>
              <TableContainer>
                <Table size="small" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        Pregunta
                      </TableCell>
                      <TableCell className={classes.tableCell}>Tipo</TableCell>
                      <TableCell className={classes.tableCell}>
                        Activa
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        align="right"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.preguntas.map((row) => {
                      return (
                        <TableRow key={row.idx}>
                          <TableCell>{row.nombre}</TableCell>
                          <TableCell>{this.getTipoPregunta(row)}</TableCell>
                          <TableCell>
                            <Switch
                              checked={row.estatus}
                              onChange={(e) => {
                                this.handleChangeRow(row.idx, e);
                              }}
                              name="estatus"
                            />
                          </TableCell>
                          <TableCell align="right">
                            <ButtonGroup
                              size="small"
                              aria-label="small outlined button group"
                            >
                              <Button
                                color="secondary"
                                title="Eliminar"
                                onClick={() => {
                                  this.confirmDelete(row);
                                }}
                              >
                                <CancelIcon></CancelIcon>
                              </Button>
                              <Button
                                color="primary"
                                title="Editar"
                                onClick={() => {
                                  this.setState({
                                    esDetallePregunta: true,
                                    preguntaEdicion: row,
                                  });
                                }}
                              >
                                <EditIcon></EditIcon>
                              </Button>
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        )}
        {this.state.esDetallePregunta && (
          <Grid item xs={12}>
            <PreguntaDetail
              resultPregunta={this.resultPregunta}
              encuestaId={this.state.id}
              preguntaEdicion={this.state.preguntaEdicion}
              esEditable={!this.state.esPublicada}
            ></PreguntaDetail>
          </Grid>
        )}
        {this.state.preguntas.length === 0 && (
          <Grid item xs={12}>
            <Box my={3}>
              <Divider></Divider>
            </Box>
          </Grid>
        )}

        {!esPublica && !this.state.esDetallePregunta && (
          <Grid item xs={12}>
            <Typography variant="button" color="primary">
              Agentes
            </Typography>
          </Grid>
        )}
        {!esPublica &&
          !this.state.esDetallePregunta &&
          this.state.agentes.map((a) => {
            return (
              <Grid item xs={12} key={a.value}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={a.seleccionado}
                        onChange={(e) => {
                          this.handleChangeRowAgentes(a.value, e);
                        }}
                        name="seleccionado"
                      />
                    }
                    label={<Typography variant="overline">{a.name}</Typography>}
                  />
                </FormControl>
              </Grid>
            );
          })}
        {!esPublica && !this.state.esDetallePregunta && (
          <Grid item xs={12}>
            <Box my={3}>
              <Divider></Divider>
            </Box>
          </Grid>
        )}
        {!this.state.esDetallePregunta && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="button" color="primary">
                  Segmentación
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl
                  size="small"
                  className={classes.formControl}
                  // error={this.showError(segmentacion?.entidadId)}
                  // required
                  disabled={true}
                >
                  <InputLabel id="lblEntidad">Entidad</InputLabel>
                  <Select
                    labelId="lblEntidad"
                    id="entidadId"
                    value={segmentacion?.entidadId}
                    name="entidadId"
                    onChange={this.handleChangeSegmentacion}
                  >
                    {this.props.entidadCatalog.map((p) => {
                      return (
                        <MenuItem key={p.value} value={p.value}>
                          {p.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl
                  size="small"
                  className={classes.formControl}
                  // error={this.showError(segmentacion?.municipioId)}
                  // required
                  disabled={this.state.campania?.ambitoTerritorialId === 5}
                >
                  <InputLabel id="lblMunicipio">Municipio</InputLabel>
                  <Select
                    labelId="lblMunicipio"
                    id="municipioId"
                    value={segmentacion?.municipioId}
                    name="municipioId"
                    onChange={this.handleChangeSegmentacion}
                  >
                    {this.props.municipioCatalog?.map((p) => {
                      return (
                        <MenuItem key={p.value} value={p.value}>
                          {p.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  className={classes.formControl}
                  // error={this.showError(segmentacion?.seccionId)}
                  type="text"
                  label="Sección"
                  name="seccionId"
                  value={segmentacion?.seccionId}
                  onChange={this.handleChangeNumber}
                  inputProps={{ maxLength: 4 }}
                  disabled={this.state.campania?.ambitoTerritorialId === 4}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl style={{ marginTop: 20 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={segmentacion?.esHombres}
                        onChange={this.handleChangeSegmentacion}
                        name="esHombres"
                      />
                    }
                    label={<Typography variant="caption">Hombres</Typography>}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl style={{ marginTop: 20 }}>
                  <FormLabel component="legend">
                    <Typography variant="caption"></Typography>
                  </FormLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={segmentacion?.esMujeres}
                        onChange={this.handleChangeSegmentacion}
                        name="esMujeres"
                      />
                    }
                    label={<Typography variant="caption">Mujeres</Typography>}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  className={classes.formControl}
                  label="Edad Inicial"
                  name="edadInicial"
                  autoFocus
                  value={segmentacion?.edadInicial}
                  onChange={this.handleChangeNumber}
                  inputProps={{ maxLength: 3 }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  className={classes.formControl}
                  label="Edad Final"
                  name="edadFinal"
                  autoFocus
                  value={segmentacion?.edadFinal}
                  onChange={this.handleChangeNumber}
                  inputProps={{ maxLength: 3 }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          // style={{
          //   visibility: !this.state.esDetallePregunta ? "visible" : "hidden",
          // }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="button" color="primary">
                Preferencias
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <EncuestaPreferencias
                campaniaId={this.state.campaniaId}
                actualizarPreferencias={this.actualizarPreferencias}
                misRespuestas={this.state.preferencias}
              ></EncuestaPreferencias>
            </Grid>
          </Grid>
        </Grid>

        {isBrowser && !this.state.esDetallePregunta && (
          <Grid item xs={12}>
            <Box align="right" pt={4}>
              <Button
                className={classes.formButton}
                variant="contained"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
              >
                Cancelar
              </Button>

              {!this.state.esPublicada && (
                <Button
                  disabled={this.state.submitted}
                  className={classes.formButton}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  Guardar
                </Button>
              )}
              {!this.state.esPublicada && (
                <Button
                  disabled={this.state.submitted}
                  className={classes.formButton}
                  variant="outlined"
                  onClick={() => {
                    this.handlePublicar();
                  }}
                >
                  Publicar
                </Button>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    );
  }

  renderBrowser() {
    return (
      <Box p={5} component={Paper}>
        <Typography variant="h4">Detalle Encuesta</Typography>
        {this.renderContent()}
      </Box>
    );
  }

  renderMobile() {
    const { classes } = this.props;
    return (
      <Box>
        <Dialog
          fullScreen
          open={this.state.isOpenDialog}
          onClose={() => this.setState({ isOpenDialog: false })}
          TransitionComponent={Transition}
          scroll="body"
          style={{ paddingTop: 40 }}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Detalle Encuesta
              </Typography>
            </Toolbar>
          </AppBar>
          <Box py={3} px={1}>
            {this.renderContent()}
            <div style={{ marginBottom: 100 }}></div>
          </Box>
          <AppBar
            position="fixed"
            color="primary"
            className={classes.appBottomBar}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  cancelar
                </Typography>
              </IconButton>
              <div className={classes.grow} />
              {!this.state.esPublicada && (
                <IconButton
                  disabled={this.state.submitted}
                  edge="end"
                  color="inherit"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                  aria-label="close"
                >
                  <Typography variant="button" display="block" gutterBottom>
                    Guardar
                  </Typography>
                </IconButton>
              )}
              {!this.state.esPublicada && (
                <IconButton
                  disabled={this.state.submitted}
                  edge="end"
                  color="inherit"
                  onClick={() => {
                    this.handlePublicar();
                  }}
                  aria-label="close"
                >
                  <Typography variant="button" display="block" gutterBottom>
                    Publicar
                  </Typography>
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
        </Dialog>
      </Box>
    );
  }

  render() {
    return (
      <Box>
        <BrowserView>{this.renderBrowser()}</BrowserView>
        <MobileView>{this.renderMobile()}</MobileView>
        <ConfirmDialog
          isDeleteDialogOpen={this.state.isDeleteDialogOpen}
          onDeleteDialogClose={this.onDeleteDialogClose}
          title={this.state.titleConfirm}
          message={this.state.textConfirm}
        ></ConfirmDialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    createEncuesta: state.encuestaState.createEncuesta,
    createEncuestaError: state.encuestaState.createEncuestaError,
    encuesta: state.encuestaState.encuesta,

    getEncuesta: state.encuestaState.getEncuesta,
    getEncuestaError: state.encuestaState.getEncuestaError,

    updateEncuesta: state.encuestaState.updateEncuesta,
    updateEncuestaError: state.encuestaState.updateEncuestaError,

    tipoPreguntaCatalog: state.tipoPreguntaState.tipoPreguntaCatalog,

    campaniaCatalog: state.campaniaState.campaniaCatalog,
    generoCatalog: state.generoState.generoCatalog,

    clienteCatalog: state.customerState.clienteCatalog,
    campaniaAvances: state.avanceState.campaniaAvances,

    delPregunta: state.preguntaState.delPregunta,
    delPreguntaError: state.preguntaState.delPreguntaError,

    customerId: state.authState.user.customerId,
    campaniaId: state.authState.user.campaniaId,

    agenteCatalog: state.agenteState.agenteCatalog,
    entidadCatalog: state.entidadState.entidadCatalog,
    municipioCatalog: state.municipioState.municipioCatalog,
    seccionCatalog: state.seccionState.seccionCatalog,
  };
};

export default connect(mapStatetoProps, {
  createAction,
  clearAction,
  updateAction,
  deleteAction,
  getCatalogAction,
})(withStyles(useStyles)(withSnackbar(EncuestaDetail)));
