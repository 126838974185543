import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Dialog,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import Transition from "../../tools/transition";
import {
  createAction,
  clearAction,
  updateAction,
  getCatalogAction,
} from "../../../actions/action-template";
import { connect } from "react-redux";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import AmbitosTerritoriales from "../../tools/ambitos-territoriales";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
  formButton: {
    marginRight: "10px",
  },
  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
});

class CampaniaList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      isOpenDialog: true,

      nombre: "",
      inicioVigencia: "",
      finVigencia: "",
      ambitoTerritorialId: "",
      entidadId: "",
      distritoFederalId: "",
      distritoLocalId: "",
      municipioId: "",
      seccionId: "",
      id: "",
    };
  }

  showError = (value) => {
    return this.state.submitted && !value ? true : false;
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value }, () => {
      if (
        name === "ambitoTerritorialId" ||
        name === "entidadId" ||
        name === "distritoFederalId" ||
        name === "seccionId" ||
        name === "municipioId"
      )
        this.configuraAmbitoTerritorial(name);
    });
  };

  configuraAmbitoTerritorial(name) {
    let {
      ambitoTerritorialId,
      entidadId,
      municipioId,
      distritoFederalId,
      distritoLocalId,
      seccionId,
    } = this.state;
    if (
      AmbitosTerritoriales.DistritoFederal === parseInt(ambitoTerritorialId)
    ) {
      distritoLocalId = "";
      municipioId = "";
      seccionId = "";
      if (name === "entidadId" || name === "ambitoTerritorialId") {
        this.props.getCatalogAction(
          "getDistritoFederalCatalogo",
          `entidadId=${entidadId}`
        );
      }
    } else if (
      AmbitosTerritoriales.DistritoLocal === parseInt(ambitoTerritorialId)
    ) {
      distritoFederalId = "";
      municipioId = "";
      seccionId = "";
      this.props.getCatalogAction(
        "getDistritoLocalCatalogo",
        `entidadId=${entidadId}`
      );
    } else if (AmbitosTerritoriales.Seccion === parseInt(ambitoTerritorialId)) {
      distritoFederalId = "";
      distritoLocalId = "";
      municipioId = "";
      this.props.getCatalogAction(
        "getSeccionCatalogo",
        `entidadId=${entidadId}`
      );
    } else if (
      AmbitosTerritoriales.Municipio === parseInt(ambitoTerritorialId)
    ) {
      distritoFederalId = "";
      distritoLocalId = "";
      seccionId = "";

      if (name === "entidadId" || name === "ambitoTerritorialId")
        this.props.getCatalogAction(
          "getMunicipioCatalogo",
          `entidadId=${entidadId}`
        );
    }

    this.setState({
      municipioId,
      distritoFederalId,
      distritoLocalId,
      seccionId,
    });
  }

  padStart(d) {
    return d.toString().padStart(2, "0");
  }

  getFormatDate = (date) => {
    let d = new Date(date);
    if (d !== "-") {
      return (
        d.getFullYear() +
        "-" +
        this.padStart(d.getMonth() + 1) +
        "-" +
        this.padStart(d.getDate())
      );
    } else return d;
  };

  validaAmbitoTerritorial(d) {
    if (d.ambitoTerritorialId === 1 && !d.entidadId > 0) return false;
    if (d.ambitoTerritorialId === 2 && !d.distritoFederalId > 0) return false;
    if (d.ambitoTerritorialId === 3 && !d.distritoLocalId > 0) return false;
    if (d.ambitoTerritorialId === 4 && !d.seccionId > 0) return false;
    if (d.ambitoTerritorialId === 5 && !d.municipioId > 0) return false;
    else {
      return true;
    }
  }

  handleSubmit = (e) => {
    const {
      nombre,
      inicioVigencia,
      finVigencia,
      ambitoTerritorialId,
      entidadId,
      distritoFederalId,
      distritoLocalId,
      municipioId,
      seccionId,
    } = this.state;
    if (
      nombre &&
      inicioVigencia &&
      finVigencia &&
      ambitoTerritorialId &&
      this.validaAmbitoTerritorial({
        ambitoTerritorialId,
        distritoFederalId,
        distritoLocalId,
        municipioId,
        seccionId,
        entidadId,
      })
    ) {
      if (+new Date(inicioVigencia) > +new Date(finVigencia)) {
        this.props.enqueueSnackbar(
          "El incio de la vigencia debe ser menor al fin de vigencia",
          {
            variant: "warning",
          }
        );
        return;
      }
      this.props.enqueueSnackbar(
        "Guardando datos, esta operación puede tardar en completarse!",
        {
          variant: "warning",
        }
      );
      this.setState({ submitted: true });

      if (!this.state.id) {
        trackPromise(
          this.props.createAction("postCampania", {
            nombre,
            inicioVigencia,
            finVigencia,
            ambitoTerritorialId,
            entidadId: entidadId !== "" ? entidadId : 0,
            distritoFederalId: distritoFederalId !== "" ? distritoFederalId : 0,
            distritoLocalId: distritoLocalId !== "" ? distritoLocalId : 0,
            municipioId: municipioId !== "" ? municipioId : 0,
            seccionId: seccionId !== "" ? seccionId : 0,
            clienteId: this.props.clienteId,
          })
        );
      } else {
        trackPromise(
          this.props.updateAction("updateCampania", this.state.id, {
            id: this.state.id,
            nombre,
            inicioVigencia,
            finVigencia,
            ambitoTerritorialId,
            entidadId: entidadId !== "" ? entidadId : 0,
            distritoFederalId: distritoFederalId !== "" ? distritoFederalId : 0,
            distritoLocalId: distritoLocalId !== "" ? distritoLocalId : 0,
            municipioId: municipioId !== "" ? municipioId : 0,
            seccionId: seccionId !== "" ? seccionId : 0,
            clienteId: this.state.clienteId,
          })
        );
      }
    } else {
      this.props.enqueueSnackbar("Debe ingresar los campos marcados con *", {
        variant: "warning",
      });
    }
  };

  componentDidUpdate() {
    const {
      createCampania,
      createCampaniaError,
      campania,
      getCampania,
      getCampaniaError,
      updateCampaniaError,
      updateCampania,
    } = this.props;
    if (createCampaniaError) {
      this.props.enqueueSnackbar(createCampaniaError, {
        variant: "error",
      });
      this.props.clearAction("clearCampania");
      this.setState({ submitted: false });
    } else if (createCampania) {
      this.props.clearAction("clearCampania");

      this.setState(
        {
          isOpenDialog: false,
          submitted: false,
        },
        () => {
          this.props.resultOp(1);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (updateCampaniaError) {
      this.props.enqueueSnackbar(updateCampaniaError, {
        variant: "error",
      });
      this.props.clearAction("clearCampania");
      this.setState({ submitted: false });
    } else if (updateCampania) {
      this.props.clearAction("clearCampania");

      this.setState(
        {
          isOpenDialog: false,
          submitted: false,
        },
        () => {
          this.props.resultOp(1);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (getCampaniaError) {
      this.props.enqueueSnackbar(getCampaniaError, {
        variant: "error",
      });
      this.props.clearAction("clearCampania");
    } else if (getCampania) {
      this.props.clearAction("clearCampania");
      this.setState(
        {
          id: campania.id,
          nombre: campania.nombre,
          inicioVigencia: this.getFormatDate(campania.inicioVigencia),
          finVigencia: this.getFormatDate(campania.finVigencia),
          ambitoTerritorialId: campania.ambitoTerritorialId,
          clienteId: campania.clienteId,
          entidadId: campania.entidadId,
          distritoFederalId: campania.distritoFederalId,
          distritoLocalId: campania.distritoLocalId,
          seccionId: campania.seccionId,
          municipioId: campania.municipioId,
        },
        () => {
          this.configuraAmbitoTerritorial("entidadId");
        }
      );
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  renderContent() {
    const {
      nombre,
      inicioVigencia,
      finVigencia,
      ambitoTerritorialId,
      entidadId,
      distritoFederalId,
      distritoLocalId,
      seccionId,
      municipioId,
    } = this.state;
    const { classes } = this.props;

    return (
      <Box mt={2}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <TextField
              className={classes.formControl}
              error={this.showError(nombre)}
              required
              label="Nombre"
              name="nombre"
              autoFocus
              value={nombre}
              onChange={this.handleChange}
              inputProps={{ maxLength: 512 }}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              className={classes.formControl}
              error={this.showError(inicioVigencia)}
              required
              type="date"
              label="Vigencia Inicio"
              name="inicioVigencia"
              value={inicioVigencia}
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              className={classes.formControl}
              error={this.showError(finVigencia)}
              required
              type="date"
              label="Vigencia Fin"
              name="finVigencia"
              value={finVigencia}
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={6} md={4}>
            <FormControl
              size="small"
              error={this.showError(ambitoTerritorialId)}
              required
              className={classes.formControl}
            >
              <InputLabel id="lblAmbito">Ambito Territorial</InputLabel>
              <Select
                labelId="lblAmbito"
                id="ambitoTerritorialId"
                value={ambitoTerritorialId}
                name="ambitoTerritorialId"
                onChange={this.handleChange}
              >
                {this.props.ambitoTerritorialCatalog.map((p) => {
                  return (
                    <MenuItem key={p.value} value={p.value}>
                      {p.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl
              size="small"
              className={classes.formControl}
              error={this.showError(entidadId)}
              required
            >
              <InputLabel id="lblEntidad">Entidad</InputLabel>
              <Select
                labelId="lblEntidad"
                id="entidadId"
                value={entidadId}
                name="entidadId"
                onChange={this.handleChange}
              >
                {this.props.entidadCatalog.map((p) => {
                  return (
                    <MenuItem key={p.value} value={p.value}>
                      {p.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          {AmbitosTerritoriales.DistritoFederal ===
            parseInt(ambitoTerritorialId) && (
            <Grid item xs={12} md={4}>
              <FormControl
                size="small"
                className={classes.formControl}
                error={this.showError(distritoFederalId)}
                required
              >
                <InputLabel id="lblDistritoFederal">
                  Distrito federal
                </InputLabel>
                <Select
                  labelId="lblDistritoFederal"
                  id="distritoFederalId"
                  value={distritoFederalId}
                  name="distritoFederalId"
                  onChange={this.handleChange}
                >
                  {this.props.distritoFederalCatalog.map((p) => {
                    return (
                      <MenuItem key={p.value} value={p.value}>
                        {p.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}

          {AmbitosTerritoriales.DistritoLocal ===
            parseInt(ambitoTerritorialId) && (
            <Grid item xs={12} md={4}>
              <FormControl
                size="small"
                className={classes.formControl}
                error={this.showError(distritoLocalId)}
                required
              >
                <InputLabel id="lblDistritoLocal">Distrito Local</InputLabel>
                <Select
                  labelId="lblDistritoLocal"
                  id="distritoLocalId"
                  value={distritoLocalId}
                  name="distritoLocalId"
                  onChange={this.handleChange}
                >
                  {this.props.distritoLocalCatalog.map((p) => {
                    return (
                      <MenuItem key={p.value} value={p.value}>
                        {p.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
          {AmbitosTerritoriales.Seccion === parseInt(ambitoTerritorialId) && (
            <Grid item xs={12} md={4}>
              <FormControl
                size="small"
                className={classes.formControl}
                error={this.showError(seccionId)}
                required
              >
                <InputLabel id="lblSeccion">Sección</InputLabel>
                <Select
                  labelId="lblSeccion"
                  id="seccionId"
                  value={seccionId}
                  name="seccionId"
                  onChange={this.handleChange}
                >
                  {this.props.seccionCatalog.map((p) => {
                    return (
                      <MenuItem key={p.value} value={p.value}>
                        {p.name}
                      </MenuItem>
                      // <option value={p.value}>{p.name}</option>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}

          {AmbitosTerritoriales.Municipio === parseInt(ambitoTerritorialId) && (
            <Grid item xs={12} md={4}>
              <FormControl
                size="small"
                className={classes.formControl}
                error={this.showError(municipioId)}
                required
              >
                <InputLabel id="lblMunicipio">Municipio</InputLabel>
                <Select
                  labelId="lblMunicipio"
                  id="municipioId"
                  value={municipioId}
                  name="municipioId"
                  onChange={this.handleChange}
                >
                  {this.props.municipioCatalog.map((p) => {
                    return (
                      <MenuItem key={p.value} value={p.value}>
                        {p.name}
                      </MenuItem>
                      // <option value={p.value}>{p.name}</option>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
        {isBrowser && (
          <Box align="right" pt={4}>
            <Button
              disabled={this.state.submitted}
              className={classes.formButton}
              variant="contained"
              onClick={() => {
                this.setState({ isOpenDialog: false }, () => {
                  this.props.resultOp(1);
                });
              }}
            >
              <ClearIcon></ClearIcon>
            </Button>

            <Button
              disabled={this.state.submitted}
              className={classes.formButton}
              variant="contained"
              color="primary"
              onClick={() => {
                this.handleSubmit();
              }}
            >
              <CheckIcon></CheckIcon>
            </Button>
          </Box>
        )}
      </Box>
    );
  }

  renderBrowser() {
    return (
      <Box component={Paper} p={2}>
        {this.renderContent()}
      </Box>
    );
  }

  renderMobile() {
    const { classes } = this.props;
    return (
      <Box>
        <Dialog
          fullScreen
          open={this.state.isOpenDialog}
          onClose={() => this.setState({ isOpenDialog: false })}
          TransitionComponent={Transition}
          scroll="body"
          style={{ paddingTop: 40 }}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Detalle Cliente
              </Typography>
            </Toolbar>
          </AppBar>
          <Box py={3} px={1}>
            {this.renderContent()}
            <div style={{ marginBottom: 100 }}></div>
          </Box>
          <AppBar
            position="fixed"
            color="primary"
            className={classes.appBottomBar}
          >
            <Toolbar>
              <IconButton
                disabled={this.state.submitted}
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(1);
                  });
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  cancelar
                </Typography>
              </IconButton>
              <div className={classes.grow} />

              <IconButton
                disabled={this.state.submitted}
                edge="end"
                color="inherit"
                onClick={() => {
                  this.handleSubmit();
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  Guardar
                </Typography>
              </IconButton>
            </Toolbar>
          </AppBar>
        </Dialog>
      </Box>
    );
  }

  render() {
    return (
      <Box>
        <BrowserView>{this.renderBrowser()}</BrowserView>
        <MobileView>{this.renderMobile()}</MobileView>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    createCampania: state.campaniaState.createCampania,
    createCampaniaError: state.campaniaState.createCampaniaError,
    campania: state.campaniaState.campania,
    getCampania: state.campaniaState.getCampania,
    getCampaniaError: state.campaniaState.getCampaniaError,

    updateCampania: state.campaniaState.updateCampania,
    updateCampaniaError: state.campaniaState.updateCampaniaError,

    ambitoTerritorialCatalog:
      state.ambitoTerritorialState.ambitoTerritorialCatalog,

    entidadCatalog: state.entidadState.entidadCatalog,
    distritoFederalCatalog: state.distritoFederalState.distritoFederalCatalog,
    distritoLocalCatalog: state.distritoLocalState.distritoLocalCatalog,
    seccionCatalog: state.seccionState.seccionCatalog,
    municipioCatalog: state.municipioState.municipioCatalog,
  };
};

export default connect(mapStatetoProps, {
  createAction,
  clearAction,
  updateAction,
  getCatalogAction,
})(withStyles(useStyles)(withSnackbar(CampaniaList)));
