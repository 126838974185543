import React from "react";
import DistritoFederalList from "./distrito-federal-list";
import DistritoFederalDetail from "./distrito-federal-detail";

class DistritosFederales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listVisible: true,
    };
  }

  showDetailItem = () => {
    this.setState({ listVisible: false });
  };

  resultOp = (o) => {
    this.setState({ listVisible: true });
  };

  render() {
    const { listVisible } = this.state;
    if (listVisible)
      return (
        <DistritoFederalList
          showDetailItem={this.showDetailItem}
          updateItem={this.updateItem}
        ></DistritoFederalList>
      );
    else
      return (
        <DistritoFederalDetail resultOp={this.resultOp}></DistritoFederalDetail>
      );
  }
}

export default DistritosFederales;
