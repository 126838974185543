import React from "react";
import {
  Box,
  Grid,
  Typography,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  Paper,
  ButtonGroup,
  Button,
  TablePagination,
  TextField,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { connect } from "react-redux";

import ConfirmDialog from "../../tools/confirm-dialog";
import { withSnackbar } from "notistack";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import Ratings from "react-ratings-declarative";
import { CopyToClipboard } from "react-copy-to-clipboard";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import {
  getAllAction,
  deleteAction,
  clearAction,
  getAction,
  getCatalogAction,
} from "../../../actions/action-template";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import {
  recoveryPasswordAction,
  clearSendPassword,
} from "../../../actions/auth/auth-actions";
import { trackPromise } from "react-promise-tracker";
import MapIcon from "@material-ui/icons/Map";

let apiURL = process.env.REACT_APP_SERVER_URL;

const useStyles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
});

class UsuarioList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 0,
      pageSize: 10,
      nombreFiltro: "",
      apFiltro: "",
      amFiltro: "",
      isDeleteDialogOpen: false,
      selectedIdForDelete: "",

      titleConfirm: "Desactivar Usuario",
      textConfirm: "¿Realmente desea desactivar el usuario?",
      clienteId: this.props.clienteId,
    };
    this.props.getCatalogAction("getRolCatalogo");
    if (this.props.clienteId) {
      this.props.getCatalogAction(
        "getCampaniaCatalogo",
        `clienteId=${this.props.clienteId}`
      );
    }
    this.fetchList();
    this.props.getCatalogAction("getGeneroCatalogo");
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  fetchList() {
    const {
      pageNumber,
      pageSize,
      nombreFiltro,
      amFiltro,
      apFiltro,
      clienteId,
    } = this.state;
    let filtro = `nombre=${nombreFiltro}&ap=${apFiltro}&am=${amFiltro}&clienteId=${clienteId}`;
    trackPromise(
      this.props.getAllAction("getAllUsuario", pageNumber, pageSize, filtro)
    );
  }

  onDeleteDialogClose = (res) => {
    if (res) {
      let o = this.props.usuarios.find(
        (e) => e.id === this.state.selectedIdForDelete
      );
      this.props.deleteAction(
        "deleteUsuario",
        this.state.selectedIdForDelete,
        !o.estatus
      );
    }
    this.setState({ isDeleteDialogOpen: false, selectedIdForDelete: "" });
  };

  padStart(d) {
    return d.toString().padStart(2, "0");
  }

  getUTCDate = (date) => {
    let d = new Date(date);
    if (d !== "-") {
      return (
        this.padStart(d.getDate()) +
        "/" +
        this.padStart(d.getMonth() + 1) +
        "/" +
        d.getFullYear()
      );
    } else return d;
  };

  clearFilter = () => {
    this.setState(
      {
        nombreFiltro: "",
        apFiltro: "",
        amFiltro: "",
      },
      () => {
        this.fetchList();
      }
    );
  };

  confirmDelete = (r) => {
    let textConfirm = "";
    let titleConfirm = "";

    if (r.estatus) {
      titleConfirm = "Desactivar Usuario";
      textConfirm = "¿Realmente desea desactivar el usuario?";
    } else {
      titleConfirm = "Activar Usuario";
      textConfirm = "¿Realmente desea activar el usuario?";
    }
    this.setState({
      isDeleteDialogOpen: true,
      selectedIdForDelete: r.id,
      textConfirm: textConfirm,
      titleConfirm: titleConfirm,
    });
  };

  sendRecoveryPasswordMail = (u) => {
    trackPromise(this.props.recoveryPasswordAction(u.login));
  };
  abrirMapa(d) {
    window.open(
      `https://www.openstreetmap.org/?mlon=${d.longitud}&mlat=${d.latitud}#map=19/${d.latitud}/${d.longitud}`,
      "_blank"
    );
  }

  renderItems() {
    const { clienteId } = this.state;

    return this.props.usuarios.map((r) => {
      return (
        <TableRow key={r.id}>
          <TableCell>{r.nombreCompleto}</TableCell>
          <TableCell>{r.telefono}</TableCell>
          <TableCell>{r.login}</TableCell>
          <TableCell>{r.puntos}</TableCell>
          <TableCell>
            <Ratings
              rating={r.estrellas}
              widgetDimensions="8px"
              widgetSpacings="0px"
            >
              <Ratings.Widget widgetRatedColor="green" />
              <Ratings.Widget widgetRatedColor="green" />
              <Ratings.Widget widgetRatedColor="green" />
              <Ratings.Widget widgetRatedColor="green" />
              <Ratings.Widget widgetRatedColor="green" />
            </Ratings>
          </TableCell>

          <TableCell>{r.estatus ? "Activo" : "Inactivo"}</TableCell>
          <TableCell>
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button
                color="primary"
                title="Enviar correo Recuperar Contraseña"
                onClick={() => {
                  this.sendRecoveryPasswordMail(r);
                }}
              >
                <MailOutlineIcon></MailOutlineIcon>
              </Button>
              {clienteId && (
                <CopyToClipboard
                  text={`${apiURL}#/registro?c=${r.clienteId}&ca=${r.campaniaId}&u=${r.id}`}
                  onCopy={() =>
                    this.props.enqueueSnackbar(
                      "El enlace se copio correctamente!",
                      {
                        variant: "success",
                      }
                    )
                  }
                >
                  <Button color="primary" title="Copiar link auto registro">
                    <GroupAddIcon></GroupAddIcon>
                  </Button>
                </CopyToClipboard>
              )}
              {r.geoReferenciado && (
                <Button
                  color="primary"
                  title={"Ver en mapa"}
                  onClick={() => {
                    this.abrirMapa(r);
                  }}
                >
                  <MapIcon></MapIcon>
                </Button>
              )}
              <Button
                color="secondary"
                title={!r.estatus ? "Activar" : "Desactivar"}
                onClick={() => {
                  this.confirmDelete(r);
                }}
              >
                {!r.estatus ? (
                  <CheckCircleIcon></CheckCircleIcon>
                ) : (
                  <CancelIcon></CancelIcon>
                )}
              </Button>
              <Button
                color="primary"
                title="Editar"
                onClick={() => {
                  this.props.getAction("getUsuario", r.id);
                  this.props.showDetailItem();
                }}
              >
                <EditIcon></EditIcon>
              </Button>
            </ButtonGroup>
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { classes } = this.props;
    const { nombreFiltro, apFiltro, amFiltro, clienteId } = this.state;
    return (
      <Box mx={clienteId ? 0 : 2} mb={4}>
        {!clienteId && (
          <Typography variant="h2" gutterBottom>
            Usuarios
          </Typography>
        )}
        {!clienteId && (
          <Paper
            style={{
              marginBottom: 20,
              padding: 20,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  name="nombreFiltro"
                  label="Nombre"
                  value={nombreFiltro}
                  onChange={this.handleChange}
                  style={{ marginRight: 5 }}
                />
                <TextField
                  size="small"
                  name="apFiltro"
                  label="Apellido Paterno"
                  value={apFiltro}
                  onChange={this.handleChange}
                  style={{ marginRight: 5 }}
                />
                <TextField
                  size="small"
                  name="amFiltro"
                  label="Apellido Materno"
                  value={amFiltro}
                  onChange={this.handleChange}
                  style={{ marginRight: 5 }}
                />

                <ButtonGroup
                  color="primary"
                  aria-label="Acciones"
                  style={{ marginTop: 10, marginLeft: 10 }}
                  size="small"
                >
                  <Button
                    title="Filtrar"
                    color="primary"
                    onClick={() => {
                      this.fetchList();
                    }}
                  >
                    <SearchIcon />
                  </Button>
                  <Button
                    title="Limpiar filtros"
                    color="primary"
                    onClick={this.clearFilter}
                  >
                    <AutorenewIcon />
                  </Button>

                  <Button
                    title="Nuevo usuario"
                    color="primary"
                    onClick={this.props.showDetailItem}
                  >
                    <AddIcon />
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Paper>
        )}
        {clienteId && (
          <Box mb={4} align="right">
            <Box mb={2}>
              <Button
                title="Nuevo usuario"
                color="primary"
                onClick={this.props.showDetailItem}
              >
                <AddIcon /> Nuevo usuario
              </Button>
            </Box>
          </Box>
        )}
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Telefono</TableCell>
                <TableCell>Correo Electrónico</TableCell>
                <TableCell>Puntos</TableCell>
                <TableCell>Estrellas</TableCell>

                <TableCell>Estatus</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{this.renderItems()}</TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={this.props.totalList}
            rowsPerPage={this.state.pageSize}
            page={this.state.pageNumber}
            onChangePage={(e, newPage) => {
              this.setState({ pageNumber: newPage }, () => {
                this.fetchList();
              });
            }}
            onChangeRowsPerPage={(e) => {
              this.setState({ pageSize: parseInt(e.target.value, 10) }, () => {
                this.fetchList();
              });
            }}
          />
        </TableContainer>

        <ConfirmDialog
          isDeleteDialogOpen={this.state.isDeleteDialogOpen}
          onDeleteDialogClose={this.onDeleteDialogClose}
          title={this.state.titleConfirm}
          message={this.state.textConfirm}
        ></ConfirmDialog>
      </Box>
    );
  }

  componentDidUpdate() {
    const {
      delUsuario,
      delUsuarioError,
      recoveryPassword,
      recoveryPasswordError,
    } = this.props;

    if (delUsuarioError) {
      this.props.enqueueSnackbar(delUsuarioError, {
        variant: "error",
      });
      this.props.clearAction("clearUsuario");
    } else if (delUsuario) {
      this.props.clearAction("clearUsuario");
      this.fetchList();
      this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
        variant: "success",
      });
    }

    if (recoveryPasswordError) {
      this.props.enqueueSnackbar(recoveryPasswordError, {
        variant: "warning",
      });
      this.props.clearSendPassword();
    }
    if (recoveryPassword) {
      this.props.enqueueSnackbar(
        "Se ha enviado un correo electrónico para recuperar la contraseña!",
        {
          variant: "success",
        }
      );
      this.props.clearSendPassword();
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    usuarios: state.usuarioState.usuarios,
    totalList: state.usuarioState.totalList,

    delUsuario: state.usuarioState.delUsuario,
    delUsuarioError: state.usuarioState.delUsuarioError,
    rolCatalog: state.rolState.rolCatalog,

    recoveryPasswordError: state.authState.recoveryPasswordError,
    recoveryPassword: state.authState.recoveryPassword,
  };
};

export default connect(mapStatetoProps, {
  getAllAction,
  deleteAction,
  clearAction,
  getAction,
  getCatalogAction,
  recoveryPasswordAction,
  clearSendPassword,
})(withStyles(useStyles)(withSnackbar(UsuarioList)));
