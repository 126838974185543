import { createMuiTheme } from "@material-ui/core/styles";
import { esES } from "@material-ui/core/locale";

const defaultTheme = {
  palette: {
    primary: {
      main: "#0277bd",
      light: "#58a5f0",
      dark: "#004c8c",
      contrastText: "#FFFFFF",
    },
  },
};

const theme = createMuiTheme(defaultTheme, esES);

export default theme;
