const defaultState = {
  createRol: false,
  createRolError: "",
  rol: {},
  delRol: false,
  delRolError: "",
  getRolError: "",

  roles: [],
  rolCatalog: [],

  totalList: 0,
};

export const rolReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_ROLES":
      return {
        ...state,
        roles: action.payload.data,
        totalList: action.payload.totalRegs,
      };
    case "FETCH_ROL-CATALOG":
      return {
        ...state,
        rolCatalog: action.payload,
      };

    case "CREATE_ROL":
      return {
        ...state,
        rol: { ...action.payload },
        createRol: true,
      };
    case "CREATE_ROL_ERROR":
      return { ...state, createRolError: action.payload.error };
    case "UPDATE_ROL":
      return {
        ...state,
        rol: { ...action.payload },
        updateRol: true,
      };
    case "UPDATE_ROL_ERROR":
      return { ...state, updateRolError: action.payload.error };
    case "CLEAR_ROL":
      return {
        ...state,
        rol: {},
        createRol: false,
        createRolError: "",
        delRol: false,
        delRolError: "",
        getRol: false,
        getRolError: "",
        updateRol: false,
        updateRolError: "",
      };
    case "DEL_ROL":
      return { ...state, delRol: true };
    case "DEL_ROL_ERROR":
      return { ...state, delRolError: action.payload.error };
    case "GET_ROL":
      return { ...state, getRol: true, rol: { ...action.payload } };
    case "GET_ROL_ERROR":
      return { ...state, getRolError: action.payload.error };
    default:
      return {
        ...state,
      };
  }
};
