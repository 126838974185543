const defaultState = {
  createUsuario: false,
  createUsuarioError: "",
  usuario: {},
  delUsuario: false,
  delUsuarioError: "",
  getUsuarioError: "",

  usuarios: [],

  totalList: 0,
};

export const usuarioReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_USUARIOS":
      return {
        ...state,
        usuarios: action.payload.data,
        totalList: action.payload.totalRegs,
      };

    case "CREATE_USUARIO":
      return {
        ...state,
        usuario: { ...action.payload },
        createUsuario: true,
      };
    case "CREATE_USUARIO_ERROR":
      return { ...state, createUsuarioError: action.payload.error };
    case "UPDATE_USUARIO":
      return {
        ...state,
        usuario: { ...action.payload },
        updateUsuario: true,
      };
    case "UPDATE_USUARIO_ERROR":
      return { ...state, updateUsuarioError: action.payload.error };
    case "CLEAR_USUARIO":
      return {
        ...state,
        usuario: {},
        createUsuario: false,
        createUsuarioError: "",
        delUsuario: false,
        delUsuarioError: "",
        getUsuario: false,
        getUsuarioError: "",
        updateUsuario: false,
        updateUsuarioError: "",
      };
    case "DEL_USUARIO":
      return { ...state, delUsuario: true };
    case "DEL_USUARIO_ERROR":
      return { ...state, delUsuarioError: action.payload.error };
    case "GET_USUARIO":
      return { ...state, getUsuario: true, usuario: { ...action.payload } };
    case "GET_USUARIO_ERROR":
      return { ...state, getUsuarioError: action.payload.error };
    default:
      return {
        ...state,
      };
  }
};
