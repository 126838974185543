import React from "react";
import ColoniaList from "./colonia-list";
import ColoniaDetail from "./colonia-detail";

class Coloniaes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listVisible: true,
    };
  }

  showDetailItem = () => {
    this.setState({ listVisible: false });
  };

  resultOp = (o) => {
    this.setState({ listVisible: true });
  };

  render() {
    const { listVisible } = this.state;
    if (listVisible)
      return (
        <ColoniaList
          showDetailItem={this.showDetailItem}
          updateItem={this.updateItem}
        ></ColoniaList>
      );
    else return <ColoniaDetail resultOp={this.resultOp}></ColoniaDetail>;
  }
}

export default Coloniaes;
