import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SettingsIcon from "@material-ui/icons/SettingsApplications";
import UsersIcon from "@material-ui/icons/People";
import ContactsIcon from "@material-ui/icons/Contacts";
import LoginIcon from "@material-ui/icons/AccountBox";
import LogOutIcon from "@material-ui/icons/ExitToApp";
import { connect } from "react-redux";
import { logout } from "../../actions/auth/auth-actions";

import { withStyles } from "@material-ui/styles";
import MenuColors from "../../theme/menu-colors";
import ViewListIcon from "@material-ui/icons/ViewList";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import modulos from "../tools/modulos";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import BarChartIcon from "@material-ui/icons/BarChart";
import { Offline, Online } from "react-detect-offline";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import NotesIcon from "@material-ui/icons/Notes";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import MapIcon from "@material-ui/icons/Map";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import ListAltIcon from "@material-ui/icons/ListAlt";

const useStyles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

class LateralMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSecurity: false,
    };
  }

  hasAccess(moduloId) {
    let tieneAccceso =
      this.props.usuarioRolModulos?.find(
        (m) => m.ModuloId === moduloId && m.TieneAcceso === true
      ) != null;
    return tieneAccceso;
  }

  onLogout = (e) => {
    e.preventDefault();
    //this.props.onMenuClick();
    this.props.logout();
  };
  mostrarAministracion() {
    if (
      this.hasAccess(modulos.Entidades) ||
      this.hasAccess(modulos.DistritosFederales) ||
      this.hasAccess(modulos.Municipios) ||
      this.hasAccess(modulos.DistritosLocales) ||
      this.hasAccess(modulos.Secciones) ||
      this.hasAccess(modulos.Colonias) ||
      this.hasAccess(modulos.Roles) ||
      this.hasAccess(modulos.Preferencias) ||
      this.hasAccess(modulos.Clientes) ||
      this.hasAccess(modulos.Usuarios)
    )
      return true;
    else return false;
  }

  renderOnline() {
    const { classes } = this.props;

    return (
      <Box>
        <List>
          <ListItem
            button
            component={Link}
            to="/"
            onClick={this.props.onMenuClick}
          >
            <ListItemIcon>
              <HomeIcon style={{ color: MenuColors.contrastText }} />
            </ListItemIcon>
            <ListItemText primary="Inicio" />
          </ListItem>
          {this.props.loggedIn && this.hasAccess(modulos.Dashboard) && (
            <ListItem
              button
              component={Link}
              to="/dashboard"
              onClick={this.props.onMenuClick}
            >
              <ListItemIcon>
                <BarChartIcon style={{ color: MenuColors.contrastText }} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          )}

          {this.props.loggedIn && this.hasAccess(modulos.Territorio) && (
            <ListItem
              button
              component={Link}
              to="/territorio"
              onClick={this.props.onMenuClick}
            >
              <ListItemIcon>
                <MapIcon style={{ color: MenuColors.contrastText }} />
              </ListItemIcon>
              <ListItemText primary="Territorio" />
            </ListItem>
          )}
        </List>
        <Divider></Divider>
        {this.props.loggedIn && (
          <List>
            {this.hasAccess(modulos.Activados) && (
              <ListItem
                button
                component={Link}
                to="/activados"
                onClick={this.props.onMenuClick}
              >
                <ListItemIcon>
                  <PeopleAltIcon style={{ color: MenuColors.contrastText }} />
                </ListItemIcon>
                <ListItemText primary="Activados" />
              </ListItem>
            )}
            {this.hasAccess(modulos.Arbol) && (
              <ListItem
                button
                component={Link}
                to="/arbol"
                onClick={this.props.onMenuClick}
              >
                <ListItemIcon>
                  <AccountTreeIcon style={{ color: MenuColors.contrastText }} />
                </ListItemIcon>
                <ListItemText primary="Árbol" />
              </ListItem>
            )}

            {this.hasAccess(modulos.Encuestas) && (
              <ListItem
                button
                component={Link}
                to="/encuestas"
                onClick={this.props.onMenuClick}
              >
                <ListItemIcon>
                  <SpeakerNotesIcon
                    style={{ color: MenuColors.contrastText }}
                  />
                </ListItemIcon>
                <ListItemText primary="Encuestas" />
              </ListItem>
            )}
            {this.hasAccess(modulos.Posts) && (
              <ListItem
                button
                component={Link}
                to="/publicaciones"
                onClick={this.props.onMenuClick}
              >
                <ListItemIcon>
                  <NotesIcon style={{ color: MenuColors.contrastText }} />
                </ListItemIcon>
                <ListItemText primary="Publicaciones" />
              </ListItem>
            )}
            {this.mostrarAministracion() && (
              <ListItem
                button
                onClick={() => {
                  this.setState({ openSecurity: !this.state.openSecurity });
                }}
              >
                <ListItemIcon>
                  <SettingsIcon style={{ color: MenuColors.contrastText }} />
                </ListItemIcon>
                <ListItemText primary="Administración" />
                {this.state.openSecurity ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            )}
            <Collapse in={this.state.openSecurity} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {this.hasAccess(modulos.Entidades) && (
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to="/entidades"
                    onClick={this.props.onMenuClick}
                  >
                    <ListItemIcon>
                      <ViewListIcon
                        style={{ color: MenuColors.contrastText }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Entidades" />
                  </ListItem>
                )}
                {this.hasAccess(modulos.DistritosFederales) && (
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to="/distritosf"
                    onClick={this.props.onMenuClick}
                  >
                    <ListItemIcon>
                      <ViewListIcon
                        style={{ color: MenuColors.contrastText }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Distritos Fed." />
                  </ListItem>
                )}
                {this.hasAccess(modulos.DistritosLocales) && (
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to="/distritosl"
                    onClick={this.props.onMenuClick}
                  >
                    <ListItemIcon>
                      <ViewListIcon
                        style={{ color: MenuColors.contrastText }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Distritos Loc." />
                  </ListItem>
                )}
                {this.hasAccess(modulos.Municipios) && (
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to="/municipios"
                    onClick={this.props.onMenuClick}
                  >
                    <ListItemIcon>
                      <ViewListIcon
                        style={{ color: MenuColors.contrastText }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Municipios" />
                  </ListItem>
                )}

                {this.hasAccess(modulos.Secciones) && (
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to="/secciones"
                    onClick={this.props.onMenuClick}
                  >
                    <ListItemIcon>
                      <ViewListIcon
                        style={{ color: MenuColors.contrastText }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Secciones" />
                  </ListItem>
                )}

                {this.hasAccess(modulos.Colonias) && (
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to="/colonias"
                    onClick={this.props.onMenuClick}
                  >
                    <ListItemIcon>
                      <ViewListIcon
                        style={{ color: MenuColors.contrastText }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Colonias" />
                  </ListItem>
                )}
                <Divider></Divider>
                {this.hasAccess(modulos.Preferencias) && (
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to="/preferencias"
                    onClick={this.props.onMenuClick}
                  >
                    <ListItemIcon>
                      <LibraryAddCheckIcon
                        style={{ color: MenuColors.contrastText }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Preferencias" />
                  </ListItem>
                )}
                {this.hasAccess(modulos.Tareas) && (
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to="/tareas"
                    onClick={this.props.onMenuClick}
                  >
                    <ListItemIcon>
                      <ListAltIcon style={{ color: MenuColors.contrastText }} />
                    </ListItemIcon>
                    <ListItemText primary="Tareas" />
                  </ListItem>
                )}
                {this.hasAccess(modulos.Roles) && (
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to="/roles"
                    onClick={this.props.onMenuClick}
                  >
                    <ListItemIcon>
                      <PlaylistAddCheckIcon
                        style={{ color: MenuColors.contrastText }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Roles" />
                  </ListItem>
                )}
                {this.hasAccess(modulos.Clientes) && (
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to="/clientes"
                    onClick={this.props.onMenuClick}
                  >
                    <ListItemIcon>
                      <ContactsIcon
                        style={{ color: MenuColors.contrastText }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Clientes" />
                  </ListItem>
                )}
                {this.hasAccess(modulos.Usuarios) && (
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to="/usuarios"
                    onClick={this.props.onMenuClick}
                  >
                    <ListItemIcon>
                      <UsersIcon style={{ color: MenuColors.contrastText }} />
                    </ListItemIcon>
                    <ListItemText primary="Usuarios" />
                  </ListItem>
                )}
              </List>
            </Collapse>
          </List>
        )}

        {!this.props.loggedIn ? (
          <List>
            <ListItem
              button
              component={Link}
              to="/login"
              onClick={this.props.onMenuClick}
            >
              <ListItemIcon>
                <LoginIcon style={{ color: MenuColors.contrastText }} />
              </ListItemIcon>
              <ListItemText primary="Iniciar Sesión" />
            </ListItem>
          </List>
        ) : (
          <List>
            <ListItem button component={Link} onClick={this.onLogout} to="/">
              <ListItemIcon>
                <LogOutIcon style={{ color: MenuColors.contrastText }} />
              </ListItemIcon>
              <ListItemText primary="Cerrar sesión" />
            </ListItem>
          </List>
        )}
      </Box>
    );
  }

  renderOffline() {
    return (
      <Box>
        <List>
          <ListItem
            button
            component={Link}
            to="/activados"
            onClick={this.props.onMenuClick}
          >
            <ListItemIcon>
              <PeopleAltIcon style={{ color: MenuColors.contrastText }} />
            </ListItemIcon>
            <ListItemText primary="Activados" />
          </ListItem>
        </List>
      </Box>
    );
  }
  render() {
    return (
      <div>
        <Offline>{this.renderOffline()}</Offline>
        <Online>{this.renderOnline()}</Online>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    username: state.authState.user.username,
    loggedIn: state.authState.loggedIn,
    usuarioRolModulos: state.authState.user.usuarioRolModulos,
  };
};

export default connect(mapStatetoProps, { logout })(
  withStyles(useStyles)(LateralMenu)
);
