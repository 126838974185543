const defaultState = {
  createColonia: false,
  createColoniaError: "",
  colonia: {},
  delColonia: false,
  delColoniaError: "",
  getColoniaError: "",

  colonias: [],
  coloniaCatalog: [],
  totalList: 0,
};

export const coloniaReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_COLONIAS":
      return {
        ...state,
        colonias: action.payload.data,
        totalList: action.payload.totalRegs,
      };
    case "FETCH_COLONIA_CATALOG":
      return {
        ...state,
        coloniaCatalog: action.payload,
      };

    case "CREATE_COLONIA":
      return {
        ...state,
        colonia: { ...action.payload },
        createColonia: true,
      };
    case "CREATE_COLONIA_ERROR":
      return { ...state, createColoniaError: action.payload.error };
    case "UPDATE_COLONIA":
      return {
        ...state,
        colonia: { ...action.payload },
        updateColonia: true,
      };
    case "UPDATE_COLONIA_ERROR":
      return { ...state, updateColoniaError: action.payload.error };
    case "CLEAR_COLONIA":
      return {
        ...state,
        colonia: {},
        createColonia: false,
        createColoniaError: "",
        delColonia: false,
        delColoniaError: "",
        getColonia: false,
        getColoniaError: "",
        updateColonia: false,
        updateColoniaError: "",
      };
    case "DEL_COLONIA":
      return { ...state, delColonia: true };
    case "DEL_COLONIA_ERROR":
      return { ...state, delColoniaError: action.payload.error };
    case "GET_COLONIA":
      return {
        ...state,
        getColonia: true,
        colonia: { ...action.payload },
      };
    case "GET_COLONIA_ERROR":
      return { ...state, getColoniaError: action.payload.error };
    default:
      return {
        ...state,
      };
  }
};
