const defaultState = {
  datosPersonales: {},
  getDatosPersonales: false,
  getDatosPersonalesError: "",
  updateDatosPersonales: false,
  updateDatosPersonalesError: "",
};

export const datosPersonalesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "UPDATE_DATOS_PERSONALES":
      return {
        ...state,
        usuario: { ...action.payload },
        updateDatosPersonales: true,
      };
    case "UPDATE_DATOS_PERSONALES_ERROR":
      return { ...state, updateDatosPersonalesError: action.payload.error };
    case "CLEAR_DATOS_PERSONALES":
      return {
        ...state,
        usuario: {},
        getDatosPersonales: false,
        getDatosPersonalesError: "",
        updateDatosPersonales: false,
        updateDatosPersonalesError: "",
      };
    case "GET_DATOS_PERSONALES":
      return {
        ...state,
        getDatosPersonales: true,
        datosPersonales: { ...action.payload },
      };
    case "GET_DATOS_PERSONALES_ERROR":
      return { ...state, getDatosPersonalesError: action.payload.error };
    default:
      return {
        ...state,
      };
  }
};
