const defaultState = {
  misPosts: [],
  totalList: 0,
  fetchedList: false,
  // createMiPost: false,
  // createMiPostError: "",
  // updateMiPost: false,
  // updateMiPostError: "",
};

export const misPostReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_MIS_POSTS":
      return {
        ...state,
        misPosts: action.payload.data,
        totalList: action.payload.totalRegs,
        fetchedList: true,
      };

    //   case "CREATE_MI_POST":
    //     return {
    //       ...state,
    //       encuesta: { ...action.payload },
    //       createMiPost: true,
    //     };
    //   case "CREATE_MI_POST_ERROR":
    //     return { ...state, createMiPostError: action.payload.error };
    //   case "UPDATE_MI_POST":
    //     return {
    //       ...state,
    //       encuesta: { ...action.payload },
    //       updateMiPost: true,
    //     };
    //   case "UPDATE_MI_POST_ERROR":
    //     return { ...state, updateMiPostError: action.payload.error };
    case "CLEAR_MI_POST":
      return {
        ...state,
        misPosts: [],
        totalList: 0,
        fetchedList: false,
      };
    //   case "DEL_MI_POST":
    //     return { ...state, delMiPost: true };
    //   case "DEL_MI_POST_ERROR":
    //     return { ...state, delMiPostError: action.payload.error };

    default:
      return {
        ...state,
      };
  }
};
