import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Dialog,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import Transition from "../../tools/transition";

import { connect } from "react-redux";
import {
  createAction,
  clearAction,
  updateAction,
} from "../../../actions/action-template";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
  formButton: {
    marginRight: "10px",
  },
  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
});

class RolDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      isOpenDialog: true,

      nombre: "",
      id: "",
      modulos: [],
    };
  }

  showError = (value) => {
    return this.state.submitted && !value ? true : false;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    const { nombre } = this.state;
    if (nombre) {
      this.setState({ submitted: true });

      if (!this.state.id) {
        trackPromise(
          this.props.createAction("postRol", {
            nombre,
            modulos: this.state.modulos,
          }),
          () => {
            this.setState({ submitted: false });
          }
        );
      } else {
        trackPromise(
          this.props.updateAction("updateRol", this.state.id, {
            id: this.state.id,
            nombre,
            modulos: this.state.modulos,
          }),
          () => {
            this.setState({ submitted: false });
          }
        );
      }
    } else {
      this.props.enqueueSnackbar("Debe ingresar los campos marcados con *", {
        variant: "warning",
      });
    }
  };

  handleCheckChange = (event) => {
    let modulos = [...this.state.modulos];
    var moduloSelected = modulos.find(
      (e) => e.moduloId === parseInt(event.target.name)
    );
    moduloSelected.tieneAcceso = event.target.checked;

    this.setState({
      modulos: modulos,
    });
  };

  configurarModulos = (modulos) => {
    const { moduloCatalog } = this.props;
    let cat = moduloCatalog.map((e) => {
      let m = modulos.find(
        (mod) => parseInt(mod.moduloId) === parseInt(e.value)
      );
      return {
        rolId: this.state.id,
        moduloId: parseInt(e.value),
        nombre: e.name,
        tieneAcceso: m ? m.tieneAcceso : false,
      };
    });
    this.setState({ modulos: cat });
  };

  static getDerivedStateFromProps(props, state) {
    const { moduloCatalog } = props;

    if (moduloCatalog.length > 0 && state.modulos.length === 0) {
      let cat = moduloCatalog.map((e) => {
        return {
          rolId: 0,
          moduloId: parseInt(e.value),
          nombre: e.name,
          tieneAcceso: false,
        };
      });
      return { modulos: cat };
    }
    return null;
  }

  componentDidUpdate() {
    const {
      createRol,
      createRolError,
      rol,
      getRol,
      getRolError,
      updateRolError,
      updateRol,
    } = this.props;

    if (createRolError) {
      this.props.enqueueSnackbar(createRolError, {
        variant: "error",
      });
      this.props.clearAction("clearRol");
      this.setState({ submitted: false });
    } else if (createRol) {
      this.props.clearAction("clearRol");

      this.setState(
        {
          isOpenDialog: false,
        },
        () => {
          this.props.resultOp(rol);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (updateRolError) {
      this.props.enqueueSnackbar(updateRolError, {
        variant: "error",
      });
      this.props.clearAction("clearRol");
      this.setState({ submitted: false });
    } else if (updateRol) {
      this.props.clearAction("clearRol");

      this.setState(
        {
          isOpenDialog: false,
        },
        () => {
          this.props.resultOp(rol);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (getRolError) {
      this.props.enqueueSnackbar(getRolError, {
        variant: "error",
      });
      this.props.clearAction("clearRol");
    } else if (getRol) {
      this.props.clearAction("clearRol");

      this.setState(
        {
          id: rol.id,
          nombre: rol.nombre,
        },
        () => {
          this.configurarModulos(rol.modulos);
        }
      );
    }
  }

  renderModulos() {
    const { classes } = this.props;
    return this.state.modulos.map((m) => {
      return (
        <Grid item xs={6} key={m.moduloId}>
          <FormControlLabel
            className={classes.formControl}
            control={<Switch checked={m.tieneAcceso} />}
            label={m.nombre}
            onChange={this.handleCheckChange}
            name={m.moduloId.toString()}
          />
        </Grid>
      );
    });
  }

  renderContent() {
    const { nombre } = this.state;
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.formControl}
            error={this.showError(nombre)}
            required
            label="Nombre"
            name="nombre"
            autoFocus
            value={nombre}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={2}>
            <Typography variant="caption">Permisos</Typography>
            <Grid container>{this.renderModulos()}</Grid>
          </Box>
        </Grid>

        {isBrowser && (
          <Grid item xs={12}>
            <Box align="right" pt={4}>
              <Button
                className={classes.formButton}
                variant="contained"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
              >
                cancelar
              </Button>

              <Button
                disabled={this.state.submitted}
                className={classes.formButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.handleSubmit();
                }}
              >
                Guardar
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  }

  renderBrowser() {
    return (
      <Box p={10}>
        <Typography variant="h4">Detalle Rol</Typography>
        {this.renderContent()}
      </Box>
    );
  }

  renderMobile() {
    const { classes } = this.props;
    return (
      <Box>
        <Dialog
          fullScreen
          open={this.state.isOpenDialog}
          onClose={() => this.setState({ isOpenDialog: false })}
          TransitionComponent={Transition}
          scroll="body"
          style={{ paddingTop: 40 }}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Detalle Rol
              </Typography>
            </Toolbar>
          </AppBar>
          <Box py={3} px={1}>
            {this.renderContent()}
            <div style={{ marginBottom: 100 }}></div>
          </Box>
          <AppBar
            position="fixed"
            color="primary"
            className={classes.appBottomBar}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  cancelar
                </Typography>
              </IconButton>
              <div className={classes.grow} />

              <IconButton
                disabled={this.state.submitted}
                edge="end"
                color="inherit"
                onClick={() => {
                  this.handleSubmit();
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  Guardar
                </Typography>
              </IconButton>
            </Toolbar>
          </AppBar>
        </Dialog>
      </Box>
    );
  }

  render() {
    return (
      <Box>
        <BrowserView>{this.renderBrowser()}</BrowserView>
        <MobileView>{this.renderMobile()}</MobileView>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    createRol: state.rolState.createRol,
    createRolError: state.rolState.createRolError,
    rol: state.rolState.rol,
    getRol: state.rolState.getRol,
    getRolError: state.rolState.getRolError,

    updateRol: state.rolState.updateRol,
    updateRolError: state.rolState.updateRolError,

    moduloCatalog: state.moduloState.moduloCatalog,
  };
};

export default connect(mapStatetoProps, {
  createAction,
  clearAction,
  updateAction,
})(withStyles(useStyles)(withSnackbar(RolDetail)));
