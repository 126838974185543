import axios from "axios";

export const getGeoReference = async (dir) => {
  let _dir = "";
  if (dir.calle) {
    if (dir.numeroExterior) {
      //_dir = `street=${dir.calle} ${dir.numeroExterior}&`;
      _dir = `${dir.calle} ${dir.numeroExterior}, `;
    } else {
      //_dir = `street=${dir.calle}&`;
      _dir = `${dir.calle}, `;
    }
  }
  if (dir.colonia) {
    // _dir += `postalcode=${dir.codigoPostal} `;
    _dir += `${dir.colonia}, `;
  }
  if (dir.codigoPostal) {
    /// _dir += `postalcode=${dir.codigoPostal} `;
    _dir += `${dir.codigoPostal}, `;
  }

  _dir += `, mexico `;
  //let urlService =`https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&q=`
  let urlService =
    "https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&q=" +
    _dir;

  const promise = await axios.get(urlService);

  let reference = { latitud: "", longitud: "", geoReferenciado: false };

  if (!promise.data.error && promise.data.length > 0) {
    reference = {
      latitud: promise.data[0].lat,
      longitud: promise.data[0].lon,
      geoReferenciado: true,
    };
  }
  return new Promise((resolve, reject) => {
    resolve(reference);
  });
};

export default getGeoReference;
