import React from "react";
import { Box, Typography } from "@material-ui/core";

class TotalView extends React.Component {
  render() {
    const { valor } = this.props;
    return (
      <Box align="center" alignItems="center">
        <Box position="relative" display="inline-flex">
          <Box style={{ height: this.props?.size ?? 80 }}></Box>
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography style={{ fontSize: "14pt" }}>{valor}</Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="caption">{this.props.titulo}</Typography>
        </Box>
      </Box>
    );
  }
}

export default TotalView;
