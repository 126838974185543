import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Dialog,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import Transition from "../../tools/transition";
import {
  createAction,
  clearAction,
  updateAction,
} from "../../../actions/action-template";
import { connect } from "react-redux";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
  formButton: {
    marginRight: "10px",
  },
  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
});

class EntidadDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      isOpenDialog: true,

      id: "",
      nombre: "",
    };
  }

  showError = (value) => {
    return this.state.submitted && !value ? true : false;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    this.setState({ submitted: true });
    const { nombre } = this.state;
    if (nombre) {
      if (!this.state.id) {
        this.props.createAction("postEntidad", {
          nombre,
        });
      } else {
        this.props.updateAction("updateEntidad", this.state.id, {
          id: this.state.id,
          nombre,
        });
      }
    } else {
      this.props.enqueueSnackbar("Debe ingresar los campos marcados con *", {
        variant: "warning",
      });
    }
  };

  componentDidUpdate() {
    const {
      createEntidad,
      createEntidadError,
      entidad,
      getEntidad,
      getEntidadError,
      updateEntidadError,
      updateEntidad,
    } = this.props;

    if (createEntidadError) {
      this.props.enqueueSnackbar(createEntidadError, {
        variant: "error",
      });
      this.props.clearAction("clearEntidad");
    } else if (createEntidad) {
      this.props.clearAction("clearEntidad");

      this.setState(
        {
          isOpenDialog: false,
        },
        () => {
          this.props.resultOp(entidad);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (updateEntidadError) {
      this.props.enqueueSnackbar(updateEntidadError, {
        variant: "error",
      });
      this.props.clearAction("clearEntidad");
    } else if (updateEntidad) {
      this.props.clearAction("clearEntidad");

      this.setState(
        {
          isOpenDialog: false,
        },
        () => {
          this.props.resultOp(entidad);
          this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
            variant: "success",
          });
        }
      );
    }

    if (getEntidadError) {
      this.props.enqueueSnackbar(getEntidadError, {
        variant: "error",
      });
      this.props.clearAction("clearEntidad");
    } else if (getEntidad) {
      this.props.clearAction("clearEntidad");
      this.setState({
        id: entidad.id,
        nombre: entidad.nombre,
      });
    }
  }

  renderContent() {
    const { nombre } = this.state;
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item xs={12} md={6}>
          <TextField
            className={classes.formControl}
            error={this.showError(nombre)}
            required
            label="Nombre"
            name="nombre"
            autoFocus
            value={nombre}
            onChange={this.handleChange}
            inputProps={{ maxLength: 128 }}
          />
        </Grid>

        {isBrowser && (
          <Grid item xs={12}>
            <Box align="right" pt={4}>
              <Button
                className={classes.formButton}
                variant="contained"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
              >
                cancelar
              </Button>

              <Button
                className={classes.formButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.handleSubmit();
                }}
              >
                Guardar
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  }

  renderBrowser() {
    return (
      <Box p={10}>
        <Typography variant="h4">Detalle Entidad</Typography>
        {this.renderContent()}
      </Box>
    );
  }

  renderMobile() {
    const { classes } = this.props;
    return (
      <Box>
        <Dialog
          fullScreen
          open={this.state.isOpenDialog}
          onClose={() => this.setState({ isOpenDialog: false })}
          TransitionComponent={Transition}
          scroll="body"
          style={{ paddingTop: 40 }}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Detalle Entidad
              </Typography>
            </Toolbar>
          </AppBar>
          <Box py={3} px={1}>
            {this.renderContent()}
            <div style={{ marginBottom: 100 }}></div>
          </Box>
          <AppBar
            position="fixed"
            color="primary"
            className={classes.appBottomBar}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ isOpenDialog: false }, () => {
                    this.props.resultOp(null);
                  });
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  cancelar
                </Typography>
              </IconButton>
              <div className={classes.grow} />

              <IconButton
                edge="end"
                color="inherit"
                onClick={() => {
                  this.handleSubmit();
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  Guardar
                </Typography>
              </IconButton>
            </Toolbar>
          </AppBar>
        </Dialog>
      </Box>
    );
  }

  render() {
    return (
      <Box>
        <BrowserView>{this.renderBrowser()}</BrowserView>
        <MobileView>{this.renderMobile()}</MobileView>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    createEntidad: state.entidadState.createEntidad,
    createEntidadError: state.entidadState.createEntidadError,
    entidad: state.entidadState.entidad,
    getEntidad: state.entidadState.getEntidad,
    getEntidadError: state.entidadState.getEntidadError,

    updateEntidad: state.entidadState.updateEntidad,
    updateEntidadError: state.entidadState.updateEntidadError,
  };
};

export default connect(mapStatetoProps, {
  createAction,
  clearAction,
  updateAction,
})(withStyles(useStyles)(withSnackbar(EntidadDetail)));
