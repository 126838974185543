import React from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { MenuItem, Menu, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SettingsIcon from "@material-ui/icons/SettingsApplications";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import MenuColors from "../../theme/menu-colors";

import { logout } from "../../actions/auth/auth-actions";

import { isBrowser } from "react-device-detect";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Online } from "react-detect-offline";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);

class TopToolBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountMenu: null,
      printMenu: null,
    };
  }

  render() {
    const { loggedIn } = this.props;
    return (
      <div>
        <Online>
          {!loggedIn ? (
            <Button color="inherit" component={Link} to="/login">
              {isBrowser ? "Iniciar Sesión" : <ExitToAppIcon />}
            </Button>
          ) : (
            <div>
              <IconButton
                style={{ color: MenuColors.light }}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(event) => {
                  this.setState({ accountMenu: event.currentTarget });
                }}
              >
                <AccountCircleIcon />
              </IconButton>
              <StyledMenu
                id="simple-menu"
                anchorEl={this.state.accountMenu}
                keepMounted
                open={Boolean(this.state.accountMenu)}
                onClose={() => {
                  this.setState({ accountMenu: null });
                }}
              >
                <StyledMenuItem
                  component={Link}
                  to="/cuenta"
                  onClick={() => {
                    this.setState({ accountMenu: null });
                  }}
                >
                  <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Mis datos" />
                </StyledMenuItem>

                <StyledMenuItem
                  component={Link}
                  to="/mispreferencias"
                  onClick={() => {
                    this.setState({ accountMenu: null });
                  }}
                >
                  <ListItemIcon>
                    <LibraryAddCheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Mis Preferencias" />
                </StyledMenuItem>

                {/* <StyledMenuItem
                onClick={() => {
                  this.setState({ accountMenu: null });
                  this.props.logout();
                }}
              >
                <ListItemIcon>
                  <LogOutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Cerrar Sesión" />
              </StyledMenuItem> */}
              </StyledMenu>
            </div>
          )}
        </Online>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    username: state.authState.user.username,
    loggedIn: state.authState.loggedIn,
  };
};
export default connect(mapStateToProps, { logout })(TopToolBar);
