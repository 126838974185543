const defaultState = {
  preferencia: {},
  preferencias: [],
  totalList: 0,
  createPreferencia: false,
  createPreferenciaError: "",
  delPreferencia: false,
  delPreferenciaError: "",
  getPreferencia: false,
  getPreferenciaError: "",
  updatePreferencia: false,
  updatePreferenciaError: "",
};

export const preferenciaReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_PREFERENCIAS":
      return {
        ...state,
        preferencias: action.payload.data,
        totalList: action.payload.totalRegs,
      };

    case "CREATE_PREFERENCIA":
      return {
        ...state,
        preferencia: { ...action.payload },
        createPreferencia: true,
      };
    case "CREATE_PREFERENCIA_ERROR":
      return { ...state, createPreferenciaError: action.payload.error };
    case "UPDATE_PREFERENCIA":
      return {
        ...state,
        preferencia: { ...action.payload },
        updatePreferencia: true,
      };
    case "UPDATE_PREFERENCIA_ERROR":
      return { ...state, updatePreferenciaError: action.payload.error };
    case "CLEAR_PREFERENCIA":
      return {
        ...state,
        preferencia: {},
        createPreferencia: false,
        createPreferenciaError: "",
        delPreferencia: false,
        delPreferenciaError: "",
        getPreferencia: false,
        getPreferenciaError: "",
        updatePreferencia: false,
        updatePreferenciaError: "",
      };
    case "DEL_PREFERENCIA":
      return { ...state, delPreferencia: true };
    case "DEL_PREFERENCIA_ERROR":
      return { ...state, delPreferenciaError: action.payload.error };
    case "GET_PREFERENCIA":
      return {
        ...state,
        getPreferencia: true,
        preferencia: { ...action.payload },
      };
    case "GET_PREFERENCIA_ERROR":
      return { ...state, getPreferenciaError: action.payload.error };

    default:
      return {
        ...state,
      };
  }
};
