const defaultState = {
  createEncuestaResumen: false,
  createEncuestaResumenError: "",
  encuestaResumen: {},
  delEncuestaResumen: false,
  delEncuestaResumenError: "",
  getEncuestaResumenError: "",
  getEncuestaResumen: false,
  encuestasResumen: [],

  totalList: 0,
};

export const encuestaResumenReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_ENCUESTAS_RESUMEN":
      return {
        ...state,
        encuestasResumen: action.payload.data,
        totalList: action.payload.totalRegs,
      };

    case "CREATE_ENCUESTA_RESUMEN":
      return {
        ...state,
        encuestaResumen: { ...action.payload },
        createEncuestaResumen: true,
      };
    case "CREATE_ENCUESTA_RESUMEN_ERROR":
      return { ...state, createEncuestaResumenError: action.payload.error };
    case "UPDATE_ENCUESTA_RESUMEN":
      return {
        ...state,
        encuestaResumen: { ...action.payload },
        updateEncuestaResumen: true,
      };
    case "UPDATE_ENCUESTA_RESUMEN_ERROR":
      return { ...state, updateEncuestaResumenError: action.payload.error };
    case "CLEAR_ENCUESTA_RESUMEN":
      return {
        ...state,
        encuestaResumen: {},
        createEncuestaResumen: false,
        createEncuestaResumenError: "",
        delEncuestaResumen: false,
        delEncuestaResumenError: "",
        getEncuestaResumen: false,
        getEncuestaResumenError: "",
        updateEncuestaResumen: false,
        updateEncuestaResumenError: "",
      };
    case "DEL_ENCUESTA_RESUMEN":
      return { ...state, delEncuestaResumen: true };
    case "DEL_ENCUESTA_RESUMEN_ERROR":
      return { ...state, delEncuestaResumenError: action.payload.error };
    case "GET_ENCUESTA_RESUMEN":
      return {
        ...state,
        getEncuestaResumen: true,
        encuestaResumen: { ...action.payload },
      };
    case "GET_ENCUESTA_RESUMEN_ERROR":
      return { ...state, getEncuestaResumenError: action.payload.error };
    default:
      return {
        ...state,
      };
  }
};
