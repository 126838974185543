import React from "react";
import SeccionList from "./seccion-list";
import SeccionDetail from "./seccion-detail";

class Secciones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listVisible: true,
    };
  }

  showDetailItem = () => {
    this.setState({ listVisible: false });
  };

  resultOp = (o) => {
    this.setState({ listVisible: true });
  };

  render() {
    const { listVisible } = this.state;
    if (listVisible)
      return (
        <SeccionList
          showDetailItem={this.showDetailItem}
          updateItem={this.updateItem}
        ></SeccionList>
      );
    else return <SeccionDetail resultOp={this.resultOp}></SeccionDetail>;
  }
}

export default Secciones;
