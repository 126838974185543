import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";

import {
  Box,
  Grid,
  Typography,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  Paper,
  ButtonGroup,
  Button,
  TablePagination,
  TextField,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { connect } from "react-redux";
import ConfirmDialog from "../../tools/confirm-dialog";
import { withSnackbar } from "notistack";
import SearchIcon from "@material-ui/icons/Search";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import {
  getAllAction,
  deleteAction,
  clearAction,
  getAction,
  getCatalogAction,
} from "../../../actions/action-template";
import { trackPromise } from "react-promise-tracker";

const useStyles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
});

class RolList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 0,
      pageSize: 10,
      nombreFiltro: "",

      isDeleteDialogOpen: false,
      selectedIdForDelete: "",

      titleConfirm: "Desactivar Rol",
      textConfirm: "¿Realmente desea desactivar el Rol?",
    };

    this.fetchList();
    this.props.getCatalogAction("getModuloCatalogo");
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  fetchList() {
    const { pageNumber, pageSize, nombreFiltro } = this.state;
    let filtro = `nombre=${nombreFiltro}`;
    trackPromise(
      this.props.getAllAction("getAllRoles", pageNumber, pageSize, filtro)
    );
  }

  onDeleteDialogClose = (res) => {
    if (res) {
      this.props.deleteAction("deleteRol", this.state.selectedIdForDelete);
    }
    this.setState({ isDeleteDialogOpen: false, selectedIdForDelete: "" });
  };

  clearFilter = () => {
    this.setState(
      {
        nombreFiltro: "",
      },
      () => {
        this.fetchList();
      }
    );
  };

  confirmDelete = (r) => {
    let textConfirm = "";
    let titleConfirm = "";

    titleConfirm = "Elimnar Registro";
    textConfirm = "¿Realmente desea eliminar el registro?";

    this.setState({
      isDeleteDialogOpen: true,
      selectedIdForDelete: r.id,
      textConfirm: textConfirm,
      titleConfirm: titleConfirm,
    });
  };

  renderItems() {
    return this.props.roles.map((r) => {
      return (
        <TableRow key={r.id}>
          <TableCell>{r.nombre}</TableCell>

          <TableCell>
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button
                color="secondary"
                title={"Eliminar"}
                onClick={() => {
                  this.confirmDelete(r);
                }}
              >
                <CancelIcon></CancelIcon>
              </Button>
              <Button
                color="primary"
                title="Editar"
                onClick={() => {
                  this.props.getAction("getRol", r.id);
                  this.props.showDetailItem();
                }}
              >
                <EditIcon></EditIcon>
              </Button>
            </ButtonGroup>
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { classes } = this.props;
    const { nombreFiltro } = this.state;
    return (
      <Box mx={2} mb={4}>
        <Typography variant="h2" gutterBottom>
          Roles
        </Typography>
        <Paper
          style={{
            marginBottom: 20,
            padding: 20,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <TextField
                size="small"
                name="nombreFiltro"
                label="Nombre"
                value={nombreFiltro}
                onChange={this.handleChange}
                style={{ marginRight: 5 }}
              />
              <ButtonGroup
                color="primary"
                aria-label="Acciones"
                style={{ marginTop: 10, marginLeft: 10 }}
                size="small"
              >
                <Button
                  title="Filtrar"
                  color="primary"
                  onClick={() => {
                    this.fetchList();
                  }}
                >
                  <SearchIcon />
                </Button>
                <Button
                  title="Limpiar filtros"
                  color="primary"
                  onClick={this.clearFilter}
                >
                  <AutorenewIcon />
                </Button>

                <Button
                  title="Nuevo Rol"
                  color="primary"
                  onClick={this.props.showDetailItem}
                >
                  <AddIcon />
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Paper>
        <TableContainer component={Paper}>
          <Table
            size="small"
            className={classes.table}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>

                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{this.renderItems()}</TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={this.props.totalList}
            rowsPerPage={this.state.pageSize}
            page={this.state.pageNumber}
            onChangePage={(e, newPage) => {
              this.setState({ pageNumber: newPage }, () => {
                this.fetchList();
              });
            }}
            onChangeRowsPerPage={(e) => {
              this.setState({ pageSize: parseInt(e.target.value, 10) }, () => {
                this.fetchList();
              });
            }}
          />
        </TableContainer>

        <ConfirmDialog
          isDeleteDialogOpen={this.state.isDeleteDialogOpen}
          onDeleteDialogClose={this.onDeleteDialogClose}
          title={this.state.titleConfirm}
          message={this.state.textConfirm}
        ></ConfirmDialog>
      </Box>
    );
  }

  componentDidUpdate() {
    const { delRol, delRolError } = this.props;

    if (delRolError) {
      this.props.enqueueSnackbar(delRolError, {
        variant: "error",
      });
      this.props.clearAction("clearRol");
    } else if (delRol) {
      this.props.clearAction("clearRol");
      this.fetchList();
      this.props.enqueueSnackbar("Los datos se guardaron correctamente!", {
        variant: "success",
      });
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    roles: state.rolState.roles,
    totalList: state.rolState.totalList,

    delRol: state.rolState.delRol,
    delRolError: state.rolState.delRolError,
  };
};

export default connect(mapStatetoProps, {
  getAllAction,
  deleteAction,
  clearAction,
  getAction,
  getCatalogAction,
})(withStyles(useStyles)(withSnackbar(RolList)));
