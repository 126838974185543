const defaultState = {
  campaniaAvances: {},
};

export const avanceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_CAMPANIA_AVANCE":
      return {
        ...state,
        campaniaAvances: action.payload.data,
      };

    case "CLEAR_CAMPANIA_AVANCE":
      return {
        ...state,
        campaniaAvances: [],
      };
    default:
      return {
        ...state,
      };
  }
};
