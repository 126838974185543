const defaultState = {
  createReportar: false,
  createReportarError: "",
  reportar: {},
  delReportar: false,
  delReportarError: "",
  getReportarError: "",
  updateReportar: false,
  updateReportarError: "",
};

export const reportarReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "CREATE_REPORTAR":
      return {
        ...state,
        reportar: { ...action.payload },
        createReportar: true,
      };
    case "CREATE_REPORTAR_ERROR":
      return { ...state, createReportarError: action.payload.error };
    case "UPDATE_REPORTAR":
      return {
        ...state,
        reportar: { ...action.payload },
        updateReportar: true,
      };
    case "UPDATE_REPORTAR_ERROR":
      return { ...state, updateReportarError: action.payload.error };
    case "CLEAR_REPORTAR":
      return {
        ...state,
        reportar: {},
        createReportar: false,
        createReportarError: "",
        delReportar: false,
        delReportarError: "",
        getReportar: false,
        getReportarOffline: false,
        getReportarError: "",
        updateReportar: false,
        updateReportarError: "",
      };
    case "DEL_REPORTAR":
      return { ...state, delReportar: true };
    case "DEL_REPORTAR_ERROR":
      return { ...state, delReportarError: action.payload.error };
    case "GET_REPORTAR":
      return { ...state, getReportar: true, reportar: { ...action.payload } };
    case "GET_REPORTAR_ERROR":
      return { ...state, getReportarError: action.payload.error };
    default:
      return {
        ...state,
      };
  }
};
