import React from "react";
import ActivadoList from "./activado-list";
import ActivadoDetail from "./activado-detail";
import IndexedDb from "../../../db/indexed-db";
import { Detector } from "react-detect-offline";

let db = new IndexedDb("appdb");

class Activados extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listVisible: true,
      dbReady: false,
    };

    db.createObjectStore("activados").then((res) => {
      this.setState({ dbReady: true });
    });
  }

  showDetailItem = () => {
    this.setState({ listVisible: false });
  };

  resultOp = (o) => {
    this.setState({ listVisible: true });
  };

  render() {
    const { listVisible } = this.state;

    if (!this.state.dbReady) return <div></div>;
    if (listVisible)
      return (
        <Detector
          render={({ online }) => (
            <ActivadoList
              db={db}
              isOnline={online}
              showDetailItem={this.showDetailItem}
              updateItem={this.updateItem}
            ></ActivadoList>
          )}
        />
      );
    else
      return (
        <Detector
          render={({ online }) => (
            <ActivadoDetail
              db={db}
              isOnline={online}
              clienteId={this.props.clienteId}
              resultOp={this.resultOp}
            ></ActivadoDetail>
          )}
        />
      );
  }
}

export default Activados;
