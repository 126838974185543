import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Dialog,
  Typography,
  IconButton,
} from "@material-ui/core";
import Transition from "../tools/transition";
import EncuestaList from "./encuesta-list";
import EncuestaDetail from "./encuesta-detail";
import EncuestaResumen from "./encuesta-resumen";
import { withStyles } from "@material-ui/core/styles";

const useStyles = (theme) => ({
  formControl: {
    width: "95%",
    marginTop: theme.spacing(2),
  },
  formButton: {
    marginRight: "10px",
  },
  appBar: {
    top: 0,
    bottom: "auto",
  },
  appBottomBar: {
    top: "auto",
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
});

class Encuestas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listVisible: true,
      isOpenDialog: false,
      encuestaResumenId: "",
    };
  }

  showDetailItem = () => {
    this.setState({ listVisible: false });
  };
  hideResumenItem = () => {
    this.setState({ isOpenDialog: false });
  };

  showResumenItem = (id) => {
    this.setState({ isOpenDialog: true, encuestaResumenId: id });
  };

  resultOp = (o) => {
    this.setState({ listVisible: true });
  };
  resultRes = (o) => {
    this.setState({ resumenVisisible: false });
  };
  renderResumen() {
    const { classes } = this.props;
    //return
    return (
      <Box>
        <Dialog
          fullScreen
          open={this.state.isOpenDialog}
          onClose={() => this.setState({ isOpenDialog: false })}
          TransitionComponent={Transition}
          scroll="body"
          style={{ paddingTop: 40 }}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6">Resumen Encuesta</Typography>
            </Toolbar>
          </AppBar>
          <Box py={3} px={1}>
            <EncuestaResumen
              hideResumenItem={this.hideResumenItem}
              encuestaId={this.state.encuestaResumenId}
            ></EncuestaResumen>
            <div style={{ marginBottom: 100 }}></div>
          </Box>
          <AppBar
            position="fixed"
            color="primary"
            className={classes.appBottomBar}
          >
            <Toolbar>
              <div className={classes.grow} />

              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ isOpenDialog: false });
                }}
                aria-label="close"
              >
                <Typography variant="button" display="block" gutterBottom>
                  Cerrar
                </Typography>
              </IconButton>
            </Toolbar>
          </AppBar>
        </Dialog>
      </Box>
    );
  }

  render() {
    const { listVisible } = this.state;

    if (listVisible)
      return (
        <Box>
          <EncuestaList
            showDetailItem={this.showDetailItem}
            showResumenItem={this.showResumenItem}
            updateItem={this.updateItem}
          ></EncuestaList>
          {this.renderResumen()}
        </Box>
      );
    else return <EncuestaDetail resultOp={this.resultOp}></EncuestaDetail>;
  }
}

export default withStyles(useStyles)(Encuestas);
