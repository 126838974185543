import React from "react";
import EntidadList from "./entidad-list";
import EntidadDetail from "./entidad-detail";

class Entidades extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listVisible: true,
    };
  }

  showDetailItem = () => {
    this.setState({ listVisible: false });
  };

  resultOp = (o) => {
    this.setState({ listVisible: true });
  };

  render() {
    const { listVisible } = this.state;
    if (listVisible)
      return (
        <EntidadList
          showDetailItem={this.showDetailItem}
          updateItem={this.updateItem}
        ></EntidadList>
      );
    else return <EntidadDetail resultOp={this.resultOp}></EntidadDetail>;
  }
}

export default Entidades;
