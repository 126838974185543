const defaultState = {
  arbol: [],
  root: "",
};

export const arbolReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_ARBOL":
      return {
        ...state,
        arbol: action.payload.data.arbol,
        root: action.payload.data.root,
      };
    case "CLEAR_ARBOL":
      return {
        ...defaultState,
      };
    default:
      return {
        ...state,
      };
  }
};
