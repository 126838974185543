const defaultState = {
  post: {},
  posts: [],
  totalList: 0,
  createPost: false,
  createPostError: "",
  delPost: false,
  delPostError: "",
  getPost: false,
  getPostError: "",
  updatePost: false,
  updatePostError: "",
};

export const postReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_POSTS":
      return {
        ...state,
        posts: action.payload.data,
        totalList: action.payload.totalRegs,
      };

    case "CREATE_POST":
      return {
        ...state,
        post: { ...action.payload },
        createPost: true,
      };
    case "CREATE_POST_ERROR":
      return { ...state, createPostError: action.payload.error };
    case "UPDATE_POST":
      return {
        ...state,
        post: { ...action.payload },
        updatePost: true,
      };
    case "UPDATE_POST_ERROR":
      return { ...state, updatePostError: action.payload.error };
    case "CLEAR_POST":
      return {
        ...state,
        post: {},
        createPost: false,
        createPostError: "",
        delPost: false,
        delPostError: "",
        getPost: false,
        getPostError: "",
        updatePost: false,
        updatePostError: "",
      };
    case "DEL_POST":
      return { ...state, delPost: true };
    case "DEL_POST_ERROR":
      return { ...state, delPostError: action.payload.error };
    case "GET_POST":
      return { ...state, getPost: true, post: { ...action.payload } };
    case "GET_POST_ERROR":
      return { ...state, getPostError: action.payload.error };

    default:
      return {
        ...state,
      };
  }
};
